import React, { useState, useRef, Fragment} from "react";

import theme from "../ui/theme";
import { DialogCntnr as DialogCntnrBase } from "../ui/Modal";
import Checkbox from "../ui/Checkbox";
import BaseButton from "../ui/Button";

import styled from "styled-components";
import { Dialog as DialogHeadless, Transition } from "@headlessui/react";
import CloseIcon from '@mui/icons-material/Close';

const DialogCntnr = styled(DialogCntnrBase)`
  .modal-header {
    margin-top: ${theme.small};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .modal-body-CG {
    overflow-y: scroll;
    max-height: 40vh;
    padding: ${theme.sall};
    border: 1px solid ${theme.grey4};
    ul {
      padding: ${theme.thin};
      .itemCG {
        list-style: none;
        h2 {
          text-transform: uppercase;
          span {
            text-transform: initial;
          }
        }
        span {
          margin-right: ${theme.small};
        }
        ul,
        ol {
          padding-left: ${theme.small};
          margin-bottom: ${theme.sall};
        }
        li {
          list-style: none;
        }
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${theme.small};
    label {
      width: 100%;
    }
  }
`;

const Button = styled(BaseButton)`
  margin-top: ${theme.thin};
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const CguDoItem = styled.li`
  font-weight: ${props => props.itemValid ? "normal" : "bold"};
  color: ${props => props.itemValid ? theme.text : theme.red};
`;

const ModalCGU = props => {
  const scrollElem = useRef(null);
  const btnRef = useRef(null);

  const [isBottom, setIsBottom] = useState(false);
  const [check, setCheck] = useState(false);

  const handleScroll = () => {
    const winScroll = scrollElem.current.scrollTop;
    const height = scrollElem.current.scrollHeight - scrollElem.current.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled >= 0.95) {
      setIsBottom(true);
    }
  };

  const handleCguAccepted = () => {
    const localized = new Date().toISOString();
    props.onSubmit(localized);
  };

  const handleCguDisaccepted = () => {
    const localized = null;
    setCheck(false);
    props.onSubmit(localized);
  };

  const onCheckChange = () => {
    setCheck(!check);
  };

  return (
    <Transition appear show={props.show} as={Fragment}>
      <DialogCntnr className="dialog" initialFocus={btnRef}  onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="overlay-enter"
          enterFrom="overlay-enter-from"
          enterTo="overlay-enter-to"
          leave="overlay-leave"
          leaveFrom="overlay-leave-from"
          leaveTo="overlay-leave-to"
        >
          <DialogHeadless.Overlay className="dialog-overlay" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="content-enter"
          enterFrom="content-enter-from"
          enterTo="content-enter-to"
          leave="content-leave"
          leaveFrom="content-leave-from"
          leaveTo="content-leave-to"
        >
          <div className="dialog-content">
            <div className="dialog-content-header">
              <DialogHeadless.Title>Conditions générales d'utilisation</DialogHeadless.Title>
              <button className="dialog-content-header-close" ref={btnRef} type="button" onClick={props.onClose}>
                <CloseIcon />
              </button>
            </div>

            <div className="modal-body">
              <p style={{marginBottom: 0}}>Pour pouvoir valider les CGU vous devez:</p>
              <ul>
                <CguDoItem itemValid={isBottom}>consulter les CGU en les faisant défiler dans leur intrégralité</CguDoItem>
              </ul>
              <div ref={scrollElem} className="modal-body-CG" onScroll={() => handleScroll()}>
                <ul>
                  <li className="itemCG">
                    <h2><span>Article 1</span>Présentation générale du logiciel</h2>
                    <p>
                      Le Logiciel propose à l’Utilisateur de réaliser et transmettre des bilans, via un Terminal.
                    </p>
                    <p>
                      La Plateforme permet à l’utilisateur de visualiser, modifier parties du bilan et envoyer
                      une nouvelle version sur le serveur et sur le Logiciel.
                    </p>
                    <p>
                      Lors d’une intervention auprès d’un Patient, l’Utilisateur entre au sein du Logiciel
                      l’ensemble des informations nécessaires à sa prise en charge par les professionnels
                      de santé, dans le cadre de la chaîne de soin selon le processus suivant&thinsp;:
                    </p>
                    <ol>
                      <li><span>1</span>Arrivée des secours sur les lieux - Bilan effectué sur le Terminal grâce au Logiciel</li>
                      <li><span>2</span>Le chef d’agrès décide d’envoyer son bilan au SAMU grâce au Logiciel</li>
                      <li><span>3</span>Régulation par le SAMU sur la Plateforme. Envoie d’informations au Logiciel</li>
                    </ol>
                    <p>
                      L’Utilisateur a accès aux services décrits sur la Plateforme, sous une forme et
                      selon les fonctionnalités et moyens techniques que Syopé juge les plus appropriés.
                    </p>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 2</span>Création d'un compte utilisateur</h2>
                    <h3>2.1 Processus de création d’un Compte Utilisateur</h3>
                    <p>
                      Avant la date de livraison, Syopé créé le profil du Client sur la Plateforme et
                      créé le premier Compte Utilisateur lié au Client.
                    </p>
                    <p>
                      Par suite, les autres Comptes Utilisateurs sont&thinsp;:
                    </p>
                    <ul>
                      <li>
                        <span>-</span>Soit créés par Syopé après transmission par le responsable du Client d’un fichier
                        formaté permettant la création des Comptes Utilisateurs&thinsp;;
                      </li>
                      <li>
                        <span>-</span>Soit créés par le premier Utilisateur auquel il est attribué le droit de créer les autres Comptes Utilisateurs
                      </li>
                    </ul>
                    <p>
                      Dans tous les cas, le responsable du Client ou le premier Utilisateur est seul responsable de
                      la désignation des Utilisateurs autorisés à accéder à la Plateforme, de l’orthographe de leur nom,
                      de leur rôle et de leurs droits d’accès à la Plateforme.
                    </p>
                    <h3>2.2 Acceptation des CGU</h3>
                    <p>
                      L’accès et l’utilisation du Logiciel supposent la création d’un Compte Utilisateur.
                      Lors sa première connexion, l’Utilisateur doit préalablement lire les présentes CGU et
                      les accepter sans réserve en cochant la mention &laquo;&thinsp;En cochant cette case, j’accepte et
                      je reconnais avoir pris connaissance des Conditions Générales d’Utilisation&thinsp;&raquo;.
                    </p>
                    <p>
                      Toute modification des CGU est portée à la connaissance de l’Utilisateur par email à l’adresse renseignée
                      lors de la création de son Compte Utilisateur. Pour poursuivre l’utilisation du Logiciel et des autres services
                      souscrits, ce dernier s’engage à accepter la version actualisée des CGU en cochant la mention «&thinsp;En cochant cette case,
                      j’accepte et je reconnais avoir pris connaissance des Conditions Générales d’Utilisation&thinsp;».
                    </p>
                    <h3>2.3 Utilisation du Compte Utilisateur</h3>
                    <p>
                      Chaque Compte Utilisateur est nominatif et le Client doit être en mesure de savoir, pour les comptes génériques,
                      quel Utilisateur a eu accès au Logiciel. Dans le cas de compte générique, Syopé décline toute responsabilité de
                      non-traçabilité de la donnée et l’acceptation des CGU par le Client vaut
                      acceptation des CGU par les Utilisateurs du compte générique.
                    </p>
                    <p>
                      L’Utilisateur garantit que les informations qu’il fournit sont exactes, à jour et complètes concernant son
                      identité et ses coordonnées. Il s’engage notamment à être titulaire de l’adresse électronique
                      7communiquée dans le cadre de l’inscription, que celle-ci soit valide et à usage professionnel et qu’il la
                      consulte régulièrement. L’Utilisateur s’engage également à mettre à jour les informations
                      communiquées sur son Compte Utilisateur, dès que l’une d’entre elles est modifiée et ce, afin de
                      maintenir l’exactitude des informations.
                    </p>
                    <p>
                      L’Utilisateur s’engage à ce que son Compte Utilisateur ne soit pas utilisé par un tiers directement ou indirectement,
                      notamment en gardant les informations relatives à son identifiant et à son mot de passe personnelles et confidentielles.
                    </p>
                    <p>
                      L’Utilisateur reconnaît que Syopé se réserve le droit de suspendre ou de lui retirer la possibilité d’accéder au Logiciel,
                      dès lors qu’il ne respecterait pas les modalités d’inscription et particulièrement s’il fournit des données fausses, inexactes,
                      incomplètes ou non mises à jour ou si le Compte Utilisateur est utilisé par un tiers.
                    </p>
                    <p>
                      En aucun cas, la responsabilité de Syopé ne peut être engagée en cas d’erreur, d’omission ou d’imprécision dans les informations
                      communiquées par l’Utilisateur sous sa seule responsabilité.
                    </p>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 3</span>Accès à la plateforme</h2>
                    <h3>3.1 Conditions d’accès</h3>
                    <p>Pour accéder à Plateforme, l’Utilisateur doit disposer&thinsp;:</p>
                    <ul>
                      <li><span>-</span>d’un accès internet, et</li>
                      <li><span>-</span>d’un Compte Utilisateur.</li>
                    </ul>
                    <p>
                      L’Utilisateur est informé et accepte que l’Utilisation du Logiciel nécessite d’être connecté à Internet et
                      la qualité de l’accès à la Plateforme dépend directement de cette connexion, dont il, ou sa société,
                      est responsable. Les frais liés à la connexion à la Plateforme facturés par les fournisseurs d’accès ou opérateurs
                      téléphoniques restent à la charge exclusive de l’Utilisateur ou sa société.
                    </p>
                    <p>
                      Le Service Syopé est accessible sans interruption, sous réserve des aléas liés à la disponibilité du réseau Internet
                      choisi par l’Utilisateur. L’Utilisateur est en outre informé que le Service Syopé peut être temporairement indisponible
                      pour maintenance ou réparation.
                    </p>
                    <h3>3.2 Limitation d’accès</h3>
                    <p>
                      Chaque Utilisateur a un statut hiérarchique fonction de son rôle au sein du Client. Les droits de l’Utilisateur
                      d’accéder aux différentes fonctions du Logiciel dépend de son statut hiérarchique.
                    </p>
                    <p>
                      L’accès au Compte Utilisateur est limité à un (1) Terminal en simultané.
                    </p>
                    <p>
                      Pour chaque Compte Utilisateur, l’utilisation du Logiciel est limitée à un (1) Terminal. Si Syopé constate une
                      utilisation du Logiciel via des identifiants correspondants à plus d’un (1) Terminal, l’utilisation du Logiciel
                      ou de la Plateforme sera considérée comme anormale. En cas d’utilisation anormale du Logiciel, Syopé se réserve le droit
                      de facturer à l’unité chaque poste supplémentaire au tarif en vigueur.
                    </p>
                    <p>
                      Syopé se réserve le droit, en cas de force majeure, de décision judiciaire ou administrative, sans préavis ni indemnité,
                      de fermer temporairement l'accès au Logiciel et n’est pas responsable des dommages de toute nature pouvant survenir de ce fait.
                    </p>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 4</span>Obligations de l'utilisateur</h2>
                    <h3>4.1 Obligations</h3>
                    <p>
                      L’Utilisateur est seul responsable de l’utilisation qui est faite de son Compte Utilisateur. Il s’engage à informer
                      immédiatement Syopé de toute utilisation qu’il n’aurait pas autorisée et de toute atteinte à la confidentialité ou
                      sécurité de ses données d’identification, par e-mail à l’adresse électronique suivante&thinsp;: contact@syope.fr
                    </p>
                    <p>
                      L’Utilisateur s’engage, dans son usage du Logiciel et de la Plateforme, à respecter les lois et règlements en vigueur
                      et à ne pas porter atteinte aux droits de tiers ou à l’ordre public. Le Logiciel
                      permet aux Utilisateurs d’exporter une fiche bilan sous format électronique. Une fois la fiche bilan
                      exportée, l’Utilisateur est seul responsable de la préservation de la sécurité des données qu’elle
                      contient. Syopé décline toute responsabilité à ce titre, notamment en cas de transmission de la fiche
                      bilan exportée à un tiers non autorisé.
                    </p>
                    <p>
                      L’Utilisateur s’engage à changer son mot de passe de manière tri annuelle afin de réduire les risques de connexion
                      par une tiers personne à son compte.
                    </p>
                    <p>
                      L’Utilisateur reconnaît avoir pris connaissance des caractéristiques et contraintes, notamment techniques, du Logiciel.
                      Il est seul responsable de son utilisation du Logiciel.
                    </p>
                    <p>
                      L’Utilisateur s’engage à faire un usage strictement nominatif et professionnel du Logiciel et de la Plateforme.
                      Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations
                      au titre des présentes à un tiers, de quelque manière que ce soit.
                    </p>
                    <p>
                      L’Utilisateur doit prendre les mesures nécessaires pour renseigner les informations nécessaires
                      (données des Patients, identité du chef d’agrès, etc.) sur le Logiciel et les sauvegarder par ses propres moyens.
                      L’Utilisateur de la Plateforme est pleinement responsable des informations renseignées par le biais de son compte
                      (Centre hospitalier de destination, numéro de dossier, ...).
                    </p>
                    <h3>4.2 Comportements prohibés</h3>
                    <p>Il est strictement interdit d’utiliser le Logiciel aux fins suivantes&thinsp;:</p>
                    <ul>
                      <li>
                        <span>-</span>l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,
                      </li>
                      <li>
                        <span>-</span>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,
                      </li>
                      <li>
                        <span>-</span>l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire,
                        contrôler, interférer, ou intercepter tout ou partie du système informatique d’un tiers,
                        en violer l’intégrité ou la sécurité,
                      </li>
                      <li>
                        <span>-</span>l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit,
                        à un ou plusieurs des actes et activités décrits ci-dessus,
                      </li>
                      <li>
                        <span>-</span>et plus généralement toute pratique détournant le Logiciel à des fins autres que celles pour
                        lesquelles ils ont été conçus.
                      </li>
                    </ul>
                    <p>
                      Sont également strictement interdits&thinsp;: (i) tous comportements de nature à interrompre, suspendre, ralentir ou empêcher
                      le fonctionnement du Logiciel, (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de Syopé,
                      (iii) tous détournements des ressources système du Logiciel, (iv) toutes actions de nature à imposer une charge
                      disproportionnée sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification,
                      (vi) tous actes de nature à porter atteinte aux droits et intérêts de Syopé, et enfin plus généralement (vii)
                      tout manquement aux présentes conditions générales.
                    </p>
                    <h3>4.3 Sanctions</h3>
                    <p>
                      En cas de manquement à l’une quelconque des dispositions des présentes conditions générales ou plus généralement,
                      d’infraction aux lois et règlements en vigueur par un Utilisateur, Syopé se réserve le droit de prendre toute mesure
                      appropriée et notamment de&thinsp;:
                    </p>
                    <ul>
                      <li>
                        <span>(i)</span>suspendre ou résilier l’accès à la Plateforme de l’Utilisateur, auteur du manquement ou
                        de l’infraction, ou y ayant participé,
                      </li>
                      <li><span>(ii)</span>avertir toute autorité concernée,</li>
                      <li><span>(iii)</span>engager toute action judiciaire.</li>
                    </ul>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 5</span>Service client et support technique</h2>
                    <p>
                      Pour toutes les questions liées à un problème d’utilisation du Logiciel, l’Utilisateur est invité à contacter Syopé
                      à l’adresse email suivante&thinsp;: contact@syope.fr.
                    </p>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 6</span>Données personnelles</h2>
                    <p>
                      Dans le cadre de la fourniture du Logiciel proposé et plus généralement de son utilisation, Syopé collecte
                      des données à caractère personnel du Client, des Utilisateurs et des Patients (ci-après les &laquo;&thinsp;Données Personnelles&thinsp;&raquo;)
                      strictement nécessaires à l’exécution du Contrat telles que&thinsp;: nom, prénom, genre, mensurations, groupe sanguin,
                      poids, taille, date de naissance, adresse, historique des maladies, hospitalisations, traitements et allergies.
                    </p>
                    <p>
                      Syopé s’engage à collecter et traiter les Données Personnelles dans le respect de la Loi n°78-17 du 6 janvier 1978
                      modifiée dite &laquo;&thinsp;Informatique et Libertés&thinsp;&raquo;, du Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection
                      des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation
                      de ces données dit &laquo;&thinsp;RGPD&thinsp;&raquo; (ci-après dénommés ensemble la &laquo;&thinsp;Règlementation&thinsp;&raquo;)
                      et conformément à sa politique de confidentialité.
                    </p>
                    <p>
                      Les Données Personnelles sont susceptibles d’être transmises à des sous-traitants domiciliées dans l’Union Européenne,
                      notamment dans le cadre de leur hébergement, lorsque cela est strictement nécessaire à l’exécution du Contrat.
                      En conformité avec la Règlementation, les Données Personnelles collectées sont chiffrées en tout ou partie et,
                      au minimum, codées.
                    </p>
                    <p>
                      En toute hypothèse, Syopé garantit assurer un niveau de protection suffisant et approprié, notamment en encadrant
                      les transferts de ces Données Personnelles vers des pays ne présentant pas un niveau de protection adéquate,
                      par les clauses contractuelles types validées par la Commission européenne ou par des règles d’entreprise contraignantes
                      intragroupe, conformément à la Réglementation.
                    </p>
                    <p>
                      Syopé met en œuvre des mesures techniques, organisationnelles, logiques et physiques en matière de sécurité
                      afin de protéger les Données Personnelles de ses clients contre toute altération, perte, accès ou usage non autorisé
                      et diffusion.
                    </p>
                    <p>
                      Syopé conserve ces Données Personnelles pour la durée strictement nécessaire à la mise en œuvre de la relation
                      commerciale et plus généralement des finalités précitées. Au-delà de ce délai, Syopé s’engage à détruire
                      les données en sa possession, sauf stipulation contraire.
                    </p>
                    <p>
                      Les personnes concernées par les traitements de données disposent des droits suivants&thinsp;: droit d’accès,
                      de rectification, d’effacement, d’opposition, droit à la portabilité des Données Personnelles et
                      à la limitation des traitements ainsi qu’au choix du sort de ces mêmes données après la mort.
                      Ces droits peuvent être exercés en s’adressant à Syopé à l’adresse email suivante&thinsp;: contact@syope.fr.
                    </p>
                    <p>
                      Par ailleurs, et conformément à la Réglementation, toute personne dispose du droit de saisir la Commission Nationale
                      de l’Informatique et des Libertés
                      (dont les coordonnées figurent à l’adresse <a href="https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil" rel="noreferrer noopener">https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil</a>)
                      dans l’hypothèse où le Client n’aurait pas fait suite
                      à une requête dans un délai d’un mois suivant cette demande. Ces délais peuvent néanmoins être ajustés en fonction
                      de la complexité et du nombre de demandes à traiter.
                    </p>
                  </li>
                  <li className="itemCG">
                    <h2><span>Article 7</span>Juridiction compétente</h2>
                    <p>Les présentes CGU sont soumises au droit français.</p>
                    <p>
                      <strong>
                        Toute contestation relative à l’interprétation et/ou la conclusion et/ou l’exécution et/ou la résiliation du contrat ou
                        de la relation commerciale ou de ses suites est de la compétence exclusive du Tribunal de commerce de Rennes, et ce,
                        même en cas d’appel en garantie ou de pluralité de défendeurs.
                      </strong>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <Checkbox
                disabled={!isBottom}
                checked={check}
                onChange={onCheckChange}
                label={"En cochant cette case, j’accepte et je reconnais avoir pris connaissance des Conditions Générales d’Utilisation "}
              />
              {props.cguAccepted && check
                ? <Button type="button" className="button-close" onClick={props.onClose} >Fermer</Button>
                : <Button type="button" className="button-close" onClick={handleCguDisaccepted} >Fermer sans accepter</Button>
              }
              <Button type="button" disabled={!check} onClick={handleCguAccepted}>Accepter les CGU</Button>
            </div>

          </div>
        </Transition.Child>
      </DialogCntnr>
    </Transition>
  );
};

export default ModalCGU;
