import React, { useContext } from 'react';
import CurrentUserContext from '../CurrentUserContext';
import { SubMenuCntnr, SubMenuItem } from '../ui/SubMenu';
import { utils as syoUtils } from '@nfsave/syo-bilan';

export default function Layout({ children }) {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <SubMenuCntnr content={children}>
      <SubMenuItem to='/gestionnaire/organisation' activeClassName='active'>
        Organisation
      </SubMenuItem>
      <SubMenuItem to='/gestionnaire/unites-organisationnelles' activeClassName='active'>
        Unités organisationnelles
      </SubMenuItem>
      {syoUtils.hasPerm(currentUser, 'web:injections-and-protocols:manage') && (
        <>
          <SubMenuItem to='/gestionnaire/protocols' activeClassName='active'>
            Schémas de protocoles
          </SubMenuItem>
          <SubMenuItem to='/gestionnaire/injections' activeClassName='active'>
            Schémas d'injections
          </SubMenuItem>
          <SubMenuItem to='/gestionnaire/units' activeClassName='active'>
            Unités
          </SubMenuItem>
          <SubMenuItem to='/gestionnaire/drug-routes' activeClassName='active'>
            Voies d'administrations
          </SubMenuItem>
        </>
      )}
      {syoUtils.hasPerm(currentUser, 'web:bounds:manage') && (
        <SubMenuItem to='/gestionnaire/bounds' activeClassName='active'>
          Bornes
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'medications:manage') && (
        <SubMenuItem to='/gestionnaire/medicaments' activeClassName='active'>
          Médicaments
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'nursing-protocols:manage') && (
        <SubMenuItem to='/gestionnaire/pisu' activeClassName='active'>
          PISU
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'manage:intervention-motives') && (
        <SubMenuItem to='/gestionnaire/motifs' activeClassName='active'>
          Motifs d'intervention
        </SubMenuItem>
      )}
      {!syoUtils.hasPerm(currentUser, 'manage:roles:hide') && (
        <SubMenuItem to='/gestionnaire/roles' activeClassName='active'>
          Rôles utilisateurs
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'manage:vehicles') && (
        <SubMenuItem to='/gestionnaire/engins' activeClassName='active'>
          Engins
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'manage:relevant-facts') && (
        <SubMenuItem to='/gestionnaire/faits-remarquables' activeClassName='active'>
          Faits remarquables
        </SubMenuItem>
      )}
      {syoUtils.hasPerm(currentUser, 'manage:parameterized-lists') && (
        <SubMenuItem to='/gestionnaire/listes-parametrees' activeClassName='active'>
          Listes paramétrées
        </SubMenuItem>
      )}
    </SubMenuCntnr>
  );
}
