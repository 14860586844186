import React, { Fragment, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import theme from "./theme";
import { Dialog as DialogHeadless, Transition } from "@headlessui/react";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CloseIcon from '@mui/icons-material/Close';
import { RoundedButton } from "./Button";

const Dialog = styled(DialogHeadless)`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  .carousel {
    &-overlay {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
    }
    &-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;

      &-header {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 13;
        &-close {
          color: ${theme.text};
          border: none;
          background-color: ${theme.backgroundColor};
          border-radius: 0 0 0 ${theme.borderRadius};
          padding: ${theme.thin};
        }
        h2 {
          background-color: ${theme.backgroundColor};
          border-radius: 0 0 ${theme.borderRadius} 0;
          padding: ${theme.thin};
        }
      }
    }
  }

  // TRANSITION
  .overlay-enter {
    transition: opacity 0.3s ease-in-out;
    &-from {
      opacity: 0%;
    }
    &-to {
      opacity: 100%;
    }
  }
  .overlay-leave {
    transition: opacity 0.2s ease-in-out;
    &-from {
      opacity: 100%;
    }
    &-to {
      opacity: 0%;
    }
  }

  .content-enter {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    &-from {
      opacity: 0%;
      transform: scale(0.8) rotate(1deg);
    }
    &-to {
      opacity: 100%;
      transform: scale(1) rotate(0deg);
    }
  }
  .content-leave {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    &-from {
      opacity: 100%;
      transform: scale(1) rotate(0deg);
    }
    &-to {
      opacity: 0%;
      transform: scale(0.8) rotate(1deg);
    }
  }
`;

const Img = styled.img`
  position: absolute;
  max-height: 100vh;
  max-width: 100vw;
  transform: rotate(${props => `${props.rotate}deg`});

  &.rotated {
    max-height: 100vw;
    max-width: 100vh;
  }
`;

const Embed = styled.embed`
  max-height: 100vh;
  max-width: 100vw;
  width: 80%;
  height: 80%;
  z-index: 15;
`;

const ActionsBar = styled.div`
  position: absolute;
  margin: auto;
  bottom: ${theme.thin};
`;

const Carousel = ({imgs, idx, isOpen, toggleOpen, onNavigate = null}) => {
  const initialRef = useRef(null);

  const [ counter, setCounter ] = useState(null);
  const [ deg, setDeg ] = useState(0);

  useEffect(() => {
    if (isOpen) setCounter(idx);
  }, [ isOpen ]);

  function downHandler({ key }) {
    if (!isOpen) return;
    if (key === "ArrowRight") moveImg(1);
    if (key === "ArrowLeft") moveImg(-1);
    if (key === "ArrowUp") rotateImg(-90);
    if (key === "ArrowDown") rotateImg(90);
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [ isOpen, counter, deg ]);

  const moveImg = (idx) => {
    let cntr = null;
    if (idx === -1) {
      if (counter === 0) {
        cntr = imgs.length - 1;
      } else {
        cntr = counter - 1;
      };
    } else {
      if (counter === imgs.length - 1) {
        cntr = 0;
      } else {
        cntr = counter + 1;
      }
    };
    setCounter(cntr);
    setDeg(0);
    if (onNavigate) onNavigate(cntr);
  };

  const rotateImg = (d) => {
    if (d === -90) {
      if (deg === 0) {
        setDeg(270);
      } else {
        setDeg(deg - 90);
      };
    } else {
      if (deg === 270) {
        setDeg(0);
      } else {
        setDeg(deg + 90);
      }
    };
  };

  const handleClose = () => {
    setDeg(90);
    toggleOpen();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog className="carousel" initialFocus={initialRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="overlay-enter"
          enterFrom="overlay-enter-from"
          enterTo="overlay-enter-to"
          leave="overlay-leave"
          leaveFrom="overlay-leave-from"
          leaveTo="overlay-leave-to"
        >
          <DialogHeadless.Overlay className="carousel-overlay" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="content-enter"
          enterFrom="content-enter-from"
          enterTo="content-enter-to"
          leave="content-leave"
          leaveFrom="content-leave-from"
          leaveTo="content-leave-to"
        >
          <div className={`carousel-content`}>
            <div className="carousel-content-header">
              <DialogHeadless.Title>{imgs[counter]?.description}</DialogHeadless.Title>
              <button className="carousel-content-header-close" ref={initialRef} type="button" onClick={toggleOpen}>
                Fermer <CloseIcon />
              </button>
            </div>
            {imgs[counter]?.isPdf
              ? <Embed type="application/pdf" src={imgs[counter]?.public_url}></Embed>
              : <Img
                className={`${deg === 90 || deg === 270 ? "rotated" : ""}`}
                src={imgs[counter]?.public_url}
                rotate={deg}
              />
            }
            <Img
                className={`${deg === 90 || deg === 270 ? "rotated" : ""}`}
                src={imgs[counter]?.annotations_layer_public_url}
                rotate={deg}/>
            <ActionsBar>
              {imgs.length > 1 && <RoundedButton onClick={() => moveImg(-1)}><NavigateBeforeIcon /></RoundedButton>}
              <RoundedButton disabled={imgs[counter]?.isPdf} onClick={() => rotateImg(-90)}><RotateLeftIcon /></RoundedButton>
              <RoundedButton disabled={imgs[counter]?.isPdf} onClick={() => rotateImg(90)}><RotateRightIcon /></RoundedButton>
              {imgs.length > 1 && <RoundedButton onClick={() => moveImg(1)}><NavigateNextIcon /></RoundedButton>}
            </ActionsBar>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Carousel;
