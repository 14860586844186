import React, {useMemo, useState} from 'react';
import http from '../../http';
import {useQuery} from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import {isAbortError} from '../../utils';
import PageHeader, {Actions, Cntnr, Title} from '../../ui/PageHeader';
import {LinkButton} from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import styled from 'styled-components';
import theme from '../../ui/theme';
import {BtnTabs, Tabs, TabsCntnr} from "../../ui/Tabs";
import GroupIcon from "@mui/icons-material/Group";
import BoyIcon from '@mui/icons-material/Boy';
import ManIcon from '@mui/icons-material/Man';
import {alphabeticSort} from "../../interventions/utils";

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: 1px solid ${theme.grey6};
  border-radius: ${theme.borderRadius};
  padding: ${theme.thin};
  margin-bottom: ${theme.small};

  ul {
    margin-bottom: ${theme.small};
  }
`;

const ItemActions = styled.div`
  margin: 0 -${theme.thin} -${theme.thin};
  background: ${theme.grey6};
  padding: ${theme.small} ${theme.thin};
  text-align: right;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: ${theme.small};

  thead {
    margin-bottom: 0.5rem;
  }

  tr {
    border-bottom: 2px solid ${theme.blueBilan};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export default function Protocols() {
  const controller = new AbortController();
  const [tabs, setTabs] = useState("all");

  const {isLoading: protocolSchemasIsLoading, data: protocolSchemas} = useQuery(
    ['protocolSchemas'],
    async () => {
      return await http
        .get(`protocol_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return alphabeticSort(res.protocol_schemas, (value) => value.name);
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des schémas de protocoles');
          throw error;
        });
    },
    {cacheTime: 0}
  );

  const tabsBtnOptions = [
    {
      option: "all",
      length: protocolSchemas?.length ?? 0,
      text: "Tous",
      icon: <GroupIcon/>
    },
    {
      option: "adult",
      length: protocolSchemas?.filter(p => p.age_range !== "CHILD")?.length ?? 0,
      text: "Adulte",
      icon: <ManIcon/>
    },
    {
      option: "pedia",
      length: protocolSchemas?.filter(p => p.age_range === "CHILD")?.length ?? 0,
      text: "Pédiatrique",
      icon: <BoyIcon/>
    }
  ];

  const actualProtocols = useMemo(() => {
    switch (tabs) {
      case "all":
        return protocolSchemas;
      case "adult":
        return protocolSchemas.filter(p => p.age_range !== "CHILD");
      case "pedia":
        return protocolSchemas.filter(p => p.age_range === "CHILD");
      default:
        return protocolSchemas;
    }
  }, [tabs, protocolSchemas]);

  const handleTabsChange = (value) => {
    setTabs(value);
  };

  const getBolusInfos = (injection) => {
    const boliMax = (injection.boli_maxi === null || injection.boli_maxi === '')
    && (injection.quantity_maxi_including_boli === null || injection.quantity_maxi_including_boli === '')
      ? ''
      : injection.boli_maxi && injection.quantity_maxi_including_boli
      ? `(max. ${injection.boli_maxi} boli ou ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
      : injection.boli_maxi
        ? `(max. ${injection.boli_maxi} boli)`
        : `(max. ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
    return injection.can_boli ? `Bolus autorisé ${boliMax}` : 'Bolus interdit'
  };

  return (
    <>
      <PageHeader style={{'margin-bottom': '1rem'}}>
        <Title>Schémas de protocoles</Title>
        <Actions>
          <LinkButton to='/gestionnaire/protocols/edit'>Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      {protocolSchemasIsLoading ? (
        <LoadingSpinner className='center vh-50'/>
      ) : (
        <>
          <TabsCntnr>
            <Tabs breakpoint="780px">
              {tabsBtnOptions.map((btn, index) => (
                <BtnTabs
                  key={index}
                  onClick={() => handleTabsChange(btn.option)}
                  className={`tabButton icon-right ${tabs === btn.option ? "active" : ""}`}
                >{btn.text}{btn.option !== "all" && btn.length > 1 ? "s" : ""} {btn.length} {btn.icon}
                </BtnTabs>
              ))}
            </Tabs>
          </TabsCntnr>
          <Cntnr>
            <UnstyledList>
              {actualProtocols.map((protocol, idx) => (
                <ListItem key={idx}>
                  <h2>{protocol.name}</h2>
                  {protocol.comment !== null && protocol.comment !== "" && <h6>{protocol.comment}</h6>}
                  <Table style={{width: '90%', margin: '1rem auto'}}>
                    <thead>
                    <tr>
                      <th>Drogue</th>
                      <th>Posologie</th>
                      <th>Voie d'administration</th>
                      <th>Bolus</th>
                      <th>Commentaire</th>
                    </tr>
                    </thead>
                    {protocol.injection_schemas?.map((injection, idx) => (
                      <tr key={idx}>
                        <td>{injection?.drug}</td>
                        <td>{injection?.quantity_maxi
                          ? `${injection?.quantity} ${injection?.unit} (max. ${injection?.quantity_maxi} ${injection?.unit_numerator})`
                          : `${injection?.quantity} ${injection?.unit}`}</td>
                        <td>{injection?.drug_route_name}</td>
                        <td>{getBolusInfos(injection)}</td>
                        <td>{injection?.comment}</td>
                      </tr>
                    ))}
                  </Table>
                  <ItemActions>
                    <LinkButton className='filled'
                                to={{pathname: `/gestionnaire/protocols/edit`, query: {protocol_id: protocol?.id}}}>
                      Modifier
                    </LinkButton>
                  </ItemActions>
                </ListItem>
              ))}
            </UnstyledList>
          </Cntnr>
        </>
      )}
    </>
  );
}
