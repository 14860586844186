import {Link, useRouter} from "found";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import http from "../../http";
import * as Sentry from "@sentry/browser";
import LoaderBarContext from "../../ui/useLoaderBar";
import styled from "styled-components";

import KeyIcon from "@mui/icons-material/VpnKey";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import theme from "../../ui/theme";
import Input, { InputIcon } from "../../ui/Input";
import BaseFormGroup from "../../ui/FormGroup";
import BaseButton, { LinkButton } from "../../ui/Button";
import Label from "../../ui/Label";
import Steps from "../../ui/Steps";
import ModalCGU from "../ModalCGU";
import InputPassword from '../../ui/InputPassword';

const NarrowContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.small};
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    overflow-y: auto;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: ${theme.small} ${theme.small} 0;
    border-radius: ${theme.thin};

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .break-mobile {
      display: none;
    }
  }

  @media (max-width: 550px) {
    form {
      h2 {
        font-size: 1.3rem;
      }
    }
  }
  @media (max-width: 385px) {
    form {
      .break-mobile {
        display: inline;
      }
    }
  }
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const Button = styled(BaseButton)`
  margin-top: ${theme.thin};
  margin-bottom: ${theme.small};
  display: block;
  width: 100%;
`;

const FormGroup = styled(BaseFormGroup)`
  display: flex;
  flex-direction: row;
  padding: 0; // UPDATE PaddingFail
  margin-bottom: ${theme.small};
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const BlockLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: ${theme.small};
`;

const BlockButtonLink = styled(LinkButton)`
  display: block;
  width: 100%;
  text-align: center;
  margin: ${theme.medium} 0;
`;

const PswrdDoItem = styled.li`
  font-weight: ${props => props.itemValid ? "normal" : "bold"};
  color: ${props => props.itemValid ? theme.green : theme.text};
`;

function PhoneReset() {
  const { match, router } = useRouter();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [ otp, setOtp ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirmation, setConfirmation ] = useState("");
  const [ hasUppercase, setHasUppercase ] = useState(false);
  const [ hasLowercase, setHasLowercase ] = useState(false);
  const [ hasDigit, setHasDigit ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ cguAccepted, setCguAccepted ] = useState(null);
  const [ invalidToken, setInvalidToken ] = useState(false);

  const disableSubmit = useMemo(() => {
    if (loaderBarState) return true;
    return !(
      otp.length > 0
      && password.length >= 10
      && password === confirmation
      && hasUppercase === true
      && hasLowercase === true
      && hasDigit === true
      && cguAccepted !== null
    );
  }, [ loaderBarState, password, confirmation, hasUppercase, hasLowercase, hasDigit, cguAccepted, otp ]);

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (disableSubmit) return;
    setLoaderBar(true);
    const token = match.params.token;
    try {
      const payload = {
        otp: otp,
        password: password,
        password_confirmation: confirmation,
        cguAccepted: cguAccepted // string in ISO format
      };
      await http.put(`phone_reset/${encodeURIComponent(token)}`, { json: payload });
      router.push('/auth/sign-in');
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      toast.error(
        "Votre code de vérification de mot de passe semble expiré. Pour le renouveler, suivez la procédure \"Mot de passe oublié\", ou contactez l'assistance Syopé : assistance@syope.fr",
        {
          pauseOnHover: true,
          hideProgressBar: false,
        }
      );
      setInvalidToken(true);
    } finally {
      setLoaderBar(false);
    };
  };

  const handleCguSubmit = (localized) => {
    if (localized === undefined) localized = null;
    setCguAccepted(localized);
    setShowModal(false);
  };

  const onPasswordChange = (ev) => {
    const input = ev.target.value;
    setPassword(input);
    setHasUppercase(input.toLowerCase() !== input);
    setHasLowercase(input.toUpperCase() !== input);
    setHasDigit(/\d/.test(input));
  };

  const onConfirmationChange = (ev) => {
    setConfirmation(ev.target.value)
  };

  const onOtpChange = (ev) => {
    setOtp(ev.target.value)
  };

  const onModalDisplayChange = (state) => {
    setShowModal(state);
  };

  useEffect(() => {
    localStorage.clear();
    return () => {};
  }, []);

  return (
    <NarrowContainer>
      <form onSubmit={handleFormSubmit}>
        <FormTitle>Réinitialisation de <br className="break-mobile" />mot de passe</FormTitle>
        {invalidToken ? (
          <>
            <Fieldset>
              <h3>Votre code de réinitialisation de mot de passe semble avoir expiré.</h3>
              <BlockButtonLink to="/auth/phone-reset-resend">Recevoir un nouveau code de réinitialisation</BlockButtonLink>
              <p style={{fontSize: ".75rem"}}>Si vous rencontrez des difficultés dans la réinitialisation de votre mot de passe vous pouvez contacter l'assistance: assistance@syope.fr</p>
              <hr/>
              <BlockLink to="/auth/sign-in">
                Retour à l'écran de connexion
              </BlockLink>
            </Fieldset>
          </>
        ) : (
          <>
            <Steps step={1} />
            <p style={{marginBottom: 0}}>
              Saisissez le mot de passe que vous voulez définir pour votre compte dans les champs ci-dessous.<br/>
              Votre mot de passe doit contenir :
            </p>
            <ul>
              <PswrdDoItem itemValid={password.length >= 10}>au moins 10 caractères</PswrdDoItem>
              <PswrdDoItem itemValid={hasUppercase}>au moins une lettre majuscule</PswrdDoItem>
              <PswrdDoItem itemValid={hasLowercase}>au moins une lettre minuscule</PswrdDoItem>
              <PswrdDoItem itemValid={hasDigit}>au moins un chiffre</PswrdDoItem>
              <PswrdDoItem itemValid={confirmation.length > 0 && password === confirmation}>les mots de passe doivent correspondre</PswrdDoItem>
            </ul>
            <Fieldset disabled={loaderBarState}>
              <Label style={{fontWeight: "bold", marginTop: theme.small}} htmlFor="password-input">Code de vérification SMS</Label>
              <FormGroup>
                <Input id="otp-input" value={otp} onChange={onOtpChange} autocomplete="off" required/>
              </FormGroup>
              <Label style={{fontWeight: "bold"}} htmlFor="password-input">Nouveau mot de passe</Label>
              <FormGroup>
                <InputPassword
                  id="password-input"
                  value={password}
                  callback={onPasswordChange}
                  placeholder="Nouveau mot de passe"
                />
              </FormGroup>
              <Label style={{fontWeight: "bold"}} htmlFor="confirmation-input">Confirmation</Label>
              <FormGroup>
                <InputIcon className="grey">
                  <KeyIcon/>
                </InputIcon>
                <Input
                  id="confirmation-input"
                  type="password"
                  value={confirmation}
                  onChange={onConfirmationChange}
                  placeholder="Confirmer votre nouveau mot de passe"
                  required
                />
              </FormGroup>
              <Steps step={2} />
              <ModalCGU
                show={showModal}
                cguAccepted={cguAccepted}
                onClose={() => onModalDisplayChange(false)}
                onSubmit={handleCguSubmit}
              />
              <Button
                className={cguAccepted === null ? "warn" : "validated"}
                type="button" onClick={() => onModalDisplayChange(true)}
              >
                {cguAccepted === null ? <CheckBoxOutlineBlankIcon className="icon-left" fontSize="small" /> : <CheckBoxIcon className="icon-left" fontSize="small" /> }
                {cguAccepted === null ? "Lire et accepter les CGU" : "CGU acceptées"}
              </Button>
            <Steps step={3} />
              <Button disabled={disableSubmit}>Mettre à jour mon mot de passe</Button>
              <p style={{fontSize: ".75rem"}}>Si vous rencontrez des difficultés dans la réinitialisation de votre mot de passe vous pouvez contacter l'assistance: assistance@syope.fr</p>
              <hr/>
              <BlockLink to="/auth/sign-in">
                Retour à l'écran de connexion
              </BlockLink>
            </Fieldset>
          </>
        )}
      </form>
    </NarrowContainer>
  );
}

export default PhoneReset;
