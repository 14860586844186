import React, { Component } from "react";
import { withRouter } from "found";
import {goToTarget, setSignInUrl, setTimeoutSession} from "../../utils";

import styled from "styled-components";
import BaseButton from "../../ui/Button";
import http, { setAuthToken } from "../../http";
import CurrentUserContext from "../../CurrentUserContext";
import LogoName from "../../components/LogoName";

const API_ORIGIN =
  process.env.NODE_ENV === "production"
    ? window.location.protocol + "//" + window.location.host
    : "http://localhost:3000";

const Container = styled.div`
  max-width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-top: 15%;
    padding: 0 2rem 2rem;
    border-radius: 0.5rem;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

const LoginButton = styled(BaseButton)`
  margin: 0.5rem;
  margin-bottom: 1rem;
  max-height: none;
`;

class Parsys extends Component {
  static contextType = CurrentUserContext;

  handleMessage = async ev => {
    if (this.state.popup == null || ev.origin !== API_ORIGIN) {
      return;
    }
    const popup = this.state.popup;
    this.setState({
      popup: null
    });
    popup.close();
    setAuthToken({
      accessToken: ev.data["access-token"],
      expiry: ev.data.expiry,
      uid: ev.data.uid,
      client: ev.data.client,
      tokenType: "Bearer"
    });
    try {
      const user = await http.get("auth/validate_token").json();
      const orgResponse = await http.get("organizations/mine").json();
      const currentUser = { organization: orgResponse[0], ...user.data };

      this.context.setUser(currentUser);
      localStorage.setItem("nfCurrentUser", JSON.stringify(currentUser));
      const customDisconnect = currentUser.permissions.find(elem => elem.key.includes('automatic:disconnect:after:'));
      setTimeoutSession(customDisconnect);
      setSignInUrl(this.props.match.location.pathname);
      goToTarget(this.props.router);
    } catch (e) {
      console.error(e);
      this.setState({
        disableLoginButton: false
      });
    }
  };

  handleFormSubmit = ev => {
    ev.preventDefault();
  };

  handleLoginButtonClick = ev => {
    ev.preventDefault();
    this.setState({
      disableLoginButton: true,
      popup: window.open(
        `${API_ORIGIN}/omniauth/nfsave_aad_test2`,
        "_blank",
        "width=480,height=520"
      )
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      disableLoginButton: false,
      popup: null
    };
  }

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
    if (this.context.currentUser !== null) {
      localStorage.clear();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  render() {
    return (
      <Container>
        <form onSubmit={this.handleFormSubmit}>
          <LogoName />
          <LoginButton
            onClick={this.handleLoginButtonClick}
            disabled={this.state.disableLoginButton}
          >
            Se connecter avec Syopé AzureAD Test2
          </LoginButton>
        </form>
      </Container>
    );
  }
}

export default withRouter(Parsys);
