import React from "react";
import {
  LineChart,
  AreaChart,
  Line,
  Area,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  LabelList
} from "recharts";

import styled from "styled-components";
import theme from "../ui/theme";

const GraphCntnr = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : "250px"};
  overflow: hidden;

  &.legendClickable .recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
    cursor: pointer;
  }
`;

const Polyline = styled.polyline`
  stroke: ${theme.text}
`;

const colors = [
  "#377eb8",
  "#e03f3f",
  "#c9a600",
  "#bd00f3",
  "#45dc52",
  "#cd34b5",
  "#ffa991",
  "#ea5f94"
];

const handleLegendClick = (e, graph) => {
  if (!graph.setData) return;
  const temp = graph.data;
  const idx = e.payload.index;
  temp[idx].hide = !temp[idx].hide;
  graph.setData([...temp]);
};

const handleLegendEnter = (e, graph) => {
  if (!graph.setData) return;
  const temp = graph.data;
  const idx = e.payload.index;
  temp.map((elem, index) => index !== idx ? elem.opacity = 0.3 : elem.opacity = 1);
  graph.setData([...temp]);
};

const handleLegendLeave = (e, graph) => {
  if (!graph.setData) return;
  const temp = graph.data;
  temp.map(elem => elem.opacity = 1);
  graph.setData([...temp]);
};

export const LineChartView = ({graph, data}) => {
  const hasGraphSetter = graph.setData ? true : false;

  return (
    <GraphCntnr height={graph.height} className={hasGraphSetter ? "legendClickable" : ""}>
      <ResponsiveContainer
        width="100%" height="100%" debounce={1}
      >
        <LineChart
          syncId={graph.syncId}
          data={data}
          margin={{
            left: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={graph.domainY || ['auto', 'auto']} />
          <Tooltip isAnimationActive={false} />
          <Legend
            onClick={(e) => handleLegendClick(e, graph)}
            onMouseEnter={e => handleLegendEnter(e, graph)}
            onMouseLeave={(e) => handleLegendLeave(e, graph)}
          />
          {graph.data.map((elem, idx) => (
            <Line
              key={idx}
              type={elem.type || "linear"}
              index={idx}
              dataKey={elem.dataKey}
              stroke={colors[idx]}
              strokeWidth={elem.strokeWidth || 2}
              strokeOpacity={elem.opacity || 1}
              strokeDasharray={elem.strokeDasharray || null}
              hide={elem.hide || false}
              name={elem.name}
              unit={elem.unit}
              isAnimationActive={false}
              connectNulls
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </GraphCntnr>
  );
};

const paTopLabel = (props) => {
  const { x, y, width, height } = props;
  const arrowHeigth = 10;
  const refX = x + width / 2;
  const refY = y + height - arrowHeigth;
  return (
    <Polyline
      points={`${refX - 10},${refY} ${refX},${refY + arrowHeigth - 1} ${refX + 10},${refY}`}
      strokeWidth="3" fill="none"
    />
  );
};
const paBottomLabel = (props) => {
  const { x, y, width } = props;
  const arrowHeigth = 10;
  const refX = x + width / 2;
  const refY = y + arrowHeigth;
  return (
    <Polyline
      points={`${refX - 10},${refY} ${refX},${refY - arrowHeigth + 1} ${refX + 10},${refY}`}
      strokeWidth="3" fill="none"
    />
  );
};

/**
 * @param {Object} graph
 * @param {string} graph.graphType - 'circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye' | ReactElement | Function
 * @returns
 */
export const ComposedChartView = ({graph, data}) => {
  const hasGraphSetter = graph.setData ? true : false;

  return (
    <GraphCntnr height={graph.height} className={hasGraphSetter ? "legendClickable" : ""}>
      <ResponsiveContainer
        width="100%" height="100%" debounce={1}
      >
        <ComposedChart
          syncId={graph.syncId}
          data={data}
          margin={{
            left: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={graph.domainY || ['auto', 'auto']} />
          <Tooltip isAnimationActive={false} />
          <Legend
            onClick={(e) => handleLegendClick(e, graph)}
            onMouseEnter={e => handleLegendEnter(e, graph)}
            onMouseLeave={(e) => handleLegendLeave(e, graph)}
          />
          {graph.data.map((elem, idx) => {
            switch (elem.graphType) {
              case "bar":
                return (
                  <Bar
                    key={idx}
                    // type={elem.type || "linear"}
                    index={idx}
                    dataKey={elem.dataKey}
                    fill={colors[idx]}
                    fillWidth={elem.width || 2}
                    fillOpacity={elem.opacity || 1}
                    hide={elem.hide || false}
                    name={elem.name}
                    // unit={elem.unit}
                    isAnimationActive={false}
                  >
                    {["pabdBar", "pabgBar"].includes(elem.dataKey) && (
                      <>
                      <LabelList dataKey={elem.dataKey} content={paTopLabel} position="top" />
                      <LabelList dataKey={elem.dataKey} content={paBottomLabel} position="bottom" />
                      </>
                    )}
                  </Bar>
                );
              case "scatter":
                return (
                  <Scatter
                    key={idx}
                    // type={elem.type || "linear"}
                    index={idx}
                    dataKey={elem.dataKey}
                    fill={colors[idx]}
                    fillWidth={elem.width || 2}
                    fillOpacity={elem.opacity || 1}
                    hide={elem.hide || false}
                    name={elem.name}
                    // unit={elem.unit}
                    isAnimationActive={false}
                  />
                );
              default:
                return (
                  <Line
                    key={idx}
                    type={elem.type || "linear"}
                    index={idx}
                    dataKey={elem.dataKey}
                    stroke={colors[idx]}
                    strokeWidth={elem.strokeWidth || 2}
                    strokeOpacity={elem.opacity || 1}
                    strokeDasharray={elem.strokeDasharray || null}
                    hide={elem.hide || false}
                    name={elem.name}
                    unit={elem.unit}
                    isAnimationActive={false}
                    connectNulls
                  />
                );
            }
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </GraphCntnr>
  );
};

export const AreaChartView = ({graph, data}) => {
  return (
    <GraphCntnr height={graph.height}>
      <ResponsiveContainer
        width="100%" height="100%"
      >
        <AreaChart
          syncId={graph.syncId}
          data={data}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={graph.domainY || ['auto', 'auto']} interval={graph.intervalY} />
          <Tooltip isAnimationActive={false} />
          <Legend />
          {graph.data.map((elem, idx) => (
            <Area
              key={idx}
              type="monotone"
              dataKey={elem.dataKey}
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
              isAnimationActive={false}
              connectNulls
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </GraphCntnr>
  );
};
