import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "found";

import styled from "styled-components";
import theme from "./theme";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SubMenuCntnrBase = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  position: relative;
`;

const SubMenuCntnrDesktop = styled(SubMenuCntnrBase)`
  @media (min-width: 801px) {
    display: flex;
    transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
    top: 0px;
    left: ${props => props.isOpen ? "0px" : `-300px`};
    width: ${props => props.isOpen ? `calc(100% + 300px)` : "100%"};
  }
`;

const SubMenu = styled.div`
  min-height: 100vh;
  width: 300px;
  background-color: ${theme.subMenu};
  padding: 0;
  margin: 0;
`;

const SubMenuNav = styled.nav`
  hr {
    border: 2px solid ${theme.backgroundColor};
  }
`;

const SubMenuContent = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 801px) {
    transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
    top: 0px;
    width: ${props => props.isOpen
      ? `calc(100% - 300px - 32px)`
      : `calc(100% - 32px)`};

    left: ${props => props.isOpen
      ? `332px`
      : `32px`};
  }

  @media (max-width: 800px) {
    width: calc(100% - 30px);
    left: 30px;
  }
`;

export const SubMenuItem = styled(Link)`
  display: block;
  padding: ${theme.small} ${theme.thin};
  color: ${theme.text};

  &.active {
    background-color: ${theme.backgroundColor};
    &:hover {
      text-decoration: none;
      background-color: ${theme.backgroundColor};
      color: ${theme.text};
    }
  }

  &:hover {
    text-decoration: none;
    background-color: ${theme.blue};
    color: ${theme.backgroundColor};
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const ShowSubMenuBtn = styled.button`
  background-color: ${theme.subMenu};
  transition: background-color 0.3s ease-in-out;
  border: none;

  width: ${theme.medium};
  .icon {
    color: ${theme.text};
    transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
    &.close {
      transform: rotate(90deg);
    }
  }
`;

const SubMenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 9;
`;

const CntnrMobil = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
  }
`;

const SubMenuCntnrMobil = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  right: ${props => props.isOpen ? `0px` : `calc(100% - 30px)`};
  transition: right 0.3s ease-in-out;
  width: 100%;
  z-index: 10;
`;

const SubMenuMobil = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: purple;
  background-color: ${theme.subMenu};
  padding: 0;
  margin: 0;
`;

export const SubMenuCntnr = ({content, children}) => {
  const [ isDesktopOpen, setIsDesktopOpen ] = useState(true);
  const [ isMobilOpen, setIsMobilOpen ] = useState(false);

  const navDesktopRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", setNavDimensions);

    return () => {
      window.removeEventListener("resize", setNavDimensions);
    };
  }, []);

  useLayoutEffect(() => {
    setNavDimensions();
  }, []);

  const setNavDimensions = () => {
    if (window.innerWidth >= 768) {
      setIsMobilOpen(false);
    } else if (window.innerWidth < 768) {
      setIsDesktopOpen(true);
    };
  };

  const handleIsDesktopOpen = () => {
    setIsDesktopOpen(!isDesktopOpen);
  };

  const handleIsMobilOpen = () => {
    setIsMobilOpen(!isMobilOpen);
  };

  return (
    <>
      <SubMenuCntnrDesktop
        isOpen={isDesktopOpen}
      >
        <SubMenuDiv>
          <SubMenu>
            <SubMenuNav ref={navDesktopRef}>
              {children}
            </SubMenuNav>
          </SubMenu>
          <ShowSubMenuBtn
            isOpen={isDesktopOpen}
            className="desktop"
            onClick={() => handleIsDesktopOpen()}
          >
            <ExpandLessIcon className={`icon ${!isDesktopOpen ? "close" : ""}`} />
          </ShowSubMenuBtn>
        </SubMenuDiv>
      </SubMenuCntnrDesktop>


      <CntnrMobil>
        <SubMenuCntnrMobil isOpen={isMobilOpen}>
          <SubMenuMobil onClick={() => setIsMobilOpen(false)}>
            <SubMenuNav>
              {children}
            </SubMenuNav>
          </SubMenuMobil>
          <ShowSubMenuBtn
            isOpen={isMobilOpen}
            className="mobil"
            onClick={() => handleIsMobilOpen()}
          >
            <ExpandLessIcon className={`icon ${!isMobilOpen ? "close" : ""}`} />
          </ShowSubMenuBtn>
        </SubMenuCntnrMobil>
      </CntnrMobil>

      <SubMenuContent
        isOpen={isDesktopOpen}
      >{content}</SubMenuContent>
    </>
  );
};




