import React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";

import illus404 from "./ui/illus-404-syope.png";
import { LinkButton } from "./ui/Button";

const Cntnr = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & #illus {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      font-size: 22rem;
      font-weight: bold;
      line-height: initial;
    }
    & img {
      margin-right: 2rem;
      width: 250px;
      align-self: end;
    }
  }

  & #text {
    width: 45%;
    display: flex;
    flex-direction: column;
    & h2 {
      font-size: 10rem;
    }
    & h3 {
      font-size: 5rem;
      line-height: initial;
    }
    & a {
      width: fit-content;
      margin: auto;
      margin-top: 2rem;
      max-height: fit-content;
    }
  }

  @media (max-width: 1400px) {
    & #illus {
      & p {
        font-size: 16rem;
      }
    }
  }
  @media (max-width: 1200px) {
    & #illus {
      & p {
        font-size: 15rem;
      }
    }
    & #text {
      & h2 {
        font-size: 8rem;
      }
      & h3 {
        font-size: 4rem;
      }
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    & #illus {
      width: 80%;
    }
    & #text {
      width: 80%;
    }
  }
  @media (max-width: 800px) {
    & #illus {
      & p {
        font-size: 13rem;
      }
      & img {
        width: 200px;
      }
    }
    & #text {
      & h2 {
        font-size: 6rem;
      }
      & h3 {
        font-size: 4rem;
      }
    }
  }
  @media (max-width: 500px) {
    & #illus {
      & p {
        font-size: 10rem;
      }
      & img {
        width: 200px;
        margin-right: 0;
      }
    }
    & #text {
      & h2 {
        font-size: 5rem;
      }
      & h3 {
        font-size: 3rem;
      }
    }
  }
  @media (max-width: 400px) {
    & #illus {
      width: 95%;
      & p {
        font-size: 8rem;
      }
      & img {
        width: 150px;
      }
    }
    & #text {
      width: 95%;
      & h2 {
        font-size: 4rem;
      }
      & h3 {
        font-size: 2rem;
      }
    }
  }
  @media (max-width: 300px) {
    & #illus {
      & p {
        font-size: 5rem;
      }
    }
  }
`;

const Page404 = ({ error }) => {

  if (error.status !== 404) {
    Sentry.captureException(error);
  };

  return (
    <Cntnr>
      <div id="illus">
        <p>{error.status || 500}</p>
        <img src={illus404} alt="" />
      </div>
      <div id="text">
        <h2>Oups!</h2>
        <h3>{error.status === 404 ? 'Page non trouvée...' : 'Une erreur s\'est produite...'}</h3>
        <LinkButton to={"/"}>Revenir à la page d'acceuil</LinkButton>
      </div>
    </Cntnr>
  );
};

export default Page404;