import AutorenewIcon from '@mui/icons-material/Autorenew';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from './theme';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ReloadIconRot = styled.span`
  margin-left: ${theme.thin};
  display: inline-flex;
  align-self: center;
  animation: ${rotate} 2s linear infinite;
  color: ${theme.grey3};
`;

const ReloadIcon = () => {
    return (
        <ReloadIconRot>
            <AutorenewIcon />
        </ReloadIconRot>
    );
};

export default ReloadIcon;
