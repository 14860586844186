import React, {useContext, useRef, useState} from 'react';

import styled from 'styled-components';
import PageHeader from '../../ui/PageHeader';
import Button from '../../ui/Button';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import Select from '../../ui/Select';
import theme from '../../ui/theme';
import {useQuery} from '@tanstack/react-query';
import http from '../../http';
import {isAbortError} from '../../utils';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LoaderBarContext from '../../ui/useLoaderBar';
import {SwitchDouble} from "../../ui/Switch";
import {alphabeticSort} from "../../interventions/utils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 3rem;
`;

const InjectionCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .injection_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

const CustomButtonBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;

  button {
    max-height: unset !important;
  }
`;

const Table = styled.div`
  padding-left: 3rem;
  border-left: 2px dashed #dce8ff;

  table {
    width: 100%;

    thead {
      margin-bottom: 0.5rem;
    }

    .clear-icon {
      cursor: pointer;
      color: ${theme.red};
    }

    tr {
      border-bottom: 2px solid ${theme.blueBilan};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const MovableTR = styled.tr`
  cursor: move;

  td:first-child {
    padding-right: 1rem;
  }
`;

export default function CreateOrUpdateProtocol({match, router}) {
  const controller = new AbortController();
  const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);

  const [name, setName] = useState('');
  const [injComment, setInjComment] = useState('');
  const [isChild, setIsChild] = useState(false);

  const [drug, setDrug] = useState(null);
  const [injectionSchema, setInjectionSchema] = useState(null);
  const [injections, setInjections] = useState([]);

  let protocol_id = null;
  if (typeof match.location.query.protocol_id !== 'undefined') {
    protocol_id = match.location.query.protocol_id;
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    // use https://medium.com/@eklavya_/implementing-react-drag-and-drop-without-any-external-library-d7ec00437afb
    dragItem.current = position
  }
  const dragEnter = (e, position) => {
    dragOverItem.current = position;

    const injectionsCopy = [...injections];
    const dragItemContent = injectionsCopy[dragItem.current];
    injectionsCopy.splice(dragItem.current, 1);
    injectionsCopy.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setInjections(injectionsCopy);
  }

  const getBolusInfos = (injection) => {
    const boliMax = (injection.boli_maxi === null || injection.boli_maxi === '')
    && (injection.quantity_maxi_including_boli === null || injection.quantity_maxi_including_boli === '')
      ? ''
      : injection.boli_maxi && injection.quantity_maxi_including_boli
      ? `(max. ${injection.boli_maxi} boli ou ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
      : injection.boli_maxi
        ? `(max. ${injection.boli_maxi} boli)`
        : `(max. ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
    return injection.can_boli ? `Bolus autorisé ${boliMax}` : 'Bolus interdit'
  };

  const row = injections.map((injection, idx) => {
      return injection && (
        <MovableTR key={idx} id={idx} draggable
                   onDragStart={(e) => dragStart(e, idx)}
                   onDragEnter={(e) => dragEnter(e, idx)}
                   onDragOver={(e) => e.preventDefault()}>
          <td><DragIndicatorIcon></DragIndicatorIcon></td>
          <td>{injection.drug}</td>
          <td>{injection.quantity_maxi
            ? `${injection.quantity} ${injection.unit} (max. ${injection.quantity_maxi} ${injection.unit_numerator})`
            : `${injection.quantity} ${injection.unit}`}</td>
          <td>{injection.drug_route_name}</td>
          <td>{getBolusInfos(injection)}</td>
          <td>{injection?.comment}</td>
          <td className={'clear-icon'}><ClearIcon className='warn' onClick={() => clearInjection(idx)}/></td>
        </MovableTR>
      )
    }
  )

  const {data: protocolSchema} = useQuery(
    ['protocolSchema'],
    async () => {
      return await http
        .get(`protocol_schemas/${match.location.query.protocol_id}.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setName(res.name);
          setInjComment(res.comment);
          setIsChild(res.age_range === "CHILD");
          setInjections(res.injection_schemas);
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    {cacheTime: 0, enabled: protocol_id !== null}
  );

  const {data: injectionSchemas} = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return alphabeticSort(
            res.drugs.map(it => {
              return {
                label: it.drug,
                value: it.drug,
                injection_schemas: it.injection_schemas,
              };
            }),
            (value) => value.label
          );
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    {cacheTime: 0}
  );

  const addInjection = () => {
    injections.push(injectionSchema.value);
    setInjections([...injections]);
    setDrug(null);
    setInjectionSchema(null);
  };

  const canNotAdd = () => {
    return drug === null || injectionSchema === null || injectionSchema.value === null;
  };

  const create = async () => {
    let url = `protocol_schema.json`;
    if (protocol_id !== null) {
      url = `protocol_schemas/${protocol_id}.json`;
    }
    try {
      await http.post(url, {
        json: {
          name: name,
          comment: injComment,
          age_range: isChild ? 'CHILD' : 'ADULT',
          injection_schema_ordered_ids: injections.map(it => it.id),
        },
      });
      router.push('/gestionnaire/protocols/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de créer ou de modifier ce schéma de protocole. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const destroy = async () => {
    try {
      await http.delete(`protocol_schemas/${protocol_id}.json`, {
        json: {
          name: name,
          injection_schema_ids: injections.map(it => it.injection_schema.id),
        },
      });
      router.push('/gestionnaire/protocols/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de supprimer ce schéma de protocole. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const canNotCreate = () => {
    return name === null || name === '' || injections === null || injections.length === 0;
  };

  const clearInjection = idx => {
    injections.splice(idx, 1);
    setInjections([...injections]);
  };

  return (
    <>
      <PageHeader style={{'margin-bottom': '1rem', 'display': 'flex', 'justify-content': 'space-between'}}>
        {protocol_id !== null
          ? (<h1>Modifier le schéma de protocole : {protocolSchema?.name}</h1>)
          : (<h1>Créer un schéma de protocole</h1>)}
        <SwitchDouble labels={['Adulte', 'Pédiatrique']} switchValue={isChild} onChange={() => setIsChild(!isChild)}/>
      </PageHeader>

      <Content>
        <div>
          <Label htmlFor='name-input'>Nom</Label>
          <Input id='name-input' value={name} onChange={ev => setName(ev.target.value)}/>
          <br/>
          <Label htmlFor='comment-input'>Commentaire</Label>
          <Input id='comment-input' value={injComment} onChange={ev => setInjComment(ev.target.value)}/>
        </div>

        <InjectionCard>
          <header><h4>Ajouter une injection au protocole</h4></header>
          <div className={'injection_card_content'}>
            <div>
              <Label htmlFor='drug-input'>Sélectionnez une drogue ou un soluté</Label>
              <Select
                id='drug-input'
                isSearchable={true}
                isClearable={true}
                options={injectionSchemas}
                value={drug}
                onChange={ev => {
                  setDrug(ev);
                  setInjectionSchema(null);
                }}
                placeholder="Drogue"
              />
            </div>
            <div>
              <Label htmlFor='schema-injection-input'>Sélectionnez un schéma d'injection</Label>
              <Select
                id='schema-injection-input'
                isSearchable={true}
                isClearable={true}
                isDisabled={drug === null}
                options={alphabeticSort(
                  drug?.injection_schemas.map(it => {
                    const boliMax = it.boli_maxi && it.quantity_maxi_including_boli
                      ? `(max. ${it.boli_maxi} boli ou ${it.quantity_maxi_including_boli} ${it.unit_numerator})`
                      : it.boli_maxi
                        ? `(max. ${it.boli_maxi} boli)`
                        : `(max. ${it.quantity_maxi_including_boli} ${it.unit_numerator})`
                    let boli = it.can_boli ? `- Bolus autorisé ${boliMax}` : '- Bolus interdit'

                    const label = !it.weightDependant
                      ? `${it.quantity} ${it.unit} - ${it.drug_route_name} ${boli}`
                      : `${it.quantity} ${it.unit} ${it.quantityMaxi ? `(max. ${it.quantityMaxi} ${it.unitNumerator})` : ''} - ${it.drug_route_name} ${boli}`

                    return {
                      value: it,
                      label: label
                    };
                  }),
                  (value) => value.label
                )}
                value={injectionSchema}
                onChange={ev => setInjectionSchema(ev)}
                placeholder="Schéma d'injection"
              />
            </div>
            <Button disabled={canNotAdd()} onClick={() => addInjection()}>Ajouter</Button>
          </div>
        </InjectionCard>

        <Table>
          <table>
            <thead>
            <tr>
              <th></th>
              <th>Drogue</th>
              <th>Posologie</th>
              <th>Voie d'administration</th>
              <th>Bolus</th>
              <th>Commentaire</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {React.Children.toArray(row)}
            </tbody>
          </table>
        </Table>

        {protocol_id !== null ? (
          <CustomButtonBar style={{'margin-top': '2rem'}}>
            <Button className={'warn'} onClick={() => destroy()}>Supprimer</Button>
            <Button disabled={canNotCreate()} onClick={() => create()}>Modifier le schéma de protocole</Button>
          </CustomButtonBar>
        ) : (
          <Button disabled={canNotCreate()} onClick={() => create()}>Créer le schéma de protocole</Button>
        )}
      </Content>
    </>
  );
}
