import theme from './theme';
import styled from 'styled-components';
import { Link } from 'found';

export const StyledContent = styled.div`
  width: 100%;
`;

export const ErrorBox = styled.div`
  border-left: 3px solid #AB091E;
  background: #FFBDBD;
  color: #AB091E;
  padding: ${theme.small};
  margin: ${theme.medium};
  max-width: 32rem;

  h3 {
    color: #AB091E;
  }
`;

export const ErrorLink = styled(Link)`
  border: 1px solid #AB091E;
  color: #AB091E;
  padding: .25rem ${theme.thin};
  border-radius: .25rem;

  :hover {
    text-decoration: none;
    color: ${theme.white};
    background: #AB091E;
  }
`;