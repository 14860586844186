import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from '../../http';
import { DateTime } from 'luxon';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { toast } from 'react-toastify';
import DocError from '../DocError';

const generateFileName = (bilan, kind) => {
  const victimeName = bilan.victime.nom.replace(/[^a-zA-Z ]/g, '').replace(' ', '_');
  const victimeSurname = bilan.victime.prenom.replace(/[^a-zA-Z ]/g, '').replace(' ', '_');
  const interSerial = bilan.info_tech.conformite.toLocaleString('fr-FR', {
    minimumIntegerDigits: 4,
    useGrouping: false,
  });
  return `NFSave_${victimeName}_${victimeSurname}_${interSerial}_${kind}`;
};

const DocViewerPage = ({ match, router }) => {
  const bilanId = match.params.bilanId;
  const docId = match.params.docId;

  const [documents, setDocuments] = useState(null);
  const [initialDoc, setInitialDoc] = useState(0);
  const [isError, setIsError] = useState(false);

  const {
    isLoading: bilanIsLoading,
    isFetching: bilanIsFetching,
    data: bilan,
    refetch: bilanRefetch,
    isError: bilanIsError,
    error: bilanError,
  } = useQuery(
    ['bilanId'],
    async () => {
      return await http
        .get(`bilans/${encodeURIComponent(bilanId)}.json`)
        .json()
        .then(res => {
          return res;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: async data => {
        try {
          if (documents === null) {
            const temp = [];
            data.attachments
              .filter(d => ['ecg', 'ecg-pdf'].includes(d.category.key))
              .map(d => {
                const localAttachment = localStorage.getItem(`nf-attachment-${d.id}`);
                d.uri = d.public_url;
                d.lastAccess = null;
                d.hasBeenSeen = false;
                if (localAttachment !== null) {
                  d.lastAccess = localAttachment;
                  const dLocal = DateTime.fromISO(localAttachment);
                  if (dLocal > DateTime.fromISO(d.created_at)) d.hasBeenSeen = true;
                }
                d.fileName = generateFileName(data, d.description);
                checkPDFLength(d.uri);
                temp.push(d);
              });
            Promise.all(
              data.annex_sheets.map(async as => {
                switch (as.kind) {
                  case 'RefusTransport':
                  case 'RefusTransportSdis38':
                    as.title = 'refus-transport';
                    break;
                  case 'RefusTransportEnglish':
                    as.title = 'refus-transport-EN';
                    break;
                  case 'RefusSignature':
                    as.title = 'refus-signature';
                    break;
                  case 'LiaisonFormAtsu35':
                    as.title = 'fiche-liaison';
                    break;
                  case 'RecuperationMaterielHelico':
                    as.title = 'recuperation-materiel-helico';
                    break;
                  case 'AutorisationSoinMineur':
                    as.title = 'autorisation-soins-mineur';
                    break;
                  case 'CertificatNaissance':
                    as.title = 'certificat-naissance';
                    break;
                  case 'CompteRenduSmur':
                    as.title = 'compte-rendu-smur';
                    break;
                  case 'Transport':
                    as.title = 'fiche-transport';
                    break;
                  default:
                    as.title = 'document';
                    break;
                }
                const pdfResponse = await http.get(`bilans/${encodeURIComponent(bilanId)}/export`, {
                  headers: {
                    Accept: 'application/pdf',
                  },
                  searchParams: {
                    annex_sheet_id: as.id,
                  },
                });

                const pdfPayload = await pdfResponse.arrayBuffer();
                const pdfBlob = new Blob([pdfPayload], {
                  type: 'application/pdf',
                });
                return {
                  id: as.id,
                  uri: window.URL.createObjectURL(pdfBlob),
                  fileName: generateFileName(data, as.title),
                };
              })
            ).then(res => {
              res.map(e => {
                temp.push(e);
              });
              setDocuments(temp);
              const idx = temp.findIndex(e => e.id === docId);
              if (idx > -1) setInitialDoc(idx);
            });
          }
        } catch (e) {
          console.error(e);
          toast.error("Une erreur est survenue.");
          throw e;
        }
      },
    }
  );

  const checkPDFLength = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const buffer = await response.arrayBuffer();
      const pdfLength = buffer.byteLength;
      const threshold = 0; // 0 bytes
      if (pdfLength === threshold) setIsError(true);
    } catch (e) {
      throw e;
    }
  }

  return (
    <>
      <div>
        {documents && !isError ? (
          <DocViewer
            language='fr'
            style={{ width: '100%', height: '100vh' }}
            documents={documents}
            initialActiveDocument={documents[initialDoc]}
            prefetchMethod='GET'
            pluginRenderers={DocViewerRenderers}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100vh - 4rem)',
            }}
          >
            {isError ? <DocError document={documents[initialDoc]} bilanId={bilanId} /> : <LoadingSpinner />}
          </div>
        )}
      </div>
    </>
  );
};

export default DocViewerPage;
