import styled from "styled-components";

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  padding: ${props => (props.size === "small" ? ".25rem" : ".5rem")};
  border-radius: ${props => (props.size === "small" ? ".125rem" : ".25rem")};
  font-size: ${props => (props.size === "small" ? ".85rem" : "1rem")};
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;

  :focus {
    border-color: #005cef;
    box-shadow: 0 0 0.25rem #005cef;
  }
`;

export default Textarea;
