import React from 'react';
import type { SyoLib } from '@nfsave/syo-bilan';
import {
  BackV2,
  BackV2Baby,
  BackV2Child,
  BackV2Female,
  FrontV2,
  FrontV2Baby,
  FrontV2Child,
  FrontV2Female,
} from './vectorV2/VectorV2';
import { Front, Back } from './vectorV1/VectorV1';

type Position = 'front' | 'back';
type MannequinVectorProps = {
  syoLesionnel: SyoLib.Lesionnel;
  position: Position;
  version: string;
  sexe: SyoLib.Lesionnel['typeSex'];
  typeAge: SyoLib.Lesionnel['typeAge'];
};

export function MannequinVector({ syoLesionnel, position, version, sexe, typeAge }: MannequinVectorProps) {
  if (version === 'v2') {
    if (position === 'front') {
      if (syoLesionnel.lesionnelAvantV2 === null) return <span>Une erreur est survenue</span>;
      if (typeAge === 'nourisson') return <FrontV2Baby lesionnel={syoLesionnel.lesionnelAvantV2} />;
      if (typeAge === 'enfant') return <FrontV2Child lesionnel={syoLesionnel.lesionnelAvantV2} />;
      if (sexe === 'femme') {
        return <FrontV2Female lesionnel={syoLesionnel.lesionnelAvantV2} />;
      }
      return <FrontV2 lesionnel={syoLesionnel.lesionnelAvantV2} />;
    } else {
      if (syoLesionnel.lesionnelArriereV2 === null) return <></>;
      if (typeAge === 'nourisson') return <BackV2Baby lesionnel={syoLesionnel.lesionnelArriereV2} />;
      if (typeAge === 'enfant') return <BackV2Child lesionnel={syoLesionnel.lesionnelArriereV2} />;
      if (sexe === 'femme') return <BackV2Female lesionnel={syoLesionnel.lesionnelArriereV2} />;
      return <BackV2 lesionnel={syoLesionnel.lesionnelArriereV2} />;
    }
  } else {
    if (position === 'front') {
      return <Front lesionnel={syoLesionnel.lesionnelAvant} />;
    } else {
      return <Back lesionnel={syoLesionnel.lesionnelArriere} />;
    }
  }
}
