import React from "react";
import range from "lodash/range";
import styled from "styled-components";
import theme from "../ui/theme";

import { RoundedButton } from "../ui/Button";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';

const Cntnr = styled.div`
  display: flex;
  justify-content: center;
  width: max-content;
  margin: auto;
`;

const PageSelector = styled.div`
  padding: 0 ${theme.small};
  @media (max-width: 335px) {
    display: none;
  }
`;

const PaginationSelect = styled.select`
  border: none;
  height: 42px;
  margin-right: 0.25rem;
  border-radius: ${theme.borderRadius};
  padding: ${theme.thin};

  &:hover {
    cursor: pointer;
  background: ${theme.grey6};
  }
`;

export default function BoundedPagination({
  count = 0,
  total = null,
  perPage = 50,
  current = null,
  onClick = () => {}
}) {
  const pages = range(1, count + 1, 1);
  const previousPage = Math.max(current - 1, 1);
  const nextPage = Math.min(current + 1, count);
  const previousDisabled = current != null && current === 1;
  const nextDisabled = current != null && current === count;
  const start = (current - 1) * perPage + 1;
  const end =
    current !== count
      ? start - 1 + perPage
      : start - 1 + (total % perPage || perPage);
  const goTo = p => onClick(p);
  if (total == null || total === 0) {
    return <></>;
  }
  return (
    <Cntnr>
      <div>
        <RoundedButton
          title="Première page"
          onClick={() => goTo(1)}
          disabled={previousDisabled}
        >
          <FirstPageIcon />
        </RoundedButton>
        <RoundedButton
          title="Page précédente"
          onClick={() => goTo(previousPage)}
          disabled={previousDisabled}
        >
          <NavigateBeforeIcon />
        </RoundedButton>
      </div>

      <PageSelector>
        <PaginationSelect
          value={current}
          onChange={input => {
            const parsed = parseInt(input.target.value, 10);
            if (isNaN(parsed)) {
              return;
            }
            goTo(parsed);
          }}
        >
          {pages.map(p => (
            <option key={p} value={p}>
              {p} / {count}
            </option>
          ))}
        </PaginationSelect>
      </PageSelector>

      <div>
        <RoundedButton
          title="Page suivante"
          onClick={() => goTo(nextPage)}
          disabled={nextDisabled}
        >
          <NavigateNextIcon />
        </RoundedButton>
        <RoundedButton
          title="Dernière page"
          onClick={() => goTo(pages.length)}
          disabled={nextDisabled}
        >
          <LastPageIcon />
        </RoundedButton>
      </div>
    </Cntnr>
  );
}
