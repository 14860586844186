import { Settings, DateTime } from 'luxon';

Settings.defaultLocale = 'fr';

/**
 ** DATE_SHORT -->             14/10/1983
 *
 ** DATE_MED -->               14 oct. 1983
 *
 ** DATE_MED_WITH_WEEKDAY -->  ven. 14 oct. 1983
 *
 ** DATE_FULL -->              14 octobre 1983
 *
 ** DATE_HUGE -->              vendredi 14 octobre 1983
 *
 ** SQL_DATE -->               2023-01-01
 */
export const getDateFromSeconds = (dtString: number, format: formatDate = 'DATE_MED') => {
  const dt = DateTime.fromSeconds(parseInt(dtString.toString(10), 10));
  if (dt.isValid) {
    if (format === 'SQL_DATE') return dt.toSQLDate();
    return dt.toLocaleString(DateTime[format]);
  }
  return 'Date invalide';
};

/**
 ** TIME_SIMPLE -->                 13:30
 *
 ** TIME_WITH_SECONDS -->           13:30:23
 *
 ** TIME_WITH_SHORT_OFFSET -->      13:30:23 UTC−4
 *
 ** TIME_WITH_LONG_OFFSET -->       13:30:23 heure d’été de l’Est
 *
 ** TIME_24_SIMPLE -->              13:30
 *
 ** TIME_24_WITH_SECONDS -->        13:30:23
 *
 ** TIME_24_WITH_SHORT_OFFSET -->   13:30:23 UTC−4
 *
 ** TIME_24_WITH_LONG_OFFSET -->    13:30:23 heure d’été de l’Est
 */
export const getTimeFromSeconds = (dtString: number, format: formatTime = 'TIME_24_SIMPLE') => {
  const dt = DateTime.fromSeconds(parseInt(dtString.toString(10), 10));
  if (dt.isValid) return dt.toLocaleString(DateTime[format]);
  return 'Heure invalide';
};

/**
 ** DATETIME_SHORT -->                 14/10/1983 à 13:30
 *
 ** DATETIME_MED -->                   14 oct. 1983 à 13:30
 *
 ** DATETIME_FULL -->                  14 octobre 1983 à 13:30 UTC−4
 *
 ** DATETIME_HUGE -->                  vendredi 14 octobre 1983 à 13:30 heure d’été de l’Est
 *
 ** DATETIME_SHORT_WITH_SECONDS -->    14/10/1983 à 13:30:23
 *
 ** DATETIME_MED_WITH_SECONDS -->      14 oct. 1983 à 13:30:23
 *
 ** DATETIME_FULL_WITH_SECONDS -->     14 octobre 1983 à 13:30:23 UTC−4
 *
 ** DATETIME_HUGE_WITH_SECONDS -->     vendredi 14 octobre 1983 à 13:30:23 heure d’été de l’Est
 */
export const getDateTimeFromSeconds = (dtString: number, format: formatDateTime = 'DATETIME_MED') => {
  const dt = DateTime.fromSeconds(parseInt(dtString.toString(10), 10));
  if (dt.isValid) return dt.toLocaleString(DateTime[format]);
  return 'Date et heure invalide';
};

/**
 ** DATE_SHORT -->             14/10/1983
 *
 ** DATE_MED -->               14 oct. 1983
 *
 ** DATE_MED_WITH_WEEKDAY -->  ven. 14 oct. 1983
 *
 ** DATE_FULL -->              14 octobre 1983
 *
 ** DATE_HUGE -->              vendredi 14 octobre 1983
 */
export const getDateFromISO = (dtString: string, format: formatDate = 'DATE_MED') => {
  const dt = DateTime.fromISO(dtString);
  if (dt.isValid) {
    if (format === 'SQL_DATE') return dt.toSQLDate();
    return dt.toLocaleString(DateTime[format]);
  }
  return 'Date invalide';
};

/**
 ** TIME_SIMPLE -->                 13:30
 *
 ** TIME_WITH_SECONDS -->           13:30:23
 *
 ** TIME_WITH_SHORT_OFFSET -->      13:30:23 UTC−4
 *
 ** TIME_WITH_LONG_OFFSET -->       13:30:23 heure d’été de l’Est
 *
 ** TIME_24_SIMPLE -->              13:30
 *
 ** TIME_24_WITH_SECONDS -->        13:30:23
 *
 ** TIME_24_WITH_SHORT_OFFSET -->   13:30:23 UTC−4
 *
 ** TIME_24_WITH_LONG_OFFSET -->    13:30:23 heure d’été de l’Est
 */
export const getTimeFromISO = (dtString: string, format: formatTime = 'TIME_24_SIMPLE') => {
  const dt = DateTime.fromISO(dtString);
  if (dt.isValid) return dt.toLocaleString(DateTime[format]);
  return 'Heure invalide';
};

/**
 ** DATETIME_SHORT -->                 14/10/1983 à 13:30
 *
 ** DATETIME_MED -->                   14 oct. 1983 à 13:30
 *
 ** DATETIME_FULL -->                  14 octobre 1983 à 13:30 UTC−4
 *
 ** DATETIME_HUGE -->                  vendredi 14 octobre 1983 à 13:30 heure d’été de l’Est
 *
 ** DATETIME_SHORT_WITH_SECONDS -->    14/10/1983 à 13:30:23
 *
 ** DATETIME_MED_WITH_SECONDS -->      14 oct. 1983 à 13:30:23
 *
 ** DATETIME_FULL_WITH_SECONDS -->     14 octobre 1983 à 13:30:23 UTC−4
 *
 ** DATETIME_HUGE_WITH_SECONDS -->     vendredi 14 octobre 1983 à 13:30:23 heure d’été de l’Est
 */
export const getDateTimeFromIso = (dtString: string, format: formatDateTime = 'DATETIME_MED') => {
  const dt = DateTime.fromISO(dtString);
  if (dt.isValid) return dt.toLocaleString(DateTime[format]);
  return 'Date et heure invalide';
};

export const getInputDateFromIso = (date: string) => {
  const dt = DateTime.fromISO(date);
  if (dt.isValid) return dt.toFormat('yyyy-MM-dd');
  return '';
};

export { DateTime, Settings };

type formatDate = 'DATE_SHORT' | 'DATE_MED' | 'DATE_MED_WITH_WEEKDAY' | 'DATE_FULL' | 'DATE_HUGE' | 'SQL_DATE';

type formatTime =
  | 'TIME_SIMPLE'
  | 'TIME_WITH_SECONDS'
  | 'TIME_WITH_SHORT_OFFSET'
  | 'TIME_WITH_LONG_OFFSET'
  | 'TIME_24_SIMPLE'
  | 'TIME_24_WITH_SECONDS'
  | 'TIME_24_WITH_SHORT_OFFSET'
  | 'TIME_24_WITH_LONG_OFFSET';

type formatDateTime =
  | 'DATETIME_SHORT'
  | 'DATETIME_MED'
  | 'DATETIME_FULL'
  | 'DATETIME_HUGE'
  | 'DATETIME_SHORT_WITH_SECONDS'
  | 'DATETIME_MED_WITH_SECONDS'
  | 'DATETIME_FULL_WITH_SECONDS'
  | 'DATETIME_HUGE_WITH_SECONDS';
