import { Route, Redirect } from "found";
import React from "react";

import Dashboard from "./screens/Dashboard";
import Create from "./screens/Create";
import Show from "./screens/Show";
import ShowId from "./screens/ShowId";
import { hasLegacyPerm } from "../utils";

export const routes = (
  <Route path="statistiques"
    getData={(data) => {
        if (!hasLegacyPerm(data.context, 'statistics:list')) data.router.replace("/droits-insuffisant");
    }}
  >
    <Redirect from="/" to="/statistiques/apercu" />
    <Route path="apercu" Component={Dashboard} />
    <Route path="nouveau" Component={Create} />
    <Route path="g/:chartId" Component={Show} />
    <Route path=":chartId" Component={ShowId} />
  </Route>
);
