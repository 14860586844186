import { useEffect, useState } from "react"

class UnaryCallState {
  constructor(_state, _reload, _response, _error) {
    this._state = _state
    this._reload = _reload
    this._response = _response
    this._error = _error
  }

  static ofPending() {
    return new UnaryCallState("loading", () => {}, undefined, undefined)
  }

  static ofSuccess(reloader, response) {
    return new UnaryCallState("success", reloader, response, undefined)
  }

  static ofError(reloader, error) {
    return new UnaryCallState("error", reloader, undefined, error)
  }

  get state() {
    return this._state
  }

  get isLoading() {
    return this.state === "loading"
  }

  get isSuccess() {
    return this.state === "success"
  }

  get isError() {
    return this.state === "error"
  }

  get response() {
    if (!this.isSuccess) {
      throw new Error(
        "Accessing response on UnaryCallState with state not `success`"
      )
    }
    return this._response
  }

  get error() {
    if (!this.isError) {
      throw new Error(
        "Accessing response on UnaryCallState with state not `error`"
      )
    }
    return this._error
  }

  reload() {
    this._reload()
  }
}

export function idClientFactory(ctor) {
  return () => new ctor("https://api.efibi.fr", null, null)
}

export function useUnaryCall(clientFactory, unaryCallFactory) {
  const [retryCounter, setRetryCounter] = useState(0)
  const [client, _] = useState(clientFactory)
  const [call, setCall] = useState(UnaryCallState.ofPending())

  useEffect(() => {
    let canceled = false

    async function main() {
      try {
        setCall(UnaryCallState.ofPending())
        let response = await unaryCallFactory(client)
        if (canceled === true) {
          return
        }
        setCall(
          UnaryCallState.ofSuccess(() => {
            setRetryCounter(retryCounter + 1)
          }, response)
        )
      } catch (e) {
        if (canceled === true) {
          return
        }
        setCall(
          UnaryCallState.ofError(() => {
            setRetryCounter(retryCounter + 1)
          }, e)
        )
      }
    }
    main()

    return () => {
      canceled = true
    }
  }, [retryCounter, client, setCall])

  return call
}
