import { Route, Redirect } from "found";
import React from "react";

import Personal from "./screens/Personal";
import PasswordChange from "./screens/PasswordChange";
import CGU from "./screens/CGU";
import MentionsLegales from "./screens/mentionsLegales";
import SignInHistory from "./screens/SignInHistory";
import ReleaseNote from "./screens/ReleaseNote";
import Layout from "./Layout";
import EstablishmentCards from "./screens/EstablishmentCards";

export const routes = (
  <Route path="reglages" Component={Layout}>
    <Redirect from="/" to="/reglages/profil" />
    <Route path="profil" Component={Personal} />
    <Route path="mot-de-passe" Component={PasswordChange} />
    <Route path="mentions-legales" Component={MentionsLegales} />
    <Route path="historique-connexions" Component={SignInHistory} />
    <Route path="note-de-version" Component={ReleaseNote} />
    <Route path="cgu" Component={CGU} />
    <Route path="cartes-etablissement" Component={EstablishmentCards} />
  </Route>
);
