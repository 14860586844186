import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  :first-child {
    border-top-width: 0;
  }
`;

export const Column = styled.div`
  flex-basis: 0;
  flex-grow: ${props => props.width || 1};
  max-width: 100%;
  padding: 0 1rem;
`;
