import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';

import styled from 'styled-components';
import theme from '../../ui/theme';
import PageHeader, { Cntnr, Title } from '../../ui/PageHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';

const CellHeader = styled.th`
  padding: ${theme.thin};
  border-bottom: 1px solid ${theme.grey5};
`;

const Cell = styled.td`
  padding: ${theme.thin};
  border-top: 1px solid ${theme.grey5};
`;

const IpAddress = styled(Cell)`
  font-variant-numeric: tabular-nums;
`;

function localize(date) {
  return DateTime.fromISO(date).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_MED);
}

export default function SignInHistory() {
  const controller = new AbortController();

  const { isLoading: payloadIsLoading, data: payload } = useQuery(['signInHistory'], async () => {
    return await http
      .get(`sign_in_attempts`, {
        signal: controller.signal,
      })
      .json()
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération de l'historique de connexion");
        throw error;
      });
  });

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <PageHeader>
        <Title>Historique des connexions</Title>
      </PageHeader>

      {!payloadIsLoading && payload ? (
        <Cntnr>
          <table>
            <thead>
              <tr>
                <CellHeader>Adresse IP</CellHeader>
                <CellHeader>Date & heure de connexion</CellHeader>
              </tr>
            </thead>
            <tbody>
              {payload.data.map((entry, idx) => (
                <tr key={idx}>
                  <IpAddress>{entry.ip}</IpAddress>
                  <Cell>{localize(entry.created_at)}</Cell>
                </tr>
              ))}
            </tbody>
          </table>
        </Cntnr>
      ) : (
        <LoadingSpinner className='center vh-50' />
      )}
    </>
  );
}
