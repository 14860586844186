import React from "react";
import styled from "styled-components";

import theme from "./theme";

import CloseIcon from '@mui/icons-material/Close';

const TagCntnr = styled.div`
  min-height: ${theme.large};
  background-color: ${theme.blue};
  margin: 10px ${theme.small} 0px 0px;
  padding: 0 ${theme.small};
  color: ${theme.backgroundColor};
  border-radius: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  button {
    border: none;
    background-color: transparent;
    margin-right: ${theme.thin};
    color: ${theme.backgroundColor};
    padding: 0;
  }
`;

export default function Tag ({text, callback}) {

  const handleClose = (ev) => {
    ev.preventDefault();
    callback();
  };

  return (
    <TagCntnr>
      <button type="button" title="Effacer le filtre" onClick={(ev) => handleClose(ev)}>
        <CloseIcon />
      </button>
      {text}
    </TagCntnr>
  )
};
