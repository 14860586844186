import React, { useEffect } from "react";

import styled from "styled-components";
import theme from "../ui/theme";

import logo from "../ui/Syope-logo-visuel-rond-web.png";
import { isFormation } from "../utils";

const LogoContainer = styled.div`
  margin: auto;
  h1 {
    color: ${theme.text};
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 400;
  }
  h2 {
    color: ${theme.text};
    text-align: center;
    margin-bottom: 2.5rem;
  }
`;

const Logo = styled.img`
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  aspect-ratio: 1 / 1;
`;

const LogoName = () => {
  return (
    <LogoContainer>
        <Logo src={logo} alt="Logo Syopé" width="250px" height="250px" style={{filter: isFormation() ? "invert(1)" : "none"}} />
        <h1>Syopé</h1>
        {isFormation() && <h2>FORMATION</h2>}
    </LogoContainer>
  );
};

export default LogoName;