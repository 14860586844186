import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LensIcon from '@mui/icons-material/Lens';
import { isEqual } from 'lodash';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import styled from 'styled-components';
import theme from '../../ui/theme';
import ErrorIcon from '@mui/icons-material/Error';

const Pastille = styled.span`
  margin-left: ${theme.thin};
  display: inline-flex;
  align-self: center;
  color: ${props => theme[props.color] || theme.blue};
`;

const SeniorityListBilans = ({payload}) => {
    const [dataSeniority, setSeniority] = useState({
        color: "blue",
        label: "En cours de chargement",
        icon: <LensIcon />,
    });

    /**
     * Compare the object dataSeniority with the new object i
     * and assign the new object only if they are different
     * @param {Object} i - New object for dataSeniority
     */
    const ifSetSeniority = useCallback((i) => {
        if (!isEqual(i, dataSeniority)) {
            setSeniority(i);
        };
    }, [dataSeniority, setSeniority]);

    /** Checks the seniority of the payload */
    const checkSeniority = useCallback(() => {
        if (payload === undefined) return;
        if (!window.navigator.onLine){
            ifSetSeniority({
                color: "red",
                label: "Perte de connexion",
                icon: <SignalWifiOffIcon />,
            });
            return;
        };
        let t = Date.now() - payload.dataSeniority;
        switch (true) {
            case t < 30000: // 30sec
                ifSetSeniority({
                    color: "green",
                    label: "Dernière mise à jour il y a moins de 30 secondes",
                    icon: <LensIcon />,
                });
                break;
            case t < 60000: // 30sec & 1min
                ifSetSeniority({
                    color: "orange",
                    label: "Dernière mise à jour il y a plus de 30 secondes",
                    icon: <LensIcon />,
                });
                break;
            case t < 180000: // 1min & 3min
                ifSetSeniority({
                    color: "red",
                    label: "Dernière mise à jour il y a plus de 1 minute",
                    icon: <LensIcon />,
                });
                break;
            case t >= 180000: // sup 3min
                ifSetSeniority({
                    color: "black",
                    label: "Dernière mise à jour il y a plus de 3 minute",
                    icon: <ErrorIcon />,
                });
                break;

            default:
                ifSetSeniority({
                    color: "blue",
                    label: "En cours de chargement",
                    icon: <LensIcon />,
                });
                break;
        };
    }, [payload, ifSetSeniority]);

    const pastilleIcon = useMemo(() => {
        return (
            <Pastille color={dataSeniority.color} title={dataSeniority.label}>
                {dataSeniority.icon}
            </Pastille>
        );
    }, [dataSeniority]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkSeniority();
        }, 1000);
        return () => clearInterval(interval);
    }, [payload, dataSeniority, checkSeniority]);

    return pastilleIcon;
};

export default SeniorityListBilans;
