import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'found';
import http, { HTTPError, signOut } from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import CurrentUserContext from '../../CurrentUserContext';
import LoaderBarContext from '../../ui/useLoaderBar';

import ModalCGU from '../../auth/ModalCGU';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import theme from '../../ui/theme';
import BaseButton from '../../ui/Button';
import Steps from '../../ui/Steps';
import LoadingSpinner from '../../ui/LoadingSpinner';

const NarrowContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.small} ${theme.small} 0;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: ${theme.small} ${theme.small} 0;
    border-radius: ${theme.thin};

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .break-mobile {
      display: none;
    }
  }

  @media (max-width: 550px) {
    form {
      h2 {
        font-size: 1.3rem;
      }
    }
  }
  @media (max-width: 385px) {
    form {
      .break-mobile {
        display: inline;
      }
    }
  }
`;

const BlockLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: ${theme.small};
`;

const Button = styled(BaseButton)`
  margin-top: ${theme.thin};
  margin-bottom: ${theme.small};
  padding: 0.8rem 1.4rem;
  display: block;
  width: 100%;
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

function Cgu({ router }) {
  const { isLoading: userIsLoading, data: user } = useQuery(
    ['userCGU'],
    async () => {
      return await http
        .get(`user/me`)
        .json()
        .catch(error => {
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue pendant la récupération de l'utilisateur.");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const [cguAccepted, setCguAccepted] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const userCtx = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const toastId = useRef(null);

  useEffect(() => {
    if (user?.terms_of_usage_approval !== null) router.replace('/');
  }, [user, router]);

  const disableSubmit = useMemo(() => {
    if (loaderBarState || cguAccepted === null) return true;
    return false;
  }, [loaderBarState, cguAccepted]);

  const handleCguSubmit = (localized = null) => {
    setCguAccepted(localized);
    setShowModal(false);
  };

  /** Validate and accept the CGU for the current user and redirect to
   * the platform or to the login */
  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      toastId.current = toast(<LoadingSpinner />, { autoClose: false });
      await http.post('terms_of_usage_approvals');
      const userResponse = await http.get('user/me').json();
      let currentUser = localStorage.getItem('nfCurrentUser');
      const organization = currentUser.organization;

      currentUser = { organization: organization, ...userResponse };

      userCtx.setUser(currentUser);
      localStorage.setItem('nfCurrentUser', JSON.stringify(currentUser));

      toast.dismiss(toastId.current);
      toast.success('Les CGU ont bien été validées et acceptées');
      router.replace('/');
    } catch (e) {
      Sentry.captureException(e);
      toast.dismiss(toastId.current);
      toast.error('Impossible de valider et accepter les CGU.');
    } finally {
      setLoaderBar(false);
    }
  };

  /** Disconnects the user and cleans the data from storage */
  const handleLogout = async ev => {
    ev.preventDefault();
    try {
      await http.delete('auth/sign_out');
      localStorage.clear();
      signOut();
      router.push('/auth/sign-in');
    } catch (e) {
      if (ev instanceof HTTPError && ev.response.status === 404) {
        router.push('/auth/sign-in');
      } else {
        console.error(ev);
      }
    }
  };

  const onModalDisplayChange = state => {
    setShowModal(state);
  };

  return (
    <NarrowContainer>
      <form onSubmit={handleFormSubmit}>
        <FormTitle>Conditions générales d'utilisation</FormTitle>
        {userIsLoading ? (
          <LoadingSpinner className='center vh-50' />
        ) : (
          <>
            <Steps step={1} />
            <h3>
              Afin de pouvoir continuer à utiliser votre compte Syopé, merci de bien vouloir lire et accepter les CGU.
            </h3>
            <Fieldset>
              <ModalCGU
                show={showModal}
                cguAccepted={cguAccepted}
                onClose={() => onModalDisplayChange(false)}
                onSubmit={handleCguSubmit}
              />
              <Button
                className={cguAccepted === null ? 'warn' : 'validated'}
                type='button'
                onClick={() => onModalDisplayChange(true)}
              >
                {cguAccepted === null ? (
                  <CheckBoxOutlineBlankIcon className='icon-left' fontSize='small' />
                ) : (
                  <CheckBoxIcon className='icon-left' fontSize='small' />
                )}
                {cguAccepted === null ? 'Lire et accepter les CGU' : 'CGU acceptées'}
              </Button>
              <Steps step={2} />
              <Button disabled={disableSubmit} type='submit'>
                Enregistrer la validation des CGU
              </Button>
              <p style={{ fontSize: '.75rem' }}>
                Si vous rencontrez des difficultés dans la validation des CGU, vous pouvez contacter
                l'assistance&thinsp;: assistance@syope.fr
              </p>
              <hr />
              <BlockLink to='/auth/sign-in' onClick={e => handleLogout(e)}>
                Se déconnecter et revenir à l'écran de connexion
              </BlockLink>
            </Fieldset>
          </>
        )}
      </form>
    </NarrowContainer>
  );
}

export default Cgu;
