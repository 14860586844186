import styled from 'styled-components';
import theme from '../../ui/theme';

export const ViewCntnr = styled.div`
  margin: .125rem ${theme.small};
  &.dotted {
    overflow-x: hidden;
    div:after {
      float: left;
      width: 0;
      white-space: nowrap;
      content:
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . ";
    }
  }
  &.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  grid-row: ${props => props.gridRow || 'auto'}};
`;

export const GridViewCntnr = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.templateColumns || 5}, 1fr)`};
  grid-column: ${props => props.gridColumn};

  &.withArrayRowCntnr {
    padding-bottom: ${theme.small};
    &:not(:first-child) {
      padding-top: ${theme.small} !important;
    }
    &:not(:last-child) {
      border-bottom: 2px ${theme.blueBilan} dashed !important;
    }
  }

  @media (max-width: 1660px) {
    &.cardGrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 1440px) {
    &.cardGridLarge {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
    &.cardGridLarge {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 820px) {
    grid-template-columns: repeat(1, 1fr);
    &.cardGrid {
      grid-template-columns: repeat(1, 1fr);
    }
    &.cardGridLarge {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const CategoryViewCntnr = styled.div`
  h5,
  h6 {
    padding-top: ${theme.thin};
    padding-left: ${theme.thin};
    margin-left: ${theme.thin};
  }
`;
export const CategoryViewCntnrMedecine = styled.div`
  margin-left: ${theme.small};
  h5,
  h6 {
    padding-top: ${theme.thin};
    padding-left: ${theme.thin};
    margin-left: ${theme.thin};
  }
`;

export const CardViewCntnr = styled('div').attrs({ className: 'CardViewCntnr' })`
  grid-column: ${props => props.gridColumn};
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  margin-top: ${theme.medium};
  &:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 0;
    height: 100%;
    border-right: 2px ${theme.blueBilan} dashed;
    transition: border-right 0.3s ease-in-out;
  }

  h4 {
    background-color: ${theme.blueBilan};
    padding-bottom: ${theme.thin}; //.25rem
    padding-top: ${theme.thin}; //.25rem
    padding-left: ${theme.thin};
    margin-left: ${theme.thin};
    text-transform: uppercase;
    min-height: 38.4px;
  }

  &:last-child {
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }
  }

  .CardViewCntnr:last-child & {
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }
  }

  .CardViewCntnr.cardCntnr &:first-child {
    margin-top: 0px;
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }
  }

  // ABCDE structure
  .CardViewCntnr.cardCntnr ${GridViewCntnr} &,
  .CardViewCntnr.cardCntnr ${GridViewCntnr}:first-child & {
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }
  }
  .CardViewCntnr.cardCntnr ${GridViewCntnr} & {
    margin-top: ${theme.medium};
  }
  .CardViewCntnr.cardCntnr ${GridViewCntnr}:first-child & {
    margin-top: 0px;
  }

  @media (max-width: 1080px) {
    grid-column: 1;
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }

    .CardViewCntnr.cardCntnr ${GridViewCntnr}:first-child &:not(:first-child) {
      margin-top: ${theme.medium};
    }
  }
`;
export const CardViewCntnrMedecine = styled.div`
  position: relative;
  overflow-x: hidden;
  margin-top: ${theme.medium};
  background-color: ${theme.backgroundColor};
  border-radius: 15px;
  //width: 60%;
  margin: 20px 0px 20px 0;
  padding: 15px;
  font-family: Roboto, sans-serif !important;

  & * {
    line-height: 24px !important;
    font-size: 18px !important;
    font-family: Roboto, sans-serif !important;
  }

  h4 {
    padding-bottom: ${theme.thin}; //.25rem
    padding-top: ${theme.thin}; //.25rem
    padding-left: ${theme.thin};
    margin-left: ${theme.thin};
    text-transform: uppercase;
    min-height: 38.4px;
  }

  @media (max-width: 1080px) {
    grid-column: 1;
    border-right: none;
    &:after {
      content: none;
      right: 0;
      top: 0;
      border-right: none;
    }
  }
`;

export const ViewLabelCntnr = styled.span`
  padding-right: ${theme.thin};
  font-style: italic;
  font-weight: 500;

  ${ViewCntnr}.dotted & {
    background-color: ${theme.backgroundColor};
  }
`;

export const DisclosureViewCntnr = styled('div').attrs({ className: 'DisclosureViewCntnr' })`
  margin-left: ${theme.small};
  margin-right: ${theme.small};
  border-left: ${theme.blueBilan} 3px solid;
  border-bottom: ${theme.blueBilan} 3px solid;

  .disclosure {
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: ${theme.blueBilan};
      border: none;
      padding-left: ${theme.thin};
      padding-right: ${theme.thin};
      text-transform: uppercase;
      width: 100%;
      > * {
        margin-bottom: 0px;
      }
      &-icon {
        color: ${theme.text};
        transition: transform 0.3s ease-in-out;
        &.close {
          transform: rotate(-180deg);
        }
      }
    }
    &-content {
      margin-top: ${theme.small};

      span.light {
        font-size: 0.9rem;
        font-style: italic;
      }
    }

    & div:last-child > .withArrayRowCntnr {
      border-bottom: none;
    }
    & div:not(:first-child) > .withArrayRowCntnr {
      padding-top: ${theme.thin};
    }

    .DisclosureViewCntnr {
      margin-bottom: ${theme.small};
      .disclosure-title {
        margin-top: ${theme.small};
      }
    }
  }
`;

export const TagBilanDottedCntnr = styled.span`
  position: relative;
  z-index: 1;
  float: right;
  background-color: ${theme.backgroundColor};
  padding-left: ${theme.thin};
`;

export const TagBilanCntnr = styled.span`
  border-radius: 3px;
  margin-bottom: 0.125rem;
  padding: 0 3px;
  font-family: 'JetBrains Mono', monospace;
  background-color: ${props => theme[props.color] || theme.blue};
  color: ${props => (props.color === 'orange' ? '#444444' : theme.backgroundColor)};
  height: max-content;
`;
export const TagBilanCntnrMedecine = styled.span`
  border-radius: 3px;
  margin-bottom: 0.125rem;
  padding: 0 3px;
  font-family: Roboto, sans-serif !important;
  height: max-content;
  background-color: ${theme.backgroundColor} !important;
`;

export const TableCntnr = styled.table`
  width: 100%;
  margin-bottom: ${theme.small};

  thead {
    border-bottom: 4px solid ${theme.blueBilan};

    tr:hover {
      background-color: transparent;
    }
    th {
      padding-left: ${theme.thin};
      padding-right: ${theme.thin};
      &.rightBorder:not(:last-child) {
        border-right: 2px solid ${theme.blueBilan};
      }
    }
  }

  tr {
    border-bottom: 2px solid ${theme.blueBilan};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${theme.blueBilan};
    }
  }
`;

export const ThCntnr = styled.th`
  font-weight: normal;
  vertical-align: top;
  padding: 0.2rem;
  border-right: 2px ${theme.blueBilan} dashed;

  &:last-child {
    border-right: none;
  }

  &:not(:first-child) {
    text-align: center;
  }

  &.alignR {
    text-align: right;
  }
  &.alignL {
    text-align: left;
  }
  &.alignContentC {
    align-content: center;
  }
  &.noBorder {
    border-right: none;
  }

  &.sticky {
    position: sticky;
    left: 0px;
  }
  &.sticky-50 {
    position: sticky;
    left: 50px;
  }
`;

export const ThBisCntnr = styled.th`
  width: 50px;
  min-width: 50px;
  vertical-align: center;
  background-color: ${theme.backgroundColor};
  position: sticky;
  left: 0px;
  border-top: red 4px solid;
  writing-mode: vertical-rl;
  text-orientation: upright;
`;

export const TdCntnr = styled.td`
  padding: 0.2rem 0.5rem;
  border-right: 2px ${theme.blueBilan} dashed;
  &:last-child {
    border-right: none;
  }

  &:not(:first-child) {
    text-align: center;
  }

  &.alignR {
    text-align: right;
  }
  &.alignL {
    text-align: left;
  }
  &.noBorder {
    border-right: none;
  }
`;

export const TableVerticalCntnr = styled.table`
  border-collapse: separate;
  overflow: hidden;
  border-spacing: 0px;

  thead {
    border-bottom: ${theme.blueBilan} 4px solid;
  }

  tr {
    border-bottom: 2px solid ${theme.blueBilan};
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: ${theme.blueBilan};
    }
    & > th {
      padding-left: ${theme.thin};
      z-index: 1;
      &:not(${ThBisCntnr}) {
        background-color: ${theme.blueBilan};
      }
    }
    & > :not(th) {
      min-width: 70px;
    }
  }

  th,
  td {
    border-bottom: 1px solid ${theme.blueBilan};
    border-top: 1px solid ${theme.blueBilan};
    &:first-child {
      border-right: none;
    }
  }

  tbody {
    td {
      position: relative;
      &:hover {
        // Survol colonne
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: ${theme.blueBilan};
          z-index: -1;
        }
      }
    }
  }
`;
