import styled from "styled-components";
import theme from "./theme";

const NarrowContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${theme.small};

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1440px) {
    width: 1280px;
  }
`;

export default NarrowContainer;
