import React, { useMemo } from 'react';
import { type SyoApi, utils as syoUtils } from '@nfsave/syo-bilan';

type FrontV1Props = {
  lesionnel: SyoApi.LesionnelAvant;
};
type BackV1Props = {
  lesionnel: SyoApi.LesionnelArriere;
};

export function Front({ lesionnel }: FrontV1Props) {
  return useMemo(() => {
    return (
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='front' transform='translate(-2.000000, 1.000000)' fill='#D8D8D8' fillRule='nonzero' strokeWidth='2'>
          <ellipse
            id='bassin_bas'
            stroke='#000000'
            cx='191.5'
            cy='382.5'
            rx='47.5'
            ry='46.5'
            fill={syoUtils.colorFiller(lesionnel.avant_bas_bassin)}
          />
          <path
            d='M121.41841,337 C117.806137,352.410853 116,364.406562 116,372.987126 C116,381.56769 117.806137,393.563399 121.41841,408.974252 L261.03474,408.974252 C265.799051,392.932381 268.181206,380.936673 268.181206,372.987126 C268.181206,365.03758 265.799051,353.041871 261.03474,337 L121.41841,337 Z'
            id='bassin'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_bassin)}
          />
          <polygon
            id='haut_corps'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_haut_corps)}
            points='122 337 262 337 262 205.572786 254.125076 134 132.006696 134 122 205.572786'
          />
          <path
            d='M92.4807924,685 C82.359001,686.928075 75.4207799,691.544419 71.6661292,698.849033 C67.9114784,706.153646 63.0227687,724.546408 57,754.027319 C58.4816021,766.974168 61.226657,774.629651 65.2351646,776.993768 C69.2436722,779.357886 78.3255482,778.677629 92.4807924,774.952999 C107.469737,758.379417 117.026025,746.187819 121.149656,738.378203 C125.178267,730.748542 129.523476,716.40542 127.878405,701.247988 C126.972345,692.899686 126.972345,692.899686 127.878405,701.247988 C110.066237,707.561165 99.9887955,710.180085 97.6460824,709.104747 C95.3033694,708.02941 93.581606,699.994494 92.4807924,685 Z'
            id='pied_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_pied_droit)}
          />
          <path
            d='M94.8241429,674.307233 L89.899971,597.083799 L94.8241429,519.860365 L148.172969,519.860365 C151.206395,553.877257 152.723107,579.618402 152.723107,597.083799 C152.723107,614.549196 151.206395,640.29034 148.172969,674.307233 L94.8241429,674.307233 Z'
            id='jambe_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_jambe_droite)}
            transform='translate(121.311539, 597.083799) rotate(6.000000) translate(-121.311539, -597.083799) '
          />
          <path
            d='M113.446615,518.115605 L108.522443,461.164145 L113.446615,404.212685 L166.795441,404.212685 C169.828866,429.299781 171.345579,448.283601 171.345579,461.164145 C171.345579,474.044689 169.828866,493.028509 166.795441,518.115605 L113.446615,518.115605 Z'
            id='cuisse_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_cuisse_droite)}
            transform='translate(139.934011, 461.164145) rotate(9.000000) translate(-139.934011, -461.164145) '
          />
          <path
            strokeWidth='2'
            d='M1,26.4269414 L1,93 L141,93 L141,26.4263304 C128.619252,14.5639857 118.691117,7.2889175 111.276632,4.60702338 C103.787458,1.89811305 90.3491994,0.790388265 70.9671389,1.31804102 C52.0904778,0.697397687 38.7502266,1.80465813 31.0026822,4.60702338 C23.3302482,7.38222044 13.3111068,14.656544 1.00000008,26.4269455 Z'
            id='bas_corps'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_bas_corps)}
            transform='translate(260, 706.164145) translate(-139.934011, -461.164145) '
          />
          <ellipse
            id='cheville_droite'
            stroke='#000000'
            cx='110'
            cy='685'
            rx='29'
            ry='26'
            fill={syoUtils.colorFiller(lesionnel.avant_articulation_pied_droit)}
          />
          <ellipse
            id='genou_droit'
            stroke='#000000'
            cx='129'
            cy='514'
            rx='29'
            ry='26'
            fill={syoUtils.colorFiller(lesionnel.avant_genou_droit)}
          />
          <path
            d='M288.480792,686 C278.359001,687.928075 271.42078,692.544419 267.666129,699.849033 C263.911478,707.153646 259.022769,725.546408 253,755.027319 C254.481602,767.974168 257.226657,775.629651 261.235165,777.993768 C265.243672,780.357886 274.325548,779.677629 288.480792,775.952999 C303.469737,759.379417 313.026025,747.187819 317.149656,739.378203 C321.178267,731.748542 325.523476,717.40542 323.878405,702.247988 C322.519315,689.725534 297.160152,711.717753 293.646082,710.104747 C291.303369,709.02941 289.581606,700.994494 288.480792,686 Z'
            id='pied_gauche'
            stroke='#020202'
            fill={syoUtils.colorFiller(lesionnel.avant_pied_gauche)}
            transform='translate(288.617822, 732.685332) scale(-1, 1) translate(-288.617822, -732.685332) '
          />
          <path
            d='M217.446615,518.115605 L212.522443,461.164145 L217.446615,404.212685 L270.795441,404.212685 C273.828867,429.299781 275.345579,448.283601 275.345579,461.164145 C275.345579,474.044689 273.828867,493.028509 270.795441,518.115605 L217.446615,518.115605 Z'
            id='cuisse_gauche'
            fill={syoUtils.colorFiller(lesionnel.avant_cuisse_gauche)}
            stroke='#000000'
            transform='translate(243.934011, 461.164145) scale(-1, 1) rotate(9.000000) translate(-243.934011, -461.164145) '
          />
          <path
            d='M234.824143,672.307233 L229.899971,595.083799 L234.824143,517.860365 L288.172969,517.860365 C291.206395,551.877257 292.723107,577.618402 292.723107,595.083799 C292.723107,612.549196 291.206395,638.29034 288.172969,672.307233 L234.824143,672.307233 Z'
            id='jambe_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_jambe_gauche)}
            transform='translate(261.311539, 595.083799) rotate(-6.000000) translate(-261.311539, -595.083799) '
          />
          <ellipse
            id='genou_gauche'
            fill={syoUtils.colorFiller(lesionnel.avant_genou_gauche)}
            stroke='#000000'
            cx='254'
            cy='515'
            rx='29'
            ry='26'
          />
          <ellipse
            id='cheville_gauche'
            stroke='#020202'
            cx='272'
            cy='686'
            rx='29'
            ry='26'
            fill={syoUtils.colorFiller(lesionnel.avant_articulation_pied_gauche)}
          />
          <path
            fill={syoUtils.colorFiller(lesionnel.avant_main_gauche)}
            d='M351.2249,372.31788 C343.944343,381.277496 338.687739,387.958887 335.455089,392.362053 C332.222439,396.765219 326.899883,404.493616 319.48742,415.547244 C319.973803,417.069928 320.452081,418.056133 320.922257,418.50586 C321.392432,418.955587 322.325057,419.318925 323.720132,419.595874 L340.139567,407.589188 C335.425316,412.883531 332.26548,416.88576 330.66006,419.595874 C329.05464,422.305988 327.287543,426.620929 325.358769,432.540696 C327.248359,439.43067 329.495889,443.576512 332.101359,444.978221 C334.706828,446.37993 340.165508,446.37993 348.4774,444.978221 C353.529796,437.402311 357.025073,431.568928 358.963232,427.478071 C360.901391,423.387214 362.888213,417.649573 364.923698,410.265147 C368.163942,403.504513 370.41216,398.340635 371.668354,394.773515 C372.924547,391.206395 374.333472,385.985348 375.895129,379.110374 L351.2249,372.31788 Z'
            id='main_gauche'
            stroke='#000000'
            transform='translate(347.691275, 409.173691) scale(-1, 1) rotate(-5.000000) translate(-347.691275, -409.173691) '
          />
          <polygon
            id='bras_gauche'
            stroke='#000000'
            transform='translate(284.793785, 210.986959) scale(-1, 1) rotate(17.000000) translate(-284.793785, -210.986959) '
            fill={syoUtils.colorFiller(lesionnel.avant_haut_bras_gauche)}
            points='266.434841 164.301627 266.434841 257.672291 303.152728 257.672291 303.152728 164.301627'
          />
          <polygon
            id='avant_bras_gauche'
            stroke='#000000'
            transform='translate(320.416503, 319.516926) scale(-1, 1) rotate(17.000000) translate(-320.416503, -319.516926) '
            fill={syoUtils.colorFiller(lesionnel.avant_avant_bras_gauche)}
            points='303.754422 272.831594 303.754422 366.202259 337.078584 366.202259 337.078584 272.831594'
          />
          <ellipse
            id='epaule_gauche'
            stroke='#000000'
            transform='translate(266.000000, 150.500000) scale(-1, 1) translate(-266.000000, -150.500000) '
            cx='266'
            cy='150.5'
            rx='25'
            ry='24.5'
            fill={syoUtils.colorFiller(lesionnel.avant_epaule_gauche)}
          />
          <ellipse
            id='coude_gauche'
            stroke='#000000'
            transform='translate(302.500000, 266.000000) scale(-1, 1) translate(-302.500000, -266.000000) '
            cx='302.5'
            cy='266'
            rx='21.5'
            ry='21'
            fill={syoUtils.colorFiller(lesionnel.avant_articulation_bras_gauche)}
          />
          <ellipse
            id='poignet_gauche'
            stroke='#000000'
            transform='translate(333.000000, 365.500000) scale(-1, 1) translate(-333.000000, -365.500000) '
            cx='333'
            cy='365.5'
            rx='21'
            fill={syoUtils.colorFiller(lesionnel.avant_poignet_gauche)}
            ry='20.5'
          />
          <path
            d='M34.8423513,372.31788 C27.5617935,381.277496 22.3051897,387.958887 19.0725399,392.362053 C15.8398902,396.765219 10.517334,404.493616 3.10487139,415.547244 C3.59125356,417.069928 4.06953226,418.056133 4.53970748,418.50586 C5.00988271,418.955587 5.94250773,419.318925 7.33758253,419.595874 L23.7570176,407.589188 C19.0427666,412.883531 15.8829312,416.88576 14.2775111,419.595874 C12.672091,422.305988 10.9049939,426.620929 8.97621984,432.540696 C10.8658099,439.43067 13.1133398,443.576512 15.7188094,444.978221 C18.3242789,446.37993 23.7829593,446.37993 32.0948505,444.978221 C37.1472466,437.402311 40.642524,431.568928 42.5806828,427.478071 C44.5188416,423.387214 46.5056635,417.649573 48.5411485,410.265147 C51.7813926,403.504513 54.0296113,398.340635 55.2858045,394.773515 C56.5419977,391.206395 57.9509227,385.985348 59.5125795,379.110374 L34.8423513,372.31788 Z'
            id='main_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_main_droite)}
            transform='translate(31.308725, 409.173691) rotate(-5.000000) translate(-31.308725, -409.173691) '
          />
          <polygon
            id='bras_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_haut_bras_droit)}
            transform='translate(94.206215, 210.986959) rotate(17.000000) translate(-94.206215, -210.986959) '
            points='75.8472718 164.301627 75.8472718 257.672291 112.565159 257.672291 112.565159 164.301627'
          />
          <polygon
            id='avant_bras_droit'
            fill={syoUtils.colorFiller(lesionnel.avant_avant_bras_droit)}
            stroke='#000000'
            transform='translate(58.583497, 319.516926) rotate(17.000000) translate(-58.583497, -319.516926) '
            points='41.9214164 272.831594 41.9214164 366.202259 75.2455784 366.202259 75.2455784 272.831594'
          />
          <ellipse
            id='epaule_droit'
            fill={syoUtils.colorFiller(lesionnel.avant_epaule_droite)}
            stroke='#000000'
            cx='114'
            cy='150.5'
            rx='25'
            ry='24.5'
          />
          <ellipse
            id='coude_droit'
            fill={syoUtils.colorFiller(lesionnel.avant_articulation_bras_droit)}
            stroke='#000000'
            cx='76.5'
            cy='266'
            rx='21.5'
            ry='21'
          />
          <ellipse
            id='poignet_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_poignet_droit)}
            cx='46'
            cy='365.5'
            rx='21'
            ry='20.5'
          />
          <ellipse
            id='cou'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_cou)}
            cx='193'
            cy='129'
            rx='24'
            ry='29'
          />
          <ellipse
            id='tete'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.avant_tete)}
            cx='192.5'
            cy='64.5'
            rx='45.5'
            ry='64.5'
          />
        </g>
      </g>
    );
  }, [lesionnel]);
}

export function Back({ lesionnel }: BackV1Props) {
  return useMemo(() => {
    return (
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Back' transform='translate(-2.000000, 1.000000)' strokeWidth='2'>
          <ellipse
            className='test'
            id='bassin_bas'
            stroke='#000000'
            fillRule='nonzero'
            cx='191.5'
            cy='382.5'
            rx='47.5'
            fill={syoUtils.colorFiller(lesionnel.arriere_bassin_bas)}
            ry='46.5'
          />
          <path
            d='M121.41841,337 C117.806137,352.410853 116,364.406562 116,372.987126 C116,381.56769 117.806137,393.563399 121.41841,408.974252 L261.03474,408.974252 C265.799051,392.932381 268.181206,380.936673 268.181206,372.987126 C268.181206,365.03758 265.799051,353.041871 261.03474,337 L121.41841,337 Z'
            id='bassin_haut'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_bassin_haut)}
            fillRule='nonzero'
          />
          <polygon
            id='dos'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_dos)}
            fillRule='nonzero'
            points='122 337 262 337 262 205.572786 254.125076 134 132.006696 134 122 205.572786'
          />
          <rect
            id='colonne_haut'
            stroke='#000000'
            x='183'
            y='100'
            fill={syoUtils.colorFiller(lesionnel.arriere_colonne_vertebrale_haut)}
            width='19'
            height='190'
            rx='8'
          />
          <rect
            id='colonne_bas'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_colonne_vertebrale_bas)}
            x='183'
            y='290'
            width='19'
            height='91'
            rx='8'
          />
          <path
            d='M92.4807924,685 C82.359001,686.928075 75.4207799,691.544419 71.6661292,698.849033 C67.9114784,706.153646 63.0227687,724.546408 57,754.027319 C58.4816021,766.974168 61.226657,774.629651 65.2351646,776.993768 C69.2436722,779.357886 78.3255482,778.677629 92.4807924,774.952999 C107.469737,758.379417 117.026025,746.187819 121.149656,738.378203 C125.178267,730.748542 129.523476,716.40542 127.878405,701.247988 C126.972345,692.899686 126.972345,692.899686 127.878405,701.247988 C110.066237,707.561165 99.9887955,710.180085 97.6460824,709.104747 C95.3033694,708.02941 93.581606,699.994494 92.4807924,685 Z'
            id='pied_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_pied_gauche)}
            fillRule='nonzero'
          />
          <path
            d='M94.8241429,674.307233 L89.899971,597.083799 L94.8241429,519.860365 L148.172969,519.860365 C151.206395,553.877257 152.723107,579.618402 152.723107,597.083799 C152.723107,614.549196 151.206395,640.29034 148.172969,674.307233 L94.8241429,674.307233 Z'
            id='jambe_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_jambe_gauche)}
            fillRule='nonzero'
            transform='translate(121.311539, 597.083799) rotate(6.000000) translate(-121.311539, -597.083799) '
          />
          <path
            d='M113.446615,518.115605 L108.522443,461.164145 L113.446615,404.212685 L166.795441,404.212685 C169.828866,429.299781 171.345579,448.283601 171.345579,461.164145 C171.345579,474.044689 169.828866,493.028509 166.795441,518.115605 L113.446615,518.115605 Z'
            id='cuisse_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_cuisse_gauche)}
            fillRule='nonzero'
            transform='translate(139.934011, 461.164145) rotate(9.000000) translate(-139.934011, -461.164145) '
          />
          <ellipse
            id='cheville_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_articulation_pied_gauche)}
            fillRule='nonzero'
            cx='110'
            cy='685'
            rx='29'
            ry='26'
          />
          <ellipse
            id='genou_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_genou_gauche)}
            fillRule='nonzero'
            cx='129'
            cy='514'
            rx='29'
            ry='26'
          />
          <path
            d='M288.480792,686 C278.359001,687.928075 271.42078,692.544419 267.666129,699.849033 C263.911478,707.153646 259.022769,725.546408 253,755.027319 C254.481602,767.974168 257.226657,775.629651 261.235165,777.993768 C265.243672,780.357886 274.325548,779.677629 288.480792,775.952999 C303.469737,759.379417 313.026025,747.187819 317.149656,739.378203 C321.178267,731.748542 325.523476,717.40542 323.878405,702.247988 C322.519315,689.725534 297.160152,711.717753 293.646082,710.104747 C291.303369,709.02941 289.581606,700.994494 288.480792,686 Z'
            id='pied_droit'
            stroke='#020202'
            fill={syoUtils.colorFiller(lesionnel.arriere_pied_droit)}
            fillRule='nonzero'
            transform='translate(288.617822, 732.685332) scale(-1, 1) translate(-288.617822, -732.685332) '
          />
          <path
            d='M217.446615,518.115605 L212.522443,461.164145 L217.446615,404.212685 L270.795441,404.212685 C273.828867,429.299781 275.345579,448.283601 275.345579,461.164145 C275.345579,474.044689 273.828867,493.028509 270.795441,518.115605 L217.446615,518.115605 Z'
            id='cuisse_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_cuisse_droite)}
            fillRule='nonzero'
            transform='translate(243.934011, 461.164145) scale(-1, 1) rotate(9.000000) translate(-243.934011, -461.164145) '
          />
          <path
            d='M234.824143,672.307233 L229.899971,595.083799 L234.824143,517.860365 L288.172969,517.860365 C291.206395,551.877257 292.723107,577.618402 292.723107,595.083799 C292.723107,612.549196 291.206395,638.29034 288.172969,672.307233 L234.824143,672.307233 Z'
            id='jambe_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_jambe_droite)}
            fillRule='nonzero'
            transform='translate(261.311539, 595.083799) rotate(-6.000000) translate(-261.311539, -595.083799) '
          />
          <ellipse
            id='genou_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_genou_droit)}
            fillRule='nonzero'
            cx='254'
            cy='515'
            rx='29'
            ry='26'
          />
          <ellipse
            id='cheville_droite'
            stroke='#020202'
            fill={syoUtils.colorFiller(lesionnel.arriere_articulation_pied_droit)}
            fillRule='nonzero'
            cx='272'
            cy='686'
            rx='29'
            ry='26'
          />
          <path
            d='M351.2249,372.31788 C343.944343,381.277496 338.687739,387.958887 335.455089,392.362053 C332.222439,396.765219 326.899883,404.493616 319.48742,415.547244 C319.973803,417.069928 320.452081,418.056133 320.922257,418.50586 C321.392432,418.955587 322.325057,419.318925 323.720132,419.595874 L340.139567,407.589188 C335.425316,412.883531 332.26548,416.88576 330.66006,419.595874 C329.05464,422.305988 327.287543,426.620929 325.358769,432.540696 C327.248359,439.43067 329.495889,443.576512 332.101359,444.978221 C334.706828,446.37993 340.165508,446.37993 348.4774,444.978221 C353.529796,437.402311 357.025073,431.568928 358.963232,427.478071 C360.901391,423.387214 362.888213,417.649573 364.923698,410.265147 C368.163942,403.504513 370.41216,398.340635 371.668354,394.773515 C372.924547,391.206395 374.333472,385.985348 375.895129,379.110374 L351.2249,372.31788 Z'
            id='main_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_main_droite)}
            fillRule='nonzero'
            transform='translate(347.691275, 409.173691) scale(-1, 1) rotate(-5.000000) translate(-347.691275, -409.173691) '
          />
          <polygon
            id='bras_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_haut_bras_droit)}
            fillRule='nonzero'
            transform='translate(284.793785, 210.986959) scale(-1, 1) rotate(17.000000) translate(-284.793785, -210.986959) '
            points='266.434841 164.301627 266.434841 257.672291 303.152728 257.672291 303.152728 164.301627'
          />
          <polygon
            id='avant_bras_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_avant_bras_droit)}
            fillRule='nonzero'
            transform='translate(320.416503, 319.516926) scale(-1, 1) rotate(17.000000) translate(-320.416503, -319.516926)'
            points='303.754422 272.831594 303.754422 366.202259 337.078584 366.202259 337.078584 272.831594'
          />
          <ellipse
            id='epaule_droite'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_epaule_droite)}
            fillRule='nonzero'
            transform='translate(266.000000, 150.500000) scale(-1, 1) translate(-266.000000, -150.500000)'
            cx='266'
            cy='150.5'
            rx='25'
            ry='24.5'
          />
          <ellipse
            id='coude_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_articulation_bras_droit)}
            fillRule='nonzero'
            transform='translate(302.500000, 266.000000) scale(-1, 1) translate(-302.500000, -266.000000)'
            cx='302.5'
            cy='266'
            rx='21.5'
            ry='21'
          />
          <ellipse
            id='poignet_droit'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_poignet_droit)}
            fillRule='nonzero'
            transform='translate(333.000000, 365.500000) scale(-1, 1) translate(-333.000000, -365.500000)'
            cx='333'
            cy='365.5'
            rx='21'
            ry='20.5'
          />
          <path
            d='M34.8423513,372.31788 C27.5617935,381.277496 22.3051897,387.958887 19.0725399,392.362053 C15.8398902,396.765219 10.517334,404.493616 3.10487139,415.547244 C3.59125356,417.069928 4.06953226,418.056133 4.53970748,418.50586 C5.00988271,418.955587 5.94250773,419.318925 7.33758253,419.595874 L23.7570176,407.589188 C19.0427666,412.883531 15.8829312,416.88576 14.2775111,419.595874 C12.672091,422.305988 10.9049939,426.620929 8.97621984,432.540696 C10.8658099,439.43067 13.1133398,443.576512 15.7188094,444.978221 C18.3242789,446.37993 23.7829593,446.37993 32.0948505,444.978221 C37.1472466,437.402311 40.642524,431.568928 42.5806828,427.478071 C44.5188416,423.387214 46.5056635,417.649573 48.5411485,410.265147 C51.7813926,403.504513 54.0296113,398.340635 55.2858045,394.773515 C56.5419977,391.206395 57.9509227,385.985348 59.5125795,379.110374 L34.8423513,372.31788 Z'
            id='main_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_main_gauche)}
            fillRule='nonzero'
            transform='translate(31.308725, 409.173691) rotate(-5.000000) translate(-31.308725, -409.173691)'
          />
          <polygon
            id='bras_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_haut_bras_gauche)}
            fillRule='nonzero'
            transform='translate(94.206215, 210.986959) rotate(17.000000) translate(-94.206215, -210.986959) '
            points='75.8472718 164.301627 75.8472718 257.672291 112.565159 257.672291 112.565159 164.301627'
          />
          <polygon
            id='avant_bras_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_avant_bras_gauche)}
            fillRule='nonzero'
            transform='translate(58.583497, 319.516926) rotate(17.000000) translate(-58.583497, -319.516926)'
            points='41.9214164 272.831594 41.9214164 366.202259 75.2455784 366.202259 75.2455784 272.831594'
          />
          <ellipse
            id='epaule_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_epaule_gauche)}
            fillRule='nonzero'
            cx='114'
            cy='150.5'
            rx='25'
            ry='24.5'
          />
          <ellipse
            id='coude_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_articulation_bras_gauche)}
            fillRule='nonzero'
            cx='76.5'
            cy='266'
            rx='21.5'
            ry='21'
          />
          <ellipse
            id='poignet_gauche'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_poignet_gauche)}
            fillRule='nonzero'
            cx='46'
            cy='365.5'
            rx='21'
            ry='20.5'
          />
          <ellipse
            id='tete'
            stroke='#000000'
            fill={syoUtils.colorFiller(lesionnel.arriere_tete)}
            fillRule='nonzero'
            cx='190.5'
            cy='64.5'
            rx='45.5'
            ry='64.5'
          />
          <ellipse
            id='cou'
            stroke='#000000'
            fillRule='nonzero'
            cx='192'
            cy='129'
            rx='24'
            fill={syoUtils.colorFiller(lesionnel.arriere_nuque)}
            ry='29'
          />
        </g>
      </g>
    );
  }, [lesionnel]);
}
