import styled from "styled-components";

import theme from "./theme";

const Card = styled.li`
  position: relative;
  list-style: none;
  background-color: ${theme.backgroundColor};
  box-shadow: ${theme.boxShadow};
  border-radius: ${theme.borderRadius};
  border: 3px solid transparent;
  contain: content;
  max-width: 400px;
  min-width: 260px;
  cursor: pointer;
  transition: 0.3s ease-in-out, transform .1s ease-in-out;
  &:hover {
    transform: scale(1.01);
    box-shadow: ${theme.boxShadowLarge};
  }

  &.selected {
    border: 3px solid ${theme.blue};
  }
`;

export default Card;

export const CardsContainer = styled.ul`
  padding: 0px ${theme.small};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${theme.medium};

  @media (min-width: 2220px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 1730px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1005px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 690px) {
    grid-template-columns: 1fr;
    max-width: 432px;
    margin: auto;
  }
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${theme.large} ${theme.medium};

  &.large {
    align-items: flex-start;
  }

  &.row {
    flex-direction: row;
  }

  > * {
    margin-bottom: 0px;
  }

  h2 {
    color: ${theme.titleSecondary};
  }
  h4 {
    color: ${theme.grey2};
  }
  h2, h4 {
    text-align: center;
  }
`;
