import { Link as BaseLink } from "found";
import { DateTime } from "luxon";
import React from "react";
import * as Sentry from "@sentry/browser";

import styled from "styled-components";
import theme from "../ui/theme";
import Card from "../ui/Card";

const Link = styled(BaseLink)`
  color: inherit;

  &:hover {
    color: ${theme.blue};
  }
`;

const BilanList = styled.ul`
  padding: ${theme.small};
  margin-bottom: 0;
`;

const BilanCard = styled(Card)`
  max-width: none;
  min-width: unset;
  margin-bottom: ${theme.small};
  padding: ${theme.thin};
  border: none;
  position: relative;
`;

const BilanLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  ${BilanCard}:hover & {
    color: ${theme.blue};
  }

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Lead = styled.div`
  font-size: 1.1rem;
`;

const Muted = styled.div`
  font-size: 0.9rem;
  color: #888;
`;

const ItemRow = styled.li`
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  & + & {
    border-top: 1px solid #ddd;
  }
`;

const HeaderRow = styled(ItemRow)`
  font-weight: 600;

  & + ${ItemRow} {
    border-top: 1px solid #ddd;
  }
`;

const Column = styled.div`
  padding: 0.25rem 1rem;

  @media (min-width: 1024px) {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.5rem;
    min-width: 0;

    & > ${Lead}, & > ${Muted} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 1023px) {
    &:empty {
      display: none;
    }

    ${Lead} {
      display: inline;
    }

    ${Muted} {
      display: inline;

      :before {
        content: " (";
      }
      :after {
        content: ")";
      }
    }
  }
`;
const LocationColumn = styled(Column)`
  flex-basis: 40%;
`;

const EffectorColumn = styled(Column)`
  flex-basis: 20%;
`;

const ReceiverColumn = styled(Column)`
  flex-basis: 20%;
`;

const ListItem = ({ bilan, sortBilan }) => {
  const yesterday = DateTime.local().minus({ days: 1 });
  const createdInterAt = DateTime.fromISO(bilan.intervention.created_at);
  const createdAt = DateTime.fromISO(bilan.created_at);
  const relativeInterTime = createdInterAt.setLocale("fr-fr").toRelative();
  const relativeTime = createdAt.setLocale("fr-fr").toRelative();
  const createDate =
    createdInterAt < yesterday
      ? `${createdInterAt.setLocale("fr-fr").toLocaleString({month: "long", day: "numeric"})}`
      : `${createdInterAt.setLocale("fr-fr").toLocaleString({hour: "numeric", minute: "2-digit"})}`;
  const receptionDate =
    createdAt < yesterday
      ? `${createdAt.setLocale("fr-fr").toLocaleString({month: "long", day: "numeric"})}`
      : `${createdAt.setLocale("fr-fr").toLocaleString({hour: "numeric", minute: "2-digit"})}`;
  const linkLocation = `/interventions/sso-smp/${bilan.intervention.numero}/bilans/${encodeURIComponent(bilan.id)}`;

    return (
      <BilanCard>
        <BilanLink to={linkLocation}>
          <LocationColumn>
            <Lead>
              <span title={bilan.sso_context?.adresse_intervention}>
                {bilan.sso_context?.adresse_intervention?.substring(0, 60) || <em>Pas d'adresse renseignée</em>}
              </span>
            </Lead>
            <Muted>
              <span>
                {bilan.sso_context?.numero_intervention ? (
                    <abbr title="Numéro intervention">
                      {bilan.sso_context.numero_intervention}
                    </abbr>
                ) : (
                    <em>Numéro intervention NR</em>
                )}
              </span>
            </Muted>
          </LocationColumn>
          <EffectorColumn>
            <Lead>
              {bilan.equipe.vehicule.length === 0 ? (
                <em title="Véhicule inconnu">
                  Véhicule inconnu
                </em>
              ) : (
                <span title={bilan.equipe.vehicule}>
                  {bilan.equipe.vehicule}
                </span>
              )}
            </Lead>
            <Muted>
              <span title={bilan.intervention.performer}>
                {bilan.intervention.performer}
              </span>
            </Muted>
          </EffectorColumn>
          <ReceiverColumn>
            <Lead>{bilan.count_sso_examen}</Lead>
            <Lead>{bilan.count_smp_examen}</Lead>
          </ReceiverColumn>
          <ReceiverColumn>
            {sortBilan === "bilans" ? (
              <>
                <Lead>{receptionDate}</Lead>
                <Muted>
                  <abbr title={createdAt.setLocale("fr-fr").toLocaleString(DateTime.DATETIME_FULL)}>
                    {relativeTime}
                  </abbr>
                </Muted>
              </>
            ) : (
              <>
                <Lead>{createDate}</Lead>
                <Muted>
                  <abbr title={createdInterAt.setLocale("fr-fr").toLocaleString(DateTime.DATETIME_FULL)}>
                    {relativeInterTime}
                  </abbr>
                </Muted>
              </>
            )}
          </ReceiverColumn>
        </BilanLink>
      </BilanCard>
    );
}

const ListHeader = ({sortBilan}) => {
  return (
    <HeaderRow>
      <LocationColumn>
        <Lead>Adresse</Lead>
        <Muted>N° intervention</Muted>
      </LocationColumn>
      <EffectorColumn>
        <Lead>Engin</Lead>
      </EffectorColumn>
      <ReceiverColumn>
        <Lead>Nbr examen SSO</Lead>
        <Lead>Nbr examen SMP</Lead>
      </ReceiverColumn>
      <ReceiverColumn>
        <Lead>Date de {sortBilan === "bilans" ? "réception" : "création"}</Lead>
        <Muted>Reçu depuis</Muted>
      </ReceiverColumn>
    </HeaderRow>
  );
}

const ListSsoSmp = ({
  bilans,
  pui = false,
  sortBilan="bilans",
}) => {
  if (bilans !== null && bilans !== undefined) {
    return (
      <BilanList>
        <ListHeader sortBilan={sortBilan} pui={pui}/>
        {bilans.length > 0 ? (
          <>
            {bilans.map((bilan, index) => (
              <ListItem
                key={index}
                bilan={bilan}
                sortBilan={sortBilan}
              />
            ))}
          </>
        ) : (
          <li style={{margin: "1rem 0.5rem", listStyle: "none"}}>
            <span>Aucune intervention à afficher.</span>
          </li>
        )}
      </BilanList>
    );
  } else {
    Sentry.captureMessage("`bilans` est null ou undefined");
    console.warn("`bilans` est null ou undefined");
    return (<p>Une erreur est survenue lors de l'affichage des interventions.</p>);
  }
}

export default ListSsoSmp;
