import { v4 as uuidV4 } from 'uuid';

export class MobilisationDispatchClass {
	// // L'identifiant unique du départ de ce véhicule / cette équipe, au cas où ils soient déclenchés plusieurs fois sur la même mobilisation
	// public_id?: string;
	// // L'immatriculation du véhicule / de l'équipe (peut être la mème chose que le nom affiché du véhicule)
	// vehicle_id?: string;
	// // Le nom affiché du véhicule / de l'équipe
	// vehicle_name?: string;
	// // L'heure de déclenchement du véhicule / de l'équipe
	// dispatched_at?: string;

  constructor() {
		this.public_id = uuidV4();
		this.vehicle_id = undefined;
		this.vehicle_name = undefined;
		this.dispatched_at = undefined;
	}
}
