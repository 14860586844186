const CHARTS = [
  {
    id: "2cf6847a-2862-4082-aad7-58addc94df36",
    title: "Interventions par jour",
    xType: "time-utc",
    crosshair: true,
    gridLines: [{ kind: "horizontal" }],
    axis: [{ kind: "x" }, { kind: "y" }],
    series: [
      {
        kind: "vertical-bar",
        title: "Nombre d'interventions",
        source: "statistics/interventions_count"
      }
    ]
  },
  {
    id: "302e01c5-cd7c-42ad-8b78-7a39d772129d",
    title: "Nombre cumulé d'interventions",
    xType: "time-utc",
    crosshair: true,
    gridLines: [{ kind: "horizontal" }],
    axis: [{ kind: "x" }, { kind: "y" }],
    series: [
      {
        kind: "line",
        curve: "curveMonotoneX",
        title: "Nombre cumulé d'interventions",
        source: "statistics/interventions_cumulative_count"
      }
    ]
  },
  {
    id: "9525dc9d-03eb-4ced-8589-ba3bbb67223f",
    previewOnly: true,
    title: "Temps médian de régulation",
    organizations: [1, 4],
    gridLines: [],
    axis: [],
    series: [
      {
        kind: "text",
        title: "Temps médian de régulation",
        source: "statistics/regulation_median"
      }
    ]
  },
  {
    id: "3f061966-7f0f-4b13-b9fb-1ddf77e54666",
    title: "Interventions par parc",
    xType: "time-utc",
    crosshair: true,
    organizations: [1, 38],
    gridLines: [{ kind: "horizontal" }],
    axis: [{ kind: "x" }, { kind: "y" }],
    series: [
      {
        kind: "vertical-bar",
        title: "Interventions Grand Parc",
        source: "statistics/interventions_count_pdf_parc"
      },
      {
        kind: "vertical-bar",
        title: "Interventions Cinéscénie",
        source: "statistics/interventions_count_pdf_cines"
      }
    ]
  },
  {
    id: "3a2b5b08-fb11-4024-adaf-a85da14b93b5",
    title: "Interventions par lieu",
    xType: "ordinal",
    crosshair: true,
    organizations: [1, 38],
    gridLines: [{ kind: "horizontal" }],
    axis: [{ kind: "x" }, { kind: "y" }],
    series: [
      {
        kind: "vertical-bar",
        title: "Lieu",
        source: "statistics/interventions_count_pdf_adresse"
      }
    ]
  }
];

export default CHARTS;
