import styled from "styled-components";

const ALERT_COLORS = new Map([
  ["primary", { background: "#1A76FF", border: "#0043D6", text: "#eee" }],
  ["danger", { background: "#FFAE1A", border: "#D77B00", text: "#fff" }],
  ["success", { background: "#155724", border: "#003E0B", text: "#fff" }]
]);

const Alert = styled.div`
  background-color: ${props =>
    ALERT_COLORS.get(props.kind || "primary").background};
  border-left: 3px solid
    ${props => ALERT_COLORS.get(props.kind || "primary").border};
  padding: 1rem;
  color: ${props => ALERT_COLORS.get(props.kind || "primary").text};
  margin-bottom: 1rem;
`;

export default Alert;

export const DangerAlert = styled(Alert)`
  background-color: #ffae1a;
  border-color: #d77b00;
  color: white;
`;
