import React, { useMemo } from 'react';
import { BackV2HandLeft, BackV2HandRight, FrontV2HandLeft, FrontV2HandRight } from './vectorV2/VectorV2';

import type { SyoLib } from '@nfsave/syo-bilan';

type MannequinPartVectorProps = {
  syoLesionnel: SyoLib.Lesionnel;
  position: SyoLib.LesionnelPosition;
  side: SyoLib.LesionnelSide;
};
export function MannequinHandsVector({ syoLesionnel, position, side }: MannequinPartVectorProps) {
  return useMemo(() => {
    if (side === 'right') {
      if (syoLesionnel.lesionnelMainDroitV2 === null) return <span>Une erreur est survenue</span>;
      if (position === 'front') return <FrontV2HandRight lesionnel={syoLesionnel.lesionnelMainDroitV2} />;
      if (position === 'back') return <BackV2HandRight lesionnel={syoLesionnel.lesionnelMainDroitV2} />;
    } else if (side === 'left') {
      if (syoLesionnel.lesionnelMainGaucheV2 === null) return <span>Une erreur est survenue</span>;
      if (position === 'front') return <FrontV2HandLeft lesionnel={syoLesionnel.lesionnelMainGaucheV2} />;
      if (position === 'back') return <BackV2HandLeft lesionnel={syoLesionnel.lesionnelMainGaucheV2} />;
    }
    return <></>;
  }, [syoLesionnel, position, side]);
}
