import styled from "styled-components";
import theme from "./theme";

const Input = styled.input`
  height: 38px;
  display: block;
  width: 100%;
  padding: ${props => (props.size === "small" ? ".25rem" : ".5rem")};
  border-radius: ${props => (props.size === "small" ? ".125rem" : ".25rem")};
  font-size: ${props => (props.size === "small" ? ".85rem" : "1rem")};
  border-color: ${theme.grey5};
  border-style: solid;
  border-width: 1px;

  :focus {
    border-color: ${theme.blue};
    box-shadow: 0 0 ${theme.borderRadius} ${theme.blue};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.not-validated,
  &:invalid {
    border-color: ${theme.red};
  }
`;

export const InputRightIconCntnr = styled.div`
  position: relative;
  width: 100%;
`;

export const InputRightIcon = styled.div`
  color: #AAA;
  margin: 0.4rem;
  position: absolute;
  right: 10px;
  top: 0px;
`;

export const InputIconCntnr = styled.div`
  display: flex;
`;

export const InputIcon = styled.div`
  border: 1px solid ${theme.grey5};
  border-right-width: 0;
  border-top-left-radius: ${theme.borderRadius};
  border-bottom-left-radius: ${theme.borderRadius};
  padding: 0.33rem ${theme.thin};
  color: ${theme.text};
  
  display: flex;
  align-items: center;

  &.grey {
    color: #AAA;
  }

  &:hover {
    cursor: default;
  }

  & + ${Input},
  & + ${InputRightIconCntnr} ${Input}{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.not-validated {
    border-color: ${theme.red};
  }
`;

export const InputSearchCntnr = styled.div`
  position: relative;
`;

export const InputSearchIcon = styled.div`
  color: #AAA;
  margin: 0.4rem;
  position: absolute;
  & + ${Input} {
    padding-left: 35px;
  }
`;

export default Input;
