import React, {useContext, useEffect, useMemo, useState} from 'react';
import http from '../../http';
import {useQuery} from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import {isAbortError} from '../../utils';
import LoaderBarContext from '../../ui/useLoaderBar';

import PageHeader, {Cntnr, Title} from '../../ui/PageHeader';
import Input, {InputSearchCntnr, InputSearchIcon} from '../../ui/Input';
import Button, {BlockButton} from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import styled from 'styled-components';
import theme from '../../ui/theme';
import Select from "../../ui/Select";
import Tag from "../../ui/Tag";
import SearchBarCompo, {FilterBar, SearchBar} from "../../ui/SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import {MEDIC_DRUG_ROUTES_CODE} from "../../interventions/utils";

const CreateRowItem = styled.div`
  display: grid;
  grid-template-columns: 38% 38% 20%;
  gap: 2%;
  
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const RowItem = styled.div`
  display: flex;
  
  input:first-child {
    margin-right: 1rem;
  }
  
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;

  & > ${BlockButton} {
    margin-left: ${theme.small};
  }

  @media (max-width: 950px) {
    margin-top: ${theme.thin};
    & > ${BlockButton}:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 300px) {
    flex-direction: column;
    & > ${BlockButton} {
      margin-left: 0;
    }
  }
`;

function DrugRouteItem({drugRoute, drugRoutesRefetch}) {
  const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);
  const [name, setName] = useState(drugRoute.name);

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.patch(`drug_routes/${encodeURIComponent(drugRoute.id)}.json`, {
        json: {
          drug_route: {
            name: name,
            code: drugRoute.code,
          }
        },
      });
      toast.success('Voie d\'administration modifiéd avec succès');
      drugRoutesRefetch();
    } finally {
      setLoaderBar(false);
    }
  };

  const handleDeleteClick = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`drug_routes/${encodeURIComponent(drugRoute.id)}.json`);
      toast.success('Voie d\'administration supprimée avec succès');
      drugRoutesRefetch();
    } finally {
      setLoaderBar(false);
    }
  };

  return (
    <form style={{paddingTop: '.5rem', paddingBottom: '.5rem'}} onSubmit={handleFormSubmit}>
      <RowItem>
        <Input value={name} onChange={ev => setName(ev.target.value)} disabled={loaderBarState}/>
        <Input value={drugRoute.code} disabled/>
        <ActionItem width={1}>
          <BlockButton type='submit' disabled={loaderBarState || name === drugRoute.name}>
            Sauvegarder
          </BlockButton>
          <BlockButton type='button' className='warn' disabled={loaderBarState} onClick={handleDeleteClick}>
            Supprimer
          </BlockButton>
        </ActionItem>
      </RowItem>
    </form>
  );
}

export default function DrugRoutes() {
  const controller = new AbortController();

  const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [search, setSearch] = useState('');

  const {
    isLoading: drugRoutesIsLoading,
    data: drugRoutes,
    refetch: drugRoutesRefetch,
  } = useQuery(
    ['drug-routes'],
    async () => {
      return await http
        .get(`drug_routes.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.drug_routes;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des voies d\'administration de votre organisation');
          throw error;
        });
    },
    {cacheTime: 0}
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post('drug_routes.json', {
        json: {
          drug_route: {
            name: name,
            code: code[0],
          }
        },
      });
      toast.success('Voie d\'administration ajouté avec succès');
      setCode('');
      setName('');
      drugRoutesRefetch();
    } catch (e) {
      console.error(e);
      // Sentry.captureException(e);
      toast.error('Impossible de créer la voie d\'administration');
    } finally {
      setLoaderBar(false);
    }
  };

  const clearFilter = () => {
    setSearch('');
  };

  const alphabeticSort = array => {
    return array?.sort((left, right) => {
      const lhs = (left.drug || '').toUpperCase();
      const rhs = (right.drug || '').toUpperCase();
      return lhs > rhs ? 1 : -1;
    });
  };

  const drugRoutesFiltered = useMemo(() => {
    let temp = [];
    drugRoutes?.map(s => {
      if (s.name?.toLowerCase().includes(search.toLocaleLowerCase())) temp.push(s);
    });
    return alphabeticSort(temp);
  }, [search, drugRoutes]);

  return (
    <>
      <PageHeader>
        <Title>Voies d'administrations</Title>
      </PageHeader>

      {drugRoutesIsLoading ? (
        <LoadingSpinner className='center vh-50'/>
      ) : (
        <Cntnr>
          <form onSubmit={handleFormSubmit}>
            <CreateRowItem>
              <Input value={name} onChange={ev => setName(ev.target.value)} placeholder='Nom' disabled={loaderBarState} />
              <Select
                id='input-select-unit'
                isSearchable={true}
                isClearable={true}
                options={MEDIC_DRUG_ROUTES_CODE}
                value={code}
                isDisabled={loaderBarState}
                getOptionLabel={option => `${option}`}
                getOptionValue={option => `${option}`}
                onChange={ev => setCode(ev)}
                placeholder='Sélectionnez un code'
              />
              <ActionItem width={1}>
                <BlockButton type='submit' disabled={loaderBarState || code === '' || name === ''}>Ajouter</BlockButton>
              </ActionItem>
            </CreateRowItem>
          </form>
          <hr/>
          <SearchBarCompo hasTag={true} tags={<>{search.length > 0 && <Tag text={search} callback={clearFilter}/>}</>}>
            <SearchBar>
              <FilterBar>
                <InputSearchCntnr>
                  <InputSearchIcon>
                    <SearchIcon/>
                  </InputSearchIcon>
                  <Input type='search' value={search} onChange={ev => setSearch(ev.target.value)} placeholder="Voies d'administations"/>
                </InputSearchCntnr>
                <Button className='warn' onClick={clearFilter}>Réinitialiser les filtres</Button>
              </FilterBar>
            </SearchBar>
          </SearchBarCompo>
          {drugRoutesFiltered.map(drugRoute => (
            <DrugRouteItem key={drugRoute.id} drugRoute={drugRoute} drugRoutesRefetch={() => drugRoutesRefetch()}/>
          ))}
        </Cntnr>
      )}
    </>
  );
}
