import React from "react";

import styled from "styled-components";
import theme from "./theme";

const LoadingCntnr = styled.div`
  position: fixed;
  z-index: 20;
  top: 0px;
  width: 100%;
  height: 4px;
`;

const Loader = styled.div`
  width: 150%;
  height: 100%;
  position: absolute;
  background: linear-gradient(45deg, ${theme.blueTransparent}, ${theme.blue}, ${theme.blueTransparent});
  left: -100%;
  animation: load 2s cubic-bezier(.6, .1, .4, .9) infinite;

  @keyframes load{
    0%{
        left: -100%;
    }
    50%{
        left: 100%;
    }
    100%{
        left: 100%;
    }
  }
`;

const LoadingBar = () => {
  return (
    <LoadingCntnr>
      <Loader />
    </LoadingCntnr>
  );
};

export default LoadingBar;