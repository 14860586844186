import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';

import styled from 'styled-components';
import PageHeader, { Actions, Title } from '../../ui/PageHeader';
import NarrowContainer from '../../ui/NarrowContainer';
import DynamicChart from '../DynamicChart';
import Input from '../../ui/Input';
import Button from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';

const ChartContainer = styled.div`
  height: 600px;
`;

export default function Show({ router, match }) {
  const controller = new AbortController();

  const { isLoading: chartIdIsLoading, data } = useQuery(
    ['chartId'],
    async () => {
      return await http
        .get(`charts/${match.params.chartId}`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération du graphique');
        });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  let intervalStart = DateTime.local().startOf('month');
  if (typeof match.location.query.intervalStart !== 'undefined') {
    intervalStart = DateTime.fromISO(match.location.query.intervalStart);
  }
  let intervalEnd = DateTime.local().endOf('month');
  if (typeof match.location.query.intervalEnd !== 'undefined') {
    intervalEnd = DateTime.fromISO(match.location.query.intervalEnd);
  }
  const handleIntervalChange = boundName => ev => {
    if (ev.target.value == null || ev.target.value === '') {
      return;
    }
    router.push({
      pathname: `/statistiques/${encodeURIComponent(data.id)}`,
      query: {
        ...match.location.query,
        [boundName]: ev.target.value,
      },
    });
  };

  const deleteChart = async () => {
    if (window.confirm('Souhaitez-vous supprimer ce graphique?'))
      try {
        await http.delete(`charts/${data.id}`).json();
        router.push('/statistiques/apercu');
      } catch (err) {
        toast('Impossible de supprimer le graphique', {
          type: 'error',
        });
      }
  };

  const chartConfig = form => {
    if (form.kind == null || form.kind.toLowerCase() === 'xy') {
      return {
        xType: 'time-utc',
        crosshair: true,
        gridLines: [{ kind: 'horizontal' }],
        axis: [{ kind: 'x' }, { kind: 'y' }],
        series: form.dataSources.map(dataSource => ({
          kind: dataSource.display,
          curve: 'curveMonotoneX',
          title: dataSource.label,
          source: dataSource.url,
        })),
      };
    } else {
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Statistiques{!chartIdIsLoading ? ` ${data.title}` : ''}</Title>
        <Actions>
          <span style={{ padding: '.5rem' }}>De</span>
          <Input
            style={{ width: 200 }}
            type='date'
            value={intervalStart.toISODate()}
            onChange={handleIntervalChange('intervalStart')}
          />
          <span style={{ padding: '.5rem' }}>à</span>
          <Input
            style={{ width: 200 }}
            type='date'
            value={intervalEnd.toISODate()}
            onChange={handleIntervalChange('intervalEnd')}
          />
          <Button style={{ marginLeft: 16 }} onClick={deleteChart}>
            Supprimer
          </Button>
        </Actions>
      </PageHeader>

      {chartIdIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <NarrowContainer>
          <p style={{ marginLeft: 16 }}>{data.description}</p>
          <ChartContainer>
            <DynamicChart config={chartConfig(data)} intervalStart={intervalStart} intervalEnd={intervalEnd} />
          </ChartContainer>
        </NarrowContainer>
      )}
    </>
  );
}
