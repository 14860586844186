import styled from "styled-components";
import Button, { LinkButton } from "./Button";
import theme from "./theme";

const PageHeader = styled.div`
  padding: 1rem;
  min-height: 62px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    padding-top: ${theme.thin};
    padding-bottom: ${theme.thin};
  }
`;

export default PageHeader;

export const FloatingHeader = styled(PageHeader)`
  position: relative;
  z-index: 9;
  @media (min-width: 768px) {
    position: sticky;
    top: 0;
    background-color: ${theme.backgroundColor};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Title = styled.h1`
  > small {
    background-color: ${theme.red};
    color: ${theme.backgroundColor};
    border-radius: 0.25rem;
    font-size: 0.6em;
    margin-left: ${theme.thin};
    padding: 0.25rem ${theme.thin};
    height: min-content;
    align-self: center;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-grow: 1;
    order: 1;
    margin-bottom: 0;
  }
`;

export const Subtitle = styled.h4`
  color: ${theme.grey3};
  margin-bottom: ${theme.thin};

  @media (min-width: 768px) {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 85%;
    order: 3;
    margin-bottom: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
  & > ${Button} {
    margin-top: ${theme.thin};
  }
  & > ${LinkButton} {
    margin-top: ${theme.thin};
    text-align: center;
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-end;
    order: 2;
    min-width: 5%;

    & > ${Button} {
      display: flex;
    }
    & > ${LinkButton} {
      width: auto;
    }

    & > ${Button} + ${Button} {
      margin-left: ${theme.thin};
    }

    & > ${Button} + ${LinkButton} {
      margin-left: ${theme.thin};
    }

    & > ${LinkButton} + ${Button} {
      margin-left: ${theme.thin};
    }

    & > ${LinkButton} + ${LinkButton} {
      margin-left: ${theme.thin};
    }
  }
`;

export const SubActions = styled.div`
  margin-top: ${theme.thin};
  @media (min-width: 768px) {
    flex-basis: 15%;
    order: 4;
  }
`;

export const Cntnr = styled.div`
  padding: 0 ${theme.small};
`;