import React, { useState, useEffect, useContext, useMemo } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { hasIndividualOrEquivalentPerm, INDIVIDUAL_PERMISSIONS, isAbortError } from '../../utils';
import CurrentUserContext from '../../CurrentUserContext';
import { data as syoData, utils as syoUtils } from '@nfsave/syo-bilan';
import queryString from 'query-string';

import List from '../List';
import PageHeader, { Title } from '../../ui/PageHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';
import BoundedPagination from '../../ui/BoundedPagination';
import Button from '../../ui/Button';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import styled from 'styled-components';
import theme from '../../ui/theme';
import SearchBarCompo, { SearchBar } from '../../ui/SearchBar';
import Select, { SelectHalfSize, ValueContainerLimitSelected as ValueContainer } from '../../ui/Select';
import LoaderBarContext from '../../ui/useLoaderBar';

const victimIdentityPerm = 'web:morgane:dont-use:full-experimental';

const FilterBar = styled.form`
  width: 100%;

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    & .item-primary {
      width: 35%;
    }
    & .item-secondary {
      width: 30%;
    }
  }

  div.rowWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    > div {
      width: 25%;
    }
  }

  div.rowAction {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-bottom: ${theme.small};
    }
    > button:first-child {
      margin-right: ${theme.small};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div.row {
      > *:not(:last-child) {
        padding-right: ${theme.small};
      }
    }

    div.rowWrap {
      > *:nth-child(even) {
        padding-right: 0;
      }
      > div {
        width: 50%;
      }
    }
  }

  @media (max-width: 680px) {
    div.row {
      flex-direction: column;
      > * {
        padding-right: 0 !important;
      }
      & .item-primary,
      & .item-secondary {
        width: 100%;
      }
    }

    div.rowWrap {
      > *:not(:last-child) {
        padding-right: 0;
      }
      > div {
        width: 100%;
      }
    }

    div.rowAction {
      flex-direction: column;
      > button {
        width: 100%;
      }
      > button:first-child {
        margin-right: 0;
      }
    }
  }
`;

const SMUR_OPTIONS = [
  { value: 'true', label: 'Oui' },
  { value: 'false', label: 'Non' },
];

const PRESENTE_OPTIONS = [
  { value: 'true', label: 'Cotation renseignée' },
  { value: 'false', label: 'Cotation non renseignée' },
];

const INTER_TYPE_OPTIONS = [
  {
    value: 'Strict',
    label: 'Égale a',
  },
  {
    value: 'Contenu',
    label: 'Contient',
  },
];

function Archive({ match, router }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { setLoaderBar } = useContext(LoaderBarContext);

  useEffect(() => {
    const bilanFilter = JSON.parse(sessionStorage.getItem('bilanFilter'));
    if (bilanFilter) {
      match.location.query = {
        ...match.location.query,
        ...bilanFilter.filter,
      };
    }
  }, [router, match]);

  const controller = new AbortController();

  const {
    isLoading: payloadIsLoading,
    isFetching: payloadIsFetching,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    ['archiveBilans'],
    async () => {
      setLoaderBar(true);
      const options = {};
      if (match.location.query.page) options.page = match.location.query.page;
      if (match.location.query.createdAfter) options.created_after = match.location.query.createdAfter;
      if (match.location.query.createdBefore) options.created_before = match.location.query.createdBefore;
      if (match.location.query.createdEntity) options.created_entity = match.location.query.createdEntity;
      if (match.location.query.vehicleNames) {
        if (Array.isArray(match.location.query.vehicleNames)) {
          options.vehicle_names = match.location.query.vehicleNames;
        } else if (typeof match.location.query.vehicleNames === 'string') {
          options.vehicle_names = [match.location.query.vehicleNames];
        }
      }
      if (match.location.query.firstResponderSerial) {
        if (match.location.query.firstResponderSerialType === 'Contenu') {
          options.first_responder_serial_contain = match.location.query.firstResponderSerial;
        } else {
          options.first_responder_serial = match.location.query.firstResponderSerial;
        }
      }
      if (match.location.query.medicalSerial) options.medical_serial = match.location.query.medicalSerial;
      if (match.location.query.victimGivenName) options.victim_given_name = match.location.query.victimGivenName;
      if (match.location.query.victimFamilyName) options.victim_family_name = match.location.query.victimFamilyName;
      if (match.location.query.victimBirthDate) options.victim_birth_date = match.location.query.victimBirthDate;
      if (match.location.query.authorId) options.author_id = match.location.query.authorId;
      if (match.location.query.priorisation) options.priorisation = match.location.query.priorisation;
      if (match.location.query.cotation38Presente)
        options.cotation38_presente = match.location.query.cotation38Presente;
      if (match.location.query.cotation38Pisu) options.cotation38_pisu = match.location.query.cotation38Pisu;
      if (match.location.query.cotation38Pathologie)
        options.cotation38_pathologie = match.location.query.cotation38Pathologie;
      if (match.location.query.cotation38Indicateurs)
        options.cotation38_indicateurs = match.location.query.cotation38Indicateurs;
      if (match.location.query.cotation38NotationPisu)
        options.cotation38_notation_pisu = match.location.query.cotation38NotationPisu;
      if (match.location.query.cotation38NotationGassppe)
        options.cotation38_notation_gassppe = match.location.query.cotation38NotationGassppe;
      if (match.location.query.cotation38NotationComportement)
        options.cotation38_notation_comportement = match.location.query.cotation38NotationComportement;
      if (match.location.query.cotation38NotationRedaction)
        options.cotation38_notation_redaction = match.location.query.cotation38NotationRedaction;
      if (match.location.query.cotation38AntalgieEvnInitial)
        options.cotation38_antalgie_evn_initial = match.location.query.cotation38AntalgieEvnInitial;
      if (match.location.query.cotation38AntalgieEvnFinal)
        options.cotation38_antalgie_evn_final = match.location.query.cotation38AntalgieEvnFinal;
      if (match.location.query.cotation38SmurSurPlace)
        options.cotation38_smur_sur_place = match.location.query.cotation38SmurSurPlace;

      const searchParamsString = queryString.stringify(options, { arrayFormat: 'bracket' });

      setFilters();

      try {
        return await http
          .get(`bilans/archive`, {
            signal: controller.signal,
            searchParams: searchParamsString,
          })
          .json()
          .then(res => {
            return res;
          });
      } catch (error) {
        if (isAbortError(error)) return;
        toast.warn(`Une erreur est survenue pendant la récupération des interventions archivées.`, {
          toastId: 'archiveBilans',
        });
        console.error(error);
        Sentry.captureException(error);
      } finally {
        setLoaderBar(false);
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: vehiclesIsLoading, data: vehicles } = useQuery(
    ['archiveBilans', 'vehicles'],
    async () => {
      return await http
        .get(`vehicles.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          const vehicles = res.data.sort((left, right) => {
            const lhs = (left.name || '').toUpperCase();
            const rhs = (right.name || '').toUpperCase();
            return lhs > rhs ? 1 : -1;
          });
          let temp = [];
          vehicles.map(v =>
            temp.push({
              value: v.name,
              label: v.name,
            })
          );
          return temp;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des engins');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const { data: users, isLoading: usersIsLoading } = useQuery(
    ['archiveBilans', 'users'],
    async () => {
      return await http
        .get(`user`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          let temp = [];
          res.map(u =>
            temp.push({
              value: u.id,
              label: `${u.full_name}${u.matricule ? ` (${u.matricule})` : ''}${u.provider !== 'email' ? ' | AD' : ''}`,
            })
          );
          return temp.sort((left, right) => {
            const lhs = (left.label || '').toLowerCase();
            const rhs = (right.label || '').toLowerCase();
            return lhs > rhs ? 1 : -1;
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des utilisateurs');
          throw error;
        });
    },
    {
      enabled:
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_ADD'), currentUser) ||
        syoUtils.hasPerm(currentUser, 'web:bilan:auteur_filtre'),
      cacheTime: 0,
    }
  );

  const { data: nursingProtocols, isLoading: nursingProtocolsIsLoading } = useQuery(
    ['archiveBilans', 'nursingProtocols'],
    async () => {
      return await http
        .get(`nursing_protocols.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des PISU');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const { isLoading: paramListsIsLoading, data: paramLists } = useQuery(
    ['archiveBilans', 'parameterized-lists'],
    async () => {
      return await http
        .get(`parameterized_lists.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des listes paramétrées');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const [optionsPisuGassppe, setOptionsPisuGassppe] = useState([]);
  const [optionsPathologie, setOptionsPathologie] = useState([]);

  const [filterCreatedAfter, setFilterCreatedAfter] = useState(match.location.query.createdAfter || '');
  const [filterCreatedBefore, setFilterCreatedBefore] = useState(match.location.query.createdBefore || '');
  const [filterSortBilan, setFilterSortBilan] = useState(match.location.query.createdEntity || 'bilans');
  const [filterInter, setFilterInter] = useState(match.location.query.firstResponderSerial || '');
  const [filterInterType, setFilterInterType] = useState(match.location.query.firstResponderSerialType || []);
  const [filterDRM, setFilterDRM] = useState(match.location.query.medicalSerial || '');
  const [filterVictimGivenName, setFilterVictimGivenName] = useState(match.location.query.victimGivenName || '');
  const [filterVictimFamilyName, setFilterVictimFamilyName] = useState(match.location.query.victimFamilyName || '');
  const [filterVictimBirthDate, setFilterVictimBirthDate] = useState(
    match.location.query.victimBirthDate?.split('/').reverse().join('-') || ''
  );
  const [filterVehicle, setFilterVehicle] = useState(match.location.query.vehicleNames || []);
  const [filterAuthorId, setFilterAuthorId] = useState(parseInt(match.location.query.authorId, 10) || '');
  const [filterPriorisation, setFilterPriorisation] = useState(parseInt(match.location.query.priorisation, 10) || '');
  const [filterPisuGassppe, setFilterPisuGassppe] = useState(
    match.location.query.cotation38Pisu ? decodeURIComponent(match.location.query.cotation38Pisu) : ''
  );
  const [filterCcmuPresente, setFilterCcmuPresente] = useState(
    match.location.query.cotation38Presente === 'true'
      ? true
      : match.location.query.cotation38Presente === 'false'
        ? false
        : ''
  );
  const [filterNotPisu, setFilterNotPisu] = useState(parseInt(match.location.query.cotation38NotationPisu, 10) || '');
  const [filterNotGassppe, setFilterNotGassppe] = useState(
    parseInt(match.location.query.cotation38NotationGassppe, 10) || ''
  );
  const [filterNotComportement, setFilterNotComportement] = useState(
    parseInt(match.location.query.cotation38NotationComportement, 10) || ''
  );
  const [filterNotRedaction, setFilterNotRedaction] = useState(
    parseInt(match.location.query.cotation38NotationRedaction, 10) || ''
  );
  const [filterAntalInit, setFilterAntalInit] = useState(
    parseInt(match.location.query.cotation38AntalgieEvnInitial, 10) || ''
  );
  const [filterAntalFinal, setFilterAntalFinal] = useState(
    parseInt(match.location.query.cotation38AntalgieEvnFinal, 10) || ''
  );
  const [filterSmur, setFilterSmur] = useState(
    match.location.query.cotation38SmurSurPlace === 'true'
      ? true
      : match.location.query.cotation38SmurSurPlace === 'false'
        ? false
        : ''
  );
  const [filterPathologie, setFilterPathologie] = useState(
    parseInt(match.location.query.cotation38Pathologie, 10) || ''
  );
  const [filterIndicateurs, setFilterIndicateurs] = useState(
    parseInt(match.location.query.cotation38Indicateurs, 10) || ''
  );

  const [hasPermCcmu, setHasPerm] = useState(true);
  const [hasPriorisationPerm, setHasPriorisationPerm] = useState(false);
  const [permEval, setPermEval] = useState(false);

  const currentPage = parseInt(match.location.query.page || '1', 10);

  const setFilters = () => {
    setFilterCreatedAfter(match.location.query.createdAfter || '');
    setFilterCreatedBefore(match.location.query.createdBefore || '');
    setFilterSortBilan(match.location.query.createdEntity || 'bilans');
    setFilterInter(match.location.query.firstResponderSerial || '');
    setFilterDRM(match.location.query.medicalSerial || '');
    setFilterVictimGivenName(match.location.query.victimGivenName || '');
    setFilterVictimFamilyName(match.location.query.victimFamilyName || '');
    setFilterVictimBirthDate(match.location.query.victimBirthDate?.split('/').reverse().join('-') || '');
    setFilterVehicle(match.location.query.vehicleNames || []);
    setFilterAuthorId(parseInt(match.location.query.authorId, 10) || '');
    setFilterPriorisation(parseInt(match.location.query.priorisation, 10) || '');
    setFilterPisuGassppe(
      match.location.query.cotation38Pisu ? decodeURIComponent(match.location.query.cotation38Pisu) : ''
    );
    setFilterCcmuPresente(
      match.location.query.cotation38Presente === 'true'
        ? true
        : match.location.query.cotation38Presente === 'false'
          ? false
          : ''
    );
    setFilterNotPisu(parseInt(match.location.query.cotation38NotationPisu, 10) || '');
    setFilterNotGassppe(parseInt(match.location.query.cotation38NotationGassppe, 10) || '');
    setFilterNotComportement(parseInt(match.location.query.cotation38NotationComportement, 10) || '');
    setFilterNotRedaction(parseInt(match.location.query.cotation38NotationRedaction, 10) || '');
    setFilterAntalInit(parseInt(match.location.query.cotation38AntalgieEvnInitial, 10) || '');
    setFilterAntalFinal(parseInt(match.location.query.cotation38AntalgieEvnFinal, 10) || '');
    setFilterSmur(
      match.location.query.cotation38SmurSurPlace === 'true'
        ? true
        : match.location.query.cotation38SmurSurPlace === 'false'
          ? false
          : ''
    );
    setFilterPathologie(parseInt(match.location.query.cotation38Pathologie, 10) || '');
    setFilterIndicateurs(parseInt(match.location.query.cotation38Indicateurs, 10) || '');
  };

  const sortBilanOptions = [
    { value: 'bilans', label: 'Réception du bilan' },
    { value: 'interventions', label: 'Création du bilan' },
  ];

  useEffect(() => {
    setHasPerm(syoUtils.hasPerm(currentUser, 'web:bilan:cotation_medicale'));
    setHasPriorisationPerm(syoUtils.hasPerm(currentUser, 'web:bilan:priorisation_filtre'));
    setPermEval(true);
  }, [currentUser]);

  useEffect(() => {
    if (Object.keys(match.location.query).length === 0) handleResetFilter();
    refetchPayload();
  }, [match, refetchPayload]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!nursingProtocolsIsLoading && !paramListsIsLoading) {
      const gassppeIndex = paramLists.findIndex(i => i.name === 'gassppe');
      let gassppeOptions = [];

      const pathologieIndex = paramLists.findIndex(i => i.name === 'pathologies-suspectees');
      let pathologieOptions = [];

      if (gassppeIndex > -1)
        gassppeOptions = paramLists[gassppeIndex].items.map(i => {
          return {
            id: i.id,
            name: i.value,
          };
        });

      if (pathologieIndex > -1) {
        pathologieOptions = paramLists[pathologieIndex].items.sort((left, right) => {
          const lhs = (left.value || '').toLowerCase();
          const rhs = (right.value || '').toLowerCase();
          return lhs > rhs ? 1 : -1;
        });
      }

      const tempPisuGassppe = [...nursingProtocols, ...gassppeOptions].sort((left, right) => {
        const lhs = (left.name || '').toLowerCase();
        const rhs = (right.name || '').toLowerCase();
        return lhs > rhs ? 1 : -1;
      });

      setOptionsPisuGassppe([...tempPisuGassppe]);
      setOptionsPathologie([...pathologieOptions]);
    }
  }, [nursingProtocolsIsLoading, nursingProtocols, paramListsIsLoading, paramLists]);

  const handleFormSubmit = ev => {
    ev.preventDefault();
    const options = {};
    options.createdEntity = filterSortBilan || 'bilans';
    if (filterCreatedAfter !== '') options.createdAfter = filterCreatedAfter;
    if (filterCreatedBefore !== '') options.createdBefore = filterCreatedBefore;
    if (filterVehicle !== '' && filterVehicle.length > 0) options.vehicleNames = filterVehicle;
    if (filterInter !== '') options.firstResponderSerial = filterInter;
    if (filterInterType !== '') options.firstResponderSerialType = filterInterType;
    if (filterDRM !== '') options.medicalSerial = filterDRM;
    if (filterVictimGivenName !== '') options.victimGivenName = filterVictimGivenName;
    if (filterVictimFamilyName !== '') options.victimFamilyName = filterVictimFamilyName;
    if (filterVictimBirthDate !== '') options.victimBirthDate = filterVictimBirthDate.split('-').reverse().join('/');
    if (filterAuthorId !== '') options.authorId = filterAuthorId;
    if (filterPriorisation !== '') options.priorisation = filterPriorisation;
    if (filterCcmuPresente !== '') options.cotation38Presente = encodeURIComponent(filterCcmuPresente);
    if (filterPathologie !== '') options.cotation38Pathologie = filterPathologie;
    if (filterPisuGassppe !== '') options.cotation38Pisu = filterPisuGassppe;
    if (filterIndicateurs !== '') options.cotation38Indicateurs = filterIndicateurs;
    if (filterNotPisu !== '') options.cotation38NotationPisu = filterNotPisu;
    if (filterNotGassppe !== '') options.cotation38NotationGassppe = filterNotGassppe;
    if (filterNotComportement !== '') options.cotation38NotationComportement = filterNotComportement;
    if (filterNotRedaction !== '') options.cotation38NotationRedaction = filterNotRedaction;
    if (filterAntalInit !== '') options.cotation38AntalgieEvnInitial = filterAntalInit;
    if (filterAntalFinal !== '') options.cotation38AntalgieEvnFinal = filterAntalFinal;
    if (filterSmur !== '') options.cotation38SmurSurPlace = encodeURIComponent(filterSmur);

    router.push({
      pathname: '/interventions/archive',
      query: {
        page: '1',
        ...options,
      },
    });
  };

  function saveFilter(filter) {
    const lastFilter = JSON.parse(sessionStorage.getItem('bilanFilter'));
    sessionStorage.setItem(
      'bilanFilter',
      JSON.stringify({
        ...lastFilter,
        filter,
      })
    );
  }

  const handleFilterCreatedAfterChange = ev => {
    setFilterCreatedAfter(ev.target.value);
    saveFilter({ createdAfter: ev.target.value });
  };

  const handleFilterCreatedBeforeChange = ev => {
    setFilterCreatedBefore(ev.target.value);
    saveFilter({ createdBefore: ev.target.value });
  };

  const handleSortBilanChange = ev => {
    setFilterSortBilan(ev.value);
    saveFilter({ createdEntity: ev.value });
  };

  const handleFilterInterChange = ev => {
    setFilterInter(ev.target.value);
    saveFilter({ firstResponderSerial: ev.target.value });
  };

  const handleFilterInterTypeChange = ev => {
    setFilterInterType(ev?.value || 'Strict');
    saveFilter({ firstResponderSerialType: ev?.value || 'Strict' });
  };

  const handleFilterDRMChange = ev => {
    if (isNaN(ev.target?.value)) return;
    setFilterDRM(ev.target.value);
    saveFilter({ medicalSerial: ev.target.value });
  };

  const handleFilterVictimFamilyNameChange = ev => {
    setFilterVictimFamilyName(ev.target.value);
    saveFilter({ victimFamilyName: ev.target.value });
  };

  const handleFilterVictimGivenNameChange = ev => {
    setFilterVictimGivenName(ev.target.value);
    saveFilter({ victimGivenName: ev.target.value });
  };

  const handleFilterVictimBirthDateChange = ev => {
    setFilterVictimBirthDate(ev.target.value);
    saveFilter({ victimBirthDate: ev.target.value });
  };

  const handleFilterVehicleChange = ev => {
    if (ev == null) {
      setFilterVehicle([]);
      saveFilter({ vehicleNames: [] });
    } else {
      const v = Array.isArray(ev) ? ev.map(x => x.label) : [];
      setFilterVehicle(v);
      saveFilter({ vehicleNames: v });
    }
  };

  const handleFilterAuthorChange = ev => {
    setFilterAuthorId(ev?.value || '');
    saveFilter({ authorId: ev?.value || '' });
  };

  const handleFilterPriorisationChange = ev => {
    setFilterPriorisation(ev?.value || '');
    saveFilter({ priorisation: ev?.value || '' });
  };

  const handleCcmuPresenteChange = ev => {
    if (ev === null) {
      setFilterCcmuPresente('');
      saveFilter({ cotation38Presente: '' });
    } else {
      setFilterCcmuPresente(ev.value);

      setFilterPisuGassppe('');
      setFilterPathologie('');
      setFilterIndicateurs('');
      setFilterNotPisu('');
      setFilterNotGassppe('');
      setFilterNotComportement('');
      setFilterNotRedaction('');
      setFilterAntalInit('');
      setFilterAntalFinal('');
      setFilterSmur('');
      saveFilter({
        cotation38Presente: ev.value,
        cotation38Pisu: '',
        cotation38Pathologie: '',
        cotation38Indicateurs: '',
        cotation38NotationPisu: '',
        cotation38NotationGassppe: '',
        cotation38NotationComportement: '',
        cotation38NotationRedaction: '',
        cotation38AntalgieEvnInitial: '',
        cotation38AntalgieEvnFinal: '',
        cotation38SmurSurPlace: '',
      });
    }
  };

  const handleFilterPisuGassppeChange = ev => {
    setFilterPisuGassppe(ev?.name || '');
    saveFilter({ cotation38Pisu: ev?.name || '' });
  };

  const handleFilterPathologieChange = ev => {
    setFilterPathologie(ev?.id || '');
    saveFilter({ cotation38Pathologie: ev?.id || '' });
  };

  const handleIndicateursChange = ev => {
    setFilterIndicateurs(ev?.value || '');
    saveFilter({ cotation38Indicateurs: ev?.value || '' });
  };

  const handleNotPisuChange = ev => {
    setFilterNotPisu(ev?.value || '');
    saveFilter({ cotation38NotationPisu: ev?.value || '' });
  };

  const handleNotGassppeChange = ev => {
    setFilterNotGassppe(ev?.value || '');
    saveFilter({ cotation38NotationGassppe: ev?.value || '' });
  };

  const handleNotComportementChange = ev => {
    setFilterNotComportement(ev?.value || '');
    saveFilter({ cotation38NotationComportement: ev?.value || '' });
  };

  const handleNotRedactionChange = ev => {
    setFilterNotRedaction(ev?.value || '');
    saveFilter({ cotation38NotationRedaction: ev?.value || '' });
  };

  const handleAntalInitChange = ev => {
    if (ev.target.value === '') {
      setFilterAntalInit('');
      saveFilter({ cotation38AntalgieEvnInitial: '' });
    } else {
      setFilterAntalInit(parseInt(ev.target.value, 10) || '');
      saveFilter({ cotation38AntalgieEvnInitial: parseInt(ev.target.value, 10) || '' });
    }
  };

  const handleAntalFinalChange = ev => {
    if (ev.target.value === '') {
      setFilterAntalFinal('');
      saveFilter({ cotation38AntalgieEvnFinal: '' });
    } else {
      setFilterAntalFinal(parseInt(ev.target.value, 10) || '');
      saveFilter({ cotation38AntalgieEvnFinal: parseInt(ev.target.value, 10) || '' });
    }
  };

  const handleFilterSmurChange = ev => {
    if (ev === null) {
      setFilterSmur('');
      saveFilter({ cotation38SmurSurPlace: '' });
    } else {
      setFilterSmur(ev?.value);
      saveFilter({ cotation38SmurSurPlace: ev?.value });
    }
  };

  const handleSelectAllVehicleType = text => {
    const temp = vehicles.filter(v => v.label.toLowerCase().includes(text.toLowerCase())).map(v => v.label);
    setFilterVehicle([...temp]);
    saveFilter({ vehicleNames: [...temp] });
  };

  const btnVehiclesFilter = useMemo(() => {
    if (vehicles) {
      return {
        vsm: vehicles.filter(v => v.label.toLowerCase().includes('vsm')).map(v => v.label),
        vls: vehicles.filter(v => v.label.toLowerCase().includes('vls')).map(v => v.label),
        vsav: vehicles.filter(v => v.label.toLowerCase().includes('vsav')).map(v => v.label),
      };
    }
    return null;
  }, [vehicles]);

  const ccmuNotPresente = useMemo(() => {
    return filterCcmuPresente === 'false' || filterCcmuPresente === false;
  }, [filterCcmuPresente]);

  const handleResetFilter = () => {
    setFilterCreatedAfter('');
    setFilterCreatedBefore('');
    setFilterSortBilan('bilans');
    setFilterInter('');
    setFilterInterType('Strict');
    setFilterDRM('');
    setFilterVictimGivenName('');
    setFilterVictimFamilyName('');
    setFilterVictimBirthDate('');
    setFilterVehicle([]);
    setFilterAuthorId('');
    setFilterPriorisation('');
    setFilterCcmuPresente('');
    setFilterPisuGassppe('');
    setFilterPathologie('');
    setFilterIndicateurs('');
    setFilterNotPisu('');
    setFilterNotGassppe('');
    setFilterNotComportement('');
    setFilterNotRedaction('');
    setFilterAntalInit('');
    setFilterAntalFinal('');
    setFilterSmur('');
    saveFilter({});
    sessionStorage.removeItem('bilanFilter');
  };

  const count = payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);

  const goTo = p =>
    router.push({
      pathname: '/interventions/archive',
      query: {
        ...match.location.query,
        page: p,
      },
    });

  const pagination = (
    <BoundedPagination
      total={payload && payload.total}
      perPage={payload && payload.per_page}
      count={count}
      current={currentPage}
      onClick={goTo}
    />
  );

  return (
    <>
      <PageHeader>
        <Title>Interventions archivées</Title>
      </PageHeader>

      <SearchBarCompo canBeCollapsed={true} permEval={permEval}>
        <SearchBar>
          <FilterBar onSubmit={handleFormSubmit}>
            <div className='row'>
              <div className='item-secondary'>
                <Label htmlFor='filter-bilans-input'>Filtrage par date de</Label>
                <Select
                  id='filter-bilans-input'
                  isSearchable={true}
                  isClearable={false}
                  options={sortBilanOptions}
                  value={sortBilanOptions.find(v => v.value === filterSortBilan) || null}
                  onChange={handleSortBilanChange}
                  placeholder=''
                />
              </div>
              <div className='item-primary'>
                <Label htmlFor='filter-created-after-input'>
                  {filterSortBilan === 'bilans' ? 'Réceptionnées' : 'Créées'} à partir du (inclus)
                </Label>
                <Input
                  id='filter-created-after-input'
                  type='date'
                  value={filterCreatedAfter}
                  max={filterCreatedBefore}
                  onChange={handleFilterCreatedAfterChange}
                />
              </div>
              <div className='item-primary'>
                <Label htmlFor='filter-created-before-input'>
                  {filterSortBilan === 'bilans' ? 'Réceptionnées' : 'Créées'} jusqu'au (non inclus)
                </Label>
                <Input
                  id='filter-created-before-input'
                  type='date'
                  value={filterCreatedBefore}
                  min={filterCreatedAfter}
                  onChange={handleFilterCreatedBeforeChange}
                />
              </div>
            </div>
            <div className='rowWrap'>
              <div>
                <Label htmlFor='filter-inter-input'>
                  {syoUtils.hasPerm(currentUser, 'web:interventions:primo-intervenant:to:numero-intervention')
                    ? "Numéro d'intervention"
                    : 'Numéro primo-intervenant'}
                </Label>
                <div style={{ display: 'flex', flexDirection: 'flex-rox', gap: theme.thin }}>
                  <Input
                    id='filter-inter-input'
                    style={{ width: '50%' }}
                    type='text'
                    value={filterInter}
                    onChange={handleFilterInterChange}
                    placeholder='Numéro complet'
                  />
                  <SelectHalfSize
                    id='filter-inter-search-input'
                    isSearchable
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={INTER_TYPE_OPTIONS}
                    value={INTER_TYPE_OPTIONS.filter(obj => filterInterType.includes(obj.value))}
                    onChange={handleFilterInterTypeChange}
                    placeholder=''
                    noOptionsMessage={() => 'Recherche strict'}
                    components={{
                      ValueContainer,
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor='filter-drm-input'>Numéro de dossier DRM</Label>
                <Input
                  id='filter-drm-input'
                  type='text'
                  inputMode='numeric'
                  pattern='\d*'
                  value={filterDRM}
                  onChange={handleFilterDRMChange}
                  placeholder='Numéro complet (chiffres uniquement)'
                />
              </div>
              {syoUtils.hasPerm(currentUser, victimIdentityPerm) && (
                <>
                  <div>
                    <Label htmlFor='filter-victim-family-name-input'>Victime: Nom de famille</Label>
                    <Input
                      id='filter-victim-family-name-input'
                      type='text'
                      value={filterVictimFamilyName}
                      onChange={handleFilterVictimFamilyNameChange}
                      placeholder='Nom complet'
                    />
                  </div>
                  {/*<div>
                    <Label htmlFor="filter-victim-given-name-input">
                      Victime: Prénom
                    </Label>
                    <Input
                      id="filter-victim-given-name-input"
                      type="text"
                      value={filterVictimGivenName}
                      onChange={handleFilterVictimGivenNameChange}
                      placeholder="Prénom complet"
                    />
                  </div>*/}
                  <div>
                    <Label htmlFor='filter-victim-birth-date-input'>Victime: Date de naissance</Label>
                    <Input
                      id='filter-victim-birth-date-input'
                      type='date'
                      value={filterVictimBirthDate}
                      onChange={handleFilterVictimBirthDateChange}
                    />
                  </div>
                </>
              )}
              {!vehiclesIsLoading && vehicles && vehicles.length > 0 && (
                <>
                  <div>
                    <Label htmlFor='filter-vehicles-input'>Véhicule(s)</Label>
                    <Select
                      id='filter-vehicles-input'
                      isSearchable
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={vehicles}
                      value={vehicles.filter(obj => filterVehicle.includes(obj.label))}
                      onChange={handleFilterVehicleChange}
                      placeholder='Véhicule(s)'
                      noOptionsMessage={() => 'Aucun véhicule'}
                      components={{
                        ValueContainer,
                      }}
                    />
                  </div>
                  {btnVehiclesFilter &&
                    (btnVehiclesFilter.vsm.length !== 0 ||
                      btnVehiclesFilter.vsav.length !== 0 ||
                      btnVehiclesFilter.vls.length !== 0) && (
                      <div>
                        <Label>Sélectionner tous les véhicules</Label>
                        <div>
                          {btnVehiclesFilter && btnVehiclesFilter.vsm.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vsm')}
                            >
                              VSM
                            </Button>
                          )}
                          {btnVehiclesFilter && btnVehiclesFilter.vsav.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vsav')}
                            >
                              VSAV
                            </Button>
                          )}
                          {btnVehiclesFilter && btnVehiclesFilter.vls.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vls')}
                            >
                              VLS
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}
              {!usersIsLoading && users && (
                <div>
                  <Label htmlFor='filter-author-input'>Auteur</Label>
                  <Select
                    id='filter-author-input'
                    isSearchable
                    isClearable
                    options={users}
                    value={users.find(u => u.value === filterAuthorId) || null}
                    onChange={handleFilterAuthorChange}
                    placeholder='Auteur'
                    noOptionsMessage={() => 'Aucun auteur'}
                  />
                </div>
              )}
              {hasPriorisationPerm && (
                <div>
                  <Label>Priorisation</Label>
                  <Select
                    isClearable
                    isSearchable
                    options={syoData.PRIORISATION}
                    value={syoData.PRIORISATION.find(h => h.value === filterPriorisation) || null}
                    onChange={handleFilterPriorisationChange}
                    placeholder='Priorisation'
                    noOptionsMessage={() => 'Aucune priorisation'}
                  />
                </div>
              )}
            </div>
            {hasPermCcmu && (
              <>
                <h3>Cotation CCMU</h3>
                <div className='rowWrap'>
                  <div>
                    <Label>Présence d'une cotation {filterCcmuPresente}</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={PRESENTE_OPTIONS}
                      value={PRESENTE_OPTIONS.find(h => h.value === filterCcmuPresente.toString()) || null}
                      onChange={handleCcmuPresenteChange}
                      placeholder="Présence d'une cotation"
                    />
                  </div>
                  <div>
                    <Label>PISU / GASSPPE</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={optionsPisuGassppe}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      value={optionsPisuGassppe?.filter(obj => filterPisuGassppe === obj.name) || null}
                      onChange={handleFilterPisuGassppeChange}
                      isDisabled={(nursingProtocolsIsLoading && paramListsIsLoading) || ccmuNotPresente}
                      placeholder='PISU / GASSPPE'
                      noOptionsMessage={() => 'Aucun PISU / GASSPPE'}
                    />
                  </div>
                  <div>
                    <Label>Pathologie suspectée</Label>
                    <Select
                      isClearable
                      isSearchable
                      getOptionLabel={option => `${option.value}`}
                      getOptionValue={option => `${option.id}`}
                      options={optionsPathologie}
                      value={optionsPathologie.find(h => h.id === filterPathologie) || null}
                      onChange={handleFilterPathologieChange}
                      isDisabled={(nursingProtocolsIsLoading && paramListsIsLoading) || ccmuNotPresente}
                      placeholder='Pathologie suspectée'
                      noOptionsMessage={() => 'Aucune pathologie suspectée'}
                    />
                  </div>
                  <div>
                    <Label>Indicateurs</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={syoData.COTATION_MEDICALE_INDICATEURS.data}
                      value={
                        syoData.COTATION_MEDICALE_INDICATEURS.data.find(h => h.value === filterIndicateurs) || null
                      }
                      onChange={handleIndicateursChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='Indicateurs'
                      noOptionsMessage={() => 'Aucun indicateurs'}
                    />
                  </div>
                  <div>
                    <Label>Notation PISU</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={syoData.COTATION_MEDICALE_NOTATION_PISU.data}
                      value={syoData.COTATION_MEDICALE_NOTATION_PISU.data.find(h => h.value === filterNotPisu) || null}
                      onChange={handleNotPisuChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='Notation PISU'
                      noOptionsMessage={() => 'Aucune notation'}
                    />
                  </div>
                  <div>
                    <Label>Notation GASSPPE</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={syoData.COTATION_MEDICALE_NOTATION_PISU.data}
                      value={
                        syoData.COTATION_MEDICALE_NOTATION_PISU.data.find(h => h.value === filterNotGassppe) || null
                      }
                      onChange={handleNotGassppeChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='Notation GASSPPE'
                      noOptionsMessage={() => 'Aucune notation'}
                    />
                  </div>
                  <div>
                    <Label>Notation comportement</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={syoData.COTATION_MEDICALE_NOTATION_COMPORTEMENT.data}
                      value={
                        syoData.COTATION_MEDICALE_NOTATION_COMPORTEMENT.data.find(
                          h => h.value === filterNotComportement
                        ) || null
                      }
                      onChange={handleNotComportementChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='Notation comportement'
                      noOptionsMessage={() => 'Aucune notation'}
                    />
                  </div>
                  <div>
                    <Label>Notation rédaction</Label>
                    <Select
                      isClearable
                      isSearchable
                      options={syoData.COTATION_MEDICALE_NOTATION_REDACTION.data}
                      value={
                        syoData.COTATION_MEDICALE_NOTATION_REDACTION.data.find(h => h.value === filterNotRedaction) ||
                        null
                      }
                      onChange={handleNotRedactionChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='Notation rédaction'
                      noOptionsMessage={() => 'Aucune notation'}
                    />
                  </div>
                  <div>
                    <Label>Antalgie EVN initial</Label>
                    <Input
                      type='number'
                      value={filterAntalInit}
                      onChange={handleAntalInitChange}
                      disabled={ccmuNotPresente}
                      placeholder='Antalgie EVN inital'
                    />
                  </div>
                  <div>
                    <Label>Antalgie EVN final</Label>
                    <Input
                      type='number'
                      value={filterAntalFinal}
                      onChange={handleAntalFinalChange}
                      disabled={ccmuNotPresente}
                      placeholder='Antalgie EVN final'
                    />
                  </div>
                  <div>
                    <Label className='title'>SMUR sur place</Label>
                    <Select
                      isClearable
                      options={SMUR_OPTIONS}
                      value={SMUR_OPTIONS.find(h => h.value === filterSmur.toString()) || null}
                      onChange={handleFilterSmurChange}
                      isDisabled={ccmuNotPresente}
                      placeholder='SMUR sur place'
                    />
                  </div>
                </div>
              </>
            )}
            <div className='rowAction'>
              <Button type='button' className='warn' onClick={() => handleResetFilter()}>
                Réinitialiser les filtres
              </Button>
              <Button className='filled' type='submit'>
                Appliquer les filtres
              </Button>
            </div>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      <div>
        {payloadIsLoading ? (
          <LoadingSpinner className='center vh-50' />
        ) : (
          <>
            {payload === undefined ? (
              <>
                <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                  <h3>Une erreur est survenue pendant la récupération des interventions archivées.</h3>
                  <p>Veillez à disposer d'une connexion internet valide.</p>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: '.5rem', marginTop: '1rem' }}>{pagination}</div>
                {payloadIsFetching ? (
                  <LoadingSpinner className='center vh-50' />
                ) : (
                  <>
                    <List
                      bilans={payload.bilans}
                      listingIdPerm='web:interventions_inactive:listing:identite'
                      showPriority={syoUtils.hasPerm(currentUser, 'web:intervention:priorite')}
                      sortBilan={match.location.query.createdEntity || 'bilans'}
                    />
                    <div>{pagination}</div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Archive;
