import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "./theme";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const FloatingBarCntnr = styled.div`
  background-color: ${theme.blue};
  color: ${theme.backgroundColor};
  position: fixed;
  bottom: calc(${theme.small} + 40px);
  padding: ${theme.small} ${theme.thin};
  border-radius: ${theme.thin} 0 0 ${theme.thin};
  display: flex;
  right: calc(-${props => props.widthVisible}px + ${theme.thin});
  transition: 0.3s ease-in-out;
  &.open {
    right: 0px;
  }
  &.close {
    right: calc(-${props => props.widthVisible}px + ${theme.thin});
  }
  &:hover {
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0px;
      margin: 0 ${theme.thin} 0 ${theme.thin};
    }
  }
`;

const ActionCntnr = styled.div`
  display: flex;
`;

const ToggleBtn = styled.span`
  color: ${theme.backgroundColor};
  background-color: transparent;
  border: none;

  svg[state="open"] {
    rotate: 0deg;
  }
  svg[state="close"] {
    rotate: 180deg;
  }
`;

function FloatingBar ({content, title, children}) {
  const [show, setShow] = useState(false);
  const [svgState, setSvgState] = useState("close");
  const [alwaysVisibleWidth, setAlwaysVisibleWidth] = useState(0);

  const floatingBarRef = useRef(null);
  const alwaysVisible = useRef(null);

  useEffect(() => {
    setAlwaysVisibleWidth(floatingBarRef.current.clientWidth - alwaysVisible.current.clientWidth);
  }, [setAlwaysVisibleWidth]);

  const handleClick = () => {
    setShow(!show);
    show ? setSvgState("close") : setSvgState("open");
  };

  return (
    <FloatingBarCntnr 
      onClick={() => handleClick()} 
      className={show ? "open" : "close"} 
      ref={floatingBarRef} 
      widthVisible={alwaysVisibleWidth}
    >
      <div ref={alwaysVisible}>
        <ToggleBtn
          title={show ? "Fermer" : "Ouvrir"}
        >
          <ArrowForwardIosIcon state={svgState}  />
        </ToggleBtn>
        <p title={title}>
          {content}
        </p>
      </div>
      <ActionCntnr>
        {children}
      </ActionCntnr>
    </FloatingBarCntnr>
  );
};

export default FloatingBar;