import React, { useState } from 'react';
import Input, {
    InputIcon,
    InputRightIcon,
    InputRightIconCntnr,
} from './Input';
import KeyIcon from '@mui/icons-material/VpnKey';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const InputPassword = ({id, value, callback, placeholder = 'Mot de passe'}) => {
    const [ visibylity, setVisibility ] = useState(false);

    return (
        <>
            <InputIcon className="grey">
                <KeyIcon />
            </InputIcon>
           <InputRightIconCntnr>
               <Input
                   id={id}
                   type={visibylity ? 'text' : 'password'}
                   placeholder={placeholder}
                   required
                   value={value}
                   onChange={ev => callback(ev)}
               />
               <InputRightIcon>
                   {visibylity ? (
                       <VisibilityOffIcon onClick={() => setVisibility(!visibylity)} />
                   ) : (
                       <VisibilityIcon onClick={() => setVisibility(!visibylity)} />
                   )}
               </InputRightIcon>
           </InputRightIconCntnr>
        </>
    );
};

export default InputPassword;
