import React, {useRef, Fragment} from "react";

import theme from "../ui/theme";
import {DialogCntnr as DialogCntnrBase} from "../ui/Modal";
import BaseButton from "../ui/Button";
import styled from "styled-components";
import { Dialog as DialogHeadless, Transition } from "@headlessui/react";

const DialogCntnr = styled(DialogCntnrBase)`
  .modal-header {
    margin-top: ${theme.small};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .modal-body-CG {
    overflow-y: scroll;
    max-height: 40vh;
    padding: ${theme.sall};
    border: 1px solid ${theme.grey4};
    ul {
      padding: ${theme.thin};
      .itemCG {
        list-style: none;
        h2 {
          text-transform: uppercase;
          span {
            text-transform: initial;
          }
        }
        span {
          margin-right: ${theme.small};
        }
        ul,
        ol {
          padding-left: ${theme.small};
          margin-bottom: ${theme.sall};
        }
        li {
          list-style: none;
        }
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${theme.small};
    label {
      width: 100%;
    }
  }
    .dialog-content-header h2 {
        color: ${theme.red} !important;
    }
`;

const Button = styled(BaseButton)`
  margin-top: ${theme.thin};
  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const ConfirmModal = ({
    label,
    open,
    onReject,
    callback,
 }) => {
    const btnRef = useRef(null);

    return (
        <Transition appear show={open} as={Fragment}>
            <DialogCntnr className="dialog" initialFocus={btnRef} static onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="overlay-enter"
                    enterFrom="overlay-enter-from"
                    enterTo="overlay-enter-to"
                    leave="overlay-leave"
                    leaveFrom="overlay-leave-from"
                    leaveTo="overlay-leave-to"
                >
                    <DialogHeadless.Overlay className="dialog-overlay" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="content-enter"
                    enterFrom="content-enter-from"
                    enterTo="content-enter-to"
                    leave="content-leave"
                    leaveFrom="content-leave-from"
                    leaveTo="content-leave-to"
                >
                    <div className="dialog-content">
                        <div className="dialog-content-header">
                            <DialogHeadless.Title>{label}</DialogHeadless.Title>
                        </div>

                        <div className="modal-body">
                            <p style={{marginBottom: 0}}>
                                Un bilan plus récent est disponible. Votre action de sauvegarde peut écraser des données, êtes-vous sûr de vouloir continuer?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" onClick={() => onReject()} ref={btnRef}>Annuler</Button>
                            <Button type="button" className={"warn"} onClick={() => callback()}>Sauvegarder quand même</Button>
                        </div>

                    </div>
                </Transition.Child>
            </DialogCntnr>
        </Transition>
    );
};

export default ConfirmModal;
