import React, { useContext } from 'react';
import CurrentUserContext from '../../CurrentUserContext';
import PageHeader, { Cntnr, Title } from '../../ui/PageHeader';

export default function Personal() {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <>
      <PageHeader>
        <Title>Informations personnelles</Title>
      </PageHeader>

      <Cntnr>
        <dl>
          <dt>Nom complet</dt>
          <dd>{currentUser.full_name}</dd>

          <dt>Nom d'utilisateur</dt>
          <dd>{currentUser.uid}</dd>

          <dt>Adresse e-mail</dt>
          <dd>{currentUser.email}</dd>

          <dt>Numéro RPPS (Médecin uniquement)</dt>
          <dd>{currentUser.numero_rpps ?? '-'}</dd>

          <dt>Organisation</dt>
          <dd>{currentUser.organization?.name}</dd>
        </dl>
      </Cntnr>
    </>
  );
}
