import React, { useEffect, useState } from "react";

import styled from "styled-components";
import theme from "../ui/theme";
import { LineChartView, AreaChartView, ComposedChartView } from "../components/Graph";

const GraphsCntnr = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const GraphTitle = styled.h5`
  color: ${theme.text};
  font-size: 1rem;
  text-transform: uppercase;
`;

const GraphChartCntnr = styled.div`
  width: 50%;
  padding: ${theme.small};
  &:not(:last-child) {
    border-bottom: 2px dashed ${theme.blueBilan};
  }

  @media (max-width: 1000px) {
    width: 100%
  }
`;

const View = ({graph, data}) => {
  const title = <GraphTitle>{graph.title}</GraphTitle>;
  switch (graph.type) {
    case "line":
      return (
        <GraphChartCntnr>
          {title}
          <LineChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );
    case "area":
      return (
        <GraphChartCntnr>
          {title}
          <AreaChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );
    case "composed":
      return (
        <GraphChartCntnr>
          {title}
          <ComposedChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );

    default:
      return null;
  };
};

const SurvGraph = ({bilan, surveillances, hasPeakFlow}) => {
  const [data, setData] = useState([]);
  const [paDomain, setPaDomain] = useState(["auto", "auto"]);

  const isNotEmpty = (s) => {
    return s !== undefined && s !== null && s !== "-1" && s !== -1 && s !== "";
  };

  const formatData = () => {
    let temp = [];
    let paValues = [];
    let init = {};
    if (isNotEmpty(bilan.respiration.fv)) init.fr = parseInt(bilan.respiration.fv, 10);
    if (isNotEmpty(bilan.circulatoire.fc)) init.fc = parseInt(bilan.circulatoire.fc, 10);
    init.spo2 = surveillances[0]?.o2o2
      ? isNotEmpty(bilan.respiration.o2o2) ? bilan.respiration.o2o2 : null
      : isNotEmpty(bilan.respiration.o2aa) ? bilan.respiration.o2aa : null;
    if (isNotEmpty(bilan.respiration.spco)) init.spco = parseInt(bilan.respiration.spco, 10);
    if (isNotEmpty(bilan.respiration.etco2)) init.etco2 = parseInt(bilan.respiration.etco2, 10);
    if (isNotEmpty(bilan.medic_info.glycemie)) init.gly = parseInt(bilan.medic_info.glycemie, 10);
    if (isNotEmpty(bilan.medic_info.hemoglobine)) init.hemo = parseInt(bilan.medic_info.hemoglobine, 10);
    if (isNotEmpty(bilan.circulatoire.tension_droite)) {
      const temp = bilan.circulatoire.tension_droite.split("/");
      init.pabd = bilan.circulatoire.tension_droite;
      init.pabdSystol = parseInt(temp[0], 10) || null;
      init.pabdDiastol = parseInt(temp[1], 10) || null;
      init.pabdBar = [parseInt(temp[0], 10) || null, parseInt(temp[1], 10) || null];
      paValues.push(...[init.pabdDiastol, init.pabdSystol]);
    };
    if (isNotEmpty(bilan.circulatoire.pamd)) init.pamd = bilan.circulatoire.pamd;
    if (isNotEmpty(bilan.circulatoire.tension_gauche)) {
      const temp = bilan.circulatoire.tension_gauche.split("/");
      init.pabg = bilan.circulatoire.tension_gauche;
      init.pabgSystol = parseInt(temp[0], 10) || null;
      init.pabgDiastol = parseInt(temp[1], 10) || null;
      init.pabgBar = [parseInt(temp[0], 10) || null, parseInt(temp[1], 10) || null];
      paValues.push(...[init.pabgDiastol, init.pabgSystol]);
    };
    if (isNotEmpty(bilan.circulatoire.pamg)) init.pamg = bilan.circulatoire.pamg;
    if (isNotEmpty(bilan.circonstance.severite)) init.eva = parseInt(bilan.circonstance.severite, 10);
    if (isNotEmpty(bilan.respiration.peak_flow)) init.peakFlow = bilan.respiration.peak_flow;
    init.name = "Initiales";
    temp.push(init);

    surveillances.map((s, idx) => {
      let surv = {};
      if (isNotEmpty(s.fr)) surv.fr = parseInt(s.fr, 10);
      if (isNotEmpty(s.fc)) surv.fc = parseInt(s.fc, 10);
      if (isNotEmpty(s.spo2)) surv.spo2 = parseInt(s.spo2, 10);
      if (isNotEmpty(s.spco)) surv.spco = s.spco;
      if (isNotEmpty(s.etco2)) surv.etco2 = s.etco2;
      if (isNotEmpty(s.glycemie)) surv.gly = s.glycemie;
      if (isNotEmpty(s.hemoglobine)) surv.hemo = s.hemoglobine;
      if (isNotEmpty(s.pabd)) {
        const temp = s.pabd.split("/");
        surv.pabd = s.pabd;
        surv.pabdSystol = parseInt(temp[0], 10) || null;
        surv.pabdDiastol = parseInt(temp[1], 10) || null;
        surv.pabdBar = [parseInt(temp[0], 10) || null, parseInt(temp[1], 10) || null];
        paValues.push(...[surv.pabdDiastol, surv.pabdSystol]);
      };
      if (isNotEmpty(s.pamd)) surv.pamd = s.pamd;
      if (isNotEmpty(s.pabg)) {
        const temp = s.pabg.split("/");
        surv.pabg = s.pabg;
        surv.pabgSystol = parseInt(temp[0], 10) || null;
        surv.pabgDiastol = parseInt(temp[1], 10) || null;
        surv.pabgBar = [parseInt(temp[0], 10) || null, parseInt(temp[1], 10) || null];
        paValues.push(...[surv.pabgDiastol, surv.pabgSystol]);
      };
      if (isNotEmpty(s.pamg)) surv.pamg = s.pamg;
      if (isNotEmpty(s.eva)) surv.en = s.eva;
      if (isNotEmpty(s.peak_flow) && hasPeakFlow) surv.peakFlow = s.peak_flow;
      surv.name = ++idx;
      temp.push(surv);
    });
    setPaDomain([
      Math.floor(Math.min(...paValues) / 10) * 10,
      Math.ceil(Math.max(...paValues) / 10) * 10 + 15
    ]);
    return [...temp];
  };

  useEffect(() => {
    setData([...formatData()]);
  }, [ bilan, surveillances ]);

  const [ freqGraph, setFreqGraph ] = useState([
    {
      dataKey: "fr",
      type: "linear",
      name: "FR",
      unit: "cpm",
      hide: false,
    },
    {
      dataKey: "fc",
      type: "linear",
      name: "FC",
      unit: "bpm",
      hide: false,
    },
  ]);

  const [ constGraph, setConstGraph ] = useState([
    {
      dataKey: "spo2",
      type: "linear",
      name: `SpO² ${surveillances[0]?.o2o2 ? "sous O2" : "ambiant"}`,
      unit: "%",
      hide: false,
    },
    {
      dataKey: "spco",
      type: "linear",
      name: "SpCO",
      unit: "%",
      hide: false,
    },
    {
      dataKey: "etco2",
      type: "linear",
      name: "EtCO²",
      unit: "mmHg",
      hide: false,
    },{
      dataKey: "peakFlow",
      type: "linear",
      name: "Peak Flow",
      unit: "L/min",
      hide: false,
    }
  ]);

  const [ paDGraph, setPaDGraph ] = useState([
    {
      graphType: "bar",
      dataKey: "pabdBar",
      type: "linear",
      name: "Pression artérielle D",
    },
    {
      graphType: "scatter",
      dataKey: "pamd",
      type: "monotone",
      name: "PAM D",
      strokeWidth: "4",
      strokeDasharray: "10 5",
    },
  ]);

  const [ paGGraph, setPaGGraph ] = useState([
    {
      graphType: "bar",
      dataKey: "pabgBar",
      type: "linear",
      name: "Pression artérielle G",
    },
    {
      graphType: "scatter",
      dataKey: "pamg",
      type: "monotone",
      name: "PAM G",
      strokeWidth: "4",
      strokeDasharray: "10 5",
    },
  ]);

  const enGraph = [
    {
      dataKey: "en",
      type: "monotone",
      name: "EN",
    },
  ];

  const graphs = [
    {
      type: "line",
      title: "Fréquences",
      syncId: "survSync",
      data: freqGraph,
      setData: setFreqGraph,
    },
    {
      type: "line",
      title: "Constantes",
      syncId: "survSync",
      data: constGraph,
      setData: setConstGraph,
    },
    {
      type: "composed",
      title: "Pression artérielle gauche",
      syncId: "survSync",
      data: paGGraph,
      setData: setPaGGraph,
      domainY: paDomain,
    },
    {
      type: "composed",
      title: "Pression artérielle droite",
      syncId: "survSync",
      data: paDGraph,
      setData: setPaDGraph,
      domainY: paDomain,
    },
    {
      type: "area",
      title: "Douleur",
      syncId: "survSync",
      data: enGraph,
      domainY: [0, 10],
      height: "100px",
      intervalY: "preserveStartEnd"
    },
  ];

  return (
    <GraphsCntnr>
      {graphs.map((graph, idx) => (
        <View key={idx} graph={graph} data={data} />
      ))}
    </GraphsCntnr>
  );
};

export default SurvGraph;