import React from "react";
import styled from "styled-components";
import theme from "./theme";

import { Switch as SwitchHeadless } from "@headlessui/react";

const SwitchCntnr = styled.div`
  .switch {
    height: 22px;
    width: 48px;
    background-color: ${theme.blue};
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    border: none;
    transition: background-color .3s ease-in-out;
    margin: 0 ${theme.small} 0 ${theme.small};
    &.checked {
      background-color: ${theme.green};
    }
    &.not-checked {
      background-color: ${theme.red};
    }

    &-label {
      margin-bottom: 0px;
      &.double {
        transition: color .3s ease-in-out;
        &.checked {
          color: ${theme.text};
        }
        &.not-checked {
          color: ${theme.grey3};
        }
      }
    }

    &-round {
      height: 16px;
      width: 16px;
      background-color: ${theme.white};
      border-radius: 36px;
      transition: transform .3s ease-in-out;

      &.not-checked {
        transform: translateX(0px);
      }
      &.checked {
        transform: translateX(24px);
      }
    }

    &:disabled {
      color: ${theme.grey3};
      background-color: ${theme.grey3};
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`;

/**
 * @param {String}   label       Label for the switch
 * @param {Function} onChange    Callback
 * @param {Boolean}  switchValue State of the switch
 * @param {Boolean}  disabled    Disable state of the switch
 */
export function Switch ({label, onChange, switchValue, disabled = false}) {
  return (
    <SwitchCntnr>
      <SwitchHeadless.Group>
        <SwitchHeadless.Label className="switch-label">{label}</SwitchHeadless.Label>
        <SwitchHeadless
          className={`switch switch-single ${!switchValue ? "not-" : ""}checked`}
          onChange={onChange}
          disabled={disabled}
        >
          <span className={`switch-round ${!switchValue ? "not-" : ""}checked`} />
        </SwitchHeadless>
      </SwitchHeadless.Group>
    </SwitchCntnr>
  );
};

/**
 * @param {Array}    labels      Labels for the switch in an array
 * @param {Function} onChange    Callback
 * @param {Boolean}  switchValue State of the switch
 * @param {Boolean}  disabled    Disable state of the switch
 */
export function SwitchDouble ({labels, onChange, switchValue, disabled = false}) {
  return (
    <SwitchCntnr>
      <SwitchHeadless.Group>
        <SwitchHeadless.Label className={`switch-label double ${switchValue ? "not-" : ""}checked`}>{labels[0]}</SwitchHeadless.Label>
        <SwitchHeadless className="switch" onChange={onChange} disabled={disabled}>
          <span className={`switch-round ${!switchValue ? "not-" : ""}checked`} />
        </SwitchHeadless>
        <SwitchHeadless.Label className={`switch-label double ${!switchValue ? "not-" : ""}checked`}>{labels[1]}</SwitchHeadless.Label>
      </SwitchHeadless.Group>
    </SwitchCntnr>
  );
}
