import React from "react";
import styled from "styled-components";

import BaseLabel from "./Label";

const Label = styled(BaseLabel)`
  display: block;
`;

const Input = styled.input`
  margin-right: 0.5rem;
  &:disabled {
    cursor: not-allowed;
  }
`;

export default function Checkbox({ label, checked, onChange, disabled }) {
  return (
    <Label style={{textAlign: 'justify'}}>
      <Input type="checkbox" checked={checked} onChange={onChange}  disabled={disabled} />
      {label}
    </Label>
  );
}
