import { Route } from "found";
import React from "react";

import Parsys from "./screens/Parsys";

export const routes = (
  <Route path="partenaires">
    <Route path="parsys" Component={Parsys} />
  </Route>
);
