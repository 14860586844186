import React from "react";

import ShowBilanComponent from '../ShowBilanComponent';

function ShowPuiBilan({ match, router }) {
  return (
      <>
        <ShowBilanComponent match={match} router={router} scope={'pui'} />
      </>
  );
}

export default ShowPuiBilan;
