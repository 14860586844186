import BilanAdaptative, { LAYOUT_SSO_SMP } from '../BilanAdaptative';
import Select from '../../ui/Select';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import PageHeader, { Actions, SubActions, Subtitle, Title } from '../../ui/PageHeader';
import Button, { LinkButton } from '../../ui/Button';
import RestoreIcon from '@mui/icons-material/Restore';
import theme from '../../ui/theme';
import { QueryClient, useQuery } from '@tanstack/react-query';
import http from '../../http';
import { isAbortError, isFormation, triggerDownload } from '../../utils';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isEqualWith } from 'lodash';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { DateTime } from 'luxon';
import { ErrorBox, ErrorLink, StyledContent } from '../../ui/ErrorBox';
import SideBarDyn from '../SideBarDyn';
import HistoryIcon from '@mui/icons-material/History';
import CurrentUserContext from '../../CurrentUserContext';
import { generatePayloadBilan } from '../utils';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LoaderBarContext from '../../ui/useLoaderBar';
import ConfirmModal from '../../components/ConfirmModal';
import History from '../HistoryBilan';
import getLocalsExport from '../textualExport';
import * as CLIP from '../layout/clip';
import Mustache from 'mustache';
import * as clipboard from 'clipboard-polyfill';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { template as syoTemplate, utils as syoUtils } from '@nfsave/syo-bilan';
import RelevantFacts from '../RelevantFacts';

const FloatingHeader = styled(PageHeader)`
  position: relative;
  z-index: 9;
  @media (min-width: 768px) {
    position: sticky;
    top: 0;
    background-color: ${theme.backgroundColor};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const ShowSsoSmp = ({ match, router }) => {
  const bilanId = match.params.bilanId;
  const interventionId = match.params.interventionId;

  const queryClient = new QueryClient();
  const controller = new AbortController();

  const { currentUser } = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [adaptativeView, setAdaptativeView] = useState({ value: 1, label: 'Adaptatif' });
  const [bilanHistory, setBilanHistory] = useState([]);
  const [lastBilan, setLastBilan] = useState(null);
  const [numeroDossier, setNumeroDossier] = useState(null);
  // STALE -> ON_UPDATE -> WAIT_REFRESH
  const [bilanState, setBilanState] = useState('STALE');
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [callbackAction, setCallbackAction] = useState(null);

  const [toastMsg, setToastMsg] = useState([]);
  const toastId = useRef(null);
  const toastOptions = {
    onClose: () => setToastMsg([]),
    type: toast.TYPE.WARNING,
    autoClose: 5000,
    toastId: 'toastMsg',
    hideProgressBar: false,
  };
  const toastContent = (
    <div>
      Une erreur est survenue pendant le rafraichissement&thinsp;:
      <ul>
        {toastMsg.map((msg, idx) => (
          <li key={idx}>{msg.message}</li>
        ))}
      </ul>
    </div>
  );

  const updateToastMsg = () => {
    if (toastMsg.length === 0) return;
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast(toastContent, toastOptions);
    } else {
      toast.update(toastId.current, {
        render: () => toastContent,
        ...toastOptions,
      });
    }
  };

  const addToastMsg = (id, msg) => {
    let temp = toastMsg;
    let index = temp.findIndex(i => i.id === id);
    if (index === -1) {
      temp.push({ id: id, message: msg });
    } else {
      temp[index].message = msg;
    }
    setToastMsg([...temp]);
  };

  useEffect(() => {
    updateToastMsg();
  }, [toastMsg]);

  const {
    isLoading: bilanIsLoading,
    isFetching: bilanIsFetching,
    data: bilan,
    refetch: bilanRefetch,
    isError: bilanIsError,
  } = useQuery(
    ['bilanId'],
    async () => {
      return await http
        .get(`bilans/${encodeURIComponent(bilanId)}.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setNumeroDossier(res.info_tech.conformite);
          refetchBilans();
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('bilanId', 'du bilan');
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: bilans, refetch: refetchBilans } = useQuery(
    ['bilansHistory'],
    async () => {
      return await http
        .get(`bilans/historique.json`, {
          signal: controller.signal,
          searchParams: {
            id: interventionId,
          },
        })
        .json()
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('bilanHistory', "de l'historique");
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchInterval: 5000,
      onSuccess: data => {
        if (
          !isEqualWith(data, bilanHistory, (a, b) => a.id && b.id) &&
          bilanHistory.length > 0 &&
          bilanState !== 'ON_UPDATE'
        ) {
          if (data[0].id !== bilan.id) toast.info('Un bilan plus récent est disponible');
          setBilanState('STALE');
        }
        setLastBilan(data[0]);
        setBilanHistory(data);
      },
    }
  );

  const handleNotConfirm = () => {
    setConfirmationIsOpen(false);
    setCallbackAction(null);
    setBilanState('STALE');
    setLoaderBar(false);
  };

  const handleActionOnBilan = async (ev, action) => {
    ev.preventDefault();
    setLoaderBar(true);
    if (bilanNeedUpdate) {
      setBilanState('ON_UPDATE');
      await queryClient.cancelQueries('bilansHistory');
      switch (action) {
        case 'EXPORT':
          setCallbackAction(() => exportBilan);
          break;
        case 'RESTORE':
          setCallbackAction(() => restoreBilan);
          break;
        case 'ARCHIVE':
          setCallbackAction(() => archiveBilan);
          break;
        default:
          return;
      }
      setConfirmationIsOpen(true);
    } else {
      setBilanState('ON_UPDATE');
      switch (action) {
        case 'EXPORT':
          await exportBilan();
          break;
        case 'RESTORE':
          await restoreBilan();
          break;
        case 'ARCHIVE':
          await archiveBilan();
          break;
        default:
          return;
      }
    }
  };

  const updateBilan = async enCours => {
    const payload = generatePayloadBilan(
      'SSO_SMP',
      bilan,
      enCours,
      bilan.devenir.hopital_id,
      bilan.info_tech.conformite,
      bilan.info_tech.conformite_id
    );
    return await http.post(`bilans`, { json: payload }).json();
  };

  const restoreBilan = async () => {
    setConfirmationIsOpen(false);
    await updateBilan('yes');
    setBilanState('WAIT_REFRESH');
    setCallbackAction(null);
    setLoaderBar(false);
    router.push({
      pathname: `/interventions/sso-smp`,
    });
  };

  const archiveBilan = async () => {
    setConfirmationIsOpen(false);
    await updateBilan('no');
    setBilanState('WAIT_REFRESH');
    setCallbackAction(null);
    setLoaderBar(false);
    router.push({
      pathname: `/interventions/sso-smp`,
    });
  };

  const exportBilan = async () => {
    setConfirmationIsOpen(false);

    try {
      const updated = await updateBilan(bilan.en_cours);
      setBilanState('WAIT_REFRESH');

      const conformite = ('0000' + numeroDossier).slice(-4);

      const pdfResponse = await http.get(`bilans/${encodeURIComponent(updated.id)}/export`, {
        headers: {
          Accept: 'application/pdf',
        },
      });
      const pdfPayload = await pdfResponse.arrayBuffer();
      try {
        const pdfBlob = new Blob([pdfPayload], {
          type: 'application/pdf',
        });
        const date = DateTime.fromISO(bilan.sso_context.engagement_date_intervention).isValid
          ? DateTime.fromISO(bilan.sso_context.engagement_date_intervention).setLocale('fr-fr').toFormat('yyyy-MM-dd')
          : DateTime.fromISO(bilan?.intervention.created_at).setLocale('fr-fr').toFormat('yyyy-MM-dd');
        const isSso = syoUtils.viewHasContent(...syoTemplate.ssoExamens, bilan, true, currentUser);
        const isSmp = syoUtils.viewHasContent(...syoTemplate.smpExamens, bilan, true, currentUser);
        await triggerDownload(
          `Syope_${bilan.sso_context.numero_intervention ?? 'NR'}${isSso ? '_SSO' : ''}${isSmp ? '_SMP' : ''}_${date}.pdf`,
          pdfBlob
        );
      } catch (err) {
        console.error(err);
        toast(`Impossible de télécharger le fichier PDF du résumé de l'intervention ${conformite}.`, {
          type: toast.TYPE.ERROR,
          autoClose: false,
        });
      }
      setCallbackAction(null);
      router.replace({
        pathname: `/interventions/sso-smp/${bilan.intervention.numero}/bilans/${updated.id}`,
      });
      setLoaderBar(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoaderBar(false);
    }
  };

  const getPanels = useMemo(() => {
    let panels = [];
    panels = panels.concat([
      {
        key: 'assessment-historyBilan',
        contents: (
          <div style={{ padding: '1rem' }}>
            <h3>Historique</h3>
            <hr />
            <History bilans={!bilans ? [] : bilans} currentUser={currentUser} optionalPath='sso-smp' />
          </div>
        ),
        opts: {
          title: 'Historique',
          icon: <HistoryIcon />,
        },
      },
    ]);
    if (syoUtils.hasPerm(currentUser, 'web:interventions:sso-smp:relevant-facts')) {
      panels = panels.concat([
        {
          key: 'relevant-facts',
          contents: <RelevantFacts interventionId={bilan?.intervention.numero} />,
          opts: {
            title: 'Faits remarquables',
            width: 480,
          },
        },
      ]);
    }
    return panels;
  }, [bilans, currentUser]);

  const createdDate = useMemo(() => {
    return DateTime.fromISO(bilan?.intervention.created_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
  }, [bilan]);

  /** Vérifie si l'utilisateur n'est pas sur le dernier bilan connu */
  const bilanNeedUpdate = useMemo(
    // () => (lastBilan !== null && match.params.bilanId !== lastBilan.id.toString() && !onUpdate),
    () => lastBilan !== null && match.params.bilanId !== lastBilan.id.toString() && bilanState === 'STALE',
    [lastBilan, match, bilanState]
  );

  const colorSaveBtn = useMemo(() => {
    if (bilanNeedUpdate) return 'not-validated';
  }, [bilanNeedUpdate]);

  useEffect(() => {
    bilanRefetch();
  }, [match]);

  return (
    <>
      {bilanIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <>
          {bilanIsError ? (
            <StyledContent>
              <ErrorBox>
                <h3>Une erreur est survenue lors du traitement de votre requête</h3>
                <p>Nos équipes ont été notifiées et s'occuperont de votre problème au plus vite.</p>
                <ErrorLink to='/'>Retourner à l'accueil</ErrorLink>
              </ErrorBox>
            </StyledContent>
          ) : (
            <SideBarDyn panels={getPanels} hasSide={true}>
              <FloatingHeader>
                <Title>
                  {bilan.sso_context.adresse_intervention || (
                    <em style={{ fontWeight: 400 }}>Adresse non renseignée</em>
                  )}
                </Title>
                <Actions>
                  {bilanNeedUpdate && (
                    <LinkButton
                      to={`/interventions/sso-smp/${bilan.intervention.numero}/bilans/${lastBilan.id}`}
                      className='warn'
                      data-sentry-id='maj'
                    >
                      <RestoreIcon className='icon-left' />
                      Mettre à jour
                    </LinkButton>
                  )}
                  {bilan.en_cours === 'yes' ? (
                    <Button
                      disabled={loaderBarState || bilanState !== 'STALE'}
                      className={colorSaveBtn}
                      onClick={ev => handleActionOnBilan(ev, 'ARCHIVE')}
                      data-sentry-id='archive'
                    >
                      <ArchiveOutlinedIcon className='icon-left' />
                      {colorSaveBtn === 'not-validated' ? 'Sauvegarder et a' : 'A'}rchiver
                    </Button>
                  ) : (
                    <Button
                      disabled={loaderBarState || bilanState !== 'STALE'}
                      className={colorSaveBtn}
                      onClick={ev => handleActionOnBilan(ev, 'RESTORE')}
                      data-sentry-id='restore'
                    >
                      <RestoreIcon className='icon-left' />
                      {colorSaveBtn === 'not-validated' ? 'Sauvegarder et r' : 'R'}estaurer
                    </Button>
                  )}
                  <Button
                    disabled={loaderBarState || bilanState !== 'STALE'}
                    className={colorSaveBtn}
                    onClick={ev => handleActionOnBilan(ev, 'EXPORT')}
                    data-sentry-id='export'
                  >
                    <FileCopyOutlinedIcon className='icon-left' />
                    {colorSaveBtn === 'not-validated' ? 'Sauvegarder et e' : 'E'}xporter
                  </Button>
                </Actions>
                <Subtitle>
                  Transmission initiale le {createdDate}, par {bilan.intervention.author} - Numéro
                  d'intervention&thinsp;: {bilan.sso_context.numero_intervention ?? 'NR'}
                </Subtitle>
                <SubActions>
                  <Select
                    options={[
                      { value: 1, label: 'Adaptatif' },
                      { value: 2, label: 'Statique' },
                    ]}
                    value={adaptativeView}
                    onChange={value => setAdaptativeView(value)}
                  />
                </SubActions>
              </FloatingHeader>

              <BilanAdaptative bilan={bilan} layout={LAYOUT_SSO_SMP} adaptative={adaptativeView.value !== 2} />
            </SideBarDyn>
          )}
          <ConfirmModal
            label={'Êtes-vous sûr?'}
            open={confirmationIsOpen}
            onReject={handleNotConfirm}
            callback={callbackAction}
          />
        </>
      )}
    </>
  );
};

export default ShowSsoSmp;
