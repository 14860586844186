import React, { useContext, useState, useMemo } from "react";
import styled from "styled-components";
import { Set } from "immutable";
import LoaderBarContext from "../../ui/useLoaderBar";

import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import Textarea from "../../ui/Textarea";
import Checkbox from "../../ui/Checkbox";
import Button from "../../ui/Button";

const PermissionDescription = styled.p`
  margin: 0;
  padding: 0 0 0 21px;
  font-style: italic;
`;

export default function RoleForm({ permissions, initial, onSubmit = () => {}, duplicate = false }) {
  const { loaderBarState } = useContext(LoaderBarContext);

  const [form, setForm] = useState(initial || {});
  const [selectedPermissions, setSelectedPermissions] = useState(() => {
    let storage = Set();
    ((initial || {}).permissions || []).forEach(p => {
      storage = storage.add(p.id);
    });
    return storage;
  });

  const handleSubmit = (ev) => {
    const expPerm = initial?.experimental_permissions?.map(p => p.id) || [];
    ev.preventDefault();
    onSubmit({
      name: form.name,
      description: form.description,
      permission_ids: [...selectedPermissions.toArray(), ...expPerm],
    });
  };

  const handleNameChanged = (ev) => {
    setForm({
      ...form,
      name: ev.target.value,
    });
  };

  const handleDescriptionChanged = (ev) => {
    setForm({
      ...form,
      description: ev.target.value,
    });
  };

  const togglePermissionSelection = (permissionId) => () => {
    if (selectedPermissions.has(permissionId)) {
      setSelectedPermissions(selectedPermissions.filterNot(value => value === permissionId));
    } else {
      setSelectedPermissions(selectedPermissions.add(permissionId));
    }
  };

  const submitIsDisabled = useMemo(() => {
    if (loaderBarState) return true;
    if (
      (duplicate && form.name !== initial.name && form.name?.length > 0)
      || (!duplicate && form.name?.length > 0)
    ) {
      return false;
    };
    return true;
  }, [ form, initial, duplicate, loaderBarState ]);

  return <form onSubmit={handleSubmit}>
    <FormGroup>
      <Label htmlFor="new-role-name-input">Nom du rôle{duplicate && " (Doit être différent du nom d'origine)"}</Label>
      <Input id="new-role-name-input" required value={form.name} onChange={handleNameChanged} />
    </FormGroup>
    <FormGroup>
      <Label htmlFor="new-role-description-input">Description (Optionnelle)</Label>
      <Textarea id="new-role-description-input" value={form.description} onChange={handleDescriptionChanged} />
    </FormGroup>
    <div style={{marginBottom: '1rem'}}>
      <h4>Permissions</h4>
      {permissions.map(permission => (
        <span key={permission.id}>
          <Checkbox
            label={permission.name || permission.key}
            checked={selectedPermissions.has(permission.id)}
            onChange={togglePermissionSelection(permission.id)}
          />
          <PermissionDescription>{permission.description}</PermissionDescription>
        </span>
      ))}
    </div>
    <Button disabled={submitIsDisabled}>{duplicate ? "Dupliquer" : "Sauvegarder"}</Button>
  </form>
}
