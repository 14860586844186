import React, { useContext, useEffect, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';
import LoaderBarContext from '../../ui/useLoaderBar';

import PageHeader, { Cntnr, Title } from '../../ui/PageHeader';
import Button from '../../ui/Button';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import LoadingSpinner from '../../ui/LoadingSpinner';

export default function EditOrganizationalUnit({ router, match }) {
  const controller = new AbortController();
  const id = match.params.id;

  const { isLoading: organizationIsLoading } = useQuery(
    ['organizationalUnitsEdit'],
    async () => {
      return await http
        .get(`organizational_units/${id}.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setName(res.name);
          setShortName(res.short_name);
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération de l'unité organisationnel");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState === true) {
      return;
    }
    setLoaderBar(true);
    const payload = {
      organizational_unit: { name: name, short_name: shortName },
    };
    try {
      await http.patch(`organizational_units/${encodeURIComponent(id)}.json`, { json: payload }).json();
      router.push('/gestionnaire/unites-organisationnelles/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Impossible de sauvegarder l'unité organisationnelle.");
    } finally {
      setLoaderBar(false);
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Modification de l'unité organisationnelle</Title>
      </PageHeader>

      {organizationIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <Cntnr>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor='unit-name-input'>Nom</Label>
              <Input
                id='unit-name-input'
                value={name}
                onChange={ev => setName(ev.target.value)}
                disabled={loaderBarState}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='unit-short-name-input'>Nom abrégé</Label>
              <Input
                id='unit-short-name-input'
                value={shortName}
                onChange={ev => setShortName(ev.target.value)}
                disabled={loaderBarState}
              />
            </FormGroup>
            <Button disabled={loaderBarState} type='submit'>
              Sauvegarder
            </Button>
          </form>
        </Cntnr>
      )}
    </>
  );
}
