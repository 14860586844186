import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from "styled-components";
import theme from "./theme";

const RadioValidatorContainer = styled.span`
  &.left {
    margin-right: 15px;
  }
  &.right {
    margin-left: 15px;
  }
  svg {
    position: relative;
    top: -1px;
  }
`;

function RadioValidator ({condition, fontSize, position}) {
  return (
    <RadioValidatorContainer className={position}>
      {condition ? <CheckCircleOutlineIcon fontSize={fontSize} /> : <RadioButtonUncheckedIcon fontSize={fontSize} /> }
    </RadioValidatorContainer>
  );
};

export default RadioValidator;

const RadioRoundedValidatorContainer = styled.span`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  color: ${theme.blue};
`;

export function RadioRoundedValidator ({top, left}) {
  return (
    <RadioRoundedValidatorContainer top={top} left={left}>
      <CheckCircleIcon />
    </RadioRoundedValidatorContainer>
  );
};