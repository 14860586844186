import React from "react";
import styled from "styled-components";
import theme from "./theme";

const Step = styled.div`
  color: ${theme.blue};
  font-weight: 700;
  font-size: 0.9rem;
  margin: ${theme.small} auto;
  width: max-content;
  display: flex;
  align-items: center;
  div {
    width: 15px;
    height: 2px;
    margin: 5px;
    background-color: ${theme.blue};
  }
  @media (max-width: 550px) {
    margin: 0.75rem auto;
  }
`;

export default function Steps ({step}) {
  return (
    <Step>
      <div></div>
      Étape {step}
      <div></div>
    </Step>
  );
};