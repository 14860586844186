import React from "react";

import ShowBilanComponent from '../ShowBilanComponent';

function ShowBilan({ match, router }) {
  return (
    <ShowBilanComponent match={match} router={router} scope={'bilan'} />
  );
}

export default ShowBilan;
