import React, { Component } from "react";

import styled from "styled-components";

const Iframe = styled.iframe`
  flex: 1 0 auto;
`;

export default class Parsys extends Component {
  static DEFAULT_FRAME_SRC = "/auth/parsys";

  handleMessage = ev => {
    if (ev.data.kind === "logout") {
      this.setState({
        frameSrc: "about:blank"
      });
      setTimeout(() => {
        this.setState({
          frameSrc: Parsys.DEFAULT_FRAME_SRC
        });
      }, 250);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      frameSrc: Parsys.DEFAULT_FRAME_SRC
    };
  }

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%"
        }}
      >
        <Iframe title="Syopé" src={this.state.frameSrc} height="100%" />
        <Iframe title="Parsys" src="https://cloud.parsys.com" height="100%" />
      </div>
    );
  }
}
