import React from 'react';
import { RadioGroup as RadioGroupHl } from '@headlessui/react';
import styled from 'styled-components';
import theme from "./theme";

const RadioGrpCntnr = styled(RadioGroupHl)`
  display: flex;
`;

const RadioOptn = styled(RadioGroupHl.Option)`
  padding: ${theme.thin} ${theme.small};
  padding-top: 0.35rem;
  max-height: 38px;

  background-color: inherit;
  border: 2px solid ${theme.blue};
  color: ${theme.blue};

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 1px;
  }
  &:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: 1px;
  }

  &[aria-checked="true"] {
    background-color: ${theme.blue};
    color: ${theme.backgroundColor};
  }
`;

/**
 * @param {Array}    options     Options for the radio group {value: int, label: string}
 * @param {Function} onChange    Callback
 * @param {Number}   radioValue  Value
 * @returns
 */
const RadioGroup = ({ options, onChange, radioValue }) => {
  return (
    <RadioGrpCntnr value={radioValue} onChange={onChange}>
      {options.map(option => (
        <RadioOptn value={option.value} key={option.value}>
        {({ checked }) => (
          <span className={checked ? 'checked' : ''}>{option.label}</span>
        )}
      </RadioOptn>
      ))}
    </RadioGrpCntnr>
  )
}

export default RadioGroup;
