import React, {useContext, useEffect, useMemo, useState} from 'react';
import http from '../../http';
import {useQuery} from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import {isAbortError} from '../../utils';
import LoaderBarContext from '../../ui/useLoaderBar';

import PageHeader, {Cntnr, Title} from '../../ui/PageHeader';
import Input, {InputSearchCntnr, InputSearchIcon} from '../../ui/Input';
import Button, {BlockButton} from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import styled from 'styled-components';
import theme from '../../ui/theme';
import Select from "../../ui/Select";
import Tag from "../../ui/Tag";
import SearchBarCompo, {FilterBar, SearchBar} from "../../ui/SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import {alphabeticSort} from "../../interventions/utils";

const RowItem = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    width: 100%;
  }

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;

  & > ${BlockButton} {
    margin-left: ${theme.small};
  }

  @media (max-width: 950px) {
    margin-top: ${theme.thin};
    & > ${BlockButton}:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 300px) {
    flex-direction: column;
    & > ${BlockButton} {
      margin-left: 0;
    }
  }
`;

function UnitItem({unit, unitsRefetch}) {
  const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);

  const handleDeleteClick = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`units/${encodeURIComponent(unit.id)}.json`);
      toast.success('Unité supprimée avec succès');
      unitsRefetch();
    } finally {
      setLoaderBar(false);
    }
  };

  return (
    <form style={{paddingTop: '.5rem', paddingBottom: '.5rem'}}>
      <RowItem>
        <Input value={unit.name} disabled/>
        <ActionItem width={1}>
          <BlockButton type='button' className='warn' disabled={loaderBarState} onClick={handleDeleteClick}>
            Supprimer
          </BlockButton>
        </ActionItem>
      </RowItem>
    </form>
  );
}

export default function Units() {
  const controller = new AbortController();

  const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);
  const [dictionaryUnit, setDictionaryUnit] = useState(null);
  const [search, setSearch] = useState('');

  const {
    isLoading: dictionaryUnitsIsLoading,
    data: dictionaryUnits
  } = useQuery(
    ['dictionary-units'],
    async () => {
      return await http
        .get(`units/dictionary.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.units
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération de la liste globale des unités');
          throw error;
        });
    },
    {cacheTime: 0}
  );

  const {
    isLoading: unitsIsLoading,
    data: units,
    refetch: unitsRefetch,
  } = useQuery(
    ['units'],
    async () => {
      return await http
        .get(`units.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.units;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des unités de votre organisation');
          throw error;
        });
    },
    {cacheTime: 0}
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post('units.json', {
        json: {
          unit: {
            name: dictionaryUnit.name,
            numerator: dictionaryUnit.numerator,
            denominator: dictionaryUnit.denominator,
            flow_rate: dictionaryUnit.flow_rate,
            weight_dependant: dictionaryUnit.weight_dependant
          }
        },
      });
      toast.success('Unité ajouté avec succès');
      setDictionaryUnit(null);
      unitsRefetch();
    } catch (e) {
      console.error(e);
      toast.error('Impossible de créer l\'unité');
    } finally {
      setLoaderBar(false);
    }
  };

  const clearFilter = () => {
    setSearch('');
  };

  const unitsFiltered = useMemo(() => {
    let temp = [];
    units?.map(s => {
      if (s.name?.toLowerCase().includes(search.toLocaleLowerCase())) temp.push(s);
    });
    return alphabeticSort(temp, (value) => value.name);
  }, [search, units]);

  return (
    <>
      <PageHeader>
        <Title>Unités</Title>
      </PageHeader>

      {dictionaryUnitsIsLoading ? (
        <LoadingSpinner className='center vh-50'/>
      ) : (
        <Cntnr>
          <form onSubmit={handleFormSubmit}>
            <RowItem>
              <Select
                id='input-select-unit'
                isSearchable={true}
                isClearable={true}
                options={dictionaryUnits}
                value={dictionaryUnit}
                isDisabled={loaderBarState}
                getOptionLabel={option => `${option.name}`}
                getOptionValue={option => `${option.name}`}
                onChange={ev => setDictionaryUnit(ev)}
                placeholder='Sélectionnez une unité'
              />
              <ActionItem width={1}>
                <BlockButton type='submit' disabled={loaderBarState}>Ajouter</BlockButton>
              </ActionItem>
            </RowItem>
          </form>
          <hr/>
          <SearchBarCompo hasTag={true} tags={<>{search.length > 0 && <Tag text={search} callback={clearFilter}/>}</>}>
            <SearchBar>
              <FilterBar>
                <InputSearchCntnr>
                  <InputSearchIcon><SearchIcon/></InputSearchIcon>
                  <Input type='search' value={search} onChange={ev => setSearch(ev.target.value)} placeholder='Unité'/>
                </InputSearchCntnr>
                <Button className='warn' onClick={clearFilter}>Réinitialiser les filtres</Button>
              </FilterBar>
            </SearchBar>
          </SearchBarCompo>
          {unitsIsLoading ? (
            <LoadingSpinner className='center vh-50'/>
          ) : (<>{unitsFiltered.map(unit => (
            <UnitItem key={unit.id} unit={unit} unitsRefetch={() => unitsRefetch()}/>
          ))}</>)}
        </Cntnr>
      )}
    </>
  );
}
