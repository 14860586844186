import React, { useRef, Fragment } from "react";
import styled from "styled-components";
import theme from "./theme";

import { Dialog as DialogHeadless, Transition } from "@headlessui/react";
import CloseIcon from '@mui/icons-material/Close';

export const DialogCntnr = styled(DialogHeadless)`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    &-overlay {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
    }

    &-content {
      width: 80%;
      /* max-height: 80vh; */
      background-color: ${theme.backgroundColor};
      position: relative;
      border-radius: ${theme.borderRadius};
      border: none;
      padding: ${theme.medium};
      box-shadow: ${theme.boxShadowLarge};
      transition: box-shadow 0.3s ease-in-out;
      overflow-y: auto;
      &:hover {
        box-shadow: ${theme.boxShadow};
      }
      &.auto {
        width: auto;
      }

      &.customTypeform {
        height: 90vh !important;
        width: 80%;
      }

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${theme.small};
        &-close {
          background-color: inherit;
          color: ${theme.text};
          border: none;
        }
      }
    }
  }

  @media (min-width: 1100px) {
    .dialog-content {
      width: 50%;
      &.auto {
        width: auto;
      }
    }
  }
  @media (max-width: 550px) {
    .dialog-content {
      width: 90%;
      max-height: 95vh;
      &.auto {
        width: auto;
      }
    }
  }

  // TRANSITION
  .overlay-enter {
    transition: opacity 0.3s ease-in-out;
    &-from {
      opacity: 0%;
    }
    &-to {
      opacity: 100%;
    }
  }
  .overlay-leave {
    transition: opacity 0.2s ease-in-out;
    &-from {
      opacity: 100%;
    }
    &-to {
      opacity: 0%;
    }
  }

  .content-enter {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    &-from {
      opacity: 0%;
      transform: scale(0.8) rotate(1deg);
    }
    &-to {
      opacity: 100%;
      transform: scale(1) rotate(0deg);
    }
  }
  .content-leave {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    &-from {
      opacity: 100%;
      transform: scale(1) rotate(0deg);
    }
    &-to {
      opacity: 0%;
      transform: scale(0.8) rotate(1deg);
    }
  }
`;

export const ActionsBtnDialog = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;

    button {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: ${theme.thin};
      }
    }
  }
`;

export function Dialog ({isOpen, toggleDialog, title, children, width = null}) {
  const initialRef = useRef(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <DialogCntnr className="dialog" initialFocus={initialRef}  onClose={toggleDialog}>
        <Transition.Child
          as={Fragment}
          enter="overlay-enter"
          enterFrom="overlay-enter-from"
          enterTo="overlay-enter-to"
          leave="overlay-leave"
          leaveFrom="overlay-leave-from"
          leaveTo="overlay-leave-to"
        >
          <DialogHeadless.Overlay className="dialog-overlay" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="content-enter"
          enterFrom="content-enter-from"
          enterTo="content-enter-to"
          leave="content-leave"
          leaveFrom="content-leave-from"
          leaveTo="content-leave-to"
        >
          <div className={`dialog-content ${width !== null ? width : ""}`}>
            <div className="dialog-content-header">
              <DialogHeadless.Title>{title}</DialogHeadless.Title>
              <button className="dialog-content-header-close" ref={initialRef} type="button" onClick={toggleDialog}>
                <CloseIcon />
              </button>
            </div>
            {children}
          </div>
        </Transition.Child>
      </DialogCntnr>
    </Transition>
  );
};