import React, {useState} from "react";
import Button from "../ui/Button";
import {useQuery, useQueryClient} from '@tanstack/react-query';
import http from "../http";
import ky from "ky";
import LoadingSpinner from "../ui/LoadingSpinner";
import {DateTime} from "luxon";
import * as Sentry from '@sentry/browser';
import {triggerDownload} from "../utils";
import SparkMD5 from "spark-md5";
import {toast} from "react-toastify";

const fmtDate = datetime =>
  datetime == null
    ? "NR"
    : DateTime.fromISO(datetime).toLocaleString(DateTime.DATE_SHORT);
const fmtTime = datetime =>
  datetime == null
    ? "NR"
    : DateTime.fromISO(datetime).toLocaleString({
      hour: "2-digit",
      minute: "2-digit"
    });

function ListEntry({interventionId, ekg}) {
  const queryClient = useQueryClient();
  const [isDownloading, setDownloading] = useState(false);
  const [isImporting, setImporting] = useState(false);

  const handleDownload = async (ev) => {
    ev.preventDefault();
    setDownloading(true);
    try {
      const pdfPayload = await http.get(`interventions/${encodeURIComponent(interventionId)}/corpuls_ekgs/${encodeURIComponent(ekg.id)}`).arrayBuffer();
      const pdfBlob = new Blob([pdfPayload], {
        type: "application/pdf"
      });
      await triggerDownload(`ECG Corpuls ${ekg.created_at}.pdf`, pdfBlob);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      toast.error("Impossible de télécharger l'ECG. A-t-il bien été transmis par le C3 ?");
    } finally {
      setDownloading(false);
    }
  };
  const handleImport = async (ev) => {
    ev.preventDefault();
    setImporting(true);
    try {
      const pdfPayload = await http.get(`interventions/${encodeURIComponent(interventionId)}/corpuls_ekgs/${encodeURIComponent(ekg.id)}`).arrayBuffer();
      const digester = new SparkMD5.ArrayBuffer();
      digester.append(pdfPayload);
      const md5Digest = window.btoa(digester.end(true));
      const signedUploadPayload = {
        blob: {
          filename: `ECG Corpuls ${ekg.created_at}`,
          byte_size: pdfPayload.byteLength,
          checksum: md5Digest,
          content_type: "application/pdf",
        },
      };
      const signedUpload = await http.post('direct_uploads.json', { json: signedUploadPayload }).json();
      await ky.put(signedUpload.direct_upload.url, {
        headers: signedUpload.direct_upload.headers,
        body: new Blob([pdfPayload], {
          type: "application/pdf"
        }),
      });
      await http.post(`interventions/${interventionId}/attachments.json`, {
        json: {
          attachment: {
            description: `ECG Corpuls ${ekg.created_at}`,
            file: signedUpload.signed_id,
            category: "ecg",
          }
        }
      });
      await queryClient.cancelQueries(['interventions', interventionId, 'attachments']);
      toast.success("L'ECG a bien été ajouté comme pièce jointe à l'intervention");
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      toast.error("Impossible d'importer l'ECG. A-t-il bien été transmis par le C3 ?");
    } finally {
      setImporting(false);
    }
  };

  return (<div style={{padding: '1rem', borderTop: '1px solid #ddd'}}>
    <h4>ECG réalisé à {fmtTime(ekg.created_at)} le {fmtDate(ekg.created_at)}</h4>
    <div style={{display: "flex", flexDirection: "row"}}>
      <Button style={{flex: "1", marginRight: ".5rem"}} onClick={handleDownload} disabled={isDownloading} data-sentry-id="corpuls-download">Télécharger</Button>
      <Button style={{flex: "1", marginLeft: ".5rem"}} onClick={handleImport} disabled={isImporting} data-sentry-id="corpuls-import">Importer</Button>
    </div>
  </div>);
}


export default function CorpulsEkgList({interventionId, missionId}) {
  const {isLoading, error, data} = useQuery(['interventions', interventionId, 'corpuls-ekgs'], () =>
    http.get(`interventions/${encodeURIComponent(interventionId)}/corpuls_ekgs.json`).json()
  );

  if (isLoading) {
    return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <h3 style={{padding: '1rem', paddingBottom: '1.5rem'}}>ECG ({missionId})</h3>
      <div style={{textAlign: 'center'}}>
        <LoadingSpinner/>
      </div>
    </div>);
  }

  if (error) {
    return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <h3 style={{padding: '1rem', paddingBottom: '1.5rem'}}>ECG ({missionId})</h3>
      <div>
        Une erreur est survenue lors de la récupération des ECG.
      </div>
    </div>);
  }

  return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
    <h3 style={{padding: '1rem', paddingBottom: '1.5rem'}}>ECG ({missionId})</h3>
    <div>
      {data.data.map(ekg => <ListEntry key={ekg.id} interventionId={interventionId} ekg={ekg}/>)}
    </div>
  </div>);
}
