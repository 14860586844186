import React, { useEffect, useState } from "react";
import { useRouter } from "found";

import {
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis
} from "react-vis/es";
import http from "../http";
import has from "lodash/has";
import LoadingSpinner from "../ui/LoadingSpinner";
import { Crosshair, LabelSeries, LineSeries } from "react-vis";

function DynamicChart({ config, intervalStart, intervalEnd }) {
  const { router } = useRouter();
  const [seriesData, setSeriesData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);
  useEffect(() => {
    const searchParams = {
      interval_start: intervalStart.toISO(),
      interval_end: intervalEnd.toISO()
    };
    Promise.all(
      config.series.map(series =>
        http.get(series.source, { searchParams }).json()
      )
    )
      .then(payloads => {
        setSeriesData(payloads.map(payload => payload.data));
        setLoading(false);
      })
      .catch(err => {
        if (has(err, "location")) {
          router.push(err.location);
          return;
        }
        console.error(err);
      });
  }, [config.series, router, intervalStart, intervalEnd]);

  if (isLoading !== false) {
    return <LoadingSpinner />;
  }

  const handleMouseLeave = () => {
    setCrosshairValues([]);
  };
  const handleNearestX = (value, { index }) => {
    setCrosshairValues(seriesData.map(d => d[index]));
  };
  const margin = { left: 10, right: 10, top: 10, bottom: 10 };
  const children = [];
  let isCrosshairInstalled = false;

  config.gridLines.forEach(line => {
    switch (line.kind) {
      case "horizontal":
        children.push(<HorizontalGridLines />);
        break;
      case "vertical":
        children.push(<VerticalGridLines />);
        break;
      default:
        console.warn(
          `Unknown gridLine kind; kind=${line.kind} id=${config.id}`
        );
    }
  });
  seriesData.forEach((data, seriesIdx) => {
    const series = config.series[seriesIdx];
    let onNearestX = undefined;
    if (isCrosshairInstalled === false) {
      isCrosshairInstalled = true;
      onNearestX = handleNearestX;
    }
    switch (series.kind) {
      case "vertical-bar":
        children.push(
          <VerticalBarSeries onNearestX={onNearestX} data={data} />
        );
        break;
      case "line":
        children.push(
          <LineSeries
            curve={series.curve}
            onNearestX={onNearestX}
            data={data}
          />
        );
        break;
      case "text":
        const labelSeriesData = [
          { x: 1, y: 1, label: data, style: { fontSize: 24 } }
        ];
        children.push(<LabelSeries data={labelSeriesData} />);
        break;
      default:
        console.warn(
          `Unknown series kind; kind=${series.kind} id=${config.id}`
        );
    }
  });
  config.axis.forEach(axis => {
    switch (axis.kind) {
      case "x":
        children.push(<XAxis />);
        margin.bottom = 60;
        break;
      case "y":
        children.push(<YAxis />);
        margin.left = 40;
        break;
      default:
        console.warn(`Unknown axis kind; kind=${axis.kind} id=${config.id}`);
    }
  });
  if (config.crosshair === true) {
    let titleFormat = undefined;
    if (config.xType === "time" || config.xType === "time-utc") {
      titleFormat = arg => {
        return {
          title: "Date",
          value: new Date(arg[0].x).toLocaleDateString()
        };
      };
    }
    const itemsFormat = data =>
      data.map((datum, idx) => {
        const fmt = { title: idx, value: datum.y };
        if (typeof config.series[idx].title !== "undefined") {
          fmt.title = config.series[idx].title;
        }
        return fmt;
      });
    children.push(
      <Crosshair
        values={crosshairValues}
        titleFormat={titleFormat}
        itemsFormat={itemsFormat}
      />
    );
  }
  return (
    <FlexibleXYPlot
      onMouseLeave={handleMouseLeave}
      xType={config.xType}
      margin={margin}
      children={children}
      dontCheckIfEmpty={true}
    />
  );
}

export default DynamicChart;

function PreviewComponent({ config, intervalStart, intervalEnd }) {
  const { router } = useRouter();
  const previewConfig = {
    gridLines: config.gridLines,
    series: config.series,
    axis: [],
    crosshair: false
  };
  return (
    <DynamicChart
      config={previewConfig}
      intervalStart={intervalStart}
      intervalEnd={intervalEnd}
      router={router}
    />
  );
}

export const Preview = PreviewComponent;
