import React from 'react';
import styled from 'styled-components';
import { data as syoData, utils as syoUtils } from '@nfsave/syo-bilan';
import theme from '../../../ui/theme';

import { MannequinVector } from './MannequinVector';
import { MannequinFootsVector } from './MannequinFootsVector';
import { MannequinHandsVector } from './MannequinHandsVector';
import { MannequinHeadVector } from './MannequinHeadVector';

import type { SyoApi, SyoLib } from '@nfsave/syo-bilan';
import type { ReactNode } from 'react';

type MannequinProps = {
  syoLesionnel: SyoLib.Lesionnel;
  lesionnel: SyoApi.Bilan['lesionnel'];
  position: SyoLib.LesionnelPosition;
  version: 'v1' | 'v2';
  sexe: SyoLib.Lesionnel['typeSex'];
  typeAge: SyoLib.Lesionnel['typeAge'];
};

type MannequinCommonProps = {
  syoLesionnel: SyoLib.Lesionnel;
  lesionnel: SyoApi.Lesionnel;
  position: SyoLib.LesionnelPosition;
  side: SyoLib.LesionnelSide;
};

type MannequinHeadProps = {
  syoLesionnel: SyoLib.Lesionnel;
  lesionnel: SyoApi.Lesionnel;
  position: SyoLib.LesionnelPosition;
  side: SyoLib.LesionnelSideHead;
};

export function textifyFront(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT, dummy);
}

export function textifyBack(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK, dummy);
}

export function textifyFrontHead(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HEAD, dummy);
}

export function textifyFrontHandRight(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HAND_RIGHT, dummy);
}

export function textifyBackHandRight(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_HAND_RIGHT, dummy);
}

export function textifyFrontHandLeft(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HAND_LEFT, dummy);
}

export function textifyBackHandLeft(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_HAND_LEFT, dummy);
}

export function textifyFrontFootRight(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_FOOT_RIGHT, dummy);
}

export function textifyBackFootRight(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_FOOT_RIGHT, dummy);
}

export function textifyFrontFootLeft(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_FOOT_LEFT, dummy);
}

export function textifyBackFootLeft(dummy: SyoApi.Lesionnel) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_FOOT_LEFT, dummy);
}

const MannequinCntnr = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.small};
`;

const MannequinHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default function Mannequin({ syoLesionnel, lesionnel, version, position, sexe, typeAge }: MannequinProps) {
  const viewBoxVersion = version === 'v2' ? '0 0 1014 1955' : '0 0 375 782';
  const widhtVersion = version === 'v2' ? '230px' : '130px';
  const heightVersion = version === 'v2' ? '373px' : '273px';

  const getTextify = () => {
    if (position === 'front') {
      return textifyFront(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
    }
    if (position === 'back') {
      return textifyBack(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
    }
    return;
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>{position === 'front' ? 'D' : 'G'}</span>
        <span>{position === 'front' ? 'Face avant' : 'Face arrière'}</span>
        <span>{position === 'front' ? 'G' : 'D'}</span>
      </MannequinHeader>

      <svg
        style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <DefsMannequin allGradients={syoLesionnel.allGradients} />
        <MannequinVector
          syoLesionnel={syoLesionnel}
          position={position}
          version={version}
          sexe={sexe}
          typeAge={typeAge}
        />
      </svg>

      <ul>{getTextify()}</ul>
    </MannequinCntnr>
  );
}

export const MannequinHead = ({ syoLesionnel, lesionnel, side, position = 'front' }: MannequinHeadProps) => {
  const viewBoxVersion = '0 0 507 507';
  const widhtVersion = '230px';
  const heightVersion = '230px';

  const getTextify = () => {
    return textifyFrontHead(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>{position === 'front' ? 'D' : 'G'}</span>
        <span>{position === 'front' ? 'Face avant' : 'Face arrière'}</span>
        <span>{position === 'front' ? 'G' : 'D'}</span>
      </MannequinHeader>

      <svg
        style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <DefsMannequin allGradients={syoLesionnel.allGradients} />
        <MannequinHeadVector syoLesionnel={syoLesionnel} position={position} side={side} />
      </svg>

      <ul>{getTextify()}</ul>
    </MannequinCntnr>
  );
};

export const MannequinHands = ({ syoLesionnel, lesionnel, position, side }: MannequinCommonProps) => {
  const viewBoxVersion = '0 0 1589.6 1955.1';
  const widhtVersion = '230px';
  const heightVersion = '373px';
  const sideTxt = side === 'right' ? 'Main droite' : 'Main gauche';
  const positionTxt = position === 'front' ? 'Face dorsale' : 'Face palmaire';

  const getTextify = () => {
    if (position === 'front') {
      if (side === 'right') {
        return textifyFrontHandRight(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      } else {
        return textifyFrontHandLeft(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      }
    } else if (position === 'back') {
      if (side === 'right') {
        return textifyBackHandRight(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      } else {
        return textifyBackHandLeft(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      }
    }
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span></span>
        <span>{`${sideTxt}: ${positionTxt}`}</span>
        <span></span>
      </MannequinHeader>

      <svg
        style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <DefsMannequin allGradients={syoLesionnel.allGradients} />
        <MannequinHandsVector syoLesionnel={syoLesionnel} position={position} side={side} />
      </svg>

      <ul>{getTextify()}</ul>
    </MannequinCntnr>
  );
};
export const MannequinFoots = ({ syoLesionnel, lesionnel, position, side }: MannequinCommonProps) => {
  const viewBoxVersion = '0 0 596 842';
  const widhtVersion = '230px';
  const heightVersion = '373px';
  const sideTxt = side === 'right' ? 'Pied droite' : 'Pied gauche';
  const positionTxt = position === 'front' ? 'Face dorsale' : 'Face palmaire';

  const getTextify = (): ReactNode => {
    if (position === 'front') {
      if (side === 'right') {
        return textifyFrontFootRight(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      } else {
        return textifyFrontFootLeft(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      }
    } else if (position === 'back') {
      if (side === 'right') {
        return textifyBackFootRight(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      } else {
        return textifyBackFootLeft(lesionnel).map(([key, line]) => <li key={key}>{line}</li>);
      }
    }
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>{position === 'front' ? (side === 'right' ? 'Int' : 'Ext') : side === 'right' ? 'Ext' : 'Int'}</span>
        <span>{`${sideTxt}: ${positionTxt}`}</span>
        <span>{position === 'front' ? (side === 'right' ? 'Ext' : 'Int') : side === 'right' ? 'Int' : 'Ext'}</span>
      </MannequinHeader>

      <svg
        style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <DefsMannequin allGradients={syoLesionnel.allGradients} />
        <MannequinFootsVector syoLesionnel={syoLesionnel} position={position} side={side} />
      </svg>

      <ul>{getTextify()}</ul>
    </MannequinCntnr>
  );
};

type GradientProps = {
  gradient: SyoLib.LesionnelGradient;
};
const Gradient = ({ gradient }: GradientProps) => {
  const exas = gradient[1];
  const length = exas.length;
  const part = 100 / length;
  const decalage = part / 2;
  return (
    <linearGradient
      id={gradient[2]}
      spreadMethod='pad'
      gradientTransform='rotate(90)'
      x1={`${decalage / 2}%`}
      y1='0%'
      x2='100%'
      y2='0%'
    >
      {exas.map((e, idx) => (
        <stop key={idx} offset={`${part * idx + decalage}%`} stopColor={exas[idx]} />
      ))}
    </linearGradient>
  );
};
type DefsMannequinProps = {
  allGradients: SyoLib.Lesionnel['allGradients'];
};
const DefsMannequin = ({ allGradients = [] }: DefsMannequinProps) => {
  return (
    <defs>
      {allGradients.map((gradient, idx) => (
        <Gradient key={idx} gradient={gradient} />
      ))}
      <linearGradient id='Vide' x1='100%' y1='0%' x2='60%' y2='0%' spreadMethod='pad'>
        <stop offset='0%' stopColor='#D8D8D8' />
        <stop offset='100%' stopColor='#D8D8D8' />
      </linearGradient>
    </defs>
  );
};
