import React, { useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { MONTHS, DAYS } from "../utils";

import styled  from "styled-components";
import theme from "./theme";
import BaseFormGroup from "./FormGroup";
import { Dialog } from "./Modal";
import Button from "./Button";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventIcon from '@mui/icons-material/Event';

export const ModalContent = styled.div`
  background-color: ${theme.backgroundColor};
  box-shadow: 0px 8px 16px 10px rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: ${theme.small};
  border-radius: ${theme.thin};
  overflow-y: auto;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
`;

export const TimePickerCntnr = styled.div`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  border-radius: ${theme.thin};
  user-select: none;
  max-width: 300px;
  margin: auto;
  margin-left: ${theme.small};
`;

export const TimeCntnr = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const HourMinute = styled.div`
  position: relative;
  min-width: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hr,
  .min {
    background: none;
    appearance: none;
    -moz-appearance: textfield;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    text-align: center;
  }

  .hr-up,
  .hr-down,
  .min-up,
  .min-down {
    width: 40px;
    height: 20px;
    cursor: pointer;
    color: ${theme.grey3};

    &:hover {
      color: ${theme.grey2};
    }
  }
`;

export const ActionTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${theme.grey4};
  padding: ${theme.thin};

  ${Button} {
    padding: ${theme.thin};
  }
`;

export const DatePickerCntnr = styled.div`
  width: 100%;
  max-width: 320px;
  background-color: ${theme.backgroundColor};

  user-select: none;

  .selected-dates {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DateView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${theme.grey4};
  padding-bottom: ${theme.thin};
`;

export const Month = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.grey4};
  padding: ${theme.thin};
`;

export const ActionDate = styled(Month)`
  justify-content: center;

  ${Button} {
    padding: ${theme.thin};
  }
`;

export const MonthAction = styled.button`
  border: none;
  background-color: inherit;
  color: ${theme.grey3};

  &:hover {
    color: ${theme.grey2};
  }
`;

export const DaysWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: ${theme.thin};
`;

export const DayWeek = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Days = styled(DaysWeek)`
  height: 200px;
`;

export const Day = styled(DayWeek)`
  &.selected {
    background-color: ${theme.blue};
    color: ${theme.white}
  }
  &.currentMonth:hover {
    cursor: pointer;
  }
`;

export const FormGroup = styled(BaseFormGroup)`
  display: flex;
  flex-direction: row;
  padding: 0px;
`;

export const Input = styled.div`
  display: block;
  width: 100%;
  padding: ${props => (props.size === "small" ? ".25rem" : ".5rem")};
  border-radius: ${props => (props.size === "small" ? ".125rem" : ".25rem")};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: ${props => (props.size === "small" ? ".85rem" : "1rem")};
  border-color: ${theme.grey5};
  border-style: solid;
  border-width: 1px;

  &:hover{
    cursor: pointer;
  }
`;

export const ButtonIcon = styled.div`
  border: 1px solid ${theme.grey5};
  border-left-width: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.33rem ${theme.thin} 0;
  color: ${theme.grey4};
`;

export const ButtonBar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: ${theme.small};
`;

const ModalCol = styled.div`
  display: flex;
  flex-direction: row;
`;

function DateTimePicker ({callback, empty = false, picker = "dateTime", today, title = ""}) {
  const [isEmpty, setIsEmpty] = useState(empty);
  const [isActive, setActive] = useState(false);
  const [savedDate, setSavedDate] = useState({
    year: today.year,
    month: today.month,
    day: today.day,
  });
  const [selectedDate, setSelectedDate] = useState({
    year: today.year,
    month: today.month,
    day: today.day,
  });
  const [activeDate, setActiveDate] = useState({
    year: today.year,
    month: today.month,
    day: today.day,
  });

  const [selectedTime, setSelectedTime] = useState({
    hour: today.hour,
    minute: today.minute
  });
  const [savedTime, setSavedTime] = useState({
    hour: today.hour,
    minute: today.minute,
  });

  /** Réinitialise la date et l'heure */
  useEffect(() => {
    setDates(today);
    setSavedDate({
      year: today.year,
      month: today.month,
      day: today.day
    });
    setSelectedTime({
      hour: today.hour,
      minute: today.minute,
    });
    setSavedTime({
      hour: today.hour,
      minute: today.minute,
    });
    setIsEmpty(empty);
  }, [today]);

  const togglePicker = (ev) => {
    setActive(!isActive);
  };

  const setDates = (date) => {
    setActiveDate({
      year: date.year,
      month: date.month,
      day: date.day
    });

    setSelectedDate({
      year: date.year,
      month: date.month,
      day: date.day
    });
  };

  /**
   * Génère l'affichage des jours dans le mois
   * @returns {Array}
   */
  const daysMonth = () => {
    const start = DateTime.local(activeDate.year, activeDate.month, 1).weekday;
    const nbrDaysInMonth = DateTime.local(activeDate.year, activeDate.month).daysInMonth;
    let daysInMonth = [];
    for (let i = 0; i < start + nbrDaysInMonth; i++) {
      if (i < start - 1) {
        daysInMonth.push({class: "oldMonth"});
      } else if (i > (start + nbrDaysInMonth - 2)) {
        daysInMonth.push({class: "nextMonth"});
      } else {
        daysInMonth.push({class: "currentMonth", value: i - (start - 2), day: i - (start - 2)});
      };
    };
    return daysInMonth;
  };

  /**
   * Génère la className pour l'élément sélectionner
   * @param {*} day 
   * @returns "selected" or ""
   */
  const daySelected = (day) => {
    return day.class === "currentMonth" && day.day === selectedDate.day && activeDate.month === selectedDate.month ? " selected" : "";
  };

  /** Cloture l'affichage du calendrier */
  const handleClose = () => {
    setDates(savedDate);
    setActive(false);
  };

  /** Sauvegarde les valeurs sélectionnées */
  const handleSave = () => {
    setSavedDate({
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day,
    });
    setSavedTime({
      hour: selectedTime.hour,
      minute: selectedTime.minute,
    });
    setDates(selectedDate);
    callback({
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day,
      hour: selectedTime.hour,
      minute: selectedTime.minute,
    });
    if (isEmpty) setIsEmpty(false);
    setActive(false);
  };

  /**
   * Assigne la nouvelle valeur du mois actif dans le calendrier
   * @param {*} month 1 || -1
   */
  const handleChangeMonth = (month) => {
    if (activeDate.month + month > 12) {
      setActiveDate({
        year: activeDate.year + 1,
        month: 1,
        day: activeDate.day,
      })
    } else if (activeDate.month + month < 1) {
      setActiveDate({
        year: activeDate.year - 1,
        month: 12,
        day: activeDate.day,
      });
    } else {
      setActiveDate({
        year: activeDate.year,
        month: activeDate.month + month,
        day: activeDate.day,
      });
    };
  };

  /**
   * Assigne la nouvelle date active au clique sur le calendrier
   * @param {*} day 
   */
  const handleClickSelected = (day) => {
    setSelectedDate({
      year: activeDate.year,
      month: activeDate.month,
      day: day
    });
  };

  /**
   * Assigne la nouvelle valeur de l'heure au clique
   * @param {Number} time   1 || -1
   * @param {Number} minute 
   */
  const handleClickHour = (time, minute) => {
    const min = minute === undefined ? selectedTime.minute : minute;

    if (selectedTime.hour + time > 23) {
      setSelectedTime({
        hour: 0,
        minute: min,
      });
    } else if (selectedTime.hour + time < 0) {
      setSelectedTime({
        hour: 23,
        minute: min,
      });
    } else {
      setSelectedTime({
        hour: selectedTime.hour + (time),
        minute: min,
      });
    };
  }; 

  /**
   * Récupère le changement de valeur dans l'input hour
   * @param {*} ev 
   */
   const handleChangeHour = (ev) => {
    if (ev.target.value !== "") {
      const newHour = parseInt(ev.target.value);
      if (newHour >= 24 || newHour < 0) {
        setSelectedTime({
          hour: 0,
          minute: selectedTime.minute,
        });
      } else {
        setSelectedTime({
          hour: newHour,
          minute: selectedTime.minute,
        });
      };
    };
  };

  /**
   * Assigne la nouvelle valeur de minute au clique
   * @param {Number} time 1 || -1
   */
  const handleClickMinute = (time) => {
    if (selectedTime.minute + time > 59) {
      handleClickHour(1, 0);
    } else if (selectedTime.minute + time < 0) {
      handleClickHour(-1, 59);
    } else {
      setSelectedTime({
        hour: selectedTime.hour,
        minute: selectedTime.minute + (time),
      });
    };
  };

  /**
   * Récupère le changement de valeur dans l'input minute
   * @param {*} ev 
   */
  const handleChangeMinute = (ev) => {
    if (ev.target.value !== "") {
      const newMin = parseInt(ev.target.value);
      if (newMin > 59) {
        handleClickHour(1, 59);
      } else if (newMin < 0) {
        handleClickHour(-1, 0);
      } else {
        setSelectedTime({
          hour: selectedTime.hour,
          minute: newMin,
        });
      };
    };
  };

  /**
   * Assigne l'heure courante à selectedTime
   */
  const handleNow = () => {
    const date = DateTime.now();
    setSelectedTime({
      hour: date.hour,
      minute: date.minute
    });
  };

  /**
   * Retourne la date au format dd/mm/yyyy
   * @param {*} date 
   * @returns dd/mm/yyyy
   */
  const formatDate = useMemo(() => {
    return DateTime.fromObject({
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day
    })
    .setLocale("fr-fr")
    .toLocaleString(DateTime.DATE_SHORT);
  }, [selectedDate]);

  /**
   * Retourne l'heure au format hh
   * @returns hh
   */
  const formatHour = useMemo(() => {
    return selectedTime.hour.toLocaleString('fr-FR', {
      minimumIntegerDigits: 2
    });
  }, [selectedTime]);

  /**
   * Retourne les minutes au format mm
   * @returns mm
   */
  const formatMinute = useMemo(() => {
    return selectedTime.minute.toLocaleString('fr-FR', {
      minimumIntegerDigits: 2
    });
  }, [selectedTime]);

  /**
   * Retourne la date et l'heure sauvegardée au format dd/mm/yy à hh:mm
   * @returns dd/mm/yyyy à hh:mm
   */
  const formatSavedDateTime = useMemo(() => {
    if (isEmpty) return "--/--/---- à --:--"
    return DateTime.fromObject({
      year: savedDate.year,
      month: savedDate.month,
      day: savedDate.day,
      hour: savedTime.hour,
      minute: savedTime.minute
    })
    .setLocale("fr-fr")
    .toLocaleString(DateTime.DATETIME_SHORT);
  }, [savedDate, savedTime]);

  /**
   * Retourne la date sauvegardée au format dd/mm/yyyy
   * @returns dd/mm/yyyy
   */
  const formatSavedDate = useMemo(() => {
    if (isEmpty) return "--/--/----"
    return DateTime.fromObject({
      year: savedDate.year,
      month: savedDate.month,
      day: savedDate.day,
    })
    .setLocale("fr-fr")
    .toLocaleString(DateTime.DATE_SHORT);
  }, [savedDate]);

  /**
   * Retourne l'heure sauvegardée au format hh:mm
   * @returns hh:mm
   */
  const formatSavedTime = useMemo(() => {
    if (isEmpty) return "--:--"
    return DateTime.fromObject({
      hour: savedTime.hour,
      minute: savedTime.minute
    })
    .setLocale("fr-fr")
    .toLocaleString(DateTime.TIME_24_SIMPLE);
  }, [savedTime]);

  return (
    <>
      <FormGroup onClick={(ev) => togglePicker(ev)}>
        <Input>
          {picker === "dateTime" 
            ? formatSavedDateTime
            : picker === "date"
              ? formatSavedDate
              : formatSavedTime
          }
        </Input>
        <ButtonIcon type="button" >
          {picker === "dateTime" || picker === "time"
            ? <ScheduleIcon />
            : <EventIcon />
          }
        </ButtonIcon>
      </FormGroup>

      <Dialog
        isOpen={isActive}
        toggleDialog={(ev) => togglePicker(ev)}
        title={title}
        width="auto"
        children={
          <>
            {picker === "dateTime" && (
              <ModalCol>
                <DatePickerCntnr>
                  <DateView>{formatDate}</DateView>
                  <div className={`dates`}>
                    <Month>
                      <MonthAction type="button" onClick={() => handleChangeMonth(-1)}>
                        <KeyboardArrowLeftIcon />
                      </MonthAction>
                      <div className="mnth">{MONTHS[activeDate.month - 1]} {activeDate.year}</div>
                      <MonthAction type="button" onClick={() => handleChangeMonth(1)}>
                        <KeyboardArrowRightIcon />
                      </MonthAction>
                    </Month>
                    <ActionDate>
                      <Button onClick={() => setDates(today)} type="button">Ajourd'hui</Button>
                    </ActionDate>
                    <DaysWeek>
                      {DAYS.map((day, idx) => (
                        <DayWeek key={`${day}-${idx}`}>{day}</DayWeek>
                      ))}
                    </DaysWeek>
                    <Days>
                      {daysMonth().map((day, idx) => (
                        <Day key={`${day}-${idx}`} onClick={() => handleClickSelected(day.day)} className={`day ${day.class}${daySelected(day)}`}>
                          <span>{day?.value}</span>
                        </Day>
                      ))}
                    </Days>
                  </div>
                </DatePickerCntnr>
                <TimePickerCntnr>
                  <ActionTime>
                    <Button onClick={() => handleNow()} type="button">Maintenant</Button>
                  </ActionTime>
                  <TimeCntnr>
                    <HourMinute>
                      <KeyboardArrowUpIcon onClick={() => handleClickHour(1)} className="hr-up" />
                      <input onChange={(ev) => handleChangeHour(ev)} className="hr" type="number" value={formatHour} />
                      <KeyboardArrowDownIcon onClick={() => handleClickHour(-1)} className="hr-down" />
                    </HourMinute>
                    <div className="separator">:</div>
                    <HourMinute>
                      <KeyboardArrowUpIcon onClick={() => handleClickMinute(1)} className="min-up" />
                      <input onChange={(ev) => handleChangeMinute(ev)} className="min" type="number" value={formatMinute} />
                      <KeyboardArrowDownIcon onClick={() => handleClickMinute(-1)} className="min-down" />
                    </HourMinute>
                  </TimeCntnr>
                </TimePickerCntnr>
              </ModalCol>
            )}
            {picker === "time" && (
              <TimePickerCntnr>
                <ActionTime>
                  <Button onClick={() => handleNow()} type="button">Maintenant</Button>
                </ActionTime>
                <TimeCntnr>
                  <HourMinute>
                    <KeyboardArrowUpIcon onClick={() => handleClickHour(1)} className="hr-up" />
                    <input onChange={(ev) => handleChangeHour(ev)} className="hr" type="number" value={formatHour} />
                    <KeyboardArrowDownIcon onClick={() => handleClickHour(-1)} className="hr-down" />
                  </HourMinute>
                  <div className="separator">:</div>
                  <HourMinute>
                    <KeyboardArrowUpIcon onClick={() => handleClickMinute(1)} className="min-up" />
                    <input onChange={(ev) => handleChangeMinute(ev)} className="min" type="number" value={formatMinute} />
                    <KeyboardArrowDownIcon onClick={() => handleClickMinute(-1)} className="min-down" />
                  </HourMinute>
                </TimeCntnr>
              </TimePickerCntnr>
            )}
            {picker === "date" && (
              <DatePickerCntnr>
                <DateView>{formatDate}</DateView>
                <div className={`dates`}>
                  <Month>
                    <MonthAction type="button" onClick={() => handleChangeMonth(-1)}>
                      <KeyboardArrowLeftIcon />
                    </MonthAction>
                    <div className="mnth">{MONTHS[activeDate.month - 1]} {activeDate.year}</div>
                    <MonthAction type="button" onClick={() => handleChangeMonth(1)}>
                      <KeyboardArrowRightIcon />
                    </MonthAction>
                  </Month>
                  <ActionDate>
                    <Button onClick={() => setDates(today)} type="button">Ajourd'hui</Button>
                  </ActionDate>
                  <DaysWeek>
                    {DAYS.map((day, idx) => (
                      <DayWeek key={`${day}-${idx}`}>{day}</DayWeek>
                    ))}
                  </DaysWeek>
                  <Days>
                    {daysMonth().map((day, idx) => (
                      <Day key={`${day}-${idx}`} onClick={() => handleClickSelected(day.day)} className={`day ${day.class}${daySelected(day)}`}>
                        <span>{day?.value}</span>
                      </Day>
                    ))}
                  </Days>
                </div>
              </DatePickerCntnr>
            )}
            <ButtonBar>
              <Button onClick={() => handleClose()} className="warn" type="button">Annuler</Button>
              <Button onClick={() => handleSave()} type="button">Ok</Button>
            </ButtonBar>
          </>
        }
      />
    </>
  );
};

export default DateTimePicker;