import React, { useState } from "react";

export const useDarkMode = () => {
  const prefDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState(prefDarkMode ? "dark" : "light");

  const toggleTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };

  return [theme, toggleTheme];
};

const ThemeModeContext = React.createContext({
  themeMode: null,
  setThemeMode: () => {}
});

export default ThemeModeContext;
