import {Link} from "found";
import React, { useEffect } from "react";
import styled from "styled-components";

import theme from "../../ui/theme";

const NarrowContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.small};
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    overflow-y: auto;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: ${theme.small} ${theme.small} 0;
    border-radius: ${theme.thin};

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .break-mobile {
      display: none;
    }
  }

  @media (max-width: 550px) {
    form {
      h2 {
        font-size: 1.3rem;
      }
    }
  }
  @media (max-width: 385px) {
    form {
      .break-mobile {
        display: inline;
      }
    }
  }
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const BlockLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: ${theme.small};
`;

function ResetEmpty() {
  useEffect(() => {
    localStorage.clear();
    return () => {};
  }, []);

  return (
    <NarrowContainer>
      <form>
        <FormTitle>Réinitialisation de <br className="break-mobile" />mot de passe</FormTitle>
        <p>
          Le lien de réinitialisation de mot de passe semble invalide.<br/>
        </p>
        <Fieldset>
          <p style={{fontSize: ".75rem"}}>Si vous rencontrez des difficultés dans la réinitialisation de votre mot de passe vous pouvez contacter l'assistance: assistance@syope.fr</p>
          <hr/>
          <BlockLink to="/auth/sign-in">
            Retour à l'écran de connexion
          </BlockLink>
        </Fieldset>
      </form>
    </NarrowContainer>
  );
}

export default ResetEmpty;
