import React, { useContext } from "react";
import { withRouter } from "found";
import http, { HTTPError, signOut } from "../http";

import styled from "styled-components";
import theme from "../ui/theme";
import {
  SubMenuCntnr,
  SubMenuItem,
} from "../ui/SubMenu";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import CurrentUserContext from "../CurrentUserContext";
import { hasPerm } from "../utils";

const Logout = styled(SubMenuItem)`
  color: ${theme.red};

  svg {
    margin-right: ${theme.thin};
  }

  &:hover {
    background-color: ${theme.backgroundColor};
    color: ${theme.red};
  }
`;

function Layout({ router, children }) {
  const { currentUser } = useContext(CurrentUserContext);

  const handleLogout = async e => {
    e.preventDefault();
    const signOutUrl = localStorage.getItem("nfSignOutUrl") || "/auth/sign-in";
    try {
      await http.delete("auth/sign_out");
      localStorage.clear();
      signOut();
      router.push(signOutUrl);
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 404) {
        localStorage.clear();
        signOut();
        router.push(signOutUrl);
      } else {
        console.error(e);
      }
    }
  };

  const getProvider = (phone, provider) => {
    if (phone !== null && phone !== undefined) {
      return "SMS";
    } else if (provider == "email") {
      return "Email";
    } else if (provider !== null || provider !== undefined) {
      return "AD";
    } else {
      return "-"
    }
  };

  return (
    <SubMenuCntnr content={children}>
      <SubMenuItem to={"/reglages/profil"} activeClassName="active">
        Informations personnelles
      </SubMenuItem>
      { getProvider(currentUser.phone_number, currentUser.provider) !== "AD" && (
        <SubMenuItem to={"/reglages/mot-de-passe"} activeClassName="active">
          Mot de passe
        </SubMenuItem>
      )}
      {hasPerm(currentUser, "web:settings:self:editEstablishmentCards") && (
        <SubMenuItem to={"/reglages/cartes-etablissement"} activeClassName="active">
          Cartes d'établissement
        </SubMenuItem>
      )}
      <SubMenuItem to={"/reglages/historique-connexions"} activeClassName="active">
        Historique des connexions
      </SubMenuItem>
      <SubMenuItem
        to={"/reglages/mentions-legales"}
        activeClassName="active"
      >
        Mentions légales
      </SubMenuItem>
      <SubMenuItem to={"/reglages/cgu"} activeClassName="active">
        Conditions générales d'utilisation
      </SubMenuItem>
      <SubMenuItem to={"/reglages/note-de-version"} activeClassName="active">
        Note de version
      </SubMenuItem>

      <hr />
      <Logout
        to={"/reglages/deconnexion"}
        onClick={handleLogout}
      >
        <ExitToAppIcon />Déconnexion
      </Logout>
    </SubMenuCntnr>
  );
}

export default withRouter(Layout);
