// SMUR
export const NAME_AR_RGX = new RegExp('AR[0-9]|\\bAR\\s');
export const NAME_VML_RGX = new RegExp('VML[0-9]|\\bVML\\s');
// SDIS
export const NAME_VSAV_RGX = new RegExp('VSAV[0-9]|\\bVSAV\\s');
export const NAME_VLS_RGX = new RegExp('VLS[0-9]|\\bVLS\\s');
// AMBULANCIERS
export const NAME_ASSU_RGX = new RegExp('ASSU[0-9]|\\bASSU\\s');
export const NAME_AMBU_RGX = new RegExp(
  'AMBU[0-9]|\\bAMBU\\s|AMBULANCE[0-9]|\\bAMBULANCE\\s|\\bAMBULANCE|AMBULANCES[0-9]|\\bAMBULANCES\\s|\\bAMBULANCES'
);
