import React, {useMemo, useState} from 'react';
import http from '../../http';
import {useQuery} from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import {isAbortError} from '../../utils';

import styled from 'styled-components';
import theme from '../../ui/theme';
import PageHeader, {Actions, Title} from '../../ui/PageHeader';
import Button, {LinkButton} from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import Card from '../../ui/Card';
import {Link} from 'found';
import Tag from '../../ui/Tag';
import SearchBarCompo, {FilterBar, SearchBar} from '../../ui/SearchBar';
import Input, {InputSearchCntnr, InputSearchIcon} from '../../ui/Input';
import SearchIcon from '@mui/icons-material/Search';
import {BtnTabs, Tabs, TabsCntnr} from "../../ui/Tabs";
import MedicalIcon from "@mui/icons-material/MedicalServices";
import MedicationIcon from "@mui/icons-material/Medication";
import SoluteIcon from "@mui/icons-material/Vaccines";
import {alphabeticSort} from "../../interventions/utils";

const SchemaInjectionList = styled.ul`
  padding: 0 ${theme.small};
`;

const SchemaInjectionCard = styled(Card)`
  max-width: none;
  min-width: none;

  &:not(:last-child) {
    margin-bottom: ${theme.small};
  }
`;

const SchemaInjectionLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.text};

  &:hover {
    text-decoration: none;
    color: ${theme.blue};
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const SchemaInjectionCardLabelItem = styled.div`
  margin: ${theme.thin};
  width: 15%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const SchemaInjectionCardContentItem = styled.div`
  margin: ${theme.thin};
  width: 80%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: calc(100% - ${theme.thin} - ${theme.thin});
  }
`;

const PosologyRow = styled.div`
  margin: 0.25rem;
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  gap: 4%;
`;

export default function Injections() {
  const controller = new AbortController();
  const [search, setSearch] = useState('');
  const [tabs, setTabs] = useState("all");

  const {isLoading: injectionSchemasIsLoading, data: injectionSchemas} = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.drugs;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    {cacheTime: 0}
  );

  const actualSchemas = useMemo(() => {
    switch (tabs) {
      case "all":
        return injectionSchemas;
      case "solute":
        return injectionSchemas.filter(i => i.injection_schemas.every(s => s.solute));
      case "medication":
        return injectionSchemas.filter(i => i.injection_schemas.every(s => !s.solute));
      default:
        return injectionSchemas;
    }
  }, [tabs, injectionSchemas]);

  const clearFilter = () => {
    setSearch('');
  };

  const schemasFiltered = useMemo(() => {
    let temp = [];
    actualSchemas?.map(s => {
      if (s.drug?.toLowerCase().includes(search.toLocaleLowerCase())) temp.push(s);
    });
    return alphabeticSort(temp, (value) => value.drug);
  }, [search, actualSchemas]);

  const getBolusInfos = (injection) => {
    const boliMax = (injection.boli_maxi === null || injection.boli_maxi === '')
    && (injection.quantity_maxi_including_boli === null || injection.quantity_maxi_including_boli === '')
      ? ''
      : injection.boli_maxi && injection.quantity_maxi_including_boli
      ? `(max. ${injection.boli_maxi} boli ou ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
      : injection.boli_maxi
        ? `(max. ${injection.boli_maxi} boli)`
        : `(max. ${injection.quantity_maxi_including_boli} ${injection.unit_numerator})`
    return injection.can_boli ? `Bolus autorisé ${boliMax}` : 'Bolus interdit'
  };

  const tabsBtnOptions = [
    {
      option: "all",
      length: injectionSchemas?.length ?? 0,
      text: "Tous",
      icon: <MedicalIcon/>
    },
    {
      option: "medication",
      length: injectionSchemas?.filter(i => i.injection_schemas.every(s => !s.solute))?.length ?? 0,
      text: "Médicament",
      icon: <MedicationIcon/>
    },
    {
      option: "solute",
      length: injectionSchemas?.filter(i => i.injection_schemas.every(s => s.solute))?.length ?? 0,
      text: "Soluté",
      icon: <SoluteIcon/>
    }
  ];

  const handleTabsChange = (value) => {
    setTabs(value);
  };

  return (
    <>
      <PageHeader style={{'margin-bottom': '1rem'}}>
        <Title>Schémas d'injections</Title>
        <Actions>
          <LinkButton to='/gestionnaire/injections/edit'>Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      <TabsCntnr>
        <Tabs breakpoint="780px">
          {tabsBtnOptions.map((btn, index) => (
            <BtnTabs key={index}
                     onClick={() => handleTabsChange(btn.option)}
                     className={`tabButton icon-right ${tabs === btn.option ? "active" : ""}`}>
              {btn.text}{btn.option !== "all" && btn.length > 1 ? "s" : ""} {btn.length} {btn.icon}
            </BtnTabs>
          ))}
        </Tabs>
      </TabsCntnr>

      <SearchBarCompo hasTag={true} tags={<>{search.length > 0 && <Tag text={search} callback={clearFilter}/>}</>}>
        <SearchBar>
          <FilterBar>
            <InputSearchCntnr>
              <InputSearchIcon><SearchIcon/></InputSearchIcon>
              <Input type='search' value={search} onChange={ev => setSearch(ev.target.value)} placeholder='Drogue'/>
            </InputSearchCntnr>
            <Button className='warn' onClick={clearFilter}>Réinitialiser les filtres</Button>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      {injectionSchemasIsLoading ? (
        <LoadingSpinner className='center vh-50'/>
      ) : (
        <>
          <SchemaInjectionList>
            {schemasFiltered?.length > 0 ? (
              <>
                {schemasFiltered.map((schema, idx) => (
                  <SchemaInjectionCard key={idx}>
                    <SchemaInjectionLink to={{pathname: `/gestionnaire/injections/edit`, query: {drug: schema?.drug}}}>
                      <SchemaInjectionCardLabelItem>{schema?.drug}</SchemaInjectionCardLabelItem>
                      <SchemaInjectionCardContentItem>
                        {schema?.injection_schemas.map((injection, idx) => (
                          <PosologyRow>
                            <span>{injection.quantity_maxi
                              ? `${injection.quantity} ${injection.unit} (max. ${injection.quantity_maxi} ${injection.unit_numerator})`
                              : `${injection.quantity} ${injection.unit}`}</span>
                            <td>{injection.drug_route_name}</td>
                            <td>{getBolusInfos(injection)}</td>
                            <td>{injection?.comment}</td>
                          </PosologyRow>
                        ))}
                      </SchemaInjectionCardContentItem>
                    </SchemaInjectionLink>
                  </SchemaInjectionCard>
                ))}
              </>
            ) : (<>
              <hr/>
              <span>Aucun schéma d'injection</span>
            </>)}
          </SchemaInjectionList>
        </>
      )}
    </>
  );
}
