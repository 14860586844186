import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import "./polyfills";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: (process.env.NODE_ENV || 'development') !== 'development' && "https://39b41d3de5a24eb4a6d16cccc354988c@sentry.io/1502661",
  environment: process.env.NODE_ENV || 'development',
  beforeSend(event, hint) {
    const isNonErrorException =
      event.exception.values[0].value.startsWith('Non-Error exception captured')
      || (hint.originalException && hint.originalException['message'] && hint.originalException['message'].startsWith('Non-Error exception captured'));
    const isTimeOutErrorException =
      event.exception.values[0].type === 'TimeoutError'
      || event.title?.startsWith('TimeoutError')
      || (hint.originalException && hint.originalException['message'] && hint.originalException['message'].startsWith('Request timed out'));

    if (isNonErrorException) {
      // Ignore those kind of errors
      const isFoundRedirectException =
        event.exception.values[0].value.includes('isFoundRedirectException')
        || event.extra?.__serialized__?.isFoundRedirectException;

      if (isFoundRedirectException) return null;
    };
    if (isTimeOutErrorException) return null;

    return event;
  },
});

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
if (isIE) {
  const ieElement = document.createElement("div");
  const ieTitle = document.createElement("h1");
  const ieTitleText = document.createTextNode("Vous utilisez un navigateur obsolète.");
  const ieText = document.createTextNode("Pour une expérience utilisateur plus sûre et plus rapide, utilisez un navigateur moderne comme Chrome, Firefox, Safari, Opera ou Edge.")
  ieTitle.appendChild(ieTitleText)
  ieElement.appendChild(ieTitle);
  ieElement.appendChild(ieText);
  document.body.appendChild(ieElement);
};

// Cookies disabled
if (!navigator.cookieEnabled) {
  const cookiesElement = document.createElement("div");
  const cookiesTitle = document.createElement("h1");
  const cookiesTitleText = document.createTextNode("La configuration de votre navigateur ne permet pas à l'application Syopé de fonctionner correctement.");
  const cookiesText = document.createTextNode("Paramètres de blocages des cookies trop restrictifs.")
  cookiesTitle.appendChild(cookiesTitleText)
  cookiesElement.appendChild(cookiesTitle);
  cookiesElement.appendChild(cookiesText);
  document.body.appendChild(cookiesElement);
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
