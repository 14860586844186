import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "found";

import theme from "./theme";

import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BlockIcon from '@mui/icons-material/Block';
import SettingsIcon from '@mui/icons-material/Settings';
import PortraitIcon from '@mui/icons-material/Portrait';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const RoundedBtnContainer = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${theme.grey6};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 100%;
  padding: ${theme.thin};
  margin: 0px ${theme.thin};
    -webkit-transition: 0.3s;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
    > svg {
      color: ${theme.grey4};
    }
  }

  > svg {
    color: ${theme.grey2};
  }
`;

const RoundedLinkBtnContainer = styled(Link)`
  display: block;
  width: 40px;
  height: 40px;
  background-color: ${theme.grey6};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 100%;
  padding: ${theme.thin};
  margin: 0px ${theme.thin};
    -webkit-transition: 0.3s;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  &.disabled {
    cursor: not-allowed;
    > svg {
      color: ${theme.grey4};
    }
  }

  > svg {
    color: ${theme.grey2};
  }
`;

export default function RoundedButton ({icon = "password", tips, callback, disabled = false}) {
  const [btnDisabled, setBtnDisabled] = useState(false);

  const handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setBtnDisabled(true);
    callback()
    .finally(() => {
      setBtnDisabled(false);
    });
  };

  return (
    <RoundedBtnContainer type="button" title={tips} onClick={(ev) => handleClick(ev)} disabled={btnDisabled || disabled} >
      {icon === "password" && <VpnKeyIcon />}
      {icon === "block" && <BlockIcon />}
      {icon === "portrait" && <PortraitIcon />}
      {icon === "checkOutline" && <CheckBoxOutlineBlankIcon />}
    </RoundedBtnContainer>
  );
};

export function RoundedLinkButton ({icon = "edit", tips, to, disabled}) {
  const handleClick = (ev) => {
    ev.stopPropagation();
    if (disabled) ev.preventDefault();
  }
  return (
    <RoundedLinkBtnContainer onClick={(ev) => handleClick(ev)} to={to} title={tips} className={disabled && "disabled"} >
      {icon === "edit" && <EditIcon />}
      {icon === "settings" && <SettingsIcon />}
    </RoundedLinkBtnContainer>
  );
};

const Dropdown = styled.div`
  position: relative;

  .dropdown {
    display: none;
    button,
    a {
      display: block;
      border: none;
      width: 100%;
      text-align: left;
      color: ${theme.grey2};
      background-color: ${theme.backgroundColor};
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      &:disabled {
        color: ${theme.grey4};
        cursor: not-allowed;
      }
    }
    > *:not(:last-child){
      border-bottom: 1px solid ${theme.grey4};
    }
  }
  .dropdown.visible {
    position: absolute;
    bottom: 62px;
    width: 180px;
    z-index: 2;
    background-color: ${theme.backgroundColor};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: ${theme.thin};
    padding: ${theme.thin};
    display: flex;
    flex-direction: column;
    left: ${props => props.position === "left" ? "-4px" : props.position === "right" ? "-121px" : "-48px"};
    &:hover {
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
      &::after {
        box-shadow: -8px 8px 8px rgba(0, 0, 0, 0.25);
      }
    }

    &::after{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: -20px;
      left: ${props => props.position === "left" ? "16px" : props.position === "right" ? "133px" : "59.5px"};
      box-sizing: border-box;
      
      border: 11px solid black;
      border-color: transparent transparent ${theme.backgroundColor} ${theme.backgroundColor};
      
      transform-origin: 0 0;
      transform: rotate(-45deg);
      
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
`;

/**
 * Hook that alerts clicks outside of the passed ref
 */
 function useOutsideAlerter(ref, callback) {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function openDropdownCtnrOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              callback();
          };
      };

      // Bind the event listener
      document.addEventListener("mousedown", openDropdownCtnrOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", openDropdownCtnrOutside);
      };
  }, [ref]);
};

export function RoundedMultipleButton ({tips, dropOptions, dropPosition, icon, disabled}) {
  const [show, setShow] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const dropdownCtnr = useRef(null);
  useOutsideAlerter(dropdownCtnr, () => {closeDropdownCtnr()});

  useEffect(() => {
    return () => {
        if(dropdownCtnr.current) {
            clearTimeout(dropdownCtnr.current?.timer);
        }
    };
  }, []);

  const hideDropdownCtnr = () => {
    dropdownCtnr.current.timer = setTimeout(() => {
      setShow(false);
    }, 10000);
  };

  const stopHideDropboxCtnr = () => {
    if(dropdownCtnr.current) {
      clearTimeout(dropdownCtnr.current.timer);
    };
  };

  const closeDropdownCtnr = () => {
    clearTimeout(dropdownCtnr.current.timer);
    setShow(false);
  };

  const openDropdownCtnr = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setShow(!show);
    if (!show) {
      hideDropdownCtnr();
    };
  };

  const handleOptionClick = (ev, callback) => {
    ev.preventDefault();
    ev.stopPropagation();
    setShow(!show);
    setBtnDisabled(true);
    callback()
    .finally(() => {
      setBtnDisabled(false);
    });
  };

  return (
    <Dropdown position={dropPosition}>
      <RoundedBtnContainer type="button" title={tips} onClick={(ev) => openDropdownCtnr(ev)} disabled={disabled}>
        {icon === "password" && <VpnKeyIcon />}
        {icon === "more" && <MoreHorizIcon />}
      </RoundedBtnContainer>
      <div ref={dropdownCtnr} onMouseOver={stopHideDropboxCtnr} onMouseLeave={hideDropdownCtnr} className={`dropdown${show ? " visible" : ""}`}>
        {
          dropOptions.map((option, index) => (
            option.type === "action" ?
              <button key={index} disabled={btnDisabled} onClick={(ev) => handleOptionClick(ev, option.onClick)} type="button">{option.text}</button>
            : option.type === "link" ?
              <Link key={index} to={option.to}>{option.text}</Link>
            : null
          ))
        }
      </div>
    </Dropdown>
  );
};