import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../ui/theme";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import {Link, useRouter} from "found";
import LoaderBarContext from "../../ui/useLoaderBar";

import BaseButton from "../../ui/Button";
import BaseFormGroup from "../../ui/FormGroup";
import Input, { InputIcon } from "../../ui/Input";
import Alert from "../../ui/Alert";
import http from "../../http";
import Label from "../../ui/Label";

const NarrowContainer = styled.div`
   width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.small};
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    overflow-y: auto;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: ${theme.small} ${theme.small} 0;
    border-radius: ${theme.thin};

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .break-mobile {
      display: none;
    }
  }

  @media (max-width: 550px) {
    form {
      h2 {
        font-size: 1.3rem;
      }
    }
  }
  @media (max-width: 385px) {
    form {
      .break-mobile {
        display: inline;
      }
    }
  }
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const Button = styled(BaseButton)`
  margin-top: ${theme.thin};
  margin-bottom: ${theme.small};
  display: block;
  width: 100%;
`;

const FormGroup = styled(BaseFormGroup)`
  display: flex;
  flex-direction: row;
`;

const BlockLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: ${theme.small};
`;

const TabItem = styled(Link)``;

const TabRow = styled.nav`
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme.small};
  margin-top: ${theme.thin};

  > ${TabItem} {
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    padding: ${theme.thin};
    border-bottom: 1px solid ${theme.grey5};

    &.active {
      border-bottom-color: ${theme.blue};
      font-weight: bold;
    }

    &:hover, &:focus {
      text-decoration: none;
    }
  }
`;

function PhoneChallenge({ data }) {
  const { router } = useRouter();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [uid, setUid] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alert, setAlert] = useState(null);

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      let normalizedPhoneNumber = phoneNumber.replaceAll(/\.|\s|-|\(|\)/g, '');
      if (normalizedPhoneNumber.startsWith("06") || normalizedPhoneNumber.startsWith("07")) {
        normalizedPhoneNumber = `+33${normalizedPhoneNumber.slice(1)}`;
      }
      const payload = await http
        .post("phone_reset", {
          json: {
            uid: uid,
            phone_number: normalizedPhoneNumber,
          }
        })
        .json();
      setAlert({
        kind: "success",
        message: `Un code de vérification a été envoyé au numéro de téléphone associé à ce compte utilisateur.`
      });
      router.push(`/auth/phone-reset/${encodeURIComponent(payload.nonce)}`);
    } catch (err) {
      setAlert({
        kind: "danger",
        message:
          "Impossible de lancer la procédure, êtes-vous sûr que ce compte utilisateur existe ?"
      });
    } finally {
      setLoaderBar(false);
    };
  };

  const handleUidChange = ev => {
    setUid(ev.target.value.toLowerCase());
  };
  const handlePhoneNumberChange = ev => {
    setPhoneNumber(ev.target.value.toLowerCase());
  };

  useEffect(() => {
    localStorage.clear();
    return () => {};
  }, []);

  return (
    <NarrowContainer>
      <form onSubmit={handleFormSubmit}>
        <FormTitle>Réinitialisation de <br className="break-mobile" />mot de passe</FormTitle>
        <h3>{data.subTitle}</h3>
        {data.tabRow && (
          <TabRow>
            <TabItem to="/auth/forgot-password" activeClassName="active">Email</TabItem>
            <TabItem to="/auth/forgot-phone" activeClassName="active">SMS</TabItem>
          </TabRow>
        )}
        <p>
          Saisissez votre identifiant utilisateur et votre numéro de téléphone dans le formulaire pour lancer
          la procédure de réinitialisation de mot de passe.
        </p>
        {alert && <Alert kind={alert.kind}>{alert.message}</Alert>}
        <Fieldset disabled={loaderBarState}>
          <Label style={{fontWeight: "bold"}} htmlFor="uid-input">Identifiant utilisateur</Label>
          <FormGroup>
            <InputIcon className="grey">
              <PersonIcon />
            </InputIcon>
            <Input placeholder="Identifiant utilisateur" id="uid-input" value={uid} onChange={handleUidChange} />
          </FormGroup>
          <Label style={{fontWeight: "bold"}} htmlFor="phone-input">Numéro de téléphone</Label>
          <FormGroup>
            <InputIcon className="grey">
              <PhoneIcon />
            </InputIcon>
            <Input placeholder="Numéro de téléphone" id="phone-input" value={phoneNumber} onChange={handlePhoneNumberChange} type="phone" />
          </FormGroup>
          <Button disabled={loaderBarState}>Réinitialiser mon mot de passe</Button>
          <hr />
          <BlockLink to="/auth/sign-in">
            Retour à l'écran de connexion
          </BlockLink>
        </Fieldset>
      </form>
    </NarrowContainer>
  );
}

export default PhoneChallenge;
