/**
 * @fileoverview gRPC-Web generated client stub for efibi.v1.relay
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: efibi/v1/relay/services/ephemeral_share.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.efibi = {};
proto.efibi.v1 = {};
proto.efibi.v1.relay = require('./ephemeral_share_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.efibi.v1.relay.EphemeralShareServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.efibi.v1.relay.EphemeralShareServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.efibi.v1.relay.CreateCodeRequest,
 *   !proto.efibi.v1.relay.CreateCodeResponse>}
 */
const methodDescriptor_EphemeralShareService_CreateCode = new grpc.web.MethodDescriptor(
  '/efibi.v1.relay.EphemeralShareService/CreateCode',
  grpc.web.MethodType.UNARY,
  proto.efibi.v1.relay.CreateCodeRequest,
  proto.efibi.v1.relay.CreateCodeResponse,
  /**
   * @param {!proto.efibi.v1.relay.CreateCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.efibi.v1.relay.CreateCodeResponse.deserializeBinary
);


/**
 * @param {!proto.efibi.v1.relay.CreateCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.efibi.v1.relay.CreateCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.efibi.v1.relay.CreateCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.efibi.v1.relay.EphemeralShareServiceClient.prototype.createCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/CreateCode',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_CreateCode,
      callback);
};


/**
 * @param {!proto.efibi.v1.relay.CreateCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.efibi.v1.relay.CreateCodeResponse>}
 *     Promise that resolves to the response
 */
proto.efibi.v1.relay.EphemeralShareServicePromiseClient.prototype.createCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/CreateCode',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_CreateCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.efibi.v1.relay.RedeemCodeRequest,
 *   !proto.efibi.v1.relay.RedeemCodeResponse>}
 */
const methodDescriptor_EphemeralShareService_RedeemCode = new grpc.web.MethodDescriptor(
  '/efibi.v1.relay.EphemeralShareService/RedeemCode',
  grpc.web.MethodType.UNARY,
  proto.efibi.v1.relay.RedeemCodeRequest,
  proto.efibi.v1.relay.RedeemCodeResponse,
  /**
   * @param {!proto.efibi.v1.relay.RedeemCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.efibi.v1.relay.RedeemCodeResponse.deserializeBinary
);


/**
 * @param {!proto.efibi.v1.relay.RedeemCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.efibi.v1.relay.RedeemCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.efibi.v1.relay.RedeemCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.efibi.v1.relay.EphemeralShareServiceClient.prototype.redeemCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/RedeemCode',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_RedeemCode,
      callback);
};


/**
 * @param {!proto.efibi.v1.relay.RedeemCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.efibi.v1.relay.RedeemCodeResponse>}
 *     Promise that resolves to the response
 */
proto.efibi.v1.relay.EphemeralShareServicePromiseClient.prototype.redeemCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/RedeemCode',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_RedeemCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.efibi.v1.relay.CheckValidityRequest,
 *   !proto.efibi.v1.relay.CheckValidityResponse>}
 */
const methodDescriptor_EphemeralShareService_CheckValidity = new grpc.web.MethodDescriptor(
  '/efibi.v1.relay.EphemeralShareService/CheckValidity',
  grpc.web.MethodType.UNARY,
  proto.efibi.v1.relay.CheckValidityRequest,
  proto.efibi.v1.relay.CheckValidityResponse,
  /**
   * @param {!proto.efibi.v1.relay.CheckValidityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.efibi.v1.relay.CheckValidityResponse.deserializeBinary
);


/**
 * @param {!proto.efibi.v1.relay.CheckValidityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.efibi.v1.relay.CheckValidityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.efibi.v1.relay.CheckValidityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.efibi.v1.relay.EphemeralShareServiceClient.prototype.checkValidity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/CheckValidity',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_CheckValidity,
      callback);
};


/**
 * @param {!proto.efibi.v1.relay.CheckValidityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.efibi.v1.relay.CheckValidityResponse>}
 *     Promise that resolves to the response
 */
proto.efibi.v1.relay.EphemeralShareServicePromiseClient.prototype.checkValidity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/efibi.v1.relay.EphemeralShareService/CheckValidity',
      request,
      metadata || {},
      methodDescriptor_EphemeralShareService_CheckValidity);
};


module.exports = proto.efibi.v1.relay;

