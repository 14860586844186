import React, { useContext, useMemo, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import CurrentUserContext from "../CurrentUserContext";
import {
  hasIndividualFromEquivalentPerm,
  hasIndividualOrEquivalentPerm,
  hasPerm,
  INDIVIDUAL_PERMISSIONS
} from "../utils";

import FormGroup from "../ui/FormGroup";
import Label from "../ui/Label";
import Input, { InputIconCntnr, InputIcon } from "../ui/Input";
import Checkbox from "../ui/Checkbox";
import Button from "../ui/Button";
import SelectBase from "../ui/Select";
import PermissionsList from "../ui/PermissionsList";
import { Column, Row as RowBase } from "../ui/FlexGrid";
import styled from "styled-components";
import theme from "../ui/theme";
import LoaderBarContext from "../ui/useLoaderBar";

const Part = styled.div`
  margin-top: ${theme.small};
`;

const Row = styled(RowBase)`
  @media (max-width: 767px) {
    flex-direction: column;
  }

  &.marginB {
    margin-bottom: ${theme.small};
  }
`;

const Select = styled(SelectBase)`
  max-width: 760px;
`;

export default function Form({ roles = [], initialData = null, onSubmit }) {
  const getBase = () => {
    const base = {
      full_name: initialData?.full_name || "",
      uid: initialData?.uid || "",
      email: initialData?.email || "",
      phone_number: initialData?.phone_number || "",
      matricule: initialData?.matricule || "",
      cis: "",
      numero_rpps: initialData?.numero_rpps || "",
      role_id: initialData?.role_id || null,
    };
    INDIVIDUAL_PERMISSIONS.forEach(permission => {
      base[permission.path] = initialData?.[permission.path] || false;
    });
    return base;
  };

  const [form, setForm] = useState(() => {
    return getBase();
  });
  const [ useEmailAsUid, setUseEmailAsUid ] = useState(form.uid === form.email);
  const { currentUser } = useContext(CurrentUserContext);
  const { loaderBarState } = useContext(LoaderBarContext);

  const isValidPhone = (phone) => {
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g;
    return phone.match(phoneRegex);
  };

  const selectedRole = roles.find(r => r.id === form.role_id);

  const handleFormChange = field => {
    return ev => {
      setForm({
        ...form,
        [field]: ev.target.value
      });
    };
  };

  const handlePhoneChange = ev => {
    let temp = ev.target.value;
    const nonDigitRegex = /[^0-9+]+/g;
    if (temp[0] === "0") temp = `+33${temp.substring(1)}`;
    temp = temp.replaceAll(nonDigitRegex, "");
    setForm({
      ...form,
      phone_number: temp,
    });
  };

  const handleFormRoleChange = ev => {
    const parsed = Number.parseInt(ev.id, 10);
    setForm({
      ...form,
      role_id: parsed,
    });
  };

  const handleFormCheck = field => {
    return ev => {
      setForm({
        ...form,
        [field]: ev.target.checked
      });
    };
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    const formUid = cloneDeep(form);
    if (useEmailAsUid) formUid.uid = formUid.email;
    if (formUid.phone_number?.length === 0) formUid.phone_number = null;
    onSubmit(formUid);
  };

  const getProvider = () => {
    if (!initialData) return null;
    if (initialData.phone_number !== null && initialData.phone_number !== undefined) {
      return "SMS";
    } else if (initialData.provider == "email") {
      return "Email";
    } else if (initialData.provider !== null || initialData.provider !== undefined) {
      return "AD";
    } else {
      return "-";
    };
  };

  const formIsValid = () => form.full_name.length > 0 && (
    (form.email.length > 0 && useEmailAsUid) ||
    (form.email.length > 0 && form.uid.length > 0 && !useEmailAsUid)
  ) && (
    form.phone_number === null || form.phone_number.length === 0 || isValidPhone(form.phone_number)
  );

  const createIsDisabled = useMemo(() => {
    if (formIsValid()) return false;
    return true;
  }, [ form, useEmailAsUid ]);

  const editIsDisabled = useMemo(() => {
    if (
      !isEqual(getBase(), form) &&
      formIsValid()
    ) return false;
    return true;
  }, [ form, initialData ]);

  const uuidIsDisabled = useMemo(() => {
    if (initialData && getProvider() === "AD") return true;
    return false;
  }, [ initialData ]);

  const hasEditRolePerm = useMemo(() => {
    return hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_EDIT_ROLES'), currentUser);
  }, [ currentUser ]);

  const permissions = useMemo(() => {
    if (!hasEditRolePerm) return [];
    const temp = [];
    INDIVIDUAL_PERMISSIONS.forEach((value, key) => {
      temp.push({
        ...value,
        key
      });
    });
    return temp;
  }, [ hasEditRolePerm ]);

  const currentRolePermissions = useMemo(() => {
    if (!hasEditRolePerm) return {};
    const perms = selectedRole?.permissions || [];
    const expPerms = selectedRole?.experimental_permissions || [];
    return {
      permissions: [...perms, ...expPerms],
    };
  }, [ hasEditRolePerm, selectedRole ]);

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset disabled={loaderBarState}>
        <Part>
          <Row>
            <Column>
              <FormGroup>
                <Label htmlFor="user-full-name-input">Nom complet (Requis)</Label>
                <Input
                  id="user-full-name-input"
                  value={form.full_name}
                  required
                  onChange={handleFormChange("full_name")}
                />
              </FormGroup>
            </Column>
            <Column>
              <FormGroup>
                <Label htmlFor="user-matricule-input">Matricule</Label>
                <Input
                  id="user-matricule-input"
                  value={form.matricule}
                  onChange={handleFormChange("matricule")}
                />
              </FormGroup>
            </Column>
            <Column>
              <FormGroup>
                <Label htmlFor="user-matricule-input">N° RPPS (Médecin uniquement)</Label>
                <Input
                  id="user-rpps-input"
                  value={form.numero_rpps}
                  onChange={handleFormChange("numero_rpps")}
                />
              </FormGroup>
            </Column>
          </Row>
        </Part>
        <Part>
          <h3>Informations de connexion</h3>
          {initialData && (
            <Row>
              <Column>
                <p>Méthode de connexion&thinsp;: {getProvider()}</p>
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              <FormGroup>
                <Label htmlFor="user-email-input">Adresse e-mail (Requis)</Label>
                <Input
                  id="user-email-input"
                  value={form.email}
                  required
                  onChange={handleFormChange("email")}
                />
              </FormGroup>
              {getProvider() !== "AD" && (
                <FormGroup>
                  <Checkbox
                    label = "Utiliser le courriel comme identifiant de connexion"
                    checked = {useEmailAsUid}
                    onChange={ev => setUseEmailAsUid(ev.target.checked)}
                  />
                </FormGroup>
              )}
              {((!isEmpty(initialData) && getProvider() !== "AD") || (isEmpty(initialData) && hasPerm(currentUser, "web:users:create:phone_number"))) && (
                <FormGroup>
                  <Label htmlFor="user-phone-input">Téléphone portable</Label>
                  <InputIconCntnr>
                    <InputIcon
                      className={(form.phone_number === null || form.phone_number.length === 0 || isValidPhone(form.phone_number)) ? "" : "not-validated"}>
                      +33
                    </InputIcon>
                    <Input
                      type="tel"
                      id="user-phone-input"
                      className={(form.phone_number === null || form.phone_number.length === 0 || isValidPhone(form.phone_number)) ? "" : "not-validated"}
                      pattern="[0-9]{10}"
                      value={form.phone_number?.replace("+33", "0") || ""}
                      onChange={ev => handlePhoneChange(ev)}
                      placeholder="Ex: 0623456789"
                      maxLength={10}
                    />
                  </InputIconCntnr>
                </FormGroup>
              )}
            </Column>
            <Column>
              {useEmailAsUid === false && (
                <FormGroup>
                  <Label htmlFor="user-uid-input">Identifiant de connexion (Requis)</Label>
                  <Input
                    id="user-uid-input"
                    value={getProvider() === "AD" ? "AD" : form.uid}
                    required
                    disabled={uuidIsDisabled}
                    onChange={handleFormChange("uid")}
                  />
                </FormGroup>
              )}
            </Column>
          </Row>
        </Part>
        <Part>
          <h3>Rôle et permissions</h3>
          {hasEditRolePerm && (
            <FormGroup>
              <Label>Rôle</Label>
              {roles.length > 0 ? (
                <Select
                  options={roles || []}
                  value={roles.find(r => r.id === form.role_id) || null}
                  onChange={handleFormRoleChange}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => `${option.name}`}
                />
              ) : (
                <p>
                  Aucun rôle disponible -
                  Créer des rôles dans le gestionnaire d'organisation afin de pouvoir
                  les assigner à un utilisateur.
                </p>
              )}
            </FormGroup>
          )}
          <Row className="marginB">
            {hasEditRolePerm && (
              <Column>
                <div>
                  <h4>Permissions individuelles</h4>
                  {permissions.map((permission, idx) => (
                    <>
                      {hasIndividualFromEquivalentPerm(permission.key, currentRolePermissions) ? (
                        <Checkbox
                          key={idx}
                          label={`${ permission.label } | (Héritée du rôle)`}
                          checked={true}
                          onChange={() => {}}
                          disabled={true}
                        />
                      ) : (
                        <Checkbox
                          key={idx}
                          label={permission.label}
                          checked={form[permission.path]}
                          onChange={handleFormCheck(permission.path)}
                        />
                      )}
                    </>
                  ))}
                  <br />
                </div>
              </Column>
            )}
            {selectedRole != null && (
              <Column>
                <div>
                  <h4>Permissions héritées du rôle</h4>
                  <PermissionsList permissions={selectedRole.permissions} />
                </div>
              </Column>
            )}
          </Row>
        </Part>

        {isEmpty(initialData) ? (
          <Button type="submit" disabled={createIsDisabled}>Ajouter l'utilisateur</Button>
        ) : (
          <Button type="submit" disabled={editIsDisabled}>Enregistrer les modifications</Button>
        )}
      </fieldset>
    </form>
  );
}
