import React, {useState} from "react";
import * as Sentry from "@sentry/browser";
import FormGroup from "../ui/FormGroup";
import Label from "../ui/Label";
import Select from "../ui/Select";
import Textarea from "../ui/Textarea";
import Button from "../ui/Button";
import {useQuery, useQueryClient} from '@tanstack/react-query';
import http from "../http";
import LoadingSpinner from "../ui/LoadingSpinner";

export default function RelevantFacts({ interventionId }) {
  const queryClient = useQueryClient();
  const { isLoading: orgIsLoading, error: orgError, data: orgData } = useQuery(['organization', 'relevant-facts'], () => {
    return http.get(`relevant_facts.json`).json()
  }, {
    enabled: true,
  });
  const { isLoading, error, data } = useQuery(['interventions', interventionId, 'relevant-facts'], () => {
    return http.get(`interventions/${interventionId}/relevant_facts.json`).json()
  }, {
    enabled: interventionId != null,
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const [template, setTemplate] = useState(null);
  const [details, setDetails] = useState("");

  if (isLoading || orgIsLoading) {
    return (<LoadingSpinner />)
  }

  if (error || orgError) {
    return (<h3>Une erreur est survenue</h3>)
  }

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (template?.value == null) {
      return;
    }
    setSubmitting(true);
    try {
      await http.post(`interventions/${interventionId}/relevant_facts.json`, {
        json: {
          fact_id: template.value,
          details: details,
        }
      });
      setDetails("");
      setTemplate(null);
      await queryClient.invalidateQueries(['interventions', interventionId, 'relevant-facts']);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setSubmitting(false);
    }
  }

  return <div style={{padding: '1rem'}}>
    <h3>Faits remarquables</h3>
    <hr/>
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="fr-categorie-input">Catégorie</Label>
        <Select
          id="fr-categorie-input"
          value={template}
          onChange={setTemplate}
          disabled={isSubmitting}
          options={orgData?.data?.filter(relevantFact => relevantFact.is_leaf === true).map(relevantFact => ({
            label: `${relevantFact.display_name} (${relevantFact.classification})`,
            value: relevantFact.id,
          }))}
          data-sentry-id="relevant-fact-list-cat"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="fr-commentaire-input">Commentaire</Label>
        <Textarea id="fr-commentaire-input" disabled={isSubmitting} value={details} onChange={ev => setDetails(ev.target.value)} data-sentry-id="relevant-fact-list-text" />
      </FormGroup>
      <Button style={{marginBottom: ".5rem"}} disabled={isSubmitting} type="submit" data-sentry-id="relevant-fact-list-save">Sauvegarder</Button>
    </form>
    {data?.data?.map(relevantFact => (<div key={relevantFact.id} style={{borderTop: "1px solid #ddd", paddingTop: ".5rem"}}>
      <h4>{relevantFact.display_name}</h4>
      <h6>{relevantFact.classification}</h6>
      <p>{relevantFact.details}</p>
    </div>))}
  </div>;
}
