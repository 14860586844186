import React, { useRef, useEffect } from 'react';
import LoadingSpinner from './ui/LoadingSpinner';
import { DateTime } from 'luxon';
import { utils as syoUtils } from '@nfsave/syo-bilan';

export function loadingRenderer({ Component, props }) {
  if (!Component || !props) {
    const styles = {
      display: 'block',
      textAlign: 'center',
      paddingTop: '15%',
    };
    return (
      <div style={styles}>
        <LoadingSpinner />
      </div>
    );
  }
  return <Component {...props} />;
}

export function useInterval(delayMillis, callback) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delayMillis !== null) {
      let id = setInterval(tick, delayMillis);
      return () => clearInterval(id);
    }
  }, [delayMillis]);
}

export function triggerDownload(filename, blob) {
  return new Promise(resolve => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
      resolve(filename);
    } else {
      const elem = window.document.createElement('a');
      elem.rel = 'noreferrer noopener';
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      resolve(filename);
    }
  });
}

/**
 * Allows automatic disconnection after 13 hours by default.
 * Customizable duration thanks to the permission  automatic:disconnect:after:${TEMP_EN_HEURE}
 */
export function setTimeoutSession(customDisconnect) {
  let expSession = Date.now() + 600000 * 6 * 13; // 13h
  if (customDisconnect !== undefined) {
    const customVal = customDisconnect.key.split(':').at(-1);
    const customInt = parseInt(customVal, 10);
    if (!isNaN(customInt)) expSession = Date.now() + 600000 * 6 * customInt;
  }
  localStorage.setItem('nfCurrentExp', JSON.stringify(expSession));
}

/** Save the path on login to return to this page on logout */
export const setSignInUrl = (url = '/auth/sign-in') => {
  localStorage.setItem('nfSignOutUrl', url);
};

/**
 * Allows to check if the current user has a permission
 * @param {*} currentUser
 * @param {*} requestedPermissionKey
 */
export const hasPerm = (currentUser, requestedPermissionKey) => {
  const userPermissions = currentUser.permissions || [];
  return userPermissions.find(p => p.key === requestedPermissionKey) != null;
};

export const isFormation = () => window.location.hostname.includes('formation');

export const isAbortError = error => {
  return error.name === 'AbortError';
};

export const fmtTime = datetime =>
  datetime == null
    ? 'NR'
    : DateTime.fromISO(datetime).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
      });

export const isoIsValid = dt => {
  return DateTime.fromISO(dt).isValid;
};

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const DAYS = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

export const getProvider = (phone, provider) => {
  if (phone !== null && phone !== undefined && typeof phone === 'string' && phone.length === 12) {
    return 'SMS';
  } else if (provider === 'email') {
    return 'Email';
  } else if (provider !== null || provider !== undefined) {
    return 'AD';
  } else {
    return '-';
  }
};

export const corpulsLiveDataUrl = currentUser => {
  if (hasPerm(currentUser, 'integrations:corpuls:tenant:sdis-73')) {
    return missionId =>
      `https://sdis73-live.eu.corpuls.cloud/live/livedata/?missionid=${encodeURIComponent(missionId)}`;
  }
  return missionId =>
    `https://demo.connectmedlive.fr/corpuls.web/live/livedata/?missionid=${encodeURIComponent(missionId)}`;
};

/**
 * Permet de naviguer vers la cible enregistrer dans le session storage
 * @param router
 * @param defaultTarget Par défault '/interventions/active' peut être personnalisé au besoin
 */
export const goToTarget = (router, defaultTarget = '/interventions/active') => {
  const target = sessionStorage.getItem('nfTarget');
  if (target) {
    sessionStorage.removeItem('nfTarget');
    router.push(target);
  } else {
    router.push(defaultTarget);
  }
};

export const orgaWithAAD = [
  4, // SDIS 35
  47, // SDIS 38
  97, // SDIS 49
  593, // SDIS 73
  90, // SDIS 79
];

/**
 * @type {Map<string, {path: 'BILAN_CREATE' | 'BILAN_INACTIVE' | 'BILAN_ALL' | 'BILAN_EXPORT' | 'USERS_ADD' | 'USERS_EDIT' | 'USERS_DISABLED' | 'USERS_EDIT_ROLES' , permissions: string[], label: string}>}
 */
export const INDIVIDUAL_PERMISSIONS = new Map([
  [
    'BILAN_CREATE',
    {
      label: 'Peut créer des interventions',
      path: 'can_create_bilan',
      permissions: ['interventions:create'],
    },
  ],
  [
    'BILAN_INACTIVE',
    {
      label: 'Peut accéder aux interventions archivées',
      path: 'can_list_inactive_bilan',
      permissions: ['interventions:list-archived'],
    },
  ],
  [
    'BILAN_ALL',
    {
      label: 'Peut accéder aux interventions qui ne lui ont pas été assignées',
      path: 'can_list_all_bilan',
      permissions: ['interventions:list-all'],
    },
  ],
  [
    'BILAN_EXPORT',
    {
      label: 'Peut télécharger les bilans',
      path: 'can_download_bilan',
      permissions: ['interventions:export'],
    },
  ],
  [
    'USERS_ADD',
    {
      label: 'Peut ajouter des utilisateurs',
      path: 'can_add_user',
      permissions: ['users:create'],
    },
  ],
  [
    'USERS_EDIT',
    {
      label: 'Peut modifier des utilisateurs',
      path: 'can_edit_user',
      permissions: ['users:edit'],
    },
  ],
  [
    'USERS_DISABLED',
    {
      label: 'Peut désactiver des utilisateurs',
      path: 'can_disable_user',
      permissions: ['users:disable'],
    },
  ],
  [
    'USERS_EDIT_ROLES',
    {
      label: 'Peut modifier les rôles des utilisateurs',
      path: 'can_edit_roles',
      permissions: ['users:edit-roles'],
    },
  ],
]);

/**
 * Permet de vérifier si l'utilisateur dispose d'une permission individuel ou d'un équivalent de permission de rôle
 * @param perm {Object} Une value de la Map INDIVIDUAL_PERMISSIONS
 * @param perm.path {string} Clé de la permission individuelle
 * @param perm.permissions {string[]} Clé des permissions de rôle équivalente à la permission individuelle
 * @param currentUser
 * @returns {boolean}
 */
export const hasIndividualOrEquivalentPerm = (perm, currentUser) => {
  if (currentUser[perm.path]) return true;
  let hasPerm = false;
  perm.permissions.forEach(p => {
    if (syoUtils.hasPerm(currentUser, p)) hasPerm = true;
  });
  return hasPerm;
};

/**
 * Permet de vérifier si l'utilisateur dispose d'un équivalent de permission individuel venant d'une permission de rôle
 * @param perm {string} Clé de la Map INDIVIDUAL_PERMISSIONS
 * @param currentUser
 * @returns {boolean}
 */
export const hasIndividualFromEquivalentPerm = (perm, currentUser) => {
  let hasPerm = false;
  INDIVIDUAL_PERMISSIONS.get(perm).permissions.forEach(p => {
    if (syoUtils.hasPerm(currentUser, p)) hasPerm = true;
  });
  return hasPerm;
};

export const hasLegacyPerm = (currentUser, perm) => {
  switch (perm) {
    case 'statistics:list':
      if (syoUtils.hasPerm(currentUser, 'statistics:list:hide')) return false;
      return (
        syoUtils.hasPerm(currentUser, 'statistics:list') ||
        (hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_ALL'), currentUser) &&
          hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_INACTIVE'), currentUser) &&
          hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_ADD'), currentUser))
      );
    case 'interventions:active':
      return (
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_ALL'), currentUser) ||
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_CREATE'), currentUser)
      );
    case 'interventions:archived':
      return hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_INACTIVE'), currentUser);
    case 'interventions:sso-smp':
      return syoUtils.hasPerm(currentUser, 'interventions:list-sso-smp');
    case 'interventions:sso-smp:archived':
      return syoUtils.hasPerm(currentUser, 'interventions:list-sso-smp-archived');
    case 'interventions:dispatch':
      return (
        syoUtils.hasPerm(currentUser, 'interventions:dispatch') ||
        (currentUser.organization_id === 1 &&
          hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_INACTIVE'), currentUser))
      );
    case 'users:list':
      return hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_ADD'), currentUser);
    case 'manage:organization':
      return (
        syoUtils.hasPerm(currentUser, 'manage:organization') ||
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_EDIT_ROLES'), currentUser)
      );
    case 'mobilisations:listing':
      return syoUtils.hasPerm(currentUser, 'web:mobilisations:listing');
    case 'interventions:inactive_with_injections':
      return syoUtils.hasPerm(currentUser, 'web:interventions_inactive_with_injections');
    case 'interventions:inactive_with_abandonment':
      return syoUtils.hasPerm(currentUser, 'web:interventions_inactive_with_abandonment');
    case 'licenses:manage':
      return syoUtils.hasPerm(currentUser, 'licenses:manage');
    default:
      return false;
  }
};
