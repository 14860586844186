import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from '../../http';
import { isAbortError } from '../../utils';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import PageHeader, { Actions, Title } from '../../ui/PageHeader';
import Button, { LinkButton } from '../../ui/Button';
import SearchBarCompo, { FilterBar, SearchBar } from '../../ui/SearchBar';
import Tag from '../../ui/Tag';
import Input, { InputSearchCntnr, InputSearchIcon } from '../../ui/Input';
import SearchIcon from '@mui/icons-material/Search';
import LoadingSpinner from '../../ui/LoadingSpinner';
import styled from 'styled-components';
import theme from '../../ui/theme';
import Card from '../../ui/Card';
import { Link } from 'found';

const SchemaInjectionList = styled.ul`
  padding: 0 ${theme.small};
`;

const SchemaInjectionCard = styled(Card)`
  max-width: none;
  min-width: none;

  &:not(:last-child) {
    margin-bottom: ${theme.small};
  }
`;

const SchemaInjectionLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${theme.text};

  &:hover {
    text-decoration: none;
    color: ${theme.blue};
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const SchemaInjectionCardLabelItem = styled.div`
  margin: ${theme.thin};
  width: 100%;
`;

const SchemaInjectionCardContentItem = styled.div`
  margin: ${theme.thin};
  width: 96%;
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  color: ${theme.black} !important;
`;

const PosologyRow = styled.div`
  margin: 0.25rem;
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18%;
  gap: 4%;
`;

const DrugRouteCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Red = styled.span`
  color: ${theme.red};
`;

const Orange = styled.span`
  color: ${theme.orange};
`;

export default function VitalSignBounds() {
  const controller = new AbortController();
  const [search, setSearch] = useState('');
  const [emptyVitalSign, setEmptyVitalSign] = useState([]);

  const { isLoading: vitalSignBoudsIsLoading, data: vitalSignBouds } = useQuery(
    ['vitalSignBouds'],
    async () => {
      return await http
        .get(`vital_signs_bounds.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setEmptyVitalSign(
            [
              { vital_sign: 'FR', unit: 'bpm' },
              { vital_sign: 'FC', unit: 'bpm' },
              { vital_sign: 'GLY', unit: 'g/L' },
              { vital_sign: 'GLY', unit: 'mmol/L' },
              { vital_sign: 'GLY', unit: 'mg/dL' },
              { vital_sign: 'TAS', unit: 'mmHg' },
              { vital_sign: 'TAS', unit: 'cmH2O' },
              { vital_sign: 'TAD', unit: 'mmHg' },
              { vital_sign: 'TAD', unit: 'cmH2O' },
              { vital_sign: 'TEMP', unit: '°C' },
              { vital_sign: 'SPO2AA', unit: '%' },
              { vital_sign: 'SPO2O2', unit: '%' },
              { vital_sign: 'SPCO', unit: '%' },
              { vital_sign: 'HEMO', unit: 'g/dl' },
              { vital_sign: 'HBCO', unit: '%' },
              { vital_sign: 'ETCO2', unit: 'mmHg' },
              { vital_sign: 'PAM', unit: 'mmHg' },
              { vital_sign: 'COCAP', unit: '%' },
              { vital_sign: 'COAA', unit: 'ppm' },
              { vital_sign: 'COEXP', unit: 'ppm' },
            ].filter(
              vs =>
                !res.vital_sign_bounds
                  .map(it => it.vital_sign.concat(it.unit_name))
                  .includes(vs.vital_sign.concat(vs.unit))
            )
          );

          return res.vital_sign_bounds;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des bornes');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const clearFilter = () => {
    setSearch('');
  };

  const vitalSignBoudsFiltered = useMemo(() => {
    let temp = [];
    vitalSignBouds?.map(bound => {
      if (getVitalSignLabel(bound.vital_sign).toLowerCase().includes(search.toLowerCase())) temp.push(bound);
    });
    return temp;
  }, [search, vitalSignBouds]);

  const emptyVitalSignFiltered = useMemo(() => {
    let temp = [];
    emptyVitalSign?.map(bound => {
      if (getVitalSignLabel(bound.vital_sign).toLowerCase().includes(search.toLowerCase())) temp.push(bound);
    });
    return temp;
  }, [search, emptyVitalSign]);

  function getVitalSignLabel(vitalSign) {
    switch (vitalSign) {
      case 'FR':
        return 'Fréquence respiratoire';
      case 'FC':
        return 'Fréquence cardiaque';
      case 'GLY':
        return 'Glycémie';
      case 'TAS':
        return 'Tension artérielle systolique';
      case 'TAD':
        return 'Tension artérielle diastolique';
      case 'TEMP':
        return 'Température';
      case 'SPO2AA':
        return 'Saturation en oxygène en air ambiant';
      case 'SPO2O2':
        return 'Saturation en oxygène sous O2';
      case 'SPCO':
        return 'Carboxyhémoglobine (SPCO)';
      case 'HEMO':
        return 'Hémoglobine';
      case 'HBCO':
        return 'Concentration HbCO';
      case 'ETCO2':
        return 'ETCO2';
      case 'PAM':
        return 'Pression artérielle moyenne';
      case 'COCAP':
        return 'Taux de CO capillaire';
      case 'COAA':
        return 'Taux de CO air ambiant';
      case 'COEXP':
        return 'Taux de CO expiré';
      default:
        return '';
    }
  }

  function canVitalSignHadLimit(vitalSign, unit = null) {
    switch (vitalSign) {
      case 'FR':
        return true;
      case 'FC':
        return true;
      case 'GLY':
        return true;
      case 'TAS':
        return unit !== 'mmHg';
      case 'TAD':
        return unit !== 'mmHg';
      case 'TEMP':
        return true;
      case 'SPO2AA':
        return false;
      case 'SPO2O2':
        return false;
      case 'SPCO':
        return false;
      case 'HEMO':
        return true;
      case 'HBCO':
        return false;
      case 'ETCO2':
        return true;
      case 'PAM':
        return true;
      case 'COCAP':
        return false;
      case 'COAA':
        return true;
      case 'COEXP':
        return true;
      default:
        return '';
    }
  }

  function getAgeRangeLabel(age_range) {
    switch (age_range) {
      case 'NEWBORN':
        return 'Nouveau-né';
      case 'INFANT':
        return 'Nourrisson';
      case 'SMALL_CHILD':
        return 'Petit enfant';
      case 'BIG_CHILD':
        return 'Grand enfant';
      case 'ADULT':
        return 'Adulte';
      default:
        return '';
    }
  }

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        <Title>Bornes de paramètres vitaux</Title>
        <Actions>
          <LinkButton to='/gestionnaire/bounds/edit'>Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      <SearchBarCompo hasTag={true} tags={<>{search.length > 0 && <Tag text={search} callback={clearFilter} />}</>}>
        <SearchBar>
          <FilterBar>
            <InputSearchCntnr>
              <InputSearchIcon>
                <SearchIcon />
              </InputSearchIcon>
              <Input
                type='search'
                value={search}
                onChange={ev => setSearch(ev.target.value)}
                placeholder='Paramètre vital'
              />
            </InputSearchCntnr>
            <Button className='warn' onClick={clearFilter}>
              Réinitialiser les filtres
            </Button>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      {vitalSignBoudsIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <>
          <SchemaInjectionList>
            {vitalSignBoudsFiltered.map((vitalSignBoud, idx) => (
              <SchemaInjectionCard key={idx}>
                <SchemaInjectionLink
                  to={{
                    pathname: `/gestionnaire/bounds/edit`,
                    query: {
                      vital_sign: vitalSignBoud.vital_sign,
                      unit: vitalSignBoud.unit_name,
                    },
                  }}
                >
                  <SchemaInjectionCardLabelItem>
                    {getVitalSignLabel(vitalSignBoud.vital_sign)} ({vitalSignBoud.unit_name})
                  </SchemaInjectionCardLabelItem>
                  <br />
                  <SchemaInjectionCardContentItem>
                    {vitalSignBoud.bounds?.map(bound => (
                      <PosologyRow>
                        <strong>{getAgeRangeLabel(bound.age_range)}</strong>
                        <Red>
                          {canVitalSignHadLimit(bound.vital_sign, bound.unit_name)
                            ? bound.limit_min
                              ? `${bound.limit_min} ${bound.unit_name}`
                              : '-'
                            : ''}
                        </Red>
                        <Orange>{bound.bound_min ? `${bound.bound_min} ${bound.unit_name}` : '-'}</Orange>
                        <Orange>{bound.bound_max ? `${bound.bound_max} ${bound.unit_name}` : '-'}</Orange>
                        <Red>
                          {canVitalSignHadLimit(bound.vital_sign, bound.unit_name)
                            ? bound.limit_max
                              ? `${bound.limit_max} ${bound.unit_name}`
                              : '-'
                            : ''}
                        </Red>
                      </PosologyRow>
                    ))}
                  </SchemaInjectionCardContentItem>
                </SchemaInjectionLink>
              </SchemaInjectionCard>
            ))}
            {emptyVitalSignFiltered.map((bound, idx) => (
              <SchemaInjectionCard key={idx}>
                <SchemaInjectionLink
                  to={{
                    pathname: `/gestionnaire/bounds/edit`,
                    query: {
                      vital_sign: bound.vital_sign,
                      unit: bound.unit,
                    },
                  }}
                >
                  <SchemaInjectionCardLabelItem>
                    {getVitalSignLabel(bound.vital_sign)} ({bound.unit})
                  </SchemaInjectionCardLabelItem>
                </SchemaInjectionLink>
              </SchemaInjectionCard>
            ))}
          </SchemaInjectionList>
        </>
      )}
    </>
  );
}
