import React, { useContext, useEffect, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';
import LoaderBarContext from '../../ui/useLoaderBar';

import PageHeader, { Title, Cntnr } from '../../ui/PageHeader';
import Input from '../../ui/Input';
import { BlockButton } from '../../ui/Button';
import { Column, Row } from '../../ui/FlexGrid';
import styled from 'styled-components';
import theme from '../../ui/theme';
import LoadingSpinner from '../../ui/LoadingSpinner';

const ListItem = styled.li`
  padding: ${theme.thin} 0;
`;

const UnstyledList = styled.ul`
  padding-left: 0;
  list-style: none;

  ${ListItem} + ${ListItem} {
    border-top: 1px solid ${theme.grey5};
  }
`;

function MotiveItem({ motive, motivesRefetch }) {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const handleDeleteMotive = motive => async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`motives/${encodeURIComponent(motive.id)}.json`);
      toast.success(`Le motif d'intervention « ${motive.value} » a bien été supprimé.`);
      motivesRefetch();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(`Impossible de supprimer le motif d'intervention « ${motive.value} ».`);
    } finally {
      setLoaderBar(false);
    }
  };

  return (
    <ListItem key={motive.id}>
      <Row>
        <Column width={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {motive.value}
        </Column>
        <Column width={1}>
          <BlockButton
            style={{ marginBottom: 0 }}
            className='warn'
            disabled={loaderBarState}
            onClick={handleDeleteMotive(motive)}
          >
            Supprimer
          </BlockButton>
        </Column>
      </Row>
    </ListItem>
  );
}

export default function Motives() {
  const controller = new AbortController();

  const {
    isLoading: motivesIsLoading,
    data: motives,
    refetch: motivesRefetch,
  } = useQuery(
    ['motives'],
    async () => {
      return await http
        .get(`motives.json`, {
          signal: controller.signal,
        })
        .json()
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des motifs d'interventions");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [newMotive, setNewMotive] = useState('');

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post('motives.json', { json: { value: newMotive } }).json();
      toast.success(`Motif d'intervention ajouté avec succès.`);
      setNewMotive('');
      motivesRefetch();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setLoaderBar(false);
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Motifs d'intervention</Title>
      </PageHeader>

      {motivesIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <Cntnr>
          <UnstyledList>
            <ListItem>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Column width={4}>
                    <Input value={newMotive} onChange={ev => setNewMotive(ev.target.value)} disabled={loaderBarState} />
                  </Column>
                  <Column width={1}>
                    <BlockButton type='submit' disabled={loaderBarState}>
                      Ajouter
                    </BlockButton>
                  </Column>
                </Row>
              </form>
            </ListItem>
            {motives.data.map(motive => (
              <MotiveItem key={motive.id} motive={motive} motivesRefetch={() => motivesRefetch()} />
            ))}
          </UnstyledList>
        </Cntnr>
      )}
    </>
  );
}
