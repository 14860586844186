import React, { useState } from "react";
import PageHeader, { Actions, Title } from "../../ui/PageHeader";
import Input from "../../ui/Input";
import { DateTime } from "luxon";
import { Container, Column, Row } from "../../ui/FlexGrid";
import Select from "../../ui/Select";
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Button from "../../ui/Button";
import DynamicChart from "../DynamicChart";
import http from "../../http";
import Textarea from "../../ui/Textarea";

const SOURCES_OPTIONS = [
  {
    label: "Interventions",
    options: [
      {
        label: "Cumulé des interventions",
        value: "statistics/interventions_cumulative_count",
      },
      {
        label: "Nombre d'interventions",
        value: "statistics/interventions_count",
      },
    ],
  },
  {
    label: "Vital",
    options: [
      {
        label: "OBVA",
        value: "statistics/count/respiratory/obva",
      },
      {
        label: "Hémorragie",
        value: "statistics/count/circulatory/hemorragie",
      },
      {
        label: "Inconscience",
        value: "statistics/count/neurological/inconscience",
      },
      {
        label: "Arret ventilatoire",
        value: "statistics/count/respiratory/arret_ventilatoire",
      },
      {
        label: "Arret cardiaque",
        value: "statistics/count/circulatory/arret_cardiaque",
      },
    ],
  },
  {
    label: "Neurologique",
    options: [
      {
        label: "PCI",
        value: "statistics/count/neurological/pci",
      },
      {
        label: "Non réponse aux ordres",
        value: "statistics/count/neurological/repond_aux_ordres",
      },
      {
        label: "Non orientation spaciale",
        value: "statistics/count/neurological/orientation_spatiale",
      },
      {
        label: "Non orientation temporelle",
        value: "statistics/count/neurological/orientation_temporelle",
      },
      {
        label: "Non motricité aux extremités",
        value: "statistics/count/neurological/motricite_extremites",
      },
      {
        label: "Trouble de la parole",
        value: "statistics/count/neurological/trouble_parole",
      },
      {
        label: "Paralysie faciale",
        value: "statistics/count/neurological/paralysie_faciale",
      },
      {
        label: "Convulsions",
        value: "statistics/count/neurological/convulsions",
      },
      {
        label: "Convulsions répétitives",
        value: "statistics/count/neurological/convulsions_repetitives",
      },
      {
        label: "Agitation",
        value: "statistics/count/neurological/agitations",
      },
      {
        label: "Pupilles anormales",
        value: "statistics/count/neurological/pupilles_anormales",
      },
      {
        label: "Pupilles symétriques",
        value: "statistics/count/neurological/pupilles_symetriques",
      },
    ],
  },
  {
    label: "Circulatoire",
    options: [
      {
        label: "Pouls mal frappé",
        value: "statistics/count/circulatory/bien_frappe",
      },
      {
        label: "Pouls irrégulier",
        value: "statistics/count/circulatory/regulier",
      },
      {
        label: "TRC >2s",
        value: "statistics/count/circulatory/trc",
      },
      {
        label: "Marbrures",
        value: "statistics/count/circulatory/marbrures",
      },
      {
        label: "Normale",
        value: "statistics/count/circulatory/normale",
      },
      {
        label: "Sensation de soif",
        value: "statistics/count/circulatory/sensation_de_soif",
      },
      {
        label: "Absence de pouls radial",
        value: "statistics/count/circulatory/absence_pouls_radial_bilateral",
      },
      {
        label: "Douleur thoracique",
        value: "statistics/count/circulatory/douleur_thoracique",
      },
    ],
  },
  {
    label: "Respiratoire",
    options: [
      {
        label: "Respiration non ample",
        value: "statistics/count/respiratory/ample",
      },
      {
        label: "Respiration irrégulière",
        value: "statistics/count/respiratory/reguliere",
      },
      {
        label: "Pauses respiratoires",
        value: "statistics/count/respiratory/pauses_respiratoires",
      },
      {
        label: "Respiration superficielle",
        value: "statistics/count/respiratory/respiration_superficielle",
      },
      {
        label: "Respiration bruyante",
        value: "statistics/count/respiratory/respiration_bruyante",
      },
      {
        label: "Respiration difficile",
        value: "statistics/count/respiratory/respiration_difficile",
      },
      {
        label: "Cyanose",
        value: "statistics/count/respiratory/cyanose",
      },
      {
        label: "Sueurs",
        value: "statistics/count/respiratory/sueurs",
      },
      {
        label: "Bruits anormaux",
        value: "statistics/count/respiratory/bruits_anormaux",
      },
      {
        label: "Difficile",
        value: "statistics/count/respiratory/difficile",
      },
      {
        label: "Plaie soufflante",
        value: "statistics/count/respiratory/plaie_soufflante",
      },
      {
        label: "Normale",
        value: "statistics/count/respiratory/normale",
      },
      {
        label: "Superficielle",
        value: "statistics/count/respiratory/superficielle",
      },
      {
        label: "Symétrique",
        value: "statistics/count/respiratory/symetrique",
      },
    ],
  },
  {
    label: "Contexte",
    options: [
      {
        label: "Accidents / blessés",
        value: "",
      },
      {
        label: "Maladie / malaise",
        value: "",
      },
      {
        label: "Allergies",
        value: "",
      },
    ],
  },
  {
    label: "Gestes",
    options: [
      {
        label: "Accouchement réalisé",
        value: "statistics/count/obstetrique/accouchement_realise",
      },
      {
        label: "Compression manuelle",
        value: "statistics/count/gestes/compr_manuelle",
      },
      {
        label: "Pansement compressif",
        value: "statistics/count/gestes/pansement_compressif",
      },
      {
        label: "Quickclot",
        value: "statistics/count/gestes/quick_lock",
      },
      {
        label: "Pansement hémostatique",
        value: "statistics/count/gestes/pansement_hemostatique",
      },
      {
        label: "Attelle traction",
        value: "statistics/count/gestes/attelle_traction",
      },
      {
        label: "Ceinture pelvienne",
        value: "statistics/count/gestes/ceinture_pelvienne",
      },
      {
        label: "Ventilation assistée",
        value: "statistics/count/gestes/ventilation_assistee",
      },
      {
        label: "Auscultation pulmonaire",
        value: "statistics/count/gestes/auscultation_pulmonaire",
      },
      {
        label: "Retournement",
        value: "statistics/count/gestes/retournement",
      },
      {
        label: "Retrait casque",
        value: "statistics/count/gestes/ret_casque",
      },
      {
        label: "Basculement tête",
        value: "statistics/count/gestes/basculement_tete",
      },
      {
        label: "LVA traumatique",
        value: "statistics/count/gestes/lva_traumatique",
      },
      {
        label: "Extraction digitale",
        value: "statistics/count/gestes/extraction_digitale",
      },
      {
        label: "Aspiration",
        value: "statistics/count/gestes/aspiration",
      },
      {
        label: "Retrait dentier",
        value: "statistics/count/gestes/retrait_dentier",
      },
      {
        label: "Claques dans le dos",
        value: "statistics/count/gestes/claquesDos",
      },
      {
        label: "Heimlich",
        value: "statistics/count/gestes/heimlich",
      },
      {
        label: "Canule de guedel",
        value: "statistics/count/gestes/guedel",
      },
      {
        label: "Collier cervical",
        value: "statistics/count/gestes/collier",
      },
      {
        label: "Pince de Magill",
        value: "statistics/count/gestes/pince_magill",
      },
      {
        label: "ACT",
        value: "statistics/count/gestes/act",
      },
      {
        label: "Attelle",
        value: "statistics/count/gestes/attelle",
      },
      {
        label: "Écharpe",
        value: "statistics/count/gestes/echarpe",
      },
      {
        label: "MID",
        value: "statistics/count/gestes/mid",
      },
      {
        label: "Plan dur",
        value: "statistics/count/gestes/plandur",
      },
      {
        label: "Brancard cuillère",
        value: "statistics/count/gestes/brancard_cuillere",
      },
      {
        label: "Maintien os long fracturé",
        value: "statistics/count/gestes/maintien_os_long_fracture",
      },
      {
        label: "Maintien tête",
        value: "statistics/count/gestes/maintien_tete",
      },
      {
        label: "Protection plaie",
        value: "statistics/count/gestes/protection_plaie",
      },
      {
        label: "Protection thermique",
        value: "statistics/count/gestes/protection_thermique",
      },
      {
        label: "Réalignement membre",
        value: "statistics/count/gestes/realignement_membre",
      },
      {
        label: "Refroidissement",
        value: "statistics/count/gestes/refroidissement",
      },
      {
        label: "Relevage",
        value: "statistics/count/gestes/relevage",
      },
      {
        label: "Pose brule stop",
        value: "statistics/count/gestes/pose_brule_stop",
      },
      {
        label: "Pansement 3 côtés",
        value: "statistics/count/gestes/pansement3_cotes",
      },
      {
        label: "Aide prise médicaments",
        value: "statistics/count/gestes/aide_prise_medicaments",
      },
      {
        label: "Planche à masser",
        value: "statistics/count/gestes/planche_a_masser",
      },
    ],
  },
  {
    label: "Témoin",
    options: [
      {
        label: "MCE",
        value: "statistics/count/gestes/smur_mce",
      },
      {
        label: "Chocs (DAE)",
        value: "statistics/count/gestes/is_chocs",
      },
      {
        label: "Arrêt hémorragie",
        value: "statistics/count/gestes/arret_hemorragie",
      },
      {
        label: "Désobstruction",
        value: "statistics/count/gestes/desobstruction",
      },
      {
        label: "Mobilisation",
        value: "statistics/count/gestes/mobilisation",
      },
      {
        label: "PLS",
        value: "statistics/count/gestes/temoin_pls",
      },
    ],
  },
  {
    label: "Puy du fou",
    options: [
      {
        label: "Victimes de type: Visiteurs",
        value: "statistics/count/victims/pdf_visiteurs",
      },
      {
        label: "Victimes de type: Non visiteurs (salariés, ...)",
        value: "statistics/count/victims/pdf_non_visiteurs",
      },
      {
        label: "Evacuations",
        value: "statistics/count/devenir/pdf_evacuations",
      },
      {
        label: "Accidents de travail",
        value: "statistics/count/circonstance/pdf_accidents_travail",
      },
    ],
  },
];

const DISPLAY_OPTIONS = [
  {
    label: "Lignes",
    value: "line",
  },
  {
    label: "Barres",
    value: "vertical-bar",
  },
];

export default function Create({ match, router }) {
  let intervalStart = DateTime.local().startOf("month");
  if (typeof match.location.query.intervalStart !== "undefined") {
    intervalStart = DateTime.fromISO(
      match.location.query.intervalStart
    ).startOf("day");
  }
  let intervalEnd = DateTime.local().endOf("month");
  if (typeof match.location.query.intervalEnd !== "undefined") {
    intervalEnd = DateTime.fromISO(match.location.query.intervalEnd).endOf(
      "day"
    );
  }

  const handleIntervalChange = boundName => ev => {
    if (ev.target.value == null || ev.target.value === "") {
      return;
    }
    router.push({
      pathname: "/statistiques/nouveau",
      query: {
        ...match.location.query,
        [boundName]: ev.target.value
      }
    });
  };

  const [form, setForm] = useState({
    title: "",
    kind: "xy",
    description: "",
    dataSources: [
      {
        label: "",
        url: "",
        display: "line"
      }
    ]
  });

  const chartConfig = form => {
    if (form.kind == null || form.kind.toLowerCase() === "xy") {
      return {
        xType: "time-utc",
        crosshair: true,
        gridLines: [{ kind: "horizontal" }],
        axis: [{ kind: "x" }, { kind: "y" }],
        series: form.dataSources.map(dataSource => ({
          kind: dataSource.display,
          curve: "curveMonotoneX",
          title: dataSource.label,
          source: dataSource.url
        }))
      };
    } else {
    }
  };

  const [setAlert] = useState(null);
  const handleFormSubmit = async ev => {
    ev.preventDefault();
    try {
      if (form.title === "") form.title = "Sans titre";
      await http.post(`charts`, { json: { chart: form } }).json();
      router.push("/statistiques/apercu");
    } catch (err) {
      setAlert({
        kind: "danger",
        text: `Impossible d'ajouter la statistique ${form.title}.`
      });
    }
  };

  const handleDataSourceUrlChange = (dataSourceIdx, value) => {
    const dataSources = form.dataSources.slice();
    dataSources[dataSourceIdx] = {
      ...form.dataSources[dataSourceIdx],
      url: value
    };
    setForm({
      ...form,
      dataSources
    });
  };

  const handleDataSourceDisplayChange = (dataSourceIdx, value) => {
    const dataSources = form.dataSources.slice();
    dataSources[dataSourceIdx] = {
      ...form.dataSources[dataSourceIdx],
      display: value
    };
    setForm({
      ...form,
      dataSources
    });
  };

  const handleChartNameChange = () => ev => {
    setForm({
      ...form,
      title: ev.target.value
    });
  };

  const handleChartDescriptionChange = () => ev => {
    setForm({
      ...form,
      description: ev.target.value
    });
  };

  const handleDataSourceLabelChange = dataSourceIdx => ev => {
    const dataSources = form.dataSources.slice();
    dataSources[dataSourceIdx] = {
      ...form.dataSources[dataSourceIdx],
      label: ev.target.value
    };
    setForm({
      ...form,
      dataSources
    });
  };

  const handleAddDataSource = () => {
    setForm({
      ...form,
      dataSources: form.dataSources.concat([
        {
          label: "",
          url: "",
          display: "line"
        }
      ])
    });
  };

  return (
    <>
      <PageHeader>
        <Title> Nouveau graphique </Title>
        <Actions>
          <span style={{ padding: ".5rem" }}>De</span>
          <Input
            style={{ width: 200 }}
            type="date"
            value={intervalStart.toISODate()}
            onChange={handleIntervalChange("intervalStart")}
          />
          <span style={{ padding: ".5rem" }}>à</span>
          <Input
            style={{ width: 200, marginRight: "1.5rem" }}
            type="date"
            value={intervalEnd.toISODate()}
            onChange={handleIntervalChange("intervalEnd")}
          />
          <Button form="statisticsCreateForm">Sauvegarder</Button>
        </Actions>
      </PageHeader>
      <Container>
        <Row>
          <Column>
            <form id="statisticsCreateForm" onSubmit={handleFormSubmit}>
              <Input
                placeholder="Nom du graphique"
                value={form.title}
                onChange={handleChartNameChange()}
              />
              <br />
              <Textarea
                style={{
                  width: "100%",
                  display: "block",
                  fontSize: "0.825rem"
                }}
                placeholder="Description du graphique"
                value={form.description}
                onChange={handleChartDescriptionChange()}
              />
              <hr />

              {form.dataSources.map((dataSource, idx) => (
                <Row key={idx}>
                  <Column>
                    <FormGroup>
                      <Label>Nom de la série {idx + 1}</Label>
                      <Input
                        value={dataSource.label}
                        onChange={handleDataSourceLabelChange(idx)}
                      />
                    </FormGroup>
                  </Column>
                  <Column width={2}>
                    <FormGroup>
                      <Label> Sélection d'une entrée </Label>
                      <Select
                        value={SOURCES_OPTIONS.find(s => s.value === dataSource.url)}
                        options={SOURCES_OPTIONS}
                        onChange={(value) => handleDataSourceUrlChange(idx, value?.value)}
                        placeholder=""
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup>
                      <Label> Affichage </Label>
                      <Select
                        value={DISPLAY_OPTIONS.find(d => d.value === dataSource.display)}
                        options={DISPLAY_OPTIONS}
                        onChange={(value) => handleDataSourceDisplayChange(idx, value?.value)}
                      />
                    </FormGroup>
                  </Column>
                </Row>
              ))}
            </form>

            <Button onClick={ev => handleAddDataSource()}>
              Ajouter une entrée
            </Button>
          </Column>
          <Column width={2}>
            <div
              style={{
                height: 600
              }}
            >
              <DynamicChart
                intervalStart={intervalStart}
                intervalEnd={intervalEnd}
                config={chartConfig(form)}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </>
  );
}
