import React, { useContext, useState, useEffect, useMemo } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { hasPerm, isAbortError, triggerDownload } from '../../utils';
import CurrentUserContext from '../../CurrentUserContext';
import LoaderBarContext from '../../ui/useLoaderBar';
import { remove } from 'lodash';
import { utils as syoUtils } from '@nfsave/syo-bilan';
import { idClientFactory } from '../../grpc';
import { EphemeralShareServicePromiseClient } from '../../__gen__/efibi/v1/relay/services/ephemeral_share_grpc_web_pb';
import * as p from '../../__gen__/efibi/v1/relay/services/ephemeral_share_pb';

import List from '../List';
import PageHeader, { Title, Actions } from '../../ui/PageHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';
import BoundedPagination from '../../ui/BoundedPagination';
import Button, { LinkButton } from '../../ui/Button';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ShareIcon from '@mui/icons-material/Share';
import { ActionsBtnDialog, Dialog } from '../../ui/Modal';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import SeniorityListBilans from '../../components/interventions/SeniorityListBilans';
import ReloadIcon from '../../ui/ReloadIcon';
import { BtnTabs, Tabs, TabsCntnr } from '../../ui/Tabs';
import {
  NAME_AMBU_RGX,
  NAME_AR_RGX,
  NAME_ASSU_RGX,
  NAME_VLS_RGX,
  NAME_VML_RGX,
  NAME_VSAV_RGX,
} from '../../utils/utils';

function Active({ match }) {
  const [page, setPage] = useState(parseInt(match.location.query.page || '1', 10));

  const controller = new AbortController();

  const { currentUser } = useContext(CurrentUserContext);
  const hasGroupedPerm = syoUtils.hasPerm(currentUser, 'web:interventions_active:vue_medecine_de_regulation');
  const hasEmptyPerm = syoUtils.hasPerm(currentUser, 'web:interventions_active:empty');
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [isLoading, setLoading] = useState('full');

  const [groupedView, setGroupedView] = useState(false);
  const [tabsChoices, setTabsChoices] = useState(['all']);

  const [codeSharingView, setCodeSharingView] = useState(false);
  const [codeSharing, setCodeSharing] = useState('');

  const [codeDownloadingView, setCodeDownloadingView] = useState(false);
  const [codeDownloading, setCodeDownloading] = useState('');

  const {
    isLoading: payloadIsLoading,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    ['activeBilans'],
    async () => {
      setLoading('background');
      return await http
        .get('bilans', {
          signal: controller.signal,
          searchParams: {
            page: page,
          },
        })
        .json()
        .then(res => {
          setLoading(null);
          res.dataSeniority = Date.now();
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          toast.warn(`Une erreur est survenue pendant le rafraichissement des interventions actives.`, {
            toastId: 'activeBilans',
          });
          Sentry.captureException(error);
          console.error(error.name);
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchInterval: 5000,
      enabled: !hasEmptyPerm,
    }
  );

  useEffect(() => {
    refetchPayload();
  }, [match]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const goTo = p => {
    setPage(p);
    setLoading('full');
  };

  const count = payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);
  const pagination = (
    <div style={{ paddingLeft: '1rem' }}>
      <BoundedPagination
        count={count}
        current={page}
        location={p => `/interventions/active?page=${p}`}
        onClick={p => goTo(p)}
      />
    </div>
  );

  const toggleCodeSharing = () => {
    setCodeSharingView(!codeSharingView);
    if (!codeSharingView) {
      setCodeSharing('');
    }
  };

  const toggleCodeDownlaoding = () => {
    setCodeDownloadingView(!codeDownloadingView);
    if (!codeDownloadingView) {
      setCodeDownloading('');
    }
  };

  const handleCodeSharing = ev => {
    setCodeSharing(ev.target.value);
  };

  const handleCodeDownloading = ev => {
    setCodeDownloading(ev.target.value.toUpperCase());
  };

  const saveCodeSharing = async ev => {
    ev.preventDefault();
    setLoaderBar(true);

    const payload = {
      code: codeSharing,
    };

    try {
      const opts = { json: payload };
      await http.post(`share/claims`, opts);
      toast.success(
        "Le partage par code a été soumis avec succès. L'intervention sera disponible d'ici quelques secondes."
      );
      toggleCodeSharing();
    } catch (error) {
      const { errors } = await error.response.json();
      error.errrors = JSON.stringify(errors);
      console.error(error);
      if (errors === undefined || errors.length === 0) {
        Sentry.captureException(error);
        toast.warn('Le partage par code a échoué.');
      } else {
        toast.warn(errors.join(' '));
      }
    } finally {
      setLoaderBar(false);
    }
  };

  const saveCodeDownloading = async ev => {
    ev.preventDefault();
    setLoaderBar(true);

    try {
      const svc = idClientFactory(EphemeralShareServicePromiseClient)();
      const request = new p.RedeemCodeRequest();
      request.setCode(codeDownloading.toUpperCase());
      const response = await svc.redeemCode(request);
      const pdfBlob = new Blob([response.getFile_asU8()], {
        type: 'application/pdf',
      });
      let filename = response.getFileName();
      if (filename.length === 0) {
        filename = `Efibi.pdf`;
      }
      await triggerDownload(filename, pdfBlob);
      setCodeDownloading('');
      toggleCodeDownlaoding();
    } catch (error) {
      console.error(error);
      if (error.message === undefined || error.message.length === 0) {
        Sentry.captureException(error);
        toast.warn('Impossible de télécharger le fichier PDF associé au code de partage.');
      } else {
        toast.warn(error.message);
      }
    } finally {
      setLoaderBar(false);
    }
  };

  const bilansList = useMemo(() => {
    if (!payload?.bilans) return [];
    if (groupedView) {
      const temp = {
        smur: payload.bilans.filter(
          b => b.equipe.vehicule.match(NAME_AR_RGX) !== null || b.equipe.vehicule.match(NAME_VML_RGX) !== null
        ),
        sdis: payload.bilans.filter(
          b => b.equipe.vehicule.match(NAME_VSAV_RGX) !== null || b.equipe.vehicule.match(NAME_VLS_RGX) !== null
        ),
        ambu: payload.bilans.filter(
          b => b.equipe.vehicule.match(NAME_ASSU_RGX) !== null || b.equipe.vehicule.match(NAME_AMBU_RGX) !== null
        ),
        other: payload.bilans.filter(
          b =>
            b.equipe.vehicule.match(NAME_AR_RGX) === null &&
            b.equipe.vehicule.match(NAME_VML_RGX) === null &&
            b.equipe.vehicule.match(NAME_VSAV_RGX) === null &&
            b.equipe.vehicule.match(NAME_VLS_RGX) === null &&
            b.equipe.vehicule.match(NAME_ASSU_RGX) === null &&
            b.equipe.vehicule.match(NAME_AMBU_RGX) === null
        ),
      };
      return temp;
    } else {
      return payload.bilans;
    }
  }, [payload, groupedView]);

  const tabsBtnGroupedViewOptions = [
    {
      option: false,
      text: "Fil de l'eau",
    },
    {
      option: true,
      text: 'Groupé',
    },
  ];

  const tabsBtnGroupedViewChoiceOptions = [
    {
      option: 'all',
      length: payload?.bilans?.length,
      text: 'Tous',
    },
    {
      option: 'smur',
      length: bilansList?.smur?.length,
      text: 'SMUR',
    },
    {
      option: 'sdis',
      length: bilansList?.sdis?.length,
      text: 'SDIS',
    },
    {
      option: 'ambu',
      length: bilansList?.ambu?.length,
      text: 'AMBU',
    },
    {
      option: 'other',
      length: bilansList?.other?.length,
      text: 'Autres',
    },
  ];

  const handleTabsViewChange = value => {
    setGroupedView(value);
  };

  const handleTabsChoicesChange = value => {
    if (value === 'all') {
      setTabsChoices([value]);
    } else {
      let temp = remove(tabsChoices, e => e !== 'all');
      if (temp.includes(value)) {
        temp = remove(temp, e => e !== value);
      } else {
        temp.push(value);
      }
      temp.length === 0 ? setTabsChoices(['all']) : setTabsChoices([...temp]);
    }
  };

  return (
    <>
      <PageHeader>
        <Title>
          Interventions{' '}
          {!hasEmptyPerm && (
            <>
              <SeniorityListBilans payload={payload} />
              {isLoading === 'background' && <ReloadIcon />}
            </>
          )}
        </Title>
        <Actions>
          {hasPerm(currentUser, 'web:interventions:integrer-par-code') && (
            <Button onClick={() => toggleCodeSharing()} type='button'>
              <ShareIcon className='icon-left' />
              Intégration par code
            </Button>
          )}
          <Dialog
            isOpen={codeSharingView}
            toggleDialog={() => toggleCodeSharing()}
            title='Intégration par code'
            children={
              <div>
                <form onSubmit={saveCodeSharing}>
                  <FormGroup>
                    <Label htmlFor='code-sharing'>Code d'intégration</Label>
                    <Input id='code-sharing' type='text' value={codeSharing} onChange={ev => handleCodeSharing(ev)} />
                  </FormGroup>
                  <hr />
                  <ActionsBtnDialog>
                    <Button type='button' className='warn' onClick={() => toggleCodeSharing()}>
                      Annuler
                    </Button>
                    <Button type='submit' disabled={loaderBarState || codeSharing.length === 0}>
                      Soumettre le code
                    </Button>
                  </ActionsBtnDialog>
                </form>
              </div>
            }
          />
          {hasPerm(currentUser, 'web:interventions:telecharger-efibi-par-code') && (
            <Button onClick={() => toggleCodeDownlaoding()} type='button'>
              <ShareIcon className='icon-left' />
              Téléchargement eFiBi par code
            </Button>
          )}
          <Dialog
            isOpen={codeDownloadingView}
            toggleDialog={() => toggleCodeDownlaoding()}
            title='Téléchargement eFiBi par code'
            children={
              <div>
                <form onSubmit={saveCodeDownloading}>
                  <FormGroup>
                    <Label htmlFor='code-downloading'>Code eFiBi</Label>
                    <Input
                      id='code-downloading'
                      type='text'
                      value={codeDownloading}
                      placeholder='Code à 8 caractères'
                      maxLength={8}
                      onChange={ev => handleCodeDownloading(ev)}
                    />
                  </FormGroup>
                  <hr />
                  <ActionsBtnDialog>
                    <Button type='button' className='warn' onClick={() => toggleCodeDownlaoding()}>
                      Annuler
                    </Button>
                    <Button type='submit' disabled={loaderBarState || codeDownloading.length !== 8}>
                      Soumettre le code
                    </Button>
                  </ActionsBtnDialog>
                </form>
              </div>
            }
          />
          {hasPerm(currentUser, 'interventions:dispatch') && (
            <LinkButton to={`/interventions/declenchement`}>
              <NoteAddIcon className='icon-left' />
              Nouvelle intervention
            </LinkButton>
          )}
        </Actions>
      </PageHeader>

      {hasEmptyPerm ? (
        <TabsCntnr>
          <p>Les droits de votre compte ne vous permettent pas d'accéder aux interventions actives.</p>
        </TabsCntnr>
      ) : (
        <>
          {payloadIsLoading || payload === undefined ? (
            <LoadingSpinner className='center vh-50' />
          ) : (
            <>
              {pagination}
              {hasGroupedPerm && (
                <div style={{ display: 'flex' }}>
                  <TabsCntnr>
                    <Tabs>
                      {tabsBtnGroupedViewOptions.map((btn, idx) => (
                        <BtnTabs
                          key={idx}
                          onClick={() => handleTabsViewChange(btn.option)}
                          className={`tabButton${groupedView === btn.option ? ' active' : ''}`}
                        >
                          {btn.text}
                        </BtnTabs>
                      ))}
                    </Tabs>
                  </TabsCntnr>
                  {groupedView && (
                    <TabsCntnr>
                      <Tabs>
                        {tabsBtnGroupedViewChoiceOptions.map((btn, idx) => (
                          <BtnTabs
                            key={idx}
                            onClick={() => handleTabsChoicesChange(btn.option)}
                            className={`tabButton${tabsChoices.includes(btn.option) ? ' active' : ''}`}
                          >
                            {btn.text} ({btn.length})
                          </BtnTabs>
                        ))}
                      </Tabs>
                    </TabsCntnr>
                  )}
                </div>
              )}
              <List
                bilans={bilansList}
                listingIdPerm='web:interventions_active:listing:identite'
                showPriority={hasPerm(currentUser, 'web:intervention:priorite')}
                visualIndicator={hasPerm(currentUser, 'web:interventions:visualIndicator')}
                groupedView={groupedView}
                groupedViewChoices={tabsChoices}
              />
              {pagination}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Active;
