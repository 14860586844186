import React from 'react';
import type { UserSyopeType } from './types/syope';

const CurrentUserContext = React.createContext<{
  currentUser: UserSyopeType | null;
  setUser: (value: UserSyopeType | null) => void;
}>({
  currentUser: null,
  setUser: () => {},
});

export default CurrentUserContext;
