import React from "react";
import styled from "styled-components";

import CHARTS from "../charts";
import PageHeader, { Actions, Title } from "../../ui/PageHeader";
import NarrowContainer from "../../ui/NarrowContainer";
import DynamicChart from "../DynamicChart";
import { DateTime } from "luxon";
import Input from "../../ui/Input";

const ChartContainer = styled.div`
  height: 600px;
`;

export default function Show({ router, match }) {
  let intervalStart = DateTime.local().startOf("month");
  if (typeof match.location.query.intervalStart !== "undefined") {
    intervalStart = DateTime.fromISO(match.location.query.intervalStart);
  }
  let intervalEnd = DateTime.local().endOf("month");
  if (typeof match.location.query.intervalEnd !== "undefined") {
    intervalEnd = DateTime.fromISO(match.location.query.intervalEnd);
  }
  const chart = CHARTS.find(c => c.id === match.params.chartId);
  const handleIntervalChange = boundName => ev => {
    if (ev.target.value == null || ev.target.value === "") {
      return;
    }
    router.push({
      pathname: `/statistiques/g/${encodeURIComponent(chart.id)}`,
      query: {
        ...match.location.query,
        [boundName]: ev.target.value
      }
    });
  };

  return (
    <>
      <PageHeader>
        <Title>{chart.title}</Title>
        <Actions>
          <span style={{ padding: ".5rem" }}>De</span>
          <Input
            style={{ width: 200 }}
            type="date"
            value={intervalStart.toISODate()}
            onChange={handleIntervalChange("intervalStart")}
          />
          <span style={{ padding: ".5rem" }}>à</span>
          <Input
            style={{ width: 200 }}
            type="date"
            value={intervalEnd.toISODate()}
            onChange={handleIntervalChange("intervalEnd")}
          />
        </Actions>
      </PageHeader>
      <NarrowContainer>
        <ChartContainer>
          <DynamicChart
            config={chart}
            intervalStart={intervalStart}
            intervalEnd={intervalEnd}
          />
        </ChartContainer>
      </NarrowContainer>
    </>
  );
}
