import { DateTime } from 'luxon';

export class MobilisationClass {
  // // Le numéro identifiant l'intervention de manière unique.
	// serial?: string;
	// // Le motif de l'intervention
	// nature?: string;
	// // L'emplacement où se déroule l'intervention
	// location_address?: string;
	// // Observations sur l'emplacement où se déroule l'intervention
	// location_address_details?: string;
	// // La commune / ville dans laquelle se déroule l'intervention
	// location_city_name?: string;
	// // L'heure de déclenchement de l'intervention, au format ISO 8601
	// mobilised_at?: string;
	// // La liste des équipes / véhicules déclenchés sur cette intervention
	// dispatches: Array<MobilisationDispatchClass>;
	// // Liste non-exhaustive de victimes ayant pu être dressée lors de l'alerte
	// victims: Array<MobilisationVictimClass>;

  constructor (withAceso) {
		this.serial = `${withAceso ? 'ACE-' : ''}${DateTime.now().toFormat('yyyyMMddHHmmss')}`;
		this.nature = undefined;
		this.location_address = undefined;
		this.location_address_details = undefined;
		this.location_city_name = undefined;
		this.mobilised_at = undefined;
		this.dispatches = [];
		this.victims = [];
	}
}