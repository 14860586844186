import React, { useMemo } from 'react';
import { FrontV2Head, FrontV2HeadMouth } from './vectorV2/VectorV2';

import type { SyoLib } from '@nfsave/syo-bilan';

type MannequinPartVectorProps = {
  syoLesionnel: SyoLib.Lesionnel;
  position: SyoLib.LesionnelPosition;
  side: SyoLib.LesionnelSideHead;
};

export function MannequinHeadVector({ syoLesionnel, position, side }: MannequinPartVectorProps) {
  return useMemo(() => {
    if (position === 'front') {
      if (syoLesionnel.lesionnelAvantTeteV2 === null) return <span>Une erreur est survenue</span>;
      if (side === 'head') return <FrontV2Head lesionnel={syoLesionnel.lesionnelAvantTeteV2} />;
      if (side === 'mouth') return <FrontV2HeadMouth lesionnel={syoLesionnel.lesionnelAvantTeteV2} />;
    }
    return <></>;
  }, [syoLesionnel, position, side]);
}
