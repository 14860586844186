import styled from "styled-components";
import theme from "./theme";

export const BtnTabs = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${theme.grey6};
  color: ${theme.grey2};
  padding: ${theme.thin};
  &.active {
    background-color: ${theme.blue};
    color: ${theme.backgroundColor};
  }
  &.icon-right svg {
    margin-left: ${theme.thin};
  }
  svg {
    &.icon-left {
      margin-right: ${theme.thin};
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  border-radius: 0.25rem;
  width: fit-content;

  @media (max-width: ${props => props.breakpoint ? props.breakpoint : "680px"}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const TabsCntnr = styled.div`
  margin: ${theme.small};
`;