import React from "react";
import styled from "styled-components";
import theme from "./theme";

import { Disclosure as DisclosureHeadless } from "@headlessui/react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const DisclosureCntnr = styled.div`
  margin-bottom: ${theme.small};
  border-left: ${theme.blueBilan} 3px solid;
  border-bottom: ${theme.blueBilan} 3px solid;

  .disclosure {
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: ${theme.blueBilan};
      border: none;
      padding: ${theme.thin};
      text-transform: uppercase;
      width: 100%;
      > * {
        margin-bottom: 0px;
      }
      &-icon {
        color: ${theme.text};
        transition: transform 0.3s ease-in-out;
        &.close {
          transform: rotate(-180deg);
        }
      }
    }
    &-content {
      margin-top: ${theme.small};

      span.light {
        font-size: 0.9rem;
        font-style: italic;
      }
    }
  }
`;

export function Disclosure ({title, children, defaultOpen = true, StyleCntnr = null}) {
  return (
      <>
          {StyleCntnr === null ? (
              <DisclosureCntnr className="disclosure">
                  <DisclosureHeadless defaultOpen={defaultOpen}>
                      {({open}) => (
                          <>
                              <DisclosureHeadless.Button className="disclosure-title">
                                  <h4>{title}</h4>
                                  <ExpandLessIcon className={`disclosure-title-icon ${open ? "open" : "close"}`} />
                              </DisclosureHeadless.Button>
                              <DisclosureHeadless.Panel className="disclosure-content">
                                  {children}
                              </DisclosureHeadless.Panel>
                          </>
                      )}
                  </DisclosureHeadless>
              </DisclosureCntnr>
          ) :(
              <StyleCntnr className="disclosure">
                  <DisclosureHeadless defaultOpen={defaultOpen}>
                      {({open}) => (
                          <>
                              <DisclosureHeadless.Button className="disclosure-title">
                                  <h4>{title}</h4>
                                  <ExpandLessIcon className={`disclosure-title-icon ${open ? "open" : "close"}`} />
                              </DisclosureHeadless.Button>
                              <DisclosureHeadless.Panel className="disclosure-content">
                                  {children}
                              </DisclosureHeadless.Panel>
                          </>
                      )}
                  </DisclosureHeadless>
              </StyleCntnr>
          )}
      </>
  );
};