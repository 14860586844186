import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

const List = styled.ul`
  padding: 0;
`;

export const ListItem = styled.li`
  list-style: none;
`;

export default function PermissionsList({ permissions = [] }) {
  return <List>
    {permissions.map(permission => <ListItem key={permission.id}>
      <CheckIcon fontSize="small" style={{'marginTop': -4, 'marginRight': 4}} />
      {permission.name || permission.key}
    </ListItem>)}
  </List>
}
