import React, { useMemo } from 'react';
import { BackV2FootLeft, BackV2FootRight, FrontV2FootLeft, FrontV2FootRight } from './vectorV2/VectorV2';

import type { SyoLib } from '@nfsave/syo-bilan';

type MannequinPartVectorProps = {
  syoLesionnel: SyoLib.Lesionnel;
  position: SyoLib.LesionnelPosition;
  side: SyoLib.LesionnelSide;
};

export const MannequinFootsVector = ({ syoLesionnel, position, side }: MannequinPartVectorProps) => {
  return useMemo(() => {
    if (side === 'right') {
      if (syoLesionnel.lesionnelPiedDroitV2 === null) return <span>Une erreur est survenue</span>;
      if (position === 'front') return <FrontV2FootRight lesionnel={syoLesionnel.lesionnelPiedDroitV2} />;
      if (position === 'back') return <BackV2FootRight lesionnel={syoLesionnel.lesionnelPiedDroitV2} />;
    } else if (side === 'left') {
      if (syoLesionnel.lesionnelPiedGaucheV2 === null) return <span>Une erreur est survenue</span>;
      if (position === 'front') return <FrontV2FootLeft lesionnel={syoLesionnel.lesionnelPiedGaucheV2} />;
      if (position === 'back') return <BackV2FootLeft lesionnel={syoLesionnel.lesionnelPiedGaucheV2} />;
    }
    return <></>;
  }, [syoLesionnel, position, side]);
};
