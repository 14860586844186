import React, { useContext, useState } from 'react';

import styled from 'styled-components';
import PageHeader, { Title } from '../../ui/PageHeader';
import Button from '../../ui/Button';
import theme from '../../ui/theme';
import { useQuery } from '@tanstack/react-query';
import http from '../../http';
import { isAbortError } from '../../utils';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import Label from '../../ui/Label';
import Input, { InputIcon, InputIconCntnr } from '../../ui/Input';
import Select from '../../ui/Select';
import LoaderBarContext from '../../ui/useLoaderBar';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 3rem;
`;

const InjectionCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .injection_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

const CustomButtonBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;

  button {
    max-height: unset !important;
  }
`;

const PosologyInputs = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 45% 45%;
  gap: 10%;
`;

const Table = styled.div`
  padding-left: 3rem;
  border-left: 2px dashed #dce8ff;

  table {
    width: 100%;

    thead {
      margin-bottom: 0.5rem;
    }

    .clear-icon {
      cursor: pointer;
      color: ${theme.red};
    }

    tr {
      border-bottom: 2px solid ${theme.blueBilan};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const Red = styled.td`
  color: ${theme.red};
`;

const Orange = styled.td`
  color: ${theme.orange};
`;

const ButtonBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;

  button {
    max-height: unset;
  }
`;

const PosRouteCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .pos_route_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

export default function CreateOrUpdateVitalSignBound({ match, router }) {
  const controller = new AbortController();
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [limitMin, setLimitMin] = useState(null);
  const [boundMin, setBoundMin] = useState(null);
  const [boundMax, setBoundMax] = useState(null);
  const [limitMax, setLimitMax] = useState(null);
  const [ageRangesOpts, setAgeRangesOpts] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);

  const [tempBounds, setTempBounds] = useState([]);

  let vitalSign = null;
  let unit = null;
  if (typeof match.location.query.vital_sign !== 'undefined') {
    vitalSign = match.location.query.vital_sign;
  }
  if (typeof match.location.query.unit !== 'undefined') {
    unit = match.location.query.unit;
  }

  const { data: vitalSignBouds } = useQuery(
    ['vitalSignBouds'],
    async () => {
      return await http
        .get(`vital_signs_bounds/edit.json`, {
          signal: controller.signal,
          searchParams: {
            vital_sign: vitalSign,
            unit: unit,
          },
        })
        .json()
        .then(res => {
          setTempBounds(res.vital_sign_bounds);
          setAgeRangesOpts(
            getAgeRanges().filter(ar => !res.vital_sign_bounds.map(it => it.age_range).includes(ar.value))
          );
          return res.vital_sign_bounds;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des bornes');
          throw error;
        });
    },
    { cacheTime: 0, enabled: vitalSign !== null && unit !== null }
  );

  function getVitalSignLabel(vitalSign) {
    switch (vitalSign) {
      case 'FR':
        return 'Fréquence respiratoire';
      case 'FC':
        return 'Fréquence cardiaque';
      case 'GLY':
        return 'Glycémie';
      case 'TAS':
        return 'Tension artérielle systolique';
      case 'TAD':
        return 'Tension artérielle diastolique';
      case 'TEMP':
        return 'Température';
      case 'SPO2AA':
        return 'Saturation en oxygène en air ambiant';
      case 'SPO2O2':
        return 'Saturation en oxygène sous O2';
      case 'SPCO':
        return 'Carboxyhémoglobine (SPCO)';
      case 'HEMO':
        return 'Hémoglobine';
      case 'HBCO':
        return 'Concentration HbCO';
      case 'ETCO2':
        return 'ETCO2';
      case 'PAM':
        return 'Pression artérielle moyenne';
      case 'COCAP':
        return 'Taux de CO capillaire';
      case 'COAA':
        return 'Taux de CO air ambiant';
      case 'COEXP':
        return 'Taux de CO expiré';
      default:
        return '';
    }
  }

  function canVitalSignHadLimit(vitalSign, unit = null) {
    switch (vitalSign) {
      case 'FR':
        return true;
      case 'FC':
        return true;
      case 'GLY':
        return true;
      case 'TAS':
        return unit !== 'mmHg';
      case 'TAD':
        return unit !== 'mmHg';
      case 'TEMP':
        return true;
      case 'SPO2AA':
        return false;
      case 'SPO2O2':
        return false;
      case 'SPCO':
        return false;
      case 'HEMO':
        return true;
      case 'HBCO':
        return false;
      case 'ETCO2':
        return true;
      case 'PAM':
        return true;
      case 'COCAP':
        return false;
      case 'COAA':
        return true;
      case 'COEXP':
        return true;
      default:
        return '';
    }
  }

  function getAgeRangeLabel(age_range) {
    switch (age_range) {
      case 'NEWBORN':
        return 'Nouveau-né';
      case 'INFANT':
        return 'Nourrisson';
      case 'SMALL_CHILD':
        return 'Petit enfant';
      case 'BIG_CHILD':
        return 'Grand enfant';
      case 'ADULT':
        return 'Adulte';
      default:
        return '';
    }
  }

  function getAgeRanges() {
    return ['NEWBORN', 'INFANT', 'SMALL_CHILD', 'BIG_CHILD', 'ADULT'].map(it => {
      return {
        value: it,
        label: getAgeRangeLabel(it),
      };
    });
  }

  const clearBound = (bound, idx) => {
    tempBounds.splice(idx, 1);
    setTempBounds([...tempBounds]);
    setAgeRangesOpts([...getAgeRanges().filter(ar => !tempBounds.map(it => it.age_range).includes(ar.value))]);
  };

  const canNotAdd = () => {
    return (limitMin === null && limitMax === null && boundMin === null && boundMax === null) || ageRanges.length === 0;
  };

  const add = () => {
    ageRanges.forEach(ar => {
      tempBounds.push({
        vital_sign: vitalSign,
        age_range: ar.value,
        limit_min: limitMin ?? null,
        limit_max: limitMax ?? null,
        bound_min: boundMin ?? null,
        bound_max: boundMax ?? null,
        unit_name: unit,
        decimal: getDefaultDecimal(),
      });
    });
    setTempBounds([...tempBounds]);
    setAgeRangesOpts([...getAgeRanges().filter(ar => !tempBounds.map(it => it.age_range).includes(ar.value))]);
    setLimitMin('');
    setLimitMax('');
    setBoundMin('');
    setBoundMax('');
    setAgeRanges([]);
  };

  const canNotUpdate = () => {
    return tempBounds.length === 0;
  };

  const update = async () => {
    try {
      await http.post(`vital_signs_bounds.json`, {
        json: {
          vital_sign: vitalSign,
          unit: unit,
          vital_signs_bound: tempBounds,
        },
      });
      router.push('/gestionnaire/bounds/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de créer ou de modifier ces bornes. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const canNotDeleteAll = () => {
    return tempBounds.length === 0;
  };

  const deleteAll = async () => {
    try {
      await http.delete(`vital_signs_bounds/delete_all.json`, {
        json: {
          vital_sign: vitalSign,
          unit: unit,
        },
      });
      router.push('/gestionnaire/bounds/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de supprimer les bornes. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const getDefaultDecimal = () => {
    switch (vitalSign) {
      case 'FR':
        return unit === 'bpm' ? 0 : null;
      case 'FC':
        return unit === 'bpm' ? 0 : null;
      case 'GLY':
        return unit === 'g/L' ? 1 : 'mmol/L' ? 2 : 'mg/dL' ? 2 : null;
      case 'TAS':
        return unit === 'mmHg' ? 0 : null;
      case 'TAD':
        return unit === 'mmHg' ? 0 : null;
      case 'TEMP':
        return unit === '°C' ? 1 : null;
      case 'SPO2':
        return unit === '%' ? 0 : null;
      default:
        throw 'IllegalArgumentException: No label for `' + vitalSign + '`';
    }
  };

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        {
          <Title>
            Modifier les bornes de {getVitalSignLabel(vitalSign)} ({unit})
          </Title>
        }
      </PageHeader>

      <Content>
        {vitalSignBouds && (
          <>
            <PosRouteCard>
              <header>
                <h4>Limites / bornes</h4>
              </header>
              <div className={'pos_route_card_content'}>
                <PosologyInputs>
                  <div>
                    <Label htmlFor='bound-min-input'>Bound min</Label>
                    <InputIconCntnr>
                      <InputIcon className='grey'>{unit}</InputIcon>
                      <Input
                        id='bound-min-input'
                        type='number'
                        inputMode='numeric'
                        placeholder='Bound min'
                        value={boundMin}
                        onChange={ev => setBoundMin(ev.target.value)}
                      />
                    </InputIconCntnr>
                  </div>
                  <div>
                    <Label htmlFor='bound-max-input'>Bound max</Label>
                    <InputIconCntnr>
                      <InputIcon className='grey'>{unit}</InputIcon>
                      <Input
                        id='bound-max-input'
                        type='number'
                        inputMode='numeric'
                        placeholder='Bound max'
                        value={boundMax}
                        onChange={ev => setBoundMax(ev.target.value)}
                      />
                    </InputIconCntnr>
                  </div>
                </PosologyInputs>
                {canVitalSignHadLimit(vitalSign, unit) && (
                  <PosologyInputs>
                    <div>
                      <Label htmlFor='limit-min-input'>Limit min</Label>
                      <InputIconCntnr>
                        <InputIcon className='grey'>{unit}</InputIcon>
                        <Input
                          id='limit-min-input'
                          type='number'
                          inputMode='numeric'
                          placeholder='Limit min'
                          value={limitMin}
                          onChange={ev => setLimitMin(ev.target.value)}
                        />
                      </InputIconCntnr>
                    </div>
                    <div>
                      <Label htmlFor='limit-max-input'>Limit max</Label>
                      <InputIconCntnr>
                        <InputIcon className='grey'>{unit}</InputIcon>
                        <Input
                          id='limit-max-input'
                          type='number'
                          inputMode='numeric'
                          placeholder='Limit max'
                          value={limitMax}
                          onChange={ev => setLimitMax(ev.target.value)}
                        />
                      </InputIconCntnr>
                    </div>
                  </PosologyInputs>
                )}
                <div>
                  <Label htmlFor='age-range-input'>Sélectionnez une ou plusieurs voies tranches d'âges</Label>
                  <Select
                    id='age-range-input'
                    isSearchable={true}
                    isClearable={true}
                    isMulti={true}
                    options={ageRangesOpts}
                    value={ageRanges}
                    onChange={ev => setAgeRanges(ev.map(it => it))}
                    placeholder="Tranche(s) d'âges"
                  />
                </div>
                <Button disabled={canNotAdd()} onClick={() => add()}>
                  Ajouter
                </Button>
              </div>
            </PosRouteCard>
            <Table>
              <table>
                <thead>
                  <tr>
                    <th>Tranche d'âge</th>
                    <th>{canVitalSignHadLimit(vitalSign, unit) ? 'Limit min' : ''}</th>
                    <th>Bound min</th>
                    <th>Bound max</th>
                    <th>{canVitalSignHadLimit(vitalSign, unit) ? 'Limit max' : ''}</th>
                    <th></th>
                  </tr>
                </thead>
                {tempBounds?.map((bound, idx) => (
                  <tr key={idx}>
                    <td>{getAgeRangeLabel(bound.age_range)}</td>
                    <Red>
                      {canVitalSignHadLimit(vitalSign, unit)
                        ? bound.limit_min
                          ? `${bound.limit_min} ${bound.unit_name}`
                          : '-'
                        : ''}
                    </Red>
                    <Orange>{bound.bound_min ? `${bound.bound_min} ${bound.unit_name}` : '-'}</Orange>
                    <Orange>{bound.bound_max ? `${bound.bound_max} ${bound.unit_name}` : '-'}</Orange>
                    <Red>
                      {canVitalSignHadLimit(vitalSign, unit)
                        ? bound.limit_max
                          ? `${bound.limit_max} ${bound.unit_name}`
                          : '-'
                        : ''}
                    </Red>
                    <td>
                      <div className={'clear-icon'}>
                        <ClearIcon className='warn' onClick={() => clearBound(idx, idx)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </Table>
          </>
        )}

        <ButtonBar>
          <Button
            style={{ 'margin-top': '2rem' }}
            className='warn'
            disabled={canNotDeleteAll()}
            onClick={() => deleteAll()}
          >
            Supprimer toutes les bornes
          </Button>
          <Button style={{ 'margin-top': '2rem' }} disabled={canNotUpdate()} onClick={() => update()}>
            Appliquer les modifications
          </Button>
        </ButtonBar>
      </Content>
    </>
  );
}
