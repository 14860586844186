import React, { useEffect, useMemo, useState } from 'react';
import http from '../http';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import theme from '../ui/theme';

import Button, { BlockButton } from '../ui/Button';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import { ActionsBtnDialog, Dialog } from '../ui/Modal';
import FormGroup from '../ui/FormGroup';
import Label from '../ui/Label';
import Input from '../ui/Input';
import Steps from '../ui/Steps';
import LoadingSpinner from '../ui/LoadingSpinner';
import Select from '../ui/Select';
import { isAbortError } from '../utils';

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  & + & {
    border-top: 1px solid ${theme.grey5};
  }

  & > div {
    padding: ${theme.thin} 0;
  }
`;

const Icon = styled.div`
  width: min-content;
  margin: auto;
  margin-bottom: ${theme.thin};
  color: ${theme.red};
`;

const Confirm = styled.div`
  margin: auto;
  font-family: 'JetBrains Mono', monospace;
  background-color: ${theme.blue};
  color: ${theme.backgroundColor};
  padding: 0 ${theme.thin};
  border-radius: ${theme.borderRadius};
  width: max-content;
`;

function ListOrga({ list, title }) {
  const getDate = date => {
    return DateTime.fromISO(date).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
  };

  return (
    <List>
      {list.length > 0 && (
        <>
          <h4>{title}</h4>
          {list.map((item, idx) => (
            <ListItem key={idx}>
              <div>
                <div>
                  {item.organization.name} ({item.organization.departement})
                </div>
                <div>{getDate(item.created_at)}</div>
              </div>
            </ListItem>
          ))}
        </>
      )}
    </List>
  );
}

export default function OrganizationsList({ orgaList, interventionNum, addToastMsg }) {
  const controller = new AbortController();

  const { isLoading: regulationsIsLoading } = useQuery(['regulationsOrgas'], async () => {
    return await http
      .get(`regulations.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        let temp = res.map(r => {
          return {
            'value': r.organization_id,
            'nom': r.nom,
            'departement': r.departement,
          };
        });
        setOrgaOptions(temp);
        return;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        addToastMsg('regulationsOrgas', 'des organisations de régulation');
        Sentry.captureException(error);
        console.error(error);
        throw error;
      });
  });

  const [regulList, setRegulList] = useState([]);
  const [hopitalList, setHopitalList] = useState([]);
  const [promptList, setPromptList] = useState([]);

  const [addOrgaView, setAddOrgaView] = useState(false);
  const [orgaIsLoading, setOrgaIsLoading] = useState(false);

  const [orgaOptions, setOrgaOptions] = useState([]);
  const [orgaValue, setOrgaValue] = useState(null);
  const [confirmValue, setConfirmValue] = useState('');
  const [confirmSample, setConfirmSample] = useState('');

  useEffect(() => {
    // Ignore "Laissé sur place"
    orgaList = orgaList.filter(i => i.organization.id !== 3);
    setRegulList(orgaList.filter(r => r.kind === 'regulation'));
    setHopitalList(orgaList.filter(h => h.kind === 'centre_hospitalier'));
    setPromptList(orgaList.filter(p => p.kind === 'prompt'));
  }, [orgaList]);

  useEffect(() => {
    let sample = orgaValue?.nom || '';
    sample = sample.trim().replace(/\W+/g, '-').toLowerCase();
    setConfirmSample(sample);
  }, [orgaValue]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const toggleAddOrgaView = () => {
    setAddOrgaView(!addOrgaView);
    if (addOrgaView) {
      setOrgaValue(null);
      setConfirmValue('');
      setConfirmSample('');
    }
  };

  const handleOrgaChange = ev => {
    setOrgaValue(ev);
  };

  const handleConfirmChange = ev => {
    setConfirmValue(ev.target.value);
  };

  const saveNewOrga = async ev => {
    ev.preventDefault();
    setOrgaIsLoading(true);

    const payload = {
      organization_id: orgaValue.value,
    };

    try {
      const opts = { json: payload };
      await http.post(`interventions/${interventionNum}/transmissions.json`, opts);
      toast.success('La nouvelle organisation a été ajouté avec succès.');
      toggleAddOrgaView();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.warn("L'ajout d'une nouvelle organisation a échoué.");
    } finally {
      setOrgaIsLoading(false);
    }
  };

  const confirmIsValid = useMemo(() => {
    if (confirmValue === confirmSample && confirmValue.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [confirmValue, confirmSample]);

  const formIsDisabled = useMemo(() => {
    if (orgaValue !== null && confirmIsValid) {
      return false;
    } else {
      return true;
    }
  }, [orgaValue, confirmValue]);

  return (
    <>
      <ListOrga list={regulList} title='Régulation' />
      <ListOrga list={hopitalList} title='Centre hospitalier' />
      <ListOrga list={promptList} title='Terrain' />
      {regulationsIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <div>
          <BlockButton onClick={() => toggleAddOrgaView()} data-sentry-id='organizations-list-open-modal-add'>
            <AddIcon className='icon-left' />
            Ajouter une organisation
          </BlockButton>
          <Dialog
            isOpen={addOrgaView}
            toggleDialog={() => toggleAddOrgaView()}
            title='Ajouter une organisation'
            children={
              <div>
                <Icon>
                  <ErrorIcon />
                </Icon>
                <p>
                  Ajouter une organisation permettra, à l'organisation en question, d'accéder et de consulter ce bilan.
                  Une fois ajouté, il ne sera plus possible de retirer le droit d'accès de l'organisation au bilan.
                </p>
                <form onSubmit={saveNewOrga}>
                  <Steps step={1} />
                  <p>Veuillez sélectionner l'organisation que vous souhaitez ajouter au bilan&thinsp;:</p>
                  <FormGroup>
                    <Label>Organisation</Label>
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      options={orgaOptions}
                      value={orgaOptions.find(m => m.value === orgaValue?.value || null)}
                      getOptionLabel={option => `${option.nom} (${option.departement})`}
                      onChange={ev => handleOrgaChange(ev)}
                      placeholder=''
                      data-sentry-id='organizations-list-modal-orga'
                    />
                  </FormGroup>
                  <Steps step={2} />
                  <FormGroup>
                    <p>
                      {orgaValue !== null && orgaValue !== '' ? (
                        <>Afin de confirmer votre choix merci de recopier le texte suivant&thinsp;:</>
                      ) : (
                        <>Veuillez choisir une organisation à ajouter avant de confirmer votre choix.</>
                      )}
                    </p>
                    <Confirm>{confirmSample}</Confirm>
                    <Label>Confirmez votre choix</Label>
                    <Input
                      disabled={orgaValue === null}
                      value={confirmValue}
                      onChange={ev => handleConfirmChange(ev)}
                      data-sentry-id='organizations-list-modal-orga-confirm'
                    />
                  </FormGroup>
                  <hr />
                  <ActionsBtnDialog>
                    <Button
                      className='warn'
                      type='button'
                      onClick={() => toggleAddOrgaView()}
                      data-sentry-id='organizations-list-modal-orga-cancel'
                    >
                      Annuler
                    </Button>
                    <Button
                      type='submit'
                      disabled={formIsDisabled || orgaIsLoading}
                      data-sentry-id='organizations-list-modal-orga-save'
                    >
                      Ajouter l'organisation
                    </Button>
                  </ActionsBtnDialog>
                </form>
              </div>
            }
          />
        </div>
      )}
    </>
  );
}
