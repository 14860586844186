import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import theme from '../ui/theme';
import {Dialog} from '../ui/Modal';
import Label from '../ui/Label';
import Select from '../ui/Select';
import Button from '../ui/Button';
import Textarea from '../ui/Textarea';
import {useQuery} from '@tanstack/react-query';
import http from '../http';
import {isAbortError} from '../utils';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-toastify';
import LoaderBarContext from '../ui/useLoaderBar';
import Checkbox from '../ui/Checkbox';
import Input from '../ui/Input';
import FormGroup from '../ui/FormGroup';
import CurrentUserContext from '../CurrentUserContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import type {SyoApi, SyoLib} from '@nfsave/syo-bilan';
import {Prescriptions} from '@nfsave/syo-bilan';
import type {UserSyopeType} from '../types/syope';
import {getDateFromISO, getTimeFromISO} from '../utils/luxon';

const ListHeader = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListHeaderItem = styled.li`
  & + & {
    border-top: 1px solid ${theme.grey5};
  }

  & > div {
    padding: ${theme.thin} 0;
  }
`;

const ListPrescription = styled.ul`
  list-style-type: disc;
  padding-top: 10px;
`;

const ListPrescriptionItem = styled.li`
  padding-bottom: 5px;

  & > div {
    padding-left: 20px;
    display: grid;
    grid-template-columns: 65% 30%;
  }
`;

const PseudoLink = styled.span`
  color: #4786ff;
  cursor: pointer;

  &:hover {
    color: #444444;
    text-decoration: underline;
  }
`;

const State = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Ended = styled.div`
  color: ${theme.green};
`;

const Error = styled.div`
  color: ${theme.red};
`;

const InProgress = styled.div`
  color: ${theme.orange};
`;

const Grey = styled.div`
  color: grey;
`;

const Break = styled.div`
  line-break: anywhere;
`;

const PrescriptionDetailContent = styled.div`
  display: block;
`;

const PrescriptionInfo = styled.div`
  display: grid;
  grid-template-columns: 55% 5% 40%;
  position: relative;
`;

const VDivider = styled.div`
  width: 1px;
  background-color: #333333;
`;

const SelfCtr = styled.div`
  justify-self: center;
  align-self: center;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItalicRed = styled.span`
  color: ${theme.red};

  &:hover {
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
  }
`;

type Props = {
    bilan: SyoApi.Bilan;
};

type VehiclesType = {
    label: string;
    value: string;
    name: string;
};

type PrescriptionSchemaType = {
    value: string;
    label: string;
    payload: SyoApi.PrescriptionPayload;
    prescription_type: SyoApi.PrescriptionType;
};

export default function PrescriptionBilan({bilan}: Props) {
    const controller = new AbortController();
    const {currentUser, setUser} = useContext(CurrentUserContext);
    const {loaderBarState, setLoaderBar} = useContext(LoaderBarContext);

    const [prescriptionDetailShow, setPrescriptionDetail] = useState(false);
    const [rppsDialogShow, setRppsDialogShow] = useState(false);
    const [currentPrescription, setCurrentPrescription] = useState<SyoLib.Prescription | null>(null);
    const [currentHeader, setCurrentHeader] = useState<SyoLib.PrescriptionHeader | null>(null);
    const [prescriptionSchema, setPrescriptionSchema] = useState<PrescriptionSchemaType | null>(null);
    const [vehicle, setVehicle] = useState<VehiclesType | null>(null);
    const [commentaire, setCommentaire] = useState('');
    const [check, setCheck] = useState(false);
    const [numeroRpps, setNumeroRpps] = useState('');
    const [rppsIsLoading, setRppsIsLoading] = useState(false);
    const [displayNewPrescription, setDisplayNewPrescription] = useState(bilan.en_cours === 'no');

    const {isLoading: prescriptionsSchemaIsLoading, data: prescriptionsSchema} = useQuery(
        ['prescriptionsChooseList'],
        async (): Promise<PrescriptionSchemaType[]> => {
            if (!currentUser) return [];
            try {
                const res = await http
                    .get(`organizations/${encodeURIComponent(currentUser.organization_id)}/prescription_schema.json`, {
                        signal: controller.signal,
                    })
                    .json<SyoApi.GetPrescriptionsSchemaResponse>();
                res.schemas.forEach(it => (it.payloadParse = JSON.parse(it.payload)));
                return res.schemas.map(it => {
                    let value: string
                    if (it.prescription_type === "GESTURE") {
                        value = it.payloadParse.geste;
                    } else {
                        value = it.payloadParse.dosage
                            ? `${it.payloadParse.label} (${it.payloadParse.dosage} ${it.payloadParse.unit} ${it.payloadParse.route})`
                            : `${it.payloadParse.label} (${it.payloadParse.route})`;
                    }
                    return {
                        value: value,
                        label: value,
                        payload: it.payloadParse,
                        prescription_type: it.prescription_type,
                    };
                });
            } catch (error) {
                if (isAbortError(error)) return [];
                console.error(error);
                Sentry.captureException(error);
                toast.warn('Une erreur est survenue lors de la récupération de la liste des schémas de prescriptions');
                throw error;
            }
        },
        {
            cacheTime: 0,
            enabled: currentUser !== null,
        }
    );

    const {
        isLoading: prescriptionsIsLoading,
        data: prescriptionsHeaders,
        refetch: refetchPrescriptions,
    } = useQuery(
        ['prescriptionsActiveList'],
        async () => {
            try {
                const res = await http
                    .get(`interventions/${encodeURIComponent(bilan.intervention_id ?? '')}/active_prescriptions.json`, {
                        signal: controller.signal,
                    })
                    .json<SyoApi.GetActivePrescriptionsResponse>();
                const temp: SyoLib.PrescriptionHeader[] = [];
                if (res.headers) {
                    res.headers.flatMap(it => it.prescriptions);
                    res.headers.forEach(e => temp.push(new Prescriptions(e, bilan)));
                    return temp;
                }
            } catch (error) {
                if (isAbortError(error)) return;
                console.error(error);
                Sentry.captureException(error);
                toast.warn('Une erreur est survenue lors de la récupération des prescriptions actives');
                throw error;
            }
        },
        {
            refetchInterval: 1000,
            cacheTime: 0,
            enabled: bilan !== null && bilan !== undefined,
        }
    );

    const setPrescriptionDetailShow = (p: SyoLib.Prescription | null, h: SyoLib.PrescriptionHeader | null) => {
        if (!prescriptionDetailShow) {
            setCurrentHeader(h);
            setCurrentPrescription(p);
            if (p && h) {
                setPrescriptionDetail(!prescriptionDetailShow);
            }
        } else {
            setPrescriptionDetail(!prescriptionDetailShow);
        }
    };

    const isMed = (p: SyoLib.Prescription | null) => p?.prescription_type === 'MEDICATION';

    const stateColorHtml = (p: SyoLib.Prescription) => {
        switch (p?.last_prescription_status?.state) {
            case 'IN_PROGRESS':
                return <InProgress>{p.stateDisplay}</InProgress>;
            case 'REFUSED':
                return <Error>{p.stateDisplay}</Error>;
            case 'CANCELED':
                return <Error>{p.stateDisplay}</Error>;
            case 'COMPLETED':
                return <Ended>{p.stateDisplay}</Ended>;
            default:
                return <span>{p.stateDisplay}</span>;
        }
    };

    const vehiclesListFromBilan = useMemo(() => {
        const vehicles: VehiclesType[] = [];
        bilan?.vehicles?.forEach(it => {
            // @ts-ignore
            if (vehicles.findIndex(v => v.label === `${it.name} (${it.performer})`) === -1) {
                vehicles.push({
                    // @ts-ignore
                    label: `${it.name} (${it.performer})`,
                    // @ts-ignore
                    value: `${it.name} (${it.performer})`,
                    // @ts-ignore
                    name: it.name,
                });
            }
        });
        if (vehicles?.length === 1) setVehicle(vehicles[0])
        return vehicles;
    }, [bilan]);

    const lastStatusUserName = (p: SyoLib.Prescription | null) => {
        if (!p) return '';
        return (
            p.status.find((it: SyoApi.PrescriptionStatus) => it.id === p.last_prescription_status.id)?.user.full_name ?? ''
        );
    };

    const completeStateHtml = (h: SyoLib.PrescriptionHeader | null, p: SyoLib.Prescription | null) => {
        const time = getTimeFromISO(p?.last_prescription_status?.observed_at ?? '', 'TIME_24_SIMPLE');
        const date = getDateFromISO(p?.last_prescription_status?.observed_at ?? '', 'DATE_SHORT');
        const userName = lastStatusUserName(p);
        switch (p?.last_prescription_status?.state) {
            case 'IN_PROGRESS':
                return (
                    <InProgress>
                        Reçue de la part {h?.procuration ? `de ${h?.procuration_name}` : `du docteur ${userName}`} à {time} le {date}
                    </InProgress>
                );
            case 'REFUSED':
                return (
                    <Error>
                        Déclarée irréalisable par {userName} à {time} le {date}
                    </Error>
                );
            case 'CANCELED':
                return (
                    <Error>
                        Annulée par {userName} à {time} le {date}
                    </Error>
                );
            case 'COMPLETED':
                return (
                    <Ended>
                        {isMed(p) ? "Administrée" : "Réalisée"} par {userName} à {time} le {date}
                    </Ended>
                );
            default:
                return '';
        }
    };

    const disablePrescrire = useMemo(() => {
        return !vehicle || !prescriptionSchema || !check;
    }, [vehicle, prescriptionSchema, check]);

    const {refetch: refetchUser} = useQuery(
        ['user'],
        async () => {
            if (!currentUser) return;
            return await http
                .get(`user/me`, {
                    signal: controller.signal,
                })
                .json<UserSyopeType>()
                .then(res => {
                    return res;
                })
                .catch(error => {
                    if (isAbortError(error)) return;
                    console.error(error);
                    Sentry.captureException(error);
                    toast.warn("Une erreur est survenue lors de la récupération de l'utilisateur");
                    throw error;
                });
        },
        {
            cacheTime: 0,
        }
    );

    const handleSubmitRpps = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault();
        if (!currentUser) return;
        setLoaderBar(true);
        setRppsIsLoading(true);
        try {
            await http.post(`user/update_rpps.json`, {searchParams: {numero_rpps: numeroRpps}}).json();
            refetchUser().then(res => {
                if (!res.data) throw 'Prescription refetch empty';
                currentUser.numero_rpps = res.data.numero_rpps;
                setUser(currentUser);
                localStorage.removeItem('nfCurrentUser');
                localStorage.setItem('nfCurrentUser', JSON.stringify(currentUser));
                setLoaderBar(false);
            });
            setRppsDialogShow(false);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error(
                "Impossible d'ajouter ce numéro RPPS à votre utilisateur. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
            );
        } finally {
            setLoaderBar(false);
            setRppsIsLoading(false);
        }
    };

    const handleCanceled = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault();

        try {
            await http
                .post(
                    `interventions/${encodeURIComponent(bilan.intervention_id ?? '')}/prescriptions/${encodeURIComponent(currentPrescription?.id ?? '')}/edit_prescription_status.json`,
                    {searchParams: {state: 'CANCELED', comment: '', observed_at: new Date().toString()}}
                )
                .json();
            setPrescriptionDetailShow(null, null);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error(
                "Impossible d'annuler cette prescription. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
            );
        } finally {
            setLoaderBar(false);
        }
    };

    const handlePrescrire = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault();

        if (loaderBarState === true || !currentUser) return;
        setLoaderBar(true);
        try {
            await http
                .post(`interventions/${encodeURIComponent(bilan.intervention_id ?? '')}/create_prescription.json`, {
                    searchParams: {vehicle_target: vehicle?.name ?? '', numero_rpps: currentUser.numero_rpps ?? ''},
                })
                .json<{ id: string }>()
                .then(async res => {
                    await http
                        .post(
                            `interventions/${encodeURIComponent(bilan.intervention_id ?? '')}/prescription_headers/${encodeURIComponent(res.id)}/add_prescription_content.json`,
                            {
                                searchParams: {
                                    payload: JSON.stringify(prescriptionSchema?.payload),
                                    prescription_type: prescriptionSchema?.prescription_type ?? '',
                                    comment: commentaire ?? '',
                                },
                            }
                        )
                        .json()
                        .then(() => {
                            setDisplayNewPrescription(false)
                            setPrescriptionSchema(null)
                            setCommentaire('')
                            setCheck(false)
                            refetchPrescriptions()
                        });
                });
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error(
                "Impossible de créer cette prescription. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
            );
        } finally {
            setLoaderBar(false);
        }
    };

    const onCheckChange = () => {
        setCheck(!check);
    };

    return (
        <div style={{padding: '1rem'}}>
            <h3>Prescriptions</h3>
            <hr/>
            <ListHeader>
                {!prescriptionsIsLoading ? (
                    prescriptionsHeaders?.map((header, idx) => (
                        <ListHeaderItem key={idx}>
                            <State>
                                N° {prescriptionsHeaders?.length - idx} |
                                {header.procuration ? ` ${header.procuration_name}` : ` Docteur ${header.user.full_name}`}
                            </State>
                            <ListPrescription>
                                {header.prescriptions.map((prescription, pIdx) => (
                                    <ListPrescriptionItem key={pIdx}>
                                        <State>
                                            <PseudoLink onClick={() => setPrescriptionDetailShow(prescription, header)}>
                                                {prescription.label}
                                            </PseudoLink>
                                            {stateColorHtml(prescription)}
                                        </State>
                                        <Grey>{prescription.commentDisplay}</Grey>
                                    </ListPrescriptionItem>
                                ))}
                            </ListPrescription>
                        </ListHeaderItem>
                    ))
                ) : (
                    <LoadingSpinner className='center vh-50'/>
                )}
                <Dialog
                    isOpen={prescriptionDetailShow}
                    toggleDialog={() => setPrescriptionDetailShow(null, null)}
                    title={'Prescription de ' + (isMed(currentPrescription) ? currentPrescription?.payloadParse.label : currentPrescription?.payloadParse.geste)}
                    children={
                        <PrescriptionDetailContent>
                            <Break>
                                <br/>
                                {currentHeader?.shared_at ? (
                                    <Grey>
                                        {
                                            (!currentHeader?.procuration ? "Envoyée par " : "Créée sur les lieux de l'intervention à l'initiative de ") +
                                            (!currentHeader?.procuration ? currentHeader?.user?.full_name : currentHeader?.procuration_name) +
                                            ' au ' +
                                            currentHeader?.vehicle_target +
                                            ' à ' +
                                            getTimeFromISO(currentHeader.shared_at, 'TIME_24_SIMPLE') +
                                            ' le ' +
                                            getDateFromISO(currentHeader.shared_at, 'DATE_SHORT')}
                                    </Grey>
                                ) : (
                                    <Grey>{bilan.en_cours !== 'no' ? "Envoi en cours..." : "Non approuvée en intervention"}</Grey>
                                )}
                                <br/>
                                <span>{completeStateHtml(currentHeader, currentPrescription)}</span>
                                <br/>
                                <br/>
                                <PrescriptionInfo>
                                    <SelfCtr>{currentPrescription?.commentDisplay}</SelfCtr>
                                    <VDivider></VDivider>
                                    {isMed(currentPrescription) ? (
                                        <FlexCol>
                                            <span>{currentPrescription?.payloadParse.label}</span>
                                            <span>{currentPrescription?.payloadParse.dosage} {currentPrescription?.payloadParse.unit}</span>
                                            <span>{currentPrescription?.payloadParse.route}</span>
                                        </FlexCol>
                                    ) : (
                                        <FlexCol>
                                            <span>{currentPrescription?.payloadParse.geste}</span>
                                            <span>{currentPrescription?.payloadParse.description}</span>
                                        </FlexCol>
                                    )}
                                </PrescriptionInfo>
                                <br/>
                                {bilan.en_cours !== 'no' &&
                                currentPrescription?.last_prescription_status?.state !== 'CANCELED' &&
                                currentPrescription?.last_prescription_status?.state !== 'COMPLETED' &&
                                currentPrescription?.last_prescription_status?.state !== 'REFUSED' ? (
                                    <div style={{display: 'flex', justifyContent: 'end'}}>
                                        <Button
                                            className='warn'
                                            style={{marginBottom: '.5rem', marginTop: '.5rem'}}
                                            onClick={ev => handleCanceled(ev)}
                                            type='submit'>Annuler la prescription</Button>
                                    </div>
                                ) : ('')}
                            </Break>
                        </PrescriptionDetailContent>
                    }
                />
            </ListHeader>
            {!displayNewPrescription && <Button
                style={{width: '100%', marginTop: '1rem'}}
                onClick={() => setDisplayNewPrescription(!displayNewPrescription)}
                type='submit'>Nouvelle prescription</Button>
            }
            {bilan?.en_cours !== 'no' && displayNewPrescription && (
                <>
                    {!prescriptionsSchemaIsLoading || !rppsIsLoading ? (
                        <>
                            <br/>
                            <br/>
                            <h3>Créer une prescription</h3>
                            <hr/>
                            <Label htmlFor='prescription_select'>Sélectionnez une prescription</Label>
                            <Select
                                id={'prescription_select'}
                                placeholder={'Sélectionnez une prescription'}
                                options={prescriptionsSchema}
                                value={prescriptionSchema}
                                onChange={(obj: PrescriptionSchemaType) => setPrescriptionSchema(obj)}
                            />
                            <br/>

                            {vehiclesListFromBilan?.length > 1 ? <>
                                <Label htmlFor='vehicle_select'>Sélectionnez un véhicule</Label>
                                {vehiclesListFromBilan?.map((v) => <Button
                                    className={vehicle?.value === v.value ? "like-disabled validated" : "like-disabled"}
                                    style={{width: '100%', marginTop: '0.25rem', marginBottom: '0.25rem'}}
                                    onClick={() => setVehicle(v)}
                                    type='submit'>{v.label}</Button>
                                )}
                            </> : (<span>Véhicule ciblé : <strong>{vehicle?.label}</strong></span>)}
                            <br/>
                            <br/>
                            <Label htmlFor='prescription_commentaire'>Commentaire</Label>
                            <Textarea id='prescription_commentaire' rows={5}
                                      onChange={ev => setCommentaire(ev.target.value)}/>
                            <br/>

                            {currentUser?.numero_rpps ? (
                                <>
                                    {rppsIsLoading ? (<LoadingSpinner className='center'/>) : (
                                        <>
                                            <Checkbox
                                                checked={check}
                                                onChange={onCheckChange}
                                                disabled={false}
                                                label={`Je certifie, ${currentUser.full_name}, que le n° RPPS "${currentUser.numero_rpps}" est bien le mien.\nPour information, cette donnée sera transmise aux services juridique de votre organisation en cas de recours légal.`}/>
                                            <ItalicRed style={{fontSize: '0.8rem'}}
                                                       onClick={() => setRppsDialogShow(true)}>
                                                Ce numéro RPPS n'est pas le mien
                                            </ItalicRed>
                                        </>
                                    )}
                                </>
                            ) : (
                                <ItalicRed onClick={() => setRppsDialogShow(true)}>
                                    Aucun n° RPPS renseigné, veuillez le renseigné ici
                                </ItalicRed>
                            )}

                            <br/>
                            <br/>
                            <span>Compte utilisé :{' '}
                                <strong>{currentUser?.full_name} ({currentUser?.organization.name})</strong>
                            </span>
                            <br/>
                            <br/>
                            <div style={{display: 'flex', gap: '1rem', justifyContent: 'space-between'}}>
                                <Button
                                    className={'warn'}
                                    style={{marginBottom: '.5rem', marginTop: '.5rem', width: '100%'}}
                                    onClick={() => setDisplayNewPrescription(!displayNewPrescription)}
                                    type='submit'>Annuler</Button>
                                <Button
                                    style={{marginBottom: '.5rem', marginTop: '.5rem', width: '100%'}}
                                    onClick={ev => handlePrescrire(ev)}
                                    disabled={disablePrescrire}
                                    type='submit'>Prescrire</Button>
                            </div>
                        </>
                    ) : (<LoadingSpinner className='center vh-50'/>)}
                </>
            )}
            <Dialog
                isOpen={rppsDialogShow}
                toggleDialog={() => setRppsDialogShow(!rppsDialogShow)}
                title={'Saisie de votre numéro RPPS'}
                children={
                    <PrescriptionDetailContent>
                        <Break>
                            <br/>
                            <FormGroup>
                                <Label htmlFor='user-matricule-input'>N° RPPS (Médecin uniquement)</Label>
                                <Input id='user-rpps-input' value={numeroRpps}
                                       onChange={ev => setNumeroRpps(ev.target.value)}/>
                            </FormGroup>
                            <div style={{display: 'flex', justifyContent: 'end'}}>
                                <Button
                                    style={{marginBottom: '.5rem', marginTop: '.5rem'}}
                                    onClick={ev => handleSubmitRpps(ev)}
                                    disabled={numeroRpps === null || numeroRpps.length < 1}
                                    type='submit'>Enregistrer mon numéro</Button>
                            </div>
                        </Break>
                    </PrescriptionDetailContent>
                }
            />
        </div>
    );
}
