import React, {useContext} from "react";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import EstablishmentCardsCompo from "../../components/EstablishmentCards";
import CurrentUserContext from "../../CurrentUserContext";

const EstablishmentCards = () => {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      <PageHeader>
        <Title>Cartes d'établissement</Title>
      </PageHeader>

      <Cntnr>
        <EstablishmentCardsCompo user={currentUser}/>
      </Cntnr>
    </>
  );
};

export default EstablishmentCards;