import { Route, Redirect } from 'found';
import React from 'react';
import { hasLegacyPerm } from '../utils';

import Active from './screens/Active';
import Archive from './screens/Archive';
import PharmacyCompliance from './screens/PharmacyCompliance';
import ShowPuiBilan from './screens/ShowPuiBilan';
import ShowBilan from './screens/ShowBilan';
import Trigger from './screens/Trigger';
import Mobilizations from './screens/Mobilizations';
import Abandonment from './screens/Abandonment';
import MobilizationTrigger from './screens/MobilizationTrigger';
import ShowSsoSmp from './screens/ShowSsoSmp';
import SsoSmp from './screens/SsoSmp';
import SsoSmpArchive from './screens/SsoSmpArchive';
import DocViewerPage from './screens/DocViewerPage';

export const routes = (
  <Route path='interventions'>
    <Redirect from='/' to='/interventions/active' />
    <Route path='active' Component={Active} />
    <Route
      path='archive'
      Component={Archive}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:archived')) data.router.replace('/droits-insuffisant');
      }}
    />
    <Route path=':interventionId/bilans/:bilanId' Component={ShowBilan} />
    <Route path=':interventionId/bilans/:bilanId/documents/:docId' Component={DocViewerPage} />

    {/* SSO SMP */}
    <Route
      path='sso-smp'
      Component={SsoSmp}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:sso-smp')) data.router.replace('/droits-insuffisant');
      }}
    />
    <Route
      path='sso-smp-archive'
      Component={SsoSmpArchive}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:sso-smp:archived')) data.router.replace('/droits-insuffisant');
      }}
    />
    <Route
      path='sso-smp/:interventionId/bilans/:bilanId'
      Component={ShowSsoSmp}
      getData={data => {
        if (
          !hasLegacyPerm(data.context, 'interventions:sso-smp') &&
          !hasLegacyPerm(data.context, 'interventions:sso-smp:archived')
        )
          data.router.replace('/droits-insuffisant');
      }}
    />

    {/* PUI */}
    <Route
      path='pui'
      Component={PharmacyCompliance}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:inactive_with_injections'))
          data.router.replace('/droits-insuffisant');
      }}
    />
    <Route
      path='pui/:interventionId/bilans/:bilanId'
      Component={ShowPuiBilan}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:inactive_with_injections'))
          data.router.replace('/droits-insuffisant');
      }}
    />

    {/* DÉCLENCHEMENT */}
    <Route
      path='declenchement'
      Component={Trigger}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:dispatch')) data.router.replace('/droits-insuffisant');
      }}
    />

    {/* MOBILISATION */}
    <Route
      path='mobilisations'
      getData={data => {
        if (!hasLegacyPerm(data.context, 'mobilisations:listing')) data.router.replace('/droits-insuffisant');
      }}
    >
      <Route path='/' Component={Mobilizations} />
      <Route path='/declenchement' Component={MobilizationTrigger} />
    </Route>

    {/* ABANDON */}
    <Route
      path='abandon'
      Component={Abandonment}
      getData={data => {
        if (!hasLegacyPerm(data.context, 'interventions:inactive_with_abandonment'))
          data.router.replace('/droits-insuffisant');
      }}
    />
  </Route>
);
