import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactSelect, { components, type ValueContainerProps } from 'react-select';
import ThemeModeContext from './useDarkMode';
import theme, { getSelectTheme } from './theme';

import type { DefaultTheme } from 'styled-components/dist/types';
import type { CSSObjectWithLabel, StylesConfig } from 'react-select';

function Select(props: any) {
  const { themeMode } = useContext(ThemeModeContext);
  const reactSelectTheme = getSelectTheme(themeMode);
  const customStyles = {
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      minHeight: 38,
      transition: 'background-color 0.3s ease-in-out',
    }),
  };

  return (
    <ReactSelect
      theme={(theme: DefaultTheme | undefined) => ({
        ...theme,
        colors: {
          ...reactSelectTheme,
        },
      })}
      styles={customStyles}
      {...props}
    />
  );
}

export function SelectHalfSize(props: any) {
  const { themeMode } = useContext(ThemeModeContext);
  const reactSelectTheme = getSelectTheme(themeMode);
  const customStyles: StylesConfig = {
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      minHeight: 38,
      transition: 'background-color 0.3s ease-in-out',
    }),
    input: () => ({
      width: '50%',
    }),
  };

  return (
    <ReactSelect
      theme={(theme: DefaultTheme | undefined) => ({
        ...theme,
        colors: {
          ...reactSelectTheme,
        },
      })}
      styles={customStyles}
      {...props}
    />
  );
}

const ItemCounter = styled.div`
  position: absolute;
  right: 0px;
  padding: 2px 10px;
  border-radius: 20px;
  background-color: ${theme.blueBilan};
`;

export const ValueContainerLimitSelected: ({ children, ...props }: ValueContainerProps<any>) => React.JSX.Element = ({
  children,
  ...props
}): React.JSX.Element => {
  if (!children || !Array.isArray(children)) return <></>;
  const hasValue = Array.isArray(children[0]);
  const temp: any[] = children[0] as any[];
  const firstValue = children[0] ? (temp[0] as any) : undefined;

  return (
    <components.ValueContainer
      {...props}
      children={
        <>
          {hasValue ? [firstValue, children[1]] : children}
          {hasValue && temp.length > 1 && (
            <ItemCounter
              title={temp
                .slice(1)
                .map((i: any) => i.key)
                .join(', ')}
            >
              + {temp.length - 1}
            </ItemCounter>
          )}
        </>
      }
    />
  );
};

export default Select;
