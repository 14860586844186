import React, { useState, useContext } from "react";
import * as Sentry from "@sentry/browser";
import LoaderBarContext from "../../ui/useLoaderBar";
import http from "../../http";
import {toast} from "react-toastify";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import Button from "../../ui/Button";
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";

export default function NewOrganizationalUnit({ router }) {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState === true) return;
    setLoaderBar(true);
    const payload = {
      organizational_unit: {name: name, short_name: shortName}
    };
    try {
      await http.post("organizational_units.json", {json: payload}).json();
      router.push("/gestionnaire/unites-organisationnelles/");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Impossible de créer l'unité organisationnelle. Vérifiez votre connexion internet ou contactez l'assistance Syopé.");
    } finally {
      setLoaderBar(false);
    };
  };

  return <>
    <PageHeader>
      <Title>Nouvelle unité organisationnelle</Title>
    </PageHeader>

    <Cntnr>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="unit-name-input">Nom</Label>
          <Input id="unit-name-input" value={name} onChange={ev => setName(ev.target.value)} disabled={loaderBarState}/>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="unit-short-name-input">Nom abrégé</Label>
          <Input id="unit-short-name-input" value={shortName} onChange={ev => setShortName(ev.target.value)}
                disabled={loaderBarState}/>
        </FormGroup>
        <Button type="submit" disabled={loaderBarState}>Ajouter</Button>
      </form>
    </Cntnr>
  </>;
}
