import styled from "styled-components";
import { Link } from "found";
import theme from "./theme";

const Button = styled.button`
  padding: ${theme.thin} ${theme.small};
  padding-top: 0.35rem;
  border-radius: 0.25rem;
  max-height: 38px;

  background-color: inherit;
  border: 2px solid ${theme.blue};
  color: ${theme.blue};

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: inset 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  }

  &.not-validated {
    font-weight: 700;
    border: 2px solid ${theme.orange};
    color: ${theme.orange};

    &.filled {
      background-color: ${theme.orange};
      border: 2px solid ${theme.orange};
      color: ${theme.white};
    }
  }

  &.validated {
    border: 2px solid ${theme.green};
    color: ${theme.green};

    &.filled {
      background-color: ${theme.green};
      border: 2px solid ${theme.green};
      color: ${theme.white};
    }
    
    &.like-disabled {
      border: 2px solid ${theme.blue};
      color: ${theme.blue};
      &.filled {
        background-color: ${theme.blue};
        border: 2px solid ${theme.blue};
        color: ${theme.white};
      }
    }
  }

  &.warn {
    border: 2px solid ${theme.red};
    color: ${theme.red};

    &.filled {
      background-color: ${theme.red};
      border: 2px solid ${theme.red};
      color: ${theme.white};
    }
  }
  
  &.like-disabled {
    border: 2px solid ${theme.grey3};
    color: ${theme.grey3};

    &.filled {
      background-color: ${theme.grey3};
      border: 2px solid ${theme.grey3};
      color: ${theme.white};
    }
  }

  &.filled {
    color: ${theme.backgroundColor};
    background: ${theme.blue};
    border: 2px solid ${theme.blue};
  }

  .icon-left {
    margin-right: 15px;
    position: relative;
    top: -2px;
  }

  &:disabled {
    cursor: not-allowed;
    border: 2px solid ${theme.grey3};
    color: ${theme.grey3};

    &.filled {
      background: ${theme.grey3};
      border: 2px solid ${theme.grey3};
      color: ${theme.white};
    }
  }
`;

export default Button;

export const BlockButton = styled(Button)`
	display: block;
	margin: 0 auto;
	width: 100%;
	margin-bottom: ${theme.thin};
`;

export const LinkButton = styled(Link)`
  padding: ${theme.thin} ${theme.small};
  padding-top: 0.35rem;
  border-radius: 0.25rem;
  max-height: 38px;
  background-color: inherit;
  border: 2px solid ${theme.blue};
  color: ${theme.blue};

  &.center {
    text-align: center;
  }

  &.warn {
    border: 2px solid ${theme.red};
    color: ${theme.red};

    :hover,
    :focus {
      color: ${theme.red};
      text-decoration: none;
    }

    &.filled {
      background-color: ${theme.red};
      border: 2px solid ${theme.red};
      color: ${theme.white};
    }
  }

  :hover {
    cursor: pointer;
  }

  :hover,
  :focus {
    color: ${theme.blue};
    text-decoration: none;
  }

  :active {
    box-shadow: inset 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  }

  &.filled {
    color: ${theme.backgroundColor};
    background: ${theme.blue};
    border: 2px solid ${theme.blue};
    :hover,
    :focus {
      color: ${theme.backgroundColor};
      text-decoration: none;
    }
  }
  
  .icon-left {
    margin-right: 15px;
    position: relative;
    top: -2px;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${theme.backgroundColor};
  color: ${theme.grey1};
  border: 2px solid ${theme.grey1};
  &:disabled {
    cursor: not-allowed;
    border: 2px solid ${theme.grey3};
    color: ${theme.grey3};

    &.filled {
      background: ${theme.grey3};
      border: 2px solid ${theme.grey3};
      color: ${theme.white};
    }
  }
`;

export const RoundedButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${theme.grey6};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 100%;
  padding: ${theme.thin};
  margin: 0px ${theme.thin};
    -webkit-transition: 0.3s;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
    > svg {
      color: ${theme.grey4};
    }
  }

  > svg {
    color: ${theme.grey2};
  }
`;
