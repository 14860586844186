import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";
import theme from "./theme";
import Button from "./Button";
import FilterListIcon from '@mui/icons-material/FilterList';

const SearchBarCntnr = styled.div`
  width: 100%;
  background-color: ${theme.grey6};
  z-index: 2;
  padding: ${theme.small} ${theme.small} 0px ${theme.small} ;
  margin-top: calc(${theme.small} - 8px);
  margin-bottom: ${props => !props.hasTag
    ? `${theme.small}`
    : `calc(50px + ${theme.small})`};
  transition: 0.3s ease-in-out;

  .btn-display-children {
    margin-bottom: ${theme.small};
  }
`;

export const SearchBarChildren = styled.div`
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  &.open {
    overflow: visible;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const FilterBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 32%;
  }

  > * {
    margin: 0 ${theme.small} ${theme.small} 0;
  }

  > button {
    height: 38px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: auto;
    }
  }

  @media (max-width: 680px) {
    > * {
      margin: 0 0 ${theme.small} 0;
    }

    > button {
      height: auto;
    }
  }
`;

const TopBarCntnr = styled.div`
  width: calc(100% - ${theme.menuWidth});
  position: fixed;
  top: 0px;
  z-index: 2;
  visibility: hidden;

  ${SearchBarChildren} {
    background-color: ${theme.grey6};

    .sbChildrenCntnr {
      padding: ${theme.small} ${theme.small} 0px ${theme.small};
    }
  }

  &.visible {
    visibility: visible;
  }

  @media (max-width: 800px) {
    width: 100%;
    max-height: 100vh;
    overflow: scroll;
  }
`;

const Actions = styled.div`
  display: flex;
  > button {
    height: 50px;
    border: none;
    border-radius: 0 0 5px 5px;
    margin-right: ${theme.small};
    margin-left: ${theme.small};
    padding: 0 ${theme.small};

    &.open {
      background-color: ${theme.grey6};
      color: ${theme.grey2};
    }
    &.close {
      background-color: ${theme.blue};
      color: ${theme.backgroundColor};
    }
  }
`;

const SearchBarCompo = ({children, hasTag = false, tags, canBeCollapsed = false, permEval = true}) => {
  /**
   * sbHeigth   {Number}  - Stores the height of the SearchBarCntnr component.
   * Passed as a parameter to the SearchBarCntnr component to automatically manage the height of the sticky position.
   * sbBottom   {Boolean} - Stores if the bottom of the SearchBarCntnr component is at the top of the browser.
   * Used to manage the state of the button in the ActionBar of the SearchBarCntnr component
   * sbIsOpen    {Boolean} - Stores if the SearchBarCntnr is open or not.
   * tbIsOpen    {Boolean} - Stores if the TopBarCntnr is open or not.
   */

  const [ dimensions, setDimensions ] = useState(null);
  const [ sbHeigth, setSbHeigth ] = useState(0);
  const [ sbBottom, setSbBottom ] = useState(false);
  const [ sbIsOpen, setSbIsOpen ] = useState(false);
  const [ tbIsOpen, setTopBarIsOpen ] = useState(false);

  const searchBarCntnrRef = useRef(null);
  const sbChildrenRef = useRef(null);
  const topChildrenRef = useRef(null);

  useLayoutEffect(() => {
    if (sbChildrenRef.current && permEval) {
      if (dimensions === null) {
        setDimensions({
          h: sbChildrenRef.current.offsetHeight,
          w: sbChildrenRef.current.offsetWidth,
        });
      };

      if (canBeCollapsed) {
        sbChildrenRef.current.style.height = "0px";
        sbChildrenRef.current.setAttribute("data-collapsed", "true");
      };
      topChildrenRef.current.style.height = "0px";
      topChildrenRef.current.setAttribute("data-collapsed", "true");
    };
  }, [permEval]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', onScroll);
    };
  }, [ sbHeigth ]);

  const updateSize = () => {
    setSbHeigth(searchBarCntnrRef.current.clientHeight);
  };

  const onScroll = () => {
    let y = searchBarCntnrRef.current.getBoundingClientRect().y;
    y <= 0 - sbHeigth
      ? setSbBottom(true)
      : setSbBottom(false);
  };

  useEffect(() => {
    if (!sbBottom) {
      collapse(topChildrenRef);
    };
  }, [ sbBottom ]);

  const collapse = (elem) => {
    if (elem.current && dimensions) {
      const transition = elem.current.style.transition;
      elem.current.style.transition = "";

      requestAnimationFrame(function() {
        elem.current.style.height = dimensions.h + "px";
        elem.current.style.transition = transition;

        requestAnimationFrame(function() {
          elem.current.style.height = 0 + "px";
        });
      });

      elem.current.setAttribute("data-collapsed", "true");
      elem.current.classList.remove("open");
    };
  };

  const expand = (elem) => {
    if (elem.current) {
      elem.current.style.height = dimensions.h + "px";

      elem.current.addEventListener("transitionend", function anFr(e) {

        elem.current.removeEventListener("transitionend", anFr);
        elem.current.style.height = null;
      });

      elem.current.setAttribute("data-collapsed", "false");
    };
  };

  const handleToggleFixedBar = () => {
    const elem = sbChildrenRef;
    const isCollapsed = elem.current.getAttribute("data-collapsed") === "true";
    if (isCollapsed) {
      expand(elem);
      elem.current.setAttribute("data-collapsed", "false");
      setTimeout(() => {
        elem.current.classList.add("open");
        updateSize();
      }, 300);
    } else {
      collapse(elem);
      setTimeout(() => {
        updateSize();
      }, 300);
    };
    setSbIsOpen(!sbIsOpen);
  };

  const handleToggleTopBar = () => {
    const elem = topChildrenRef;
    const isCollapsed = elem.current.getAttribute("data-collapsed") === "true";
    if (isCollapsed) {
      expand(elem);
      elem.current.setAttribute("data-collapsed", "false");
      setTimeout(() => {
        elem.current.classList.add("open");
        updateSize();
      }, 300);
    } else {
      elem.current.classList.remove("open");
      collapse(elem);
      setTimeout(() => {
        updateSize();
      }, 300);
    };
    setTopBarIsOpen(!tbIsOpen);
  };

  return (
    <>
      <SearchBarCntnr
        ref={searchBarCntnrRef}
        hasTag={hasTag}
        className={sbIsOpen ? "open" : "close"}
      >
        {canBeCollapsed && (
          <Button onClick={() => handleToggleFixedBar()} className="btn-display-children">
            {sbIsOpen ? "Rabattre les filtres" : "Afficher les filtres"}
          </Button>
        )}
        <SearchBarChildren ref={sbChildrenRef} className={!canBeCollapsed && "open"}>
          <div className="sbChildrenCntnr">
            {children}
          </div>
        </SearchBarChildren>
      </SearchBarCntnr>

      <TopBarCntnr
        className={sbBottom ? "visible" : ""}
      >
        <SearchBarChildren
          ref={topChildrenRef}
          data-collapsed="true"
        >
          <div className="sbChildrenCntnr">
            {children}
          </div>
        </SearchBarChildren>
        <Actions>
          <button
            className={`${tbIsOpen ? "open" : "close"}`}
            onClick={() => handleToggleTopBar()}
          >
            <FilterListIcon /> {tbIsOpen ? "Fermer les filtres" : "Ouvrir les filtres"}
          </button>
          {hasTag && tags}
        </Actions>
      </TopBarCntnr>
    </>
  );
};

export default SearchBarCompo;