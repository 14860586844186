import {
  DownloadingSharp,
  FileDownload,
  WarningAmber,
} from '@mui/icons-material';
import styled from 'styled-components';
import http from '../http';
import { triggerDownload } from '../utils';
import { toast } from 'react-toastify';
import Button from '../ui/Button';

export default function DocError({ document, bilanId }) {
  const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
  `;

  const P16 = styled.p`
    color: black;
    font-size: 16px;
    margin: 0;
  `;

  const P14 = styled.p`
    color: black;
    font-size: 14px;
    margin: 0;
  `;

  const ButtonStyled = styled(Button)`
    display: flex;
    gap: 5px;
  `;

  const handleDownloadPdf = async (id, kind) => {
    try {
      try {
        const response = await fetch(document.public_url);
        const pdfData = await response.blob();
        await triggerDownload(`${document.fileName}-${kind}.pdf`, pdfData);
      } catch (err) {
        console.error(err);
        toast.error(
          `Impossible de télécharger le refus PDF de l'intervention.`
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <WarningAmber
        style={{
          color: 'red',
          fontSize: '50px',
        }}
      />
      <Col>
        <P16>{"Le document n'a pas pu être chargé."}</P16>
        <P16>{"Vous pouvez contacter le support pour plus d'informations"}</P16>
      </Col>
      <ButtonStyled
        onClick={() => handleDownloadPdf(document.id, document.kind)}
        data-sentry-id='download-error-pdf'
      >
        <P14>{'Télécharger le document'}</P14>
        <FileDownload />
      </ButtonStyled>
    </Container>
  );
}
