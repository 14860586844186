import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import http from '../../http';
import { isAbortError } from '../../utils';
import { DateTime } from 'luxon';
import CurrentUserContext from '../../CurrentUserContext';
import { utils as syoUtils } from '@nfsave/syo-bilan';

import Card from '../../ui/Card';
import LoadingSpinner from '../../ui/LoadingSpinner';
import PageHeader, { Actions, Title } from '../../ui/PageHeader';
import Button from '../../ui/Button';
import theme from '../../ui/theme';
import LoaderBarContext from '../../ui/useLoaderBar';
import { LinkButton } from '../../ui/Button';

const HeaderRow = styled.li`
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

const MobilList = styled.ul`
  padding: ${theme.small};
  margin-bottom: 0;
`;

const MobilCard = styled(Card)`
  max-width: none;
  min-width: unset;
  margin-bottom: ${theme.small};
  padding: ${theme.thin};
  border: none;
  position: relative;
  cursor: auto;
  &:hover {
    transform: none;
    box-shadow: ${theme.boxShadowLarge};
  }
`;

const MobilRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  &.tab {
    margin-top: ${theme.thin};
  }

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Lead = styled.div`
  font-size: 1.1rem;
`;

const Muted = styled.div`
  font-size: 0.9rem;
  color: #888;
`;

const Column = styled.div`
  padding: 0.25rem 1rem;

  @media (min-width: 1024px) {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.5rem;
    min-width: 0;

    & > ${Lead}, & > ${Muted} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 1023px) {
    &:empty {
      display: none;
    }

    ${Lead} {
      display: inline;
    }

    ${Muted} {
      display: inline;

      :before {
        content: ' (';
      }
      :after {
        content: ')';
      }
    }
  }
`;

const DateColumn = styled(Column)`
  flex-basis: 10%;
`;

const NatureColumn = styled(Column)`
  flex-basis: 30%;
`;

const LocationColumn = styled(Column)`
  flex-basis: 60%;
`;

const SplitColumn = styled(Column)`
  flex-basis: 50%;
`;

const Table = styled.table`
  width: 100%;

  & th,
  & td {
    width: 33%;
  }

  &.right {
    border-left: solid 2px white;
    & th:first-child,
    & td:first-child {
      padding-left: ${theme.thin};
    }
  }
`;

const Mobilizations = () => {
  const { setLoaderBar } = useContext(LoaderBarContext);
  const { currentUser } = useContext(CurrentUserContext);

  const {
    data: mobilizations,
    isLoading: mobilizationsIsLoading,
    refetch,
  } = useQuery(
    ['mobilizations'],
    async () => {
      setLoaderBar(true);
      return await http
        .get('mobilisations.json')
        .json()
        .then(res => {
          return res.data;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          toast.warn(`Une erreur est survenue`);
          console.error(error);
        })
        .finally(() => {
          setLoaderBar(false);
        });
    },
    {}
  );

  const handleCloseMobilization = async (mobilSerial, dispatchPublicId) => {
    try {
      setLoaderBar(true);
      await http.delete(`mobilisations/${mobilSerial}/dispatches/${dispatchPublicId}`);
      await refetch();
    } catch (e) {
      console.error(e);
      toast.warn(`Une erreur est survenue`);
    } finally {
      setLoaderBar(false);
    }
  };

  const getGender = gender => {
    switch (gender) {
      case 1:
        return 'Homme';
      case 2:
        return 'Femme';
      default:
        return 'Indéterminé';
    }
  };

  const getAge = (age, unit) => {
    if (age === null) return "Pas d'âge renseigné";
    switch (unit) {
      case 1:
        return `${age} jours`;
      case 2:
        return `${age} mois`;
      default:
        return `${age} ans`;
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Mobilisations</Title>
        <Actions>
          {syoUtils.hasPerm(currentUser, 'web:mobilisations:create') && (
            <LinkButton to={`/interventions/mobilisations/declenchement`}>Déclencher une mobilisation</LinkButton>
          )}
        </Actions>
      </PageHeader>

      {mobilizationsIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <MobilList>
          <HeaderRow>
            <MobilRow>
              <NatureColumn>
                <Lead>Motif d'intervention</Lead>
              </NatureColumn>
              <LocationColumn>
                <Lead>Adresse</Lead>
              </LocationColumn>
              <DateColumn>
                <Lead>Date</Lead>
              </DateColumn>
            </MobilRow>
            <MobilRow>
              <SplitColumn>Véhicules</SplitColumn>
              <SplitColumn>Victimes</SplitColumn>
            </MobilRow>
          </HeaderRow>
          {mobilizations.length > 0 ? (
            <>
              {mobilizations.map(
                mobilItem =>
                  mobilItem.mobilised_at !== null && (
                    <MobilCard key={mobilItem.id}>
                      <MobilRow>
                        <NatureColumn>
                          <Lead>{mobilItem.nature}</Lead>
                        </NatureColumn>
                        <LocationColumn>
                          <Lead>
                            {mobilItem.location_address?.toLowerCase()}, {mobilItem.location_city_insee}{' '}
                            {mobilItem.location_city_name}
                          </Lead>
                        </LocationColumn>
                        <DateColumn>
                          <Lead>
                            {DateTime.fromISO(mobilItem.mobilised_at)
                              .setLocale('fr-fr')
                              .toLocaleString(DateTime.DATETIME_MED)}
                          </Lead>
                        </DateColumn>
                      </MobilRow>
                      {(mobilItem.dispatches.length > 0 || mobilItem.victims.length > 0) && (
                        <MobilRow className='tab'>
                          <SplitColumn>
                            {mobilItem.dispatches.length > 0 && (
                              <Table>
                                <thead>
                                  <tr>
                                    <th colSpan={3}>Véhicules</th>
                                  </tr>
                                  <tr>
                                    <th>
                                      <Muted>Nom véhicule</Muted>
                                    </th>
                                    <th>
                                      <Muted>Dispatché à</Muted>
                                    </th>
                                    <th>
                                      <Muted>Retourné à</Muted>
                                    </th>
                                    <th>
                                      <Muted style={{ width: '100px' }}>Action</Muted>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mobilItem.dispatches.map(dispatch => (
                                    <tr key={dispatch.id}>
                                      <td>{dispatch.vehicle_name !== null ? dispatch.vehicle_name : '--'}</td>
                                      <td>
                                        {dispatch.dispatched_at !== null
                                          ? DateTime.fromISO(dispatch.dispatched_at)
                                              .setLocale('fr-fr')
                                              .toLocaleString(DateTime.DATETIME_MED)
                                          : '--'}
                                      </td>
                                      <td>
                                        {dispatch.returned_at !== null
                                          ? DateTime.fromISO(dispatch.returned_at)
                                              .setLocale('fr-fr')
                                              .toLocaleString(DateTime.DATETIME_MED)
                                          : '--'}
                                      </td>
                                      <td>
                                        {dispatch.returned_at === null ? (
                                          <Button
                                            onClick={() =>
                                              handleCloseMobilization(mobilItem.serial, dispatch.public_id)
                                            }
                                          >
                                            Clôturer
                                          </Button>
                                        ) : (
                                          <span style={{ width: '100px' }}>--</span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                          </SplitColumn>
                          <SplitColumn>
                            {mobilItem.victims.length > 0 && (
                              <Table className='right'>
                                <thead>
                                  <tr>
                                    <th colSpan={2}>Victimes</th>
                                  </tr>
                                  <tr>
                                    <th>
                                      <Muted>Sexe, age</Muted>
                                    </th>
                                    <th>
                                      <Muted>Nom de famille</Muted>
                                    </th>
                                    <th>
                                      <Muted>Prénom</Muted>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mobilItem.victims.map(victim => (
                                    <tr key={victim.id}>
                                      <td>
                                        {getGender(victim.gender)}, {getAge(victim.age, victim.age_unit)}
                                      </td>
                                      <td>{victim.family_name || '--'}</td>
                                      <td>{victim.given_name?.toLowerCase() || '--'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                          </SplitColumn>
                        </MobilRow>
                      )}
                    </MobilCard>
                  )
              )}
            </>
          ) : (
            <p>Aucune mobilisation</p>
          )}
        </MobilList>
      )}
    </>
  );
};

export default Mobilizations;
