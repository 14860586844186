import styled from "styled-components";

const FormGroup = styled.div`
  padding: 0 0 1rem;
`;

export const FormGroupRow = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * UPDATE:
 * PaddingFail
 * Changement du padding en margin?
 * Fichier concerné:
 * Auth -> password-reset - phone-reset
 */

export default FormGroup;
