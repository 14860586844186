export class MobilisationVictimClass {
  // // Le genre de la personne à prendre en charge (1 : homme, 2 : femme)
	// gender?: 1 | 2 | null;
	// // Le prénom de la personne à prendre en charge
	// given_name?: string;
	// // Le nom de famille de la personne à prendre en charge
	// family_name?: string;
	// // La date de naissance de la personne à prendre en charge
	// birth_date?: string;
	// // L'âge de la personne à prendre en charge
	// age?: number;
	// // L'unité de l'âge renseigné de la personne à prendre en charge (1 : jours, 2 : mois, 3 : ans)
	// age_unit?: 1 | 2 | 3 | null;

  constructor() {
		this.gender = null;
		this.given_name = undefined;
		this.family_name = undefined;
		this.birth_date = undefined;
		this.age = undefined;
		this.age_unit = null;
	}
}
